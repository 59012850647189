import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input[selectOnClick],textarea[selectOnClick]'
})
export class SelectTextDirective {
  constructor() {}

  @HostListener('click', ['$event.target'])
  onClick(target) {
    target.select();
  }
}
