const commonEndpoint = 'https://beta-api.pokerleaderboards.com';

export const environment = {
  production: true,
  AUTHENTICATION_API: commonEndpoint,
  AUTHORIZATION_API: commonEndpoint,
  USERS_API: commonEndpoint,
  ADS_API: commonEndpoint,
  AVATARS_API: commonEndpoint,
  DEALERS_API: commonEndpoint,
  EVENTS_API: commonEndpoint,
  LEADERBOARDS_API: commonEndpoint,
  LEAGUES_API: commonEndpoint,
  MESSAGES_API: commonEndpoint,
  PAYMENTS_API: commonEndpoint,
  PLAYERS_API: commonEndpoint,
  REGIONS_API: commonEndpoint,
  REPORTS_API: commonEndpoint,
  SCORING_API: commonEndpoint,
  SEASONS_API: commonEndpoint,
  TOURNAMENTS_API: commonEndpoint,
  VENUES_API: commonEndpoint,
  DATE_FORMAT: 'MM/dd/yyyy',
  DATETIME_FORMAT: 'MM/dd/yyyy HH:mm:ss',
  DATEPICKER_FORMAT: 'MM/DD/YYYY',
  EXECUTIVE_COST: 97.0,
  EXECUTIVE_RENEWAL_COST: 97.0,
  EXECUTIVE_ITEM: 'Executive Membership Upgrade - Tournaments for 1 Year',
  EXECUTIVE_RENEWAL_ITEM: 'Executive Membership Renewal - Tournaments for 1 Year'
};
