<div class="col-12">
  <div class="card card-leaderboard-details">
    <div class="card-header">
    Points Calculator <span class="calculator-header">(APC Default Scoring System)</span>
    </div>
    <div class="card-block recent-winners">
      <div class="points-calculator-selection-logic">
        <div class="points-calculator-selection-padding">
          <div class="points-calculator-row">
            <div class="col-4">
              <mat-form-field class="editor-mat-field">
                <input required matInput type="text" [ngModel]="numEntries" placeholder="Total # Players"
                  (ngModelChange)="numPlayersChanged($event)" />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="editor-mat-field">
                <mat-select required placeholder="Type" [(ngModel)]="tournamentType" (selectionChange)="tournamentTypeChanged()">
                  <mat-option *ngFor="let type of tournamentTypes" [value]="type.code">{{type.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="points-calculator-row">
            <div class="col-12">

            </div>

          </div>
        </div>
      </div>
      <div class="points-calculator-data">
        <table rules="rows" class="lightTable">
          <thead>
            <tr style="background-color:#d8d5de; font-weight: bold">
              <td align="left">Place
              </td>
              <td align="right">Points
              </td>
              <td align="right">Payout Percent
              </td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payout of payouts">
              <td align="left">
                {{payout.place| number}}
              </td>
              <td align="right">
                {{payout.points_awarded| number: '1.2-2'}}
              </td>
              <td align="right">
                {{payout.payout_percent | percent:'1.2-2'}}
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="leaderboard-brand-logic">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
