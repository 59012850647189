import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NglModule } from 'ng-lightning/ng-lightning';
import {
  AccordionModule,
  BsDatepickerModule,
  CollapseModule,
  PaginationModule
} from 'ngx-bootstrap';
import { LoggerModule, NgxLoggerLevel, NGXLogger } from 'ngx-logger';

import { environment } from '../environments/environment';

import { CompareValidator } from './_validators/compare.validator';
import { DateValidator } from './_validators/date.validator';
import { UniqueEmailValidator } from './_validators/unique-email.validator';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UncaughtExceptionHandler } from './components/uncaught-error.handler';
import { AppStorage } from './models/app.storage';
import { PublicModule } from './public/public.module';
import { SecureModule } from './secure/secure.module';
import { SystemModule } from './secure/system/system.module';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { LoaderService } from './services/spinner.service';

// Set the level of the nameless loggr to WARN, so only log items
// with severity WARN or higher get logged.
// const ajaxAppender = JL.createAjaxAppender('ajaxAppender');
// const consoleAppender = JL.createConsoleAppender('consoleAppender');
// if (environment.production) {
//  JL().setOptions({ 'level': JL.getWarnLevel() });
// JL().setOptions({ 'appenders': [ajaxAppender] });
// } else {
// JL().setOptions({ 'level': JL.getTraceLevel(), enabled: false });
// JL().setOptions({ 'appenders': [consoleAppender] });
// }

@NgModule({
  declarations: [
    // components.  Stuff that can be put on the page <my-component></my-component>
    AppComponent
  ],
  imports: [
    // i think this is ONLY for stuff in this particular module (not submodules)\
    NglModule.forRoot(),
    AppRoutingModule,
    PublicModule,
    SecureModule,
    SystemModule,
    BrowserModule,
    HttpClientModule,
    AccordionModule.forRoot(),
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: environment.production ? NgxLoggerLevel.INFO : NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR
    })
  ],
  exports: [],
  providers: [
    // injectable stuff.  services, interceptors, etc.
    // all this is common to all modules in scope.
    AuthService,
    AppStorage,
    CompareValidator,
    DateValidator,
    LoaderService,
    TokenService,
    UniqueEmailValidator,
    NGXLogger,
    { provide: ErrorHandler, useClass: UncaughtExceptionHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
