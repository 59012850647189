import { OnInit, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UUID } from '../../models/uuid';

@Component({
  templateUrl: './ad-iframe.html'
})
export class AdIframeComponent implements OnInit {
  public adId: UUID;
  public leagueId: UUID;
  public location: string;

  constructor(protected route: ActivatedRoute) {}

  public ngOnInit() {
    if (this.route.snapshot.queryParamMap.get('league_id')) {
      this.leagueId = new UUID(this.route.snapshot.queryParamMap.get('league_id'));
    }
    if (this.route.snapshot.queryParamMap.get('location')) {
      this.location = this.route.snapshot.queryParamMap.get('location');
    }
    if (this.route.snapshot.queryParamMap.get('ad_id')) {
      this.adId = new UUID(this.route.snapshot.queryParamMap.get('ad_id'));
    }
  }
}
