<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
    </div>
  </div>
</div>
<div class="col-12">
<div class="card report-details-card center">
    <div class="card-block">
      <div class="col-12">
        <div class="report_parameters">
          <span class="report-header-title">{{ data?.user.first_name }} {{data?.user.last_name}}</span>
        </div>
        <div class="report_header_main">
          <h4>
            <span>Payroll Report</span>
          </h4>
          <div class="report_range">
            <span>{{ model?.start_date | date }} to {{ model?.end_date | date }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
    Employee Details
    </div>
    <div class="card-block breakdown-card">
      <div class="col-12">
        <table>
          <thead>
            <tr class="report_header_row report_rows">
              <td style="text-align: left; width: 15%;" scope="col">Date</td>
              <td style="text-align: left; width: 45%;" scope="col">Venue</td>
              <td style="text-align: left; width: 25%;" scope="col">Position</td>
              <td style="text-align: left; width: 15%;" scope="col">Pay</td>
            </tr>
            </thead>
            <tbody>
            <tr class="report_item_row report_rows" *ngFor="let row of data?.tournaments">
              <td style="text-align: left;">{{ row.start_date | date}}</td>
              <td style="text-align: left;">{{ row.venue_name }}</td>
              <td style="text-align: left;">{{ row.role }}</td>
              <td style="text-align: left;">{{ row.amount_owed | currency}}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
  <div class="card-footer">
    Total Pay: {{ data?.total_pay | currency }}
  </div>
  </div>
</div>






<div class="card leaderboard-display">
  <div class="card-header">
Report Details
  </div>
  <div class="card-block">
    <div style="font-size: 12px">
      <table border="0" cellpadding="0" cellspacing="0" class="lbHeader">
        <tr>
          <th>Employee Name:</th>
          <td> {{ data?.user.first_name }} {{data?.user.last_name}} </td>
        </tr>
        <tr>
          <th>Start Date:</th>
          <td> {{ model?.start_date | date }} </td>
        </tr>
        <tr>
          <th>End Date:</th>
          <td> {{ model?.end_date | date }} </td>
        </tr>
        <tr>
          <th>Pay Total:</th>
          <td> {{ data?.total_pay | currency }} </td>
        </tr>
      </table>
      <br />
    </div>
    <div>
      <table class="lbGridDefault" cellspacing="0" cellpadding="2" rules="all" border="1" id="grdDealerDetail" style="border-color:#CCCCCC;font-size:12px;width:600px;border-collapse:collapse;">
        <tbody>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Venue</th>
            <th scope="col">Position</th>
            <th scope="col">Pay</th>
          </tr>
          <tr class="lbMainRow" *ngFor="let row of data?.tournaments">
            <td align="right" style="width:50px;">{{ row.start_date | date }}</td>
            <td align="left" style="width:250px;">{{ row.venue_name }}</td>
            <td align="left" style="width:250px;">
              {{ row.role }}</td>

            <td align="right" style="width:50px;">
              {{ row.amount_owed | currency}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      </div>
</div>
