import { DatePipe } from '@angular/common';
import { Component, ComponentFactoryResolver, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of, Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { YesNoPipe } from '../../_pipes/yes-no.pipe';
import { NewReportDialogComponent } from '../../components/dialogs/new-report/new-report.dialog.component';
import { PageableComponent } from '../../components/pageable.component';
import { Ad } from '../../models/ad';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';
import { Venue } from '../../models/venue';
import { AdService } from '../../services/ad.service';
import { LeagueService } from '../../services/league.service';
import { ReportService } from '../../services/report.service';
import { GetVenuesResponse } from '../../services/responses/venue.responses';
import { LoaderService } from '../../services/spinner.service';
import { VenueService } from '../../services/venue.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();
  datePipe: DatePipe = new DatePipe('en-US');
  _propertySubscription: Subscription;
  leagues: League[] = [];
  venues: Venue[] = [];
  ads: Ad[] = [];

  constructor(
    protected leagueStorage: LeagueStorage,
    private dialog: MatDialog,
    protected reportService: ReportService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private permissionManager: PermissionManager,
    private leagueService: LeagueService,
    private venueService: VenueService,
    private adService: AdService,
    private loaderService: LoaderService
  ) {
    super(reportService, leagueStorage);
    this.setColumns([
      {
        title: 'Run',
        name: 'run',
        filtering: false,
        render: (value, row) => {
          return '<a><i class="far fa-play-circle"></i></a>';
        }
      },
      {
        title: 'Created',
        name: 'created_at',
        sortIndex: 1,
        sort: 'desc',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATETIME_FORMAT);
        }
      },
      {
        title: 'Type',
        name: 'report_type',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Start',
        name: 'start_date',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'End',
        name: 'end_date',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'League',
        name: 'ParamLeague.name',
        filtering: { filterString: '', placeholder: 'Filter' },
        defaultValue: '(all)'
      },
      {
        title: 'Venue',
        name: 'ParamVenue.name',
        filtering: { filterString: '', placeholder: 'Filter' },
        defaultValue: (column, row) => {
          switch (row.report_type) {
            case 'tournament':
              return '(all)';
            default:
              return 'n/a';
          }
        }
      },
      {
        title: 'Location',
        name: 'param_ad_location',
        filtering: { filterString: '', placeholder: 'Filter' },
        defaultValue: (column, row) => {
          switch (row.report_type) {
            case 'ads':
              return '(all)';
            default:
              return 'n/a';
          }
        }
      },
      {
        title: 'Ad',
        name: 'ParamAd.name',
        filtering: { filterString: '', placeholder: 'Filter' },
        defaultValue: (column, row) => {
          switch (row.report_type) {
            case 'ads':
              return '(all)';
            default:
              return 'n/a';
          }
        }
      },
      {
        title: 'Summary',
        name: 'param_is_summary',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      },
      {
        title: 'Delete',
        name: 'delete',
        filtering: false,
        render: (value, row) => {
          return '<i class="fas fa-trash delete-report"></i>';
        }
      }
    ]);
  }

  getLeagues(): Observable<League[]> {
    // get all the leagues the user has access to.
    // if an admin, they have access to everything, so go retrieve all leagues
    // otherwise, grab them from the current list of leagues
    if (this.permissionManager.isAdmin) {
      // go get leagues
      return this.leagueService
        .getPublicLeagues({
          select: 'id,name',
          'order_by[name]': 'asc',
          pageSize: 100000
        })
        .pipe(map(response => response.leagues));
    } else {
      return of([this.league]);
      // return this.leagueStorage.getLeagues();
    }
  }

  openNewReport() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      leagues: this.leagues,
      venues: this.venues,
      ads: this.ads,
      league: this.league,
      adLocations: AdService.AD_LOCATIONS
    };
    this.dialog
      .open(NewReportDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.refreshTable();
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();

    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        forkJoin([
          this.getLeagues(),
          this.venueService.getVenues({
            select: 'id,name',
            'order_by[name]': 'asc',
            pageSize: 100000
          }),
          this.adService.getAds({
            select: 'id,name',
            'order_by[name]': 'asc',
            pageSize: 100000
          })
        ]).subscribe(([leagues, venuesResponse, adsResponse]) => {
          leagues.sort((a, b) => a.name.localeCompare(b.name));
          this.leagues = leagues;
          this.venues = venuesResponse.venues;
          this.ads = adsResponse.ads;
        });
      }
    });
  }

  public onCellClick(data: any): any {
    if (data.column === 'run') {
      this.router.navigate([data.row.id], { relativeTo: this.activatedRoute });
    } else if (data.column === 'delete') {
      if (confirm(`Are you sure you want to delete this report?`)) {
        this.loaderService.displayLoader(true);
        this.reportService
          .deleteReport(data.row.id)
          .pipe(finalize(() => this.loaderService.displayLoader(false)))
          .subscribe(response => {
            this.refreshTable();
          });
      }
    }
  }
}
