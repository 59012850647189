import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { UniqueNameValidator } from '../../../_validators/unique-name.validator';
import { Ad } from '../../../models/ad';
import { AdForm } from '../../../models/forms/ad.form';
import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { UUID } from '../../../models/uuid';
import { AdService } from '../../../services/ad.service';
import { LoaderService } from '../../../services/spinner.service';
import { SystemAdService } from '../../../services/system-ads.service';

@Component({
  selector: 'app-system-ad-detail',
  templateUrl: './system-ad-detail.component.html'
})
export class SystemAdDetailComponent implements OnInit, OnDestroy {
  isNewModel = true;
  _propertySubscription: Subscription;
  myForm: FormGroup;
  submitted = false;
  model = new Ad();
  league: League;
  locations = AdService.AD_LOCATIONS;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: SystemAdService,
    private uniqueNameValidator: UniqueNameValidator,
    protected leagueStorage: LeagueStorage,
    private route: ActivatedRoute
  ) {
    this.myForm = fb.group({
      id: '',
      name: ['', Validators.required, uniqueNameValidator.create().bind(this)],
      ad_text: ['', Validators.required],
      location: ['', Validators.required],
      is_active: true
    });
  }

  // handy access to form fields
  get name(): FormControl {
    return this.myForm.get('name') as FormControl;
  }
  get is_active(): FormControl {
    return this.myForm.get('is_active') as FormControl;
  }
  get ad_text(): FormControl {
    return this.myForm.get('ad_text') as FormControl;
  }
  get location(): FormControl {
    return this.myForm.get('location') as FormControl;
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getAd();
      }
    });
  }

  getAd(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getAd(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = response.ad; // only used in the Name check, to allow the original name to work.
        this.resetForm(this.model);
      });
    }
  }

  public submitAndClose(value: AdForm) {
    this._submit(value, (model: Ad) => {
      this.router.navigate(['/system/ads']);
    });
  }

  public submit(value: AdForm) {
    this._submit(value, (model: Ad) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/system/ads/' + model.id]);
    });
  }

  private resetForm(ad: Ad) {
    this.myForm.reset();
    this.myForm.setValue(new AdForm(ad), { onlySelf: true });
  }

  public _submit(value: AdForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createAd(value)
        : this.service.updateAd(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.ad);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.name
        }? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteAd(this.model.id)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/system/ads']);
        });
    }
  }
}
