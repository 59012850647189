import { Scoring } from '../scoring';
import { UUID } from '../uuid';
export class ScoringForm {
  public id: UUID;
  public name: string;
  public payout_type: number;
  public total_points: number;
  public positions_paid: number;
  public is_active: boolean;
  public payouts: {
    finish_position: number;
    points: number;
  }[];
  constructor(model: Scoring) {
    this.id = model.id;
    this.name = model.name;
    this.total_points = model.total_points;
    this.positions_paid = model.positions_paid;
    this.is_active = model.is_active;

    if (model.payouts && model.payouts.length > 0) {
      this.payout_type = model.payouts[0].payout_type;
      this.payouts = model.payouts.map(payout => {
        return {
          finish_position: payout.finish_position,
          points: payout.payout_type ? payout.percent_points_awarded : payout.points_awarded
        };
      });
    }
  }
}
