import { Player } from '../player';
import { UUID } from '../uuid';
export class PlayerForm {
  public id: UUID;
  public name: string;
  public email?: string;
  public is_active: boolean;
  public notes?: string;
  public membership_type?: string;
  public is_membership: boolean;
  // public is_12_month_package: boolean;
  // public is_first_6_month_package: boolean;
  // public is_second_6_month_package: boolean;
  public start_date?: Date;
  public end_date?: Date;
  // public qualified_date?: Moment;
  public is_invited_main_event: boolean;

  constructor(model: Player) {
    this.id = model.id;
    this.name = model.name;
    this.email = model.email;
    this.is_active = model.is_active;
    this.notes = model.notes;
    this.membership_type = model.membership_type;
    this.is_membership = model.is_membership;
    // this.is_12_month_package = model.is_12_month_package;
    // this.is_first_6_month_package = model.is_first_6_month_package;
    // this.is_second_6_month_package = model.is_second_6_month_package;
    this.start_date = model.start_date == null ? null : new Date(model.start_date.toString());
    this.end_date = model.end_date == null ? null : new Date(model.end_date.toString());
    // this.qualified_date = model.qualified_date;
    this.is_invited_main_event = model.is_invited_main_event;
  }
}
