
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NumericValidator } from '../../_validators/numeric.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { ScoringForm } from '../../models/forms/scoring.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Scoring } from '../../models/scoring';
import { UUID } from '../../models/uuid';
import { ScoringService } from '../../services/scoring.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  selector: 'app-scoring-detail',
  templateUrl: './scoring-detail.component.html',
  styleUrls: ['./scoring-detail.component.css']
})
export class ScoringDetailComponent implements OnInit, OnDestroy {
  isNewModel = true;
  _propertySubscription: Subscription;
  myForm: FormGroup;
  submitted = false;
  model = new Scoring();
  league: League;
  isLeagueScoring = true;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: ScoringService,
    private numericValidator: NumericValidator,
    private uniqueNameValidator: UniqueNameValidator,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute
  ) {
    this.myForm = fb.group({
      id: '',
      name: ['', Validators.required, uniqueNameValidator.create().bind(this)],
      total_points: ['', numericValidator.create({ min: 0, minInclusive: false })],
      positions_paid: [3, numericValidator.create({ min: 1, max: 1000, integerOnly: true })],
      is_active: true,
      payout_type: 0, // 0 for points, 1 for percent
      payouts: fb.array([])
    });
  }

  // handy access to form fields
  get name(): FormControl {
    return this.myForm.get('name') as FormControl;
  }
  get total_points(): FormControl {
    return this.myForm.get('total_points') as FormControl;
  }
  get positions_paid(): FormControl {
    return this.myForm.get('positions_paid') as FormControl;
  }
  get is_active(): FormControl {
    return this.myForm.get('is_active') as FormControl;
  }
  get payout_type(): FormControl {
    return this.myForm.get('payout_type') as FormControl;
  }
  get payouts(): FormArray {
    return this.myForm.get('payouts') as FormArray;
  }

  private setPayouts(desired: number) {
    const numNow: number = this.payouts.length;

    if (numNow < desired) {
      // we have too few, add more until we reach the desired number
      for (let j: number = numNow; j < desired; j++) {
        const payout = this.fb.group({
          finish_position: [j + 1, this.numericValidator.create({ min: 1, integerOnly: true })],
          points: ['', this.numericValidator.create({ allowNulls: false })]
        });

        this.payouts.push(payout);
      }
    } else if (numNow > desired) {
      // we have too many, remove from the end until we reach the desired number
      // const numToRemove = numNow - desired;
      for (let j: number = numNow; j > desired; j--) {
        this.payouts.removeAt(j - 1);
      }
    }
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getScoring();
      }
    });
  }

  getScoring(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getScoring(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = response.scoring; // only used in the Name check, to allow the original name to work.
        if (this.model.league_id) {
          this.resetForm(this.model);
        } else {
          this.myForm.patchValue({ name: this.model.name });
          this.isLeagueScoring = false;
        }
      });
    } else {
      this.setPayouts(3);
    }
  }

  public changePositionsPaid(val) {
    if (this.positions_paid.valid) {
      this.setPayouts(this.positions_paid.value);
    }
  }

  public submitAndClose(value: ScoringForm) {
    this._submit(value, (model: Scoring) => {
      this.router.navigate(['/admin/scoring']);
    });
  }

  public submit(value: ScoringForm) {
    this._submit(value, (model: Scoring) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/scoring/' + model.id]);
    });
  }

  private resetForm(scoring: Scoring) {
    this.myForm.reset();
    const formValue = new ScoringForm(scoring);
    this.setPayouts(formValue.positions_paid);

    this.myForm.setValue(formValue, { onlySelf: true, emitEvent: true });
  }

  public _submit(value: ScoringForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createScoring(value)
        : this.service.updateScoring(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.scoring);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.name
        }? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteScoring(this.model.id).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/scoring']);
        });
    }
  }
}
