import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { keys, without } from 'lodash';
import { forkJoin } from 'rxjs';

import { PageableComponent } from '../../components/pageable.component';
import { Season } from '../../models/season';
import { Venue } from '../../models/venue';
import { GetPagedResponse } from '../../services/responses/generic.responses';
import { SeasonService } from '../../services/season.service';
import { VenueDetailService } from '../../services/venue-detail.service';
import { VenueService } from '../../services/venue.service';

@Component({
  templateUrl: './venue-detail.component.html',
  styleUrls: ['./venue-detail.component.css']
})
export class VenueDetailComponent extends PageableComponent implements OnInit, OnDestroy {
  loading = true;
  foundData = false;
  season: Season;
  venue: Venue;
  private myColumns: any = [
    { title: 'POS', name: 'position', sort: 'asc' },
    { title: 'NAME', name: 'player_name' },
    { title: 'POINTS', name: 'points' }
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected venueDetailService: VenueDetailService,
    private venueService: VenueService,
    private seasonService: SeasonService
  ) {
    super(venueDetailService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    const seasonId: any = this.route.snapshot.queryParamMap.get('season_id');
    const venueId: any = this.route.snapshot.queryParamMap.get('venue_id');

    this.setDefaultParams({ seasonId, venueId });
    forkJoin([
      this.venueService.getPublicVenue(venueId),
      this.seasonService.getPublicSeason(seasonId)
    ]).subscribe(([venueResponse, seasonResponse]) => {
      this.venue = venueResponse.venue;
      this.season = seasonResponse.season;
    });

    this.queryParamObservable.subscribe((data: GetPagedResponse) => {
      // gives us a chance to add more columns.
      if (data.data.length > 0 && !this.foundData) {
        this.foundData = true; // so we don't have to do this again.
      }
      const theKeys = keys(data.data[0]);
      const cols = without(theKeys, ...['position', 'player_id', 'player_name', 'points']);
      this.myColumns.push(...cols.map(col => ({ title: col, name: col })));

      this.setColumns(this.myColumns);
      this.loading = false;
    });
    super.ngOnInit();
  }
}
