<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/events">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button mat-menu-item [disabled]="isNewModel" id="btnDelete" type="submit" (click)="onDelete()">
          <mat-icon>delete</mat-icon> Remove Event
        </button>
      </mat-menu>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSaveContinue" type="submit"
        class="action-save-button" (click)="submit(myForm.value)">
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSave" type="submit" class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)">
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>

<form id="form1" [formGroup]="myForm">
  <input type="hidden" formControlName="id" />
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <span class="card-header-edit-label">{{ isNewModel? 'Create' : 'Edit' }} Event</span> {{name.value}}
      </div>
      <div class="card-block">
        <div class="editpanel-column left-edit col-lg-6">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="name" placeholder="Event Name">
              <mat-error *ngIf="name.hasError('required') && !name.pristine">
                <i class="fas fa-times-circle"></i> Event name is required.
              </mat-error>
              <mat-error *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)">
                <i class="fas fa-times-circle"></i> Event name already exists.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-6">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="event_date" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_date"
                placeholder="Event Date" (click)="picker_date.open()" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="picker_date"></mat-datepicker-toggle>
              <mat-datepicker #picker_date></mat-datepicker>
              <mat-error *ngIf="event_date.hasError('date') && !event_date.pristine">
                <i class="fas fa-times-circle"></i> Event date must be a valid date.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-6">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="announce_date" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_announce"
                placeholder="Announce Date" (click)="picker_announce.open()" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="picker_announce"></mat-datepicker-toggle>
              <mat-datepicker #picker_announce></mat-datepicker>
              <mat-error *ngIf="announce_date.hasError('required') && !announce_date.pristine">
                <i class="fas fa-times-circle"></i> Announce date is required.
              </mat-error>
              <mat-error *ngIf="announce_date.hasError('date') && !announce_date.pristine">
                <i class="fas fa-times-circle"></i> Announce date must be a valid date.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-6">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="start_qualify" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_qualify_start"
                placeholder="Qualifying Starts" (click)="picker_qualify_start.open()" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="picker_qualify_start"></mat-datepicker-toggle>
              <mat-datepicker #picker_qualify_start></mat-datepicker>
              <mat-error *ngIf="start_qualify.hasError('required') && !start_qualify.pristine">
                <i class="fas fa-times-circle"></i> Qualifying start is required.
              </mat-error>
              <mat-error *ngIf="start_qualify.hasError('date') && !start_qualify.pristine">
                <i class="fas fa-times-circle"></i> Qualifying start must be a valid date.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-6">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="end_qualify" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_qualify_end"
                placeholder="Qualifying Ends" (click)="picker_qualify_end.open()" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="picker_qualify_end"></mat-datepicker-toggle>
              <mat-datepicker #picker_qualify_end></mat-datepicker>
              <mat-error *ngIf="end_qualify.hasError('required') && !end_qualify.pristine">
                <i class="fas fa-times-circle"></i> End date is required.
              </mat-error>
              <mat-error *ngIf="end_qualify.hasError('date') && !end_qualify.pristine">
                <i class="fas fa-times-circle"></i> End date must be a valid date.
              </mat-error>
              <mat-error *ngIf="myForm.hasError('startLTEEnd') && !end_qualify.pristine">
                <i class="fas fa-times-circle"></i> End date must be after start date.
              </mat-error>
            </mat-form-field>
          </div>

        </div>
        <div class="editpanel-column col-lg-6">
          <div class="card">
            <div class="card-header">
              Criteria
            </div>
            <div class="card-block items-block">
              <div class="col-md-12">
                <div class="itemsrow" formArrayName="event_criteria">
                  <div class="itemrow" [formGroupName]="i" *ngFor="let criteria of event_criteria.controls; let i=index">
                    <input type="hidden" formControlName="rank" />

                    <div class="panelrow col-md-7">
                      <mat-form-field class="editor-mat-field">
                        <mat-select required formControlName="type" placeholder="Type">
                          <mat-option value="#">Top # of players</mat-option>
                          <mat-option value="w">Minimum # of wins</mat-option>
                          <mat-option value="p">Minimum # of points</mat-option>
                        </mat-select>
                        <mat-hint>Select Type</mat-hint>
                        <mat-error *ngIf="criteria.get('type').hasError('required') && !criteria.get('type').pristine">
                          <i class="fas fa-times-circle"></i> Criteria type is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="panelrow col-md-5">
                      <mat-form-field class="editor-mat-field">
                        <input required matInput formControlName="criteria" placeholder="Criteria">
                        <mat-error *ngIf="criteria.get('criteria').hasError('numeric') && !criteria.get('criteria').pristine">
                          <i class="fas fa-times-circle"></i> Positive integer required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div title="Remove" class="position-remove">
                      <div class="panelrow">
                        <button mat-icon-button (click)="removeCriteria(i)">
                          <mat-icon aria-label="Remove Position">highlight_off</mat-icon>
                        </button>
                      </div>
                    </div>


                    <div class="panelrow col-md-7">
                      <mat-form-field class="editor-mat-field">
                        <mat-select required multiple formControlName="coverage_values" (selectionChange)="selectedCoverageChanged(criteria)"
                          placeholder="Coverage">
                          <mat-option value="o">Overall</mat-option>
                          <mat-option value="v">Any Venue</mat-option>
                          <mat-option *ngFor="let venue of venues" [value]="venue.id">{{venue.name}}</mat-option>
                        </mat-select>
                        <mat-hint>Select Event Coverage</mat-hint>
                        <mat-error *ngIf="criteria.get('coverage_values').hasError('required') && !criteria.get('coverage_values').pristine">
                          <i class="fas fa-times-circle"></i> Coverage selection is required.
                        </mat-error>
                        <mat-error *ngIf="criteria.get('coverage_values').hasError('coverage')">
                          <i class="fas fa-times-circle"></i> Select one of: Overall, Any venue, or any combination of
                          specific
                          venues.
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer editor-footer text-muted">
              <button mat-raised-button color="primary" class="gridview-editor-btn" (click)="addCriteria()">
                <i class="fas fa-plus"></i> Add Criteria</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
