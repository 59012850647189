<div class="col-md-12 noselect">
  <div class="loginlogo">
    <img name="Logo" src="/assets/images/login/logo.png" width="100%" height="auto" alt="" />
  </div>
  <form id="loginForm" [formGroup]="myForm" (ngSubmit)="submit(myForm.value)">
    <div class="card text-center">
      <div id="member_login">
        <div class="card-block">
          <div class="panelrow col-md-12">
            <div class="caption" *ngIf="message">
              <span>{{message}}</span>
            </div>
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="reset_hash" placeholder="Reset token">
              <mat-hint>Check email for this one-time code</mat-hint>
              <mat-error *ngIf="reset_hash.hasError('required') && !reset_hash.pristine">
                Reset token is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput type="password" formControlName="password" placeholder="New Password">
              <mat-hint>Password</mat-hint>
              <mat-error *ngIf="password.hasError('required') && !password.pristine">
                New password is required
              </mat-error>
              <mat-error *ngIf="password.hasError('minlength') && !password.pristine">
                Password must be at least 8 characters.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <button mat-raised-button color="primary" class="loginsignup loginbtn gridview-editor-btn" [disabled]="!myForm.valid || submitted"
              type="submit">Reset Password
            </button>
          </div>
        </div>
        <div class="card-footer">
          <div class="login-footer">
            <a routerLink="/login" class="forget_pass forgetpass">Login</a> |
            <a class="create-account" routerLink="/signup">Create a league</a>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="loginprivacy">We value your
    <a style="cursor: pointer;" (click)="openPrivacy()">privacy</a>
  </div>
</div>
