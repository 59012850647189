import { Component, OnChanges } from '@angular/core';

import { Router } from '../../../../node_modules/@angular/router';
import { TableCellComponent } from '../../components/ng2-table/components/table/table-cell.component';

@Component({
  selector: 'app-ad-iframe-cell',
  template: `
<div class="copy-to-clipboard">
  <input selectOnClick readonly style="width: 100%;" ngxClipboard [cbContent]="iframe" [value]="iframe" (cbOnSuccess)="onCopy()">
  <div *ngIf="copied" class="copied-message">Copied!</div>
</div>
  `
})
export class AdIframeComponent extends TableCellComponent {
  // this is needed because we try to only make one component factory per component type
  // after compilation we lose the class name.  This lets us uniquely identify
  // the type of class we are creating.
  static componentName = 'AdIframeComponent';
  url: string;
  iframe = '';
  copied = false;
  constructor(private router: Router) {
    super();
  }

  onChanges() {
    // const endpoint = this.router.createUrlTree(['/banner', this.row.id]);
    // http://localhost:4200/banner?league_id=b895b284-01d1-4958-aef2-c99f0cc0e8d6&ad_id=71e6dc13-2a22-4963-9841-72c44618ba04
    this.url = `${window.location.origin}/banner?league_id=${this.row.league_id}&ad_id=${
      this.row.id
    }`;
    this.iframe = `<iframe src="${this.url}" width="728px" height="98px" frameborder="0"></iframe>`;
  }

  onCopy() {
    this.copied = true;
    setTimeout(() => (this.copied = false), 1000);
  }
}
