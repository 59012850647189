import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
interface Options {
  allowNulls?: boolean;
  min?: number;
  minInclusive?: boolean;
  max?: number;
  maxInclusive?: boolean;
  integerOnly?: boolean;
}
@Injectable()
export class NumericValidator {
  constructor() {}
  public create(options?: Options): ValidatorFn {
    if (!options) {
      options = {};
    }
    if (options.allowNulls === undefined || options.allowNulls === null) {
      options.allowNulls = false;
    }
    if (options.integerOnly === undefined || options.integerOnly === null) {
      options.integerOnly = false;
    }
    if (options.minInclusive === undefined || options.minInclusive === null) {
      options.minInclusive = true;
    }
    if (options.maxInclusive === undefined || options.maxInclusive === null) {
      options.maxInclusive = true;
    }

    if (options.min === undefined || options.min === null) {
      options.min = Number.MIN_VALUE;
    }

    if (options.max === undefined || options.max === null) {
      options.max = Number.MAX_VALUE;
    }

    return (c: FormControl): { [s: string]: boolean } => {
      const val = c.value;

      // allow nulls
      if (options.allowNulls && !val) {
        return null;
      }

      // is it a valid number
      if (isNaN(val - parseFloat(val))) {
        return { numeric: true };
      }

      // is it an integer
      if (options.integerOnly && !/^\+?(0|[1-9]\d*)$/.test(val)) {
        return { numeric: true };
      }

      // is it within range
      // is it min
      if (
        options.min !== Number.MIN_VALUE &&
        ((options.minInclusive && val < options.min) ||
          (!options.minInclusive && val <= options.min))
      ) {
        return { numeric: true };
      }

      if (
        options.max !== Number.MAX_VALUE &&
        ((options.maxInclusive && val > options.max) ||
          (!options.maxInclusive && val >= options.max))
      ) {
        return { numeric: true };
      }

      return null;
    };
  }
}
