<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-new-button" (click)="openNewleague()">
        <mat-icon>note_add</mat-icon> Add New
      </button>
    </div>
  </div>
</div>
<div id="quartz-main-left" class="col-lg-6">
  <div *ngIf="league" class="card">
    <div class="card-header">
      <i class="fas fa-tachometer-alt"></i> Account Info
      <span class="card-header-right">
        <div class="card-membership-iscurrent-header iscurrent">
          <span *ngIf="showBilling" [class.error]="!accountCurrent" class="paragraph">
            {{ accountCurrent ? 'Your account is current!' : 'Your account is due!' }}
          </span>
        </div>
      </span>
    </div>
    <div class="card-block">
      <div *ngIf="showBilling" [class.error]="!accountCurrent" class="paragraph panelrow col-md-12" class="">
        <div class="card-membership-iscurrent-content iscurrent">
          {{ accountCurrent ? 'Your account is current!' : 'Your account is due!' }}
        </div>
      </div>
      <div class="panelrow col-md-12">
        <div class="card-membership-account">
          <div class="dashboard-billing-item">
            <div class="dashboard-panel-heading-sub">
              <i class="fas fa-user-circle"></i> Account Type
            </div>
            <div class="dashboard-panel-heading">
              {{ league.account_type | lowercase | capitalize }}
            </div>
            <a class="exeterms-link" *ngIf="league.account_type == 'EXECUTIVE'" (click)="openExeterms()">(Executive
              Terms)</a>
          </div>
        </div>
      </div>
      <div class="panelrow col-md-12" id="accountDatesRow" *ngIf="billing">
        <div class="dashboard-billing-item">
          <div class="dashboard-panel-heading-sub">
            <i class="fa fa-calendar card-icon"></i> Account Dates:
          </div>
          <div class="dashboard-panel-heading">
            {{ billing.effective_date | date }} -
            <span *ngIf="billing.is_trial">{{billing.grace_date | date }}</span>
            <span *ngIf="!billing.is_trial">{{billing.due_date | date }}</span>

          </div>
        </div>
      </div>
      <div class="panelrow col-md-12">
        <div class="dashboard-panel-heading-sub">
          <i class="fa fa-calendar card-icon"></i> Signup Date
        </div>
        <div class="dashboard-panel-heading created">
          {{ league.created_at | date: 'M/d/yyyy' }}
        </div>
      </div>

      <div class="dashboard-billing-sep"></div>

      <div *ngIf="billing">
        <div *ngIf="billing?.is_due_upcoming && league.account_type == 'EXECUTIVE'"
          class="billing-panel-section alertsection">
          <div class="panelrow col-md-12">
            <div class="dashboard-billing-item">
              <div class="dashboard-panel-heading-sub">
                <i class="fas fa-sync-alt"></i> New Account Dates
              </div>
              <div class="dashboard-panel-heading created">
                {{ billing.due_date | date }} - {{billing.future_due_date | date }}
              </div>
            </div>
          </div>
          <div class="panelrow col-md-12">
            <div class="dashboard-billing-item">
              <div class="dashboard-panel-heading-sub">
                <i class="fas fa-file-invoice-dollar"></i> Amount Due
              </div>
              <div class="dashboard-panel-heading amountdue">
                {{ cost | currency: 'USD':'symbol-narrow':'1.2-2' }}
              </div>
            </div>
            <div class="admin-panel-row bold error">Due By: {{ billing.grace_date | date }}</div>
          </div>
          <div class="admin-panel-row renewal-notice" *ngIf="billing.is_due_immedidately">
            <div class="panelrow col-md-12">
              Your Poker Leaderboards Executive Account is now in the 14 day renewal grace period.
              Your account will be downgraded to a Free account once the grace period expires.
              Please pay your outstanding balance to keep your Executive Account features active.
            </div>
          </div>
          <div class="paypal_due paypal_float">
            <input type="image" id="btnPaypal" src="/assets/images/paypal-button.png" style="border: none;"
              title="PayPal" (click)="startRenewal()" />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="league.account_type !== 'EXEMPT'" class="card-footer text-muted" id="accountFooter">
      <button *ngIf="league.account_type !== 'EXECUTIVE'" mat-button routerLink="../upgrade"
        class="action-upgrade-button">
        <div *ngIf="!settings?.trial_used">
          <mat-icon>next_week</mat-icon> Free Executive Trial!
        </div>
        <div *ngIf="settings?.trial_used">
          <mat-icon>next_week</mat-icon> Upgrade To Executive
        </div>
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header"><i class="fas fa-pencil-alt"></i> League Details</div>
    <div class="card-block card-admin">
      <form id="leagueNameForm" [formGroup]="leagueNameForm" (ngSubmit)="changeLeagueName(leagueNameForm.value)">
        <div class="panelrow col-8">
          <mat-form-field class="editor-mat-field">
            <input required #leagueName="ngForm" matInput [formControl]="leagueNameForm.controls['name']"
              [value]="league?.name" placeholder="League Name" />
            <mat-error *ngIf="leagueName.hasError('required') && !leagueName.pristine">
              League Name is required.
            </mat-error>
            <mat-error *ngIf="
                leagueName.hasError('nameTaken') &&
                !leagueName.pristine &&
                leagueName.value !== league.name
              ">
              League Name is already taken, please choose another.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="panelrow col-4">
          <button mat-button [disabled]="!leagueNameForm.valid || submitted" id="btnSignup" type="submit"
            class="btn btn-success leaguename-save">
            Save
          </button>
        </div>
      </form>

      <div class="panelrow col-md-12 file-drop-logic">
        <div class="dashboard-panel-heading-sub">
          League Logo
        </div>
        <div class="dashboard-inputnotice">
          Logo will show on player profile pages. Square images work best.
        </div>
        <div class="dashboard-leage-details-input-logo">
          <img src="{{ league_logo }}" class="league-logo-preview" *ngIf="league_logo" />
        </div>
        <div *ngIf="fileMessage" class="alert alert-danger ">
          {{fileMessage}}
        </div>
        <file-drop customstyle="my-drop-zone" (onFileDrop)="dropped($event)">
          <span class="drop-zone-content">
            <div class="col-5 drop-zone-info" title="Drop new image file here to upload.">
              <mat-icon>arrow_drop_down_circle</mat-icon> Drop New Logo
            </div>
            <div class="col-7">
              <label for="files" class="file-input-btn">Select a file</label>
              <input id="files" style="display: none" type="file" (change)="fileSelected($event.target.files)" />
            </div>
          </span>
        </file-drop>

        <div></div>
        <div *ngIf="file" class="pending-file-logic">
          <div class="pending-file-icon">
            <i class="far fa-check-circle"></i>
          </div>
          <div class="pending-file-content">
            <div class="profile-panel-heading-sub">
              Pending upload
            </div>
            {{ file.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
      <button mat-raised-button color="primary" class="gridview-editor-btn" (click)="modifyLogo()"
        [disabled]="!file || submitted">
        <mat-icon>cloud_upload</mat-icon> Upload
      </button>
    </div>
  </div>

  <div class="card">
    <div class="card-header"><i class="fas fa-pencil-alt"></i> League Colors</div>
    <div class="card-block card-admin">
      <form id="colorsForm" [formGroup]="colorsForm">
        <div id="sample_player_form" class="profile-sample-form-logic"
          [style.background-color]="background_color.value">
          <table class="leaderboardquartz-lbGridDefault" cellspacing="0" cellpadding="2" rules="all" border="1"
            id="grdLeaderBoardQuartz" style="font-size:12px;border-collapse:collapse;">
            <tbody>
              <tr [style.color]="header_text_color.value" [style.background-color]="header_background_color.value"
                [style.border-bottom-color]="border_color.value">
                <th class="leaderboard-header" scope="col">
                  col1
                </th>
                <th class="leaderboard-header" scope="col">
                  col2
                </th>
                <th class="leaderboard-header" scope="col">
                  col3
                </th>
              </tr>
              <tr class="leaderboardquartz-lbMainRow" [style.background-color]="
                  i % 2 == 0 ? row_background_color.value : alt_row_background_color.value
                " [style.color]="i % 2 == 0 ? row_text_color.value : alt_row_text_color.value"
                *ngFor="let record of [1, 2, 3]; let i = index" [class.lbGridDefaultAltRow]="i % 2 == 1"
                [style.border-bottom-color]="border_color.value">
                <td align="right" style="width:50px;">value 1 {{ record }}</td>
                <td align="right" style="width:50px;">value 2 {{ record }}</td>
                <td align="right" style="width:50px;">value 3 {{ record }}</td>
              </tr>
            </tbody>
          </table>
          <div class="card-footer page-footer">
            <div class="gridview-paginator-logic" [style.color]="footer_text_color.value">
              <mat-paginator [length]="3" [pageSize]="2" [showFirstLastButtons]="false"
                [pageSizeOptions]="pageSizeOptions" [pageIndex]="0" [hidePageSize]="true">
              </mat-paginator>
            </div>
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Grid lines:
          </div>

          <input [colorPicker]="border_color.value" [style.background]="border_color.value"
            (colorPickerChange)="setColor(border_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="border_color.hasError('required') && !border_color.pristine" class="alert alert-danger ">
            Grid line color is required
          </div>
        </div>

        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Header Text:
          </div>

          <input [colorPicker]="header_text_color.value" [style.background]="header_text_color.value"
            (colorPickerChange)="setColor(header_text_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="header_text_color.hasError('required') && !header_text_color.pristine"
            class="alert alert-danger ">
            Header text color is required
          </div>
        </div>

        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Header Background:
          </div>

          <input [colorPicker]="header_background_color.value" [style.background]="header_background_color.value"
            (colorPickerChange)="setColor(header_background_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Header background color is required
          </div>
        </div>

        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Row Text:
          </div>

          <input [colorPicker]="row_text_color.value" [style.background]="row_text_color.value"
            (colorPickerChange)="setColor(row_text_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="row_text_color.hasError('required') && !row_text_color.pristine" class="alert alert-danger ">
            Row text color is required
          </div>
        </div>

        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Alternating Row Text:
          </div>
          <input [colorPicker]="alt_row_text_color.value" [style.background]="alt_row_text_color.value"
            (colorPickerChange)="setColor(alt_row_text_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="alt_row_text_color.hasError('required') && !alt_row_text_color.pristine"
            class="alert alert-danger ">
            Alternating row text color is required
          </div>
        </div>

        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Row Background:
          </div>
          <input [colorPicker]="row_background_color.value" [style.background]="row_background_color.value"
            (colorPickerChange)="setColor(row_background_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="row_background_color.hasError('required') && !row_background_color.pristine"
            class="alert alert-danger ">
            Row background color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Alternating Row Background:
          </div>
          <input [colorPicker]="alt_row_background_color.value" [style.background]="alt_row_background_color.value"
            (colorPickerChange)="setColor(alt_row_background_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="
              alt_row_background_color.hasError('required') && !alt_row_background_color.pristine
            " class="alert alert-danger ">
            Alternating row background color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Background:
          </div>
          <input [colorPicker]="background_color.value" [style.background]="background_color.value"
            (colorPickerChange)="setColor(background_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="background_color.hasError('required') && !background_color.pristine" class="alert alert-danger ">
            Background color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Banner Text:
          </div>

          <input [colorPicker]="banner_text_color.value" [style.background]="banner_text_color.value"
            (colorPickerChange)="setColor(banner_text_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Banner text color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Banner background (inside):
          </div>

          <input [colorPicker]="banner_background_color_inside.value"
            [style.background]="banner_background_color_inside.value"
            (colorPickerChange)="setColor(banner_background_color_inside, $event)" readonly="readonly"
            class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Banner background (inside radial) color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Banner background (outside):
          </div>

          <input [colorPicker]="banner_background_color_outside.value"
            [style.background]="banner_background_color_outside.value"
            (colorPickerChange)="setColor(banner_background_color_outside, $event)" readonly="readonly"
            class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Banner background (outside radial) color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Button Background:
          </div>

          <input [colorPicker]="button_background_color.value" [style.background]="button_background_color.value"
            (colorPickerChange)="setColor(button_background_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Button background color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Button text:
          </div>

          <input [colorPicker]="button_text_color.value" [style.background]="button_text_color.value"
            (colorPickerChange)="setColor(button_text_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Button text color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Footer Background:
          </div>

          <input [colorPicker]="footer_background_color.value" [style.background]="footer_background_color.value"
            (colorPickerChange)="setColor(footer_background_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Footer background color is required
          </div>
        </div>
        <div class="col-6 profile-colorpicker-section">
          <div class="profile-panel-heading-sub">
            Footer text:
          </div>

          <input [colorPicker]="footer_text_color.value" [style.background]="footer_text_color.value"
            (colorPickerChange)="setColor(footer_text_color, $event)" readonly="readonly" class="color_picker" />
          <div *ngIf="
              header_background_color.hasError('required') && !header_background_color.pristine
            " class="alert alert-danger ">
            Footer text color is required
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer text-muted">
      <button mat-raised-button color="primary" class="gridview-editor-btn" (click)="changeColors(colorsForm.value)"
        [disabled]="colorsForm.invalid || colorsForm.pristine || submitted">
        <mat-icon>cloud_upload</mat-icon> Save Colors
      </button>
      <button mat-button id="btnReset" type="submit" class="action-reset-button" (click)="setDefaults()">
        <mat-icon>settings_backup_restore</mat-icon> Defaults
      </button>
    </div>
  </div>
</div>
<div *ngIf="league" id="quartz-main-right" class="col-lg-6">
  <div id="exeleaguestats" class="card">
    <div class="card-header"><i class="fas fa-chart-line"></i> League Stats</div>
    <div class="card-block">
      <div class="panelrow col-md-12">
        <div class="dashboard-panel-heading-sub">
          <i class="fa fa-trophy card-icon"></i> Tournaments This Month
        </div>
        <span class="label_margin tournament-count-logic" id="lblCurMonthTournies">
          {{ tournamentSummary?.current_month_tournaments || 0 }}
        </span>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header"><i class="fas fa-chart-line"></i> League Activity</div>
    <div class="card-block card-admin">
      <div class="gridview-logic grid-activity">
        <div class="gridview-padding">
          <ng-table [config]="config" (tableChanged)="onChangeTable(config)" [rows]="rows" [columns]="columns">
          </ng-table>
        </div>
      </div>
    </div>
    <div *ngIf="config.paging" class="card-footer page-footer">
      <div class="gridview-paginator-logic smallgrid">
        <div class="mat-paginator-page-goto">
          <mat-form-field>
            <mat-select [ngModel]="pageIndex + 1" (ngModelChange)="manualPageChanged($event)">
              <mat-option [value]="1">Page</mat-option>
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{ mp }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator [length]="length" [pageSize]="itemsPerPage" [showFirstLastButtons]="true"
          [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" (page)="onChangeTable(config, $event)"
          (numPages)="numPages = $event">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
