import {
  HeadsUp,
  LastChance,
  PreGame,
  SecondChance,
  Standard,
  TournamentType,
  TournamentTypesList,
  VenueChampionship
} from '../models/tournament.type';

export function recalculateDefaultScoring(numEntries: string, tournamentType: string) {
  let numPlayers = parseInt(numEntries, 10);
  // default # players is 20
  // get tournament type, if it's HeadsUp, then set places paid = 8
  // if heads up, also set FinishPosition to 1, 2, 4, or 8 depending on which round they were in.
  // if 2nd chance, set places paid = 1, num_players = 10, total points = 20.
  // else, if num players > 40, set num paid to 20.
  // else, set num paid to Math.ceil(num players / 2)

  // now set points
  // if 2nd chance, flat 20
  // else, START with: total points = N ^ (1 / 2) * 200(where N = total number of players)
  // give full points to Standard, VenueChampionship, LastChance, and HEadsUp tourneys.
  // give 70% total points to PreGame tourneys.
  // all others get 0%
  // now assign players points based on this percent:
  // double[] placePercents = {
  // 0.300, 0.200, 0.100, 0.080, 0.070, 0.050, 0.040, 0.030, 0.020, 0.015,
  // 0.014, 0.013, 0.012, 0.011, 0.010, 0.009, 0.008, 0.007, 0.006, 0.005
  // };
  // so first place gets 30%, second 20%, etc.
  // for heads up tourneys, 3rd and 4th place share the prize, 5th/6th/7th/8th all share the same prize.

  let numPlaces = 20; // default # is 20
  let totalPoints = 0;
  if (tournamentType === HeadsUp.code) {
    numPlaces = 8; // if heads up, set places = 8
  } else if (tournamentType === SecondChance.code) {
    // if 2nd chance, set places paid = 1, num_players = 10, total points = 20.
    numPlaces = 1;
    numPlayers = 10;
  } else {
    if (numPlayers > 40) {
      numPlaces = 20;
    } else {
      numPlaces = Math.ceil((numPlayers * 1.0) / 2);
    }
  }

  if (tournamentType === SecondChance.code) {
    totalPoints = 20;
  } else {
    totalPoints = Math.pow(numPlayers, 0.5) * 200;

    let factor = 0;
    if (
      [Standard.code, VenueChampionship.code, LastChance.code, HeadsUp.code].includes(
        tournamentType
      )
    ) {
      // 100%
      factor = 1;
    } else if ([PreGame.code].includes(tournamentType)) {
      // 70%
      factor = 0.7;
    } else {
      // maybe more later.
    }

    totalPoints *= factor;
  }

  // now assign players points based on this percent:
  const placePercents = [
    0.3,
    0.2,
    0.1,
    0.08,
    0.07,
    0.05,
    0.04,
    0.03,
    0.02,
    0.015,
    0.014,
    0.013,
    0.012,
    0.011,
    0.01,
    0.009,
    0.008,
    0.007,
    0.006,
    0.005
  ];
  const playerPoints = [];
  // so first place gets 30%, second 20%, etc.

  // recap: we have # players, # paid, total points.
  // assign payouts.
  const stopPoint = tournamentType === HeadsUp.code ? 8 : placePercents.length;
  for (let i = 0; i < stopPoint; i++) {
    const index = i % numPlaces;
    // console.log('iteration -> index', i, index);
    if (!playerPoints[index]) {
      // console.log('initializing index to 0', index);
      playerPoints[index] = 0;
    }
    playerPoints[index] += totalPoints * placePercents[i];
    // console.log('playerPoints[index]', index, playerPoints[index]);
  }

  // now go through again, and update for heads up
  if (tournamentType === HeadsUp.code) {
    playerPoints[2] = playerPoints[3] = (playerPoints[2] + playerPoints[3]) / 2;
    playerPoints[4] = playerPoints[5] = playerPoints[6] = playerPoints[7] =
      (playerPoints[4] + playerPoints[5] + playerPoints[6] + playerPoints[7]) / 2;
  }
  return {
    numPlaces,
    payouts: playerPoints.map((points, index) => ({
      place: index + 1,
      points_awarded: points.toFixed(2),
      payout_percent: placePercents[index]
    }))
  };
}
