import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { VenueForm } from '../models/forms/venue.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import { GetVenuesResponse, GetVenueResponse } from './responses/venue.responses';

@Injectable()
export class VenueService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;

  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init) it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.VENUES_API}/league/${this.league.id}/venue/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getVenues(params).pipe(
      map(r => {
        return {
          data: r.venues,
          pagination: r.pagination
        };
      })
    );
  }

  public getVenues(params: any): Observable<GetVenuesResponse> {
    this.logger.debug('Called getVenues');
    if (this.league == null) {
      this.logger.debug('returning default venues');
      return of(this.defaultResponse('venues'));
    }
    this.logger.debug('calling get venues service');

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetVenuesResponse | any>(
        `${environment.VENUES_API}/league/${this.league.id}/venue`,
        options
      )
      .pipe(tap(response => this.logger.debug('getVenues response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPublicVenue(venueId: UUID): Observable<GetVenueResponse> {
    this.logger.debug('Called getPublicVenue');

    return this.http
      .get<GetVenueResponse | any>(`${environment.VENUES_API}/p/venue/${venueId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getPublicVenue response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPublicVenues(leagueId: UUID, params: any = {}): Observable<GetVenuesResponse> {
    this.logger.debug('Called getPublicVenues');
    this.logger.debug('calling get venues service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetVenuesResponse | any>(`${environment.VENUES_API}/p/league/${leagueId}/venue`, options)
      .pipe(tap(response => this.logger.debug('getPublicVenues response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getVenue(venueId: UUID): Observable<GetVenueResponse | any> {
    this.logger.debug('Called getVenue');
    return this.http
      .get<GetVenueResponse>(
        `${environment.VENUES_API}/league/${this.league.id}/venue/${venueId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getVenue response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createVenue(value: VenueForm): Observable<GetVenueResponse | any> {
    this.logger.debug('Called createVenue');
    return this.http
      .post<GetVenueResponse>(
        `${environment.VENUES_API}/league/${this.league.id}/venue`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createVenue response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateVenue(value: VenueForm): Observable<GetVenueResponse | any> {
    this.logger.debug('Called updateVenue', value);
    return this.http
      .patch<GetVenueResponse>(
        `${environment.VENUES_API}/league/${this.league.id}/venue/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateVenue response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteVenue(venueId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteVenue');
    return this.http
      .delete<any>(
        `${environment.VENUES_API}/league/${this.league.id}/venue/${venueId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deleteVenue response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
