export class UUID {
  private str: string;

  private static getNewGUIDString() {
    // your favourite guid generation function could go here
    // ex: http://stackoverflow.com/a/8809472/188246
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === 'function') {
      d += performance.now(); // use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public static isGuid(str) {
    if (
      str &&
      !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(str)
    ) {
      return false;
    }

    return true;
  }

  constructor(str?: string) {
    this.str = str || UUID.getNewGUIDString();

    if (!UUID.isGuid(str)) {
      throw new Error('invalid UUID string: ' + str);
    }
  }

  public toString() {
    return this.str;
  }
}
