

    <table class="table dataTable" ngClass="{{config.className || ''}}"
           role="grid" style="width: 100%;">
      <thead>
        <tr role="row">
          <th *ngFor="let column of columns" [ngTableSorting]="config" [column]="column"
              (sortChanged)="onChangeSort($event)" ngClass="{{column.className || ''}}">
            {{column.title}}
            <i *ngIf="config && column.sort" class="pull-right fa"
              [ngClass]="{'fa-chevron-down': column.sort === 'desc', 'fa-chevron-up': column.sort === 'asc'}"></i>
          </th>
        </tr>
      </thead>
      <tbody>
      <tr *ngIf="showFilterRow" class="filter-row">
        <td *ngFor="let column of columns">
          <div *ngIf="column.filtering">
            <input *ngIf="!column.filtering.boolean" placeholder="{{column.filtering.placeholder}}"
                 [ngTableFiltering]="column.filtering"
                 class="form-control"
                 style="width: auto;"
                 (tableChanged)="onChangeFilter(config)"/>

            <mat-checkbox
                 *ngIf="column.filtering.boolean"
                 [indeterminate]="column.filtering.isIndeterminate"
                 [checked]="column.filtering.isChecked"
                 [ngTableFiltering]="column.filtering"
                 (tableChanged)="onChangeFilter(config)">Filter</mat-checkbox>
            </div>

        </td>
      </tr>
        <tr *ngFor="let row of rows">
          <td (click)="cellClick(row, column.name)" *ngFor="let column of columns">
            <div #container></div>
          </td>
        </tr>
      </tbody>
    </table>
  