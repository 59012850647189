<div class="col-md-12 noselect">
  <div class="loginlogo">
    <a routerLink="/">
      <img src="/assets/images/login/logo.png" width="100%" height="auto" alt="Poker Leaderboards" />
    </a>
  </div>
  <div class="card text-center">
    <div class="card-header">
      League Signup
      <div class="signup-info-content">Fill out the following form to create your free account</div>
    </div>
    <div class="card-block">
      <div id="membership">
        <div class="signupinputlogic">
          <form id="form1" [formGroup]="myForm" (ngSubmit)="signup(myForm.value)">
            <div class="formgroup">
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field signup-leaguename">
                  <input required #leagueName="ngForm" matInput [formControl]="myForm.controls['name']" placeholder="League Name">
                  <mat-hint>Required</mat-hint>
                  <mat-error *ngIf="leagueName.hasError('required') && !leagueName.pristine">
                    League Name is required.
                  </mat-error>
                  <mat-error *ngIf="leagueName.hasError('nameTaken') && !leagueName.pristine">
                    League Name is already taken, please choose another.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="signup-info-heading">Primary User / League Administrator</div>
            <div class="formgroup">
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input required #firstName="ngForm" matInput [formControl]="myForm.controls['first_name']"
                    placeholder="First Name">
                  <mat-hint>Required</mat-hint>
                  <mat-error *ngIf="firstName.hasError('required') && !firstName.pristine">
                    First Name is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input required #lastName="ngForm" matInput [formControl]="myForm.controls['last_name']" placeholder="Last Name">
                  <mat-hint>Required</mat-hint>
                  <mat-error *ngIf="firstName.hasError('required') && !firstName.pristine">
                    Last Name is required
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input required #email="ngForm" matInput [formControl]="myForm.controls['email']" placeholder="Email">
                  <mat-hint>Required</mat-hint>
                  <mat-error *ngIf="email.hasError('required') && !email.pristine">
                    Email is required
                  </mat-error>
                  <mat-error *ngIf="email.hasError('email') && !email.pristine">
                    Invalid email
                  </mat-error>
                  <mat-error *ngIf="email.hasError('emailTaken') && !email.pristine">
                    Email is already taken, please choose another.
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input required #password="ngForm" matInput [formControl]="myForm.controls['password']" placeholder="Password"
                    type="password">
                  <mat-hint>At least 8 characters</mat-hint>
                  <mat-error *ngIf="password.hasError('required') && !password.pristine">
                    Password is required
                  </mat-error>
                  <mat-error *ngIf="password.hasError('minlength') && !password.pristine">
                    Password must be at least 8 characters.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input required #passwordVerify="ngForm" matInput [formControl]="myForm.controls['password_verify']"
                    placeholder="Repeat Password" type="password">
                  <mat-hint>Confirm Password</mat-hint>
                  <mat-error *ngIf="passwordVerify.hasError('required') && !passwordVerify.pristine">
                    Please type your password again
                  </mat-error>
                  <mat-error *ngIf="myForm.hasError('passwordMustMatch') && !passwordVerify.pristine">
                    Password mismatch.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-12">
                <button mat-raised-button color="primary" class="loginsignup signupbtn gridview-editor-btn" [disabled]="!myForm.valid || submitted"
                  type="submit">
                  SIGN UP</button>
              </div>


            </div>


          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="loginprivacy">We value your
    <a style="cursor: pointer;" (click)="openPrivacy()">privacy</a>
  </div>
</div>
