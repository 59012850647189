<div class="filter-instructions-logic">
  <div class="filter-instructions-label">Fuzzy Filter Instructions</div>
  <div class="filter-instructions-section">
    To search enabled columns by a specific operator, put that operator as the first character in the filter box.
  </div>
  <div class="filter-instructions-section">
    <h4>Examples:</h4>
    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "dog"
      </div>
      <div class="filter-instructions-content">
        Matches "dog", "the dog barks", "doggone"
      </div>
    </div>

    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "= dog"
      </div>
      <div class="filter-instructions-content">
        Matches "dog" only
      </div>
    </div>

    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "123"
      </div>
      <div class="filter-instructions-content">
        Matches "123" exactly for a numeric column; "test123", "01234 test" for string columns
      </div>
    </div>

    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "&lt; 123"
      </div>
      <div class="filter-instructions-content">
        Matches "42", "122"
      </div>
    </div>

    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "&lt;= 123"
      </div>
      <div class="filter-instructions-content">
        Matches "42", "122", "123"
      </div>
    </div>
    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "&gt;= 123"
      </div>
      <div class="filter-instructions-content">
        Matches "142", "123"
      </div>
    </div>
    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "&gt; 123"
      </div>
      <div class="filter-instructions-content">
        Matches "142", "124"
      </div>
    </div>
  </div>
</div>
