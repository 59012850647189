/**
 * Returns the number of seconds represented by the input string.
 *
 * Input is pretty forgiving:
 * 1d 30m 45s
 * 1 day, 2 hours
 * 3hours20minutes
 *
 * If the input can't be parsed at all, it will just return 0.
 *
 * @param input free-form input string of days/hours/minutes
 * @returns Number of seconds represented by the input string.
 */
export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function parseDuration(input: any): number {
  if (isNumeric(input)) {
    // just assume it's in hours.
    return parseFloat(input) * 60 * 60;
  }

  let seconds = 0;
  const days = input.match(/(\d+)\s*d/);
  const hours = input.match(/(\d+)\s*h/);
  const minutes = input.match(/(\d+)\s*m/);
  const s = input.match(/(\d+)\s*s/);
  if (days) {
    seconds += parseInt(days[1], 10) * 86400;
  }
  if (hours) {
    seconds += parseInt(hours[1], 10) * 3600;
  }
  if (minutes) {
    seconds += parseInt(minutes[1], 10) * 60;
  }
  if (s) {
    seconds += parseInt(s[1], 10);
  }

  return seconds;
}

export function formatDuration(s: number): string {
  if (s === 0) {
    return '0h';
  }
  const days = Math.floor(s / 86400);
  s -= days * 86400;

  const hours = Math.floor(s / 3600) % 24;
  s -= hours * 3600;

  const minutes = Math.floor(s / 60) % 60;
  s -= minutes * 60;

  const seconds = s % 60;

  const parts = [];
  if (days > 0) {
    parts.push(days + 'd');
  }
  if (hours > 0) {
    parts.push(hours + 'h');
  }
  if (minutes > 0) {
    parts.push(minutes + 'm');
  }
  if (seconds > 0) {
    parts.push(seconds + 's');
  }

  return parts.join(' ');
}
