import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { FileSystemDirectoryEntry, FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { ExecTermsDialogComponent } from '../../components/dialogs/exec-terms/exec-terms.dialog.component';
import { NewLeagueDialogComponent } from '../../components/dialogs/new-league/new-league.dialog.component';
import { PageableComponent } from '../../components/pageable.component';
import { AppStorage } from '../../models/app.storage';
import { ChangeLeagueNameForm } from '../../models/forms/change-league-name.form';
import { LeagueSettingForm } from '../../models/forms/league-setting.form';
import { League } from '../../models/league';
import { LeagueSettings } from '../../models/league-setting';
import { LeagueBilling } from '../../models/league.billing';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';
import { TournamentSummary } from '../../models/tournament-summary';
import { LeagueActivityService } from '../../services/league-activity.service';
import { LeagueService } from '../../services/league.service';
import { PaymentsService } from '../../services/payments.service';
import { LoaderService } from '../../services/spinner.service';
import { TournamentService } from '../../services/tournament.service';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends PageableComponent implements OnInit, OnDestroy {
  file: File;
  datePipe: DatePipe = new DatePipe('en-US');
  _propertySubscription: Subscription;
  _leagueSettingsSubscription: Subscription;
  league: League;
  league_logo: string;
  model: League; // only for auto-name checks
  billing: LeagueBilling;
  settings: LeagueSettings;
  leagueNameForm: FormGroup;
  colorsForm: FormGroup;
  submitted = false;
  cost: number;
  tournamentSummary: TournamentSummary;
  fileMessage = '';

  constructor(
    private loaderService: LoaderService,
    private service: LeagueService,
    private tournamentService: TournamentService,
    public leagueActivityService: LeagueActivityService,
    private uniqueNameValidator: UniqueNameValidator,
    private fb: FormBuilder,
    public leagueStorage: LeagueStorage,
    public appStorage: AppStorage,
    public dialog: MatDialog,
    private router: Router,
    private paymentsService: PaymentsService,
    private cd: ChangeDetectorRef,
    private permissionManager: PermissionManager
  ) {
    super(leagueActivityService, leagueStorage);
    this.setColumns([
      {
        title: 'Date',
        name: 'activity_date',
        sort: 'desc',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Activity',
        name: 'activity_text',
        filtering: { filterString: '', placeholder: 'Filter activity' }
      }
    ]);

    this.leagueNameForm = fb.group({
      name: ['', Validators.required, uniqueNameValidator.create().bind(this)]
    });
    this.colorsForm = fb.group({
      league_id: '',
      border_color: ['', Validators.required],
      header_text_color: ['', Validators.required],
      header_background_color: ['', Validators.required],
      row_text_color: ['', Validators.required],
      alt_row_text_color: ['', Validators.required],
      row_background_color: ['', Validators.required],
      alt_row_background_color: ['', Validators.required],
      background_color: ['', Validators.required],
      banner_text_color: ['', Validators.required],
      banner_background_color_inside: ['', Validators.required],
      banner_background_color_outside: ['', Validators.required],
      button_background_color: ['', Validators.required],
      button_text_color: ['', Validators.required],
      footer_background_color: ['', Validators.required],
      footer_text_color: ['', Validators.required]
    });
    this.cost = environment.EXECUTIVE_RENEWAL_COST;
  }

  get border_color(): FormControl {
    return this.colorsForm.get('border_color') as FormControl;
  }

  get header_text_color(): FormControl {
    return this.colorsForm.get('header_text_color') as FormControl;
  }

  get header_background_color(): FormControl {
    return this.colorsForm.get('header_background_color') as FormControl;
  }

  get row_text_color(): FormControl {
    return this.colorsForm.get('row_text_color') as FormControl;
  }

  get alt_row_text_color(): FormControl {
    return this.colorsForm.get('alt_row_text_color') as FormControl;
  }

  get row_background_color(): FormControl {
    return this.colorsForm.get('row_background_color') as FormControl;
  }

  get alt_row_background_color(): FormControl {
    return this.colorsForm.get('alt_row_background_color') as FormControl;
  }

  get background_color(): FormControl {
    return this.colorsForm.get('background_color') as FormControl;
  }

  get banner_text_color(): FormControl {
    return this.colorsForm.get('banner_text_color') as FormControl;
  }

  get banner_background_color_inside(): FormControl {
    return this.colorsForm.get('banner_background_color_inside') as FormControl;
  }

  get banner_background_color_outside(): FormControl {
    return this.colorsForm.get('banner_background_color_outside') as FormControl;
  }

  get button_background_color(): FormControl {
    return this.colorsForm.get('button_background_color') as FormControl;
  }

  get button_text_color(): FormControl {
    return this.colorsForm.get('button_text_color') as FormControl;
  }

  get footer_background_color(): FormControl {
    return this.colorsForm.get('footer_background_color') as FormControl;
  }

  get footer_text_color(): FormControl {
    return this.colorsForm.get('footer_text_color') as FormControl;
  }

  ngOnDestroy() {
    if (this._propertySubscription) {
      this._propertySubscription.unsubscribe();
    }
    if (this._leagueSettingsSubscription) {
      this._leagueSettingsSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public ngOnInit() {
    super.ngOnInit();
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));

    this._leagueSettingsSubscription = this.leagueStorage
      .getLeagueSettings()
      .subscribe(settings => {
        this.settings = settings;
        this.colorsForm.reset();
        if (this.settings) {
          this.colorsForm.patchValue(new LeagueSettingForm(this.settings), {
            onlySelf: true
          });
        }
      });

    // some kind of redirect if the user is just a TD (check action permissions)
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = this.model = league;

      if (league != null && !this.permissionManager.hasPermission('get dashboard')) {
        // they need to go somewhere else
        console.log('halted dashboard load');
        return this.permissionManager.maybeRedirect('/admin/tournaments');
      }
      if (league != null) {
        this.league_logo = `${environment.AVATARS_API}/p/league/${
          this.league.id
        }/logo?buster=${new Date().getTime()}`;

        this.tournamentService
          .getTournamentSummary()
          .subscribe(data => (this.tournamentSummary = data.summary));
      }
      if (league != null && league.account_type === 'EXECUTIVE') {
        this.paymentsService.getLeagueBilling(this.league.id).subscribe(billingData => {
          this.billing = billingData.billing;
        });
      } else {
        this.billing = null;
      }
    });
  }

  validateFile(file: File) {
    this.fileMessage = '';
    if (file.size < 2000000) {
      this.file = file;
      this.leagueNameForm.markAsDirty();
    } else {
      this.fileMessage = 'Image is too large. Max size is 2MB.';
    }
    this.cd.detectChanges();
  }

  fileSelected(files: FileList) {
    if (files && files.length > 0) {
      this.validateFile(files[0]);
    }
  }

  public dropped(event: UploadEvent) {
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        // this.file = droppedFile;
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          this.validateFile(file);
        });
      }
    }
  }

  public modifyLogo(): void {
    if (!this.submitted) {
      this.loaderService.displayLoader(true);
      this.service
        .uploadlogo(this.league.id, this.file)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(data => {
          this.league_logo = `${environment.AVATARS_API}/p/league/${
            this.league.id
          }/logo?buster=${new Date().getTime()}`;
          this.file = null;
        });
    }
  }

  public changeLeagueName(value: ChangeLeagueNameForm): void {
    if (this.leagueNameForm.valid && !this.submitted) {
      this.service
        .updateLeague(this.league.id, value)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(data => {
          // Page redirect when getting response
          this.leagueStorage.setSelectedLeague(data.league);
          // this.updateLeague(data.league);
        });
    }
  }

  public changeColors(value: LeagueSettingForm): void {
    if (this.colorsForm.valid && !this.submitted) {
      // const observables = [this.service.updatePlayerSettings(value)];
      this.loaderService.displayLoader(true);
      this.service
        .updateLeagueSettings(this.league.id, value)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(settingsResponse => {
          const newSettings = settingsResponse[0].league_setting;
          this.leagueStorage.setLeagueSettings(newSettings);
        });
    }
  }

  setDefaults() {
    this.setColor(this.border_color, '#cccccc');
    this.setColor(this.header_text_color, '#ffffff');
    this.setColor(this.header_background_color, '#323366');
    this.setColor(this.row_text_color, '#000000');
    this.setColor(this.alt_row_text_color, '#000000');
    this.setColor(this.row_background_color, '#f2f2f2');
    this.setColor(this.alt_row_background_color, '#ffffff');
    this.setColor(this.background_color, '#ffffff');
    this.setColor(this.banner_text_color, '#ffffff');
    this.setColor(this.banner_background_color_inside, '#212366');
    this.setColor(this.banner_background_color_outside, '#323366');
    this.setColor(this.button_background_color, '#1e1f46');
    this.setColor(this.button_text_color, '#ffffff');
    this.setColor(this.footer_background_color, '#f7f7f7');
    this.setColor(this.footer_text_color, '#3b3e6b');
  }

  setColor(control: FormControl, color: any) {
    control.setValue(color);
    control.markAsDirty();
  }

  startRenewal() {
    if (this.league) {
      this.loaderService.displayLoader(true);
      this.paymentsService
        .startCheckout({
          cancel_url: `${window.location.origin}${this.router.createUrlTree([
            '/admin',
            'dashboard'
          ])}`,
          return_url: `${window.location.origin}${this.router.createUrlTree([
            '/admin',
            'confirm-order'
          ])}`
        })
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe((data: any) => {
          if (data.redirectTo) {
            window.location.href = data.redirectTo;
          }
        });
    }
  }

  openNewleague() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(NewLeagueDialogComponent, dialogConfig);
  }

  openExeterms() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    this.dialog.open(ExecTermsDialogComponent, dialogConfig);
  }

  /*

  private updateLeague(league) {
    this.leagueStorage.le;
    this.leagueStorage.leagues.subscribe(leagues => {
      for (let i = 0; i < leagues.length; i++) {
        if (leagues[i].id === league.id) {
          leagues[i] = league;
          break;
        }
      }

      this.leagueStorage.leagues = of(leagues);
    });
  }

  */
}
