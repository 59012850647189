import { Component, OnChanges } from '@angular/core';

import { Router } from '../../../../node_modules/@angular/router';
import { TableCellComponent } from '../../components/ng2-table/components/table/table-cell.component';

@Component({
  selector: 'app-event-iframe-cell',
  template: `
    <div class="copy-to-clipboard">
      <input
        selectOnClick
        readonly
        style="width: 100%;"
        ngxClipboard
        [cbContent]="iframe"
        [value]="iframe"
        (cbOnSuccess)="onCopy()"
      />
      <div *ngIf="copied" class="copied-message">Copied!</div>
    </div>
  `
})
export class EventIframeComponent extends TableCellComponent {
  // this is needed because we try to only make one component factory per component type
  // after compilation we lose the class name.  This lets us uniquely identify
  // the type of class we are creating.
  static componentName = 'EventIframeComponent';
  url: string;
  iframe = '';
  copied = false;
  // if (
  //   data.column === 'first_name' &&
  //   (this.permissionManager.hasPermission('update staff') ||
  //     this.permissionManager.hasPermission('get staff'))
  // ) {
  //   this.router.navigate([data.row.id], { relativeTo: this.activatedRoute });
  // }
  constructor(private router: Router) {
    super();
  }

  onChanges() {
    const endpoint = this.router.createUrlTree(['/event', this.row.id]);
    this.url = `${window.location.origin}${endpoint}`;
    this.iframe = `<iframe src="${this.url}" width="410px" height="800px" frameborder="0"></iframe>`;
  }

  onCopy() {
    this.copied = true;
    setTimeout(() => (this.copied = false), 1000);
  }
}
