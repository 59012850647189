import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AdForm } from '../models/forms/ad.form';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetAdsResponse, GetAdResponse } from './responses/ad.responses';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';

@Injectable()
export class SystemAdService extends GenericService implements PageableService {
  constructor(private http: HttpClient, protected logger: NGXLogger) {
    super(logger);
  }

  public checkNameTaken(name: string): Observable<boolean> {
    return this.http
      .post<NameExistsResponse>(
        `${environment.ADS_API}/system/ad/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getAds(params).pipe(
      map(r => {
        return {
          data: r.ads,
          pagination: r.pagination
        };
      })
    );
  }

  public getAds(params: any): Observable<GetAdsResponse | any> {
    this.logger.debug('Called getAds');
    this.logger.debug('calling get ads service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetAdsResponse>(`${environment.ADS_API}/system/ad`, options)
      .pipe(tap(response => this.logger.debug('getAds response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getAd(adId: UUID): Observable<GetAdResponse | any> {
    this.logger.debug('Called getAd');
    this.logger.debug('calling get ad service');
    return this.http
      .get<GetAdResponse>(`${environment.ADS_API}/system/ad/${adId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createAd(value: AdForm): Observable<GetAdResponse | any> {
    this.logger.debug('Called createAd');
    return this.http
      .post<GetAdResponse>(`${environment.ADS_API}/system/ad`, value, this.httpOptions)
      .pipe(tap(response => this.logger.debug('createAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateAd(value: AdForm): Observable<GetAdResponse | any> {
    this.logger.debug('Called updateAd');
    return this.http
      .patch<GetAdResponse>(`${environment.ADS_API}/system/ad/${value.id}`, value, this.httpOptions)
      .pipe(tap(response => this.logger.debug('updateAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteAd(adId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteAd');
    return this.http
      .delete<any>(`${environment.ADS_API}/system/ad/${adId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('deleteAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
