import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { MessageForm } from '../models/forms/message.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import { GetMessagesResponse, GetMessageResponse } from './responses/message.responses';

@Injectable()
export class SystemMessagesService extends GenericService implements PageableService {
  /*
  This is a primary alert—check it out!
This is a secondary alert—check it out!
This is a success alert—check it out!
This is a danger alert—check it out!
This is a warning alert—check it out!
This is a info alert—check it out!
This is a light alert—check it out!
This is a dark alert—check it out!
*/

  public static LEVELS: { name: string; description: string; class: string }[] = [
    { name: 'Informational', description: 'Very light blue/aqua alert', class: 'info' },
    { name: 'Warning', description: 'Light yellow alert', class: 'warning' },
    { name: 'Error', description: 'Light red alert', class: 'danger' },
    { name: 'Success', description: 'Light green alert', class: 'success' },
    { name: 'General alert', description: 'Light blue alert', class: 'primary' },
    { name: 'General alert (2)', description: 'Light grey alert', class: 'secondary' },
    { name: 'Light', description: 'Extremely lightly colored alert', class: 'light' },
    { name: 'Dark', description: 'Darker grey alert', class: 'dark' }
  ];
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    protected logger: NGXLogger,
    private leagueStorage: LeagueStorage
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getMessages(params).pipe(
      map(r => {
        return {
          data: r.messages,
          pagination: r.pagination
        };
      })
    );
  }

  public getMessages(params: any): Observable<GetMessagesResponse | any> {
    this.logger.debug('Called getMessages');
    this.logger.debug('calling get messages service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetMessagesResponse>(`${environment.MESSAGES_API}/system/message`, options)
      .pipe(tap(response => this.logger.debug('getMessages response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getMyMessages(params: any = {}): Observable<GetMessagesResponse | any> {
    this.logger.debug('Called getMyMessages');
    this.logger.debug('calling get messages service');
    if (this.league == null) {
      this.logger.debug('returning default messages');
      return of(this.defaultResponse('messages'));
    }
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetMessagesResponse>(
        `${environment.MESSAGES_API}/league/${this.league.id}/message`,
        options
      )
      .pipe(tap(response => this.logger.debug('getMyMessages response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public acknowledgeMessage(messageId): Observable<any> {
    this.logger.debug('Called createMessage');
    return this.http
      .post<any>(
        `${environment.MESSAGES_API}/league/${this.league.id}/message/${messageId}`,
        {},
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('acknowledgeMessage response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getMessage(messageId: UUID): Observable<GetMessageResponse | any> {
    this.logger.debug('Called getMessage');
    this.logger.debug('calling get message service');
    return this.http
      .get<GetMessageResponse>(
        `${environment.MESSAGES_API}/system/message/${messageId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getMessage response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createMessage(value: MessageForm): Observable<GetMessageResponse | any> {
    this.logger.debug('Called createMessage');
    return this.http
      .post<GetMessageResponse>(
        `${environment.MESSAGES_API}/system/message`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createMessage response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateMessage(value: MessageForm): Observable<GetMessageResponse | any> {
    this.logger.debug('Called updateMessage');
    return this.http
      .patch<GetMessageResponse>(
        `${environment.MESSAGES_API}/system/message/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateMessage response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteMessage(messageId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteMessage');
    return this.http
      .delete<any>(`${environment.MESSAGES_API}/system/message/${messageId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('deleteMessage response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public sendEmail(messageId: UUID): Observable<any | any> {
    this.logger.debug('Called sendEmail');
    return this.http
      .post<any>(
        `${environment.MESSAGES_API}/system/message/${messageId}/email`,
        {},
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('sendEmail response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
