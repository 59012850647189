<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/venues">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button mat-menu-item [disabled]="isNewModel" id="btnDelete" type="submit" (click)="onDelete()">
          <mat-icon>delete</mat-icon> Remove Venue
        </button>
      </mat-menu>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSaveContinue" type="submit"
        class="action-save-button" (click)="submit(myForm.value)">
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSave" type="submit"
        class="action-saveclose-button" (click)="submitAndClose(myForm.value)">
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>
<form id="form1" [formGroup]="myForm">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <span class="card-header-edit-label">{{ isNewModel? 'Create' : 'Edit' }} Venue</span> {{name.value}}
        </div>
        <div class="card-block">
          <div class="panelrow col-md-4">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="venue_number" placeholder="Venue Number">
            </mat-form-field>
          </div>

          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="name" placeholder="Name">
              <mat-error *ngIf="name.hasError('required') && !name.pristine">
                Venue name is required
              </mat-error>
              <mat-error *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)">
                Venue name already taken
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12" *ngIf="isExecutive">
            <mat-form-field>
              <mat-select class="season-select" formControlName="region_id" placeholder="Region (optional)">
                <mat-option [value]="null">No Region</mat-option>
                <mat-option *ngFor="let region of regions" [value]="region.id">{{region.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <input *ngIf="!isExecutive" type="hidden" formControlName="region_id" value="" />
        </div>
        <div class="card-footer text-muted">
          <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">Payouts</div>
        <div class="card-block">
          <div class="panelrow col-md-10">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="amount_owed" placeholder="Amount Owed" (change)="amountOwedChanged()">
              <span matPrefix>$&nbsp;</span>
              <mat-hint>Enter amount owed.</mat-hint>
              <mat-error *ngIf="amount_owed.hasError('numeric') && !amount_owed.pristine">
                <i class="fas fa-times-circle"></i> Valid positive number required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="card-footer editor-footer text-muted">
          <div class="totalrow">
            <div class="row">
              <div class="col-sm-3">
                <div class="total-row-title">
                  Totals
                </div>
              </div>
              <div class="col-sm-4">
                <div class="panelrow totalpercent">
                  <input disabled="disabled" [value]="totalPercent | percent: '1.1-1'" />
                </div>
              </div>
              <div class="col-sm-4">
                <div class="panelrow totalamount">
                  <input disabled="disabled" [value]="totalAmount | currency: 'USD':'symbol-narrow':'1.2-2'">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Staff Expenses</div>
        <div class="card-block items-block">
          <div class="col-md-12">
            <div class="itemsrow" formArrayName="staff_expenses">
              <div class="itemrow" [formGroupName]="i" *ngFor="let staff of staff_expenses.controls; let i=index">
                <div class="row">
                  <div class="panelrow col-md-5">
                    <input type="hidden" formControlName="level" />
                    <mat-form-field class="editor-mat-field">
                      <mat-select required placeholder="Role" formControlName="role_id"
                        (selectionChange)="roleChanged(staff.get('role_id').value)">
                        <mat-option *ngFor="let role of allRoles" [value]="role.id">{{role.name}}
                        </mat-option>
                      </mat-select>
                      <mat-hint>Select Role</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="panelrow col-md-5">
                    <input type="hidden" formControlName="level" />
                    <mat-form-field class="editor-mat-field">
                      <mat-select required placeholder="User" formControlName="user_id"
                        (selectionChange)="roleChanged(staff.get('role_id').value)">
                        <mat-option *ngFor="let user of usersByRole[staff.get('role_id').value]" [value]="user.id">
                          {{user.first_name}}
                          {{user.last_name}}
                        </mat-option>
                      </mat-select>
                      <mat-hint>Select User</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="panelrow col-md-4">
                    <mat-form-field class="editor-mat-field">
                      <input required matInput formControlName="payout_percent" placeholder="Payout Percent"
                        (change)="percentChanged(staff.get('payout_percent'), staff.get('payout_amount'))">
                      <span matPrefix>%&nbsp;</span>
                      <mat-error
                        *ngIf="staff.get('payout_percent').hasError('required') && !staff.get('payout_percent').pristine">
                        <i class="fas fa-times-circle"></i> Payout percent is required
                      </mat-error>
                      <mat-error
                        *ngIf="staff.get('payout_percent').hasError('numeric') && !staff.get('payout_percent').pristine">
                        <i class="fas fa-times-circle"></i> Valid positive number required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="panelrow col-md-4">
                    <mat-form-field class="editor-mat-field">
                      <input required matInput formControlName="payout_amount" placeholder="Payout Amount"
                        (change)="amountChanged(staff.get('payout_amount'), staff.get('payout_percent'))">
                      <span matPrefix>$&nbsp;</span>
                      <mat-error
                        *ngIf="staff.get('payout_amount').hasError('required') && !staff.get('payout_amount').pristine">
                        <i class="fas fa-times-circle"></i> Payout amount is required.
                      </mat-error>
                      <mat-error
                        *ngIf="staff.get('payout_amount').hasError('numeric') && !staff.get('payout_amount').pristine">
                        <i class="fas fa-times-circle"></i> Valid positive number required.
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div title="Remove" class="position-remove">
                    <div class="panelrow">
                      <button mat-icon-button (click)="removeStaff(i)">
                        <mat-icon aria-label="Remove Position">highlight_off</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer editor-footer text-muted">
          <button mat-raised-button color="primary" class="gridview-editor-btn" (click)="addStaff()">
            <i class="fas fa-plus"></i> Add Staff Expense</button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Items</div>
        <div class="card-block items-block">
          <div class="col-md-12">
            <div class="itemsrow" formArrayName="item_expenses">
              <div class="itemrow" [formGroupName]="i" *ngFor="let item of item_expenses.controls; let i=index">

                <div class="panelrow col-md-10">
                  <mat-form-field class="editor-mat-field">
                    <input type="hidden" formControlName="level" />
                    <input required matInput formControlName="label" placeholder="Expense Item Name">
                    <mat-error *ngIf="item.get('label').hasError('required') && !item.get('label').pristine">
                      Expense name is required
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="panelrow col-md-4">
                  <mat-form-field class="editor-mat-field">
                    <input required matInput formControlName="payout_percent" placeholder="Payout Percent"
                      (change)="percentChanged(item.get('payout_percent'), item.get('payout_amount'))">
                    <span matPrefix>%&nbsp;</span>
                    <mat-error
                      *ngIf="item.get('payout_percent').hasError('required') && !item.get('payout_percent').pristine">
                      Payout percent is required.
                    </mat-error>
                    <mat-error
                      *ngIf="item.get('payout_percent').hasError('numeric') && !item.get('payout_percent').pristine">
                      Must be a valid positive number.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="panelrow col-md-4">
                  <mat-form-field class="editor-mat-field">
                    <input required matInput formControlName="payout_amount" placeholder="Payout Amount"
                      (change)="amountChanged(item.get('payout_amount'), item.get('payout_percent'))">
                    <span matPrefix>$&nbsp;</span>
                    <mat-error
                      *ngIf="item.get('payout_amount').hasError('required') && !item.get('payout_amount').pristine">
                      Payout amount is required.
                    </mat-error>
                    <mat-error
                      *ngIf="item.get('payout_amount').hasError('numeric') && !item.get('payout_amount').pristine">
                      Must be a valid positive number.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div title="Remove" class="position-remove">
                  <div class="panelrow">
                    <button mat-icon-button (click)="removeItem(i)">
                      <mat-icon aria-label="Remove Position">highlight_off</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="card-footer editor-footer text-muted">
          <button mat-raised-button color="primary" class="gridview-editor-btn" (click)="addItem()">
            <i class="fas fa-plus"></i> Add Item Expense</button>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Venue Information</div>
        <div class="card-block">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <textarea rows="5" matInput formControlName="description" placeholder="Venue Notes"></textarea>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="url" placeholder="Venue Website" (change)="amountOwedChanged()">
              <mat-hint>https://venuewebsite.com</mat-hint>
              <mat-error *ngIf="amount_owed.hasError('numeric') && !amount_owed.pristine">
                Amount owed must be a valid postive number
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
