<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button routerLink="new" class="action-new-button">
        <mat-icon>note_add</mat-icon> Add New</button>
    </div>
  </div>
</div>
<div class="col-lg-12">
  <div class="card">
    <div class="card-header">
      <i class="fas fa-star"></i> Scoring
      <div class="card-info">
        <mat-slide-toggle class="card-showall" [(ngModel)]="showDefaultScoring" (change)="showDefaultScoringChanged($event.checked)">Show APC Default</mat-slide-toggle>
        <mat-icon [matMenuTriggerFor]="menu">help</mat-icon>
        <mat-menu #menu="matMenu">
          <app-filter-instructions></app-filter-instructions>
        </mat-menu>
      </div>
    </div>
    <div class="card-block card-admin">
      <div class="gridview-logic grid-scoring">
        <div class="gridview-padding">
          <ng-table [config]="config" (tableChanged)="onChangeTable(config)" [rows]="rows" [columns]="columns">
          </ng-table>
        </div>
      </div>
    </div>
    <div *ngIf="config.paging" class="card-footer page-footer">
      <div class="gridview-paginator-logic">
        <div class="mat-paginator-page-goto">
          <mat-form-field>
            <mat-select [ngModel]="pageIndex+1" (ngModelChange)="manualPageChanged($event)">
              <mat-option [value]="1">Page</mat-option>
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{mp}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator [length]="length" [pageSize]="itemsPerPage" [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex" (page)="onChangeTable(config, $event)" (numPages)="numPages = $event">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
