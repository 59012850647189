import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { LeaderboardService } from '../../../services/leaderboard.service';
import { GetLeaderboardResponse } from '../../../services/responses/leaderboard.responses';

@Component({
  templateUrl: './membership.html',
  styleUrls: ['./membership.css']
})
export class MembershipLeaderboardComponent implements OnInit {
  datePipe: DatePipe = new DatePipe('en-US');
  queryParamObservable: Subscription;
  private queryTerms = new Subject<any>();
  loading = false;

  public pageIndex = 0;
  public itemsPerPage = 25;
  public pageSizeOptions = [25, 50, 100, 250, 500, 1000, 2500];
  public maxSize = 5;
  public numPages = 0;
  public length = 0;
  public manualPages = [];

  rows: Array<any> = [];
  public columns: Array<any> = [];

  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: LeaderboardService
  ) {}

  public ngOnInit() {
    const leagueId: any = this.route.snapshot.paramMap.get('league_id');
    this.setColumns([
      {
        title: 'First Name',
        name: 'name',
        sort: 'asc',
        render: (value, row) => {
          return value; // '<app-player-profile-link [player]="record"></app-player-profile-link>';
        }
      },
      {
        title: 'Date',
        name: 'daterange',
        render: (value, row) => {
          return this.datePipe.transform(value);
        }
      }
    ]);

    this.queryParamObservable = this.queryTerms
      .pipe(
        // wait 200ms after each keystroke before considering the term
        debounceTime(200),

        // ignore new term if same as previous term
        distinctUntilChanged(),

        // switch to new search observable each time the term changes
        switchMap((params: any) => {
          return this.getLeaderboard(leagueId, params);
        })
      )
      .subscribe(response => {
        if (response) {
          this.rows = response.records;
          // this.pageIndex = response.pagination.page;
          // this.numPages = response.pagination.pageCount;
          this.length = response.pagination.rowCount;
          // this.itemsPerPage = response.pagination.pageSize;

          const pagesArray = [];
          const target = Math.ceil(this.length / this.itemsPerPage);
          for (let i = 0; i < target; i++) {
            pagesArray.push(i + 1);
          }

          this.manualPages = pagesArray;
          // if the response would have some rows (length), but we didn't get anything, it
          // means we ran off the end of the list.  This can happen if we filter on a later
          // page but get less results back.
          // Back up to where we would actually get some data and try again
          if (this.rows.length === 0 && this.length > 0) {
            // put us on the last page we'd get results for.
            this.pageIndex = Math.ceil(this.length / this.itemsPerPage) - 1;
            this.refreshTable();
          }
        }
      });
    this.patchQueryParams({});
  }

  private getLeaderboard(leagueId, params): Observable<GetLeaderboardResponse> {
    this.loading = true;

    return this.service
      .getMembership(leagueId, params)
      .pipe(finalize(() => (this.loading = false)));
  }

  protected setColumns(columns: Array<any>): void {
    this.columns = columns;
    this.config.sorting.columns = this.columns;
  }

  showAll() {
    this.patchQueryParams({ showAll: true });
  }

  manualPageChanged(newPage) {
    this.pageIndex = newPage - 1;
    this.refreshTable();
  }

  patchQueryParams(values) {
    this.itemsPerPage = values.ps || this.itemsPerPage || 25;
    this.pageIndex = values.page || 0;
    if (values.showAll === true) {
      // this.showAll = true;
      this.pageIndex = 0;
      this.itemsPerPage = 9999999;
    }

    const params: any = {
      pageSize: this.itemsPerPage,
      page: this.pageIndex + 1
    };

    this.queryTerms.next(params);
  }

  refreshTable() {
    this.onChangeTable(this.config);
  }

  public onChangeTable(
    config: any,
    page: any = { pageIndex: this.pageIndex, pageSize: this.itemsPerPage }
  ): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    this.itemsPerPage = page.pageSize;
    this.pageIndex = page.pageIndex;

    const params = {};
    let curIndex = 0;

    cloneDeep(this.config.sorting.columns)
      .sort((a, b) => {
        if (!a.sortIndex && !b.sortIndex) {
          return 0;
        }
        if (!a.sortIndex && b.sortIndex) {
          return 1;
        }
        if (a.sortIndex && !b.sortIndex) {
          return -1;
        }

        return a.sortIndex - b.sortIndex;
      })
      .forEach((column: any) => {
        if (column.sort) {
          if (column.sortIndex) {
            params['order_by[' + column.sortIndex + '][' + column.name + ']'] = column.sort;
          } else {
            params['order_by[' + curIndex + '][' + column.name + ']'] = column.sort;
            curIndex++;
          }
        }

        if (column.filtering && column.filtering.filterString) {
          params['filters[' + column.name + ']'] = column.filtering.filterString;
        }
      });

    params['pageSize'] = page.pageSize;
    params['page'] = page.pageIndex + 1;

    // shove this set of params into the observable queue.  It will be debounced and sent very soon!
    this.queryTerms.next(params);
  }
}
