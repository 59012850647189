import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../_guards/auth.guard';
import { CanDeactivateGuard } from '../_guards/can-deactivate.guard';
import { AdminComponent } from '../layouts/admin/admin.component';

import { AdDetailComponent } from './ad-detail/ad-detail.component';
import { AdsComponent } from './ads/ads.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DealerDetailComponent } from './dealer-detail/dealer-detail.component';
import { DealersComponent } from './dealers/dealers.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventsComponent } from './events/events.component';
import { LeaderboardsComponent } from './leaderboards/leaderboards.component';
import { PlayerDetailComponent } from './player-detail/player-detail.component';
import { PlayerSettingComponent } from './player-profile/player-setting.component';
import { PlayersComponent } from './players/players.component';
import { RegionDetailComponent } from './region-detail/region-detail.component';
import { RegionsComponent } from './regions/regions.component';
import { AdsDetailReportComponent } from './report-detail/ads-report/ads-detail-report.component';
import { DealerDetailReportComponent } from './report-detail/dealer-report/dealer-detail-report.component';
import { PayrollDetailReportComponent } from './report-detail/payroll-report/payroll-detail-report.component';
import { ReportDetailComponent } from './report-detail/report-detail.component';
import { ReportsComponent } from './reports/reports.component';
import { ScoringDetailComponent } from './scoring-detail/scoring-detail.component';
import { ScoringComponent } from './scoring/scoring.component';
import { SeasonDetailComponent } from './season-detail/season-detail.component';
import { SeasonsComponent } from './seasons/seasons.component';
import { StaffDetailComponent } from './staff-detail/staff-detail.component';
import { StaffComponent } from './staff/staff.component';
import { TournamentDetailComponent } from './tournament-detail/tournament-detail.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { UpgradeConfirmationComponent } from './upgrade-confirmation/upgrade-confirmation.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { VenueDetailComponent } from './venue-detail/venue-detail.component';
import { VenuesComponent } from './venues/venues.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'dashboard',
            component: DashboardComponent,
            data: { title: 'Dashboard | Poker Leaderboards' }
          },
          {
            path: 'upgrade',
            component: UpgradeComponent,
            data: { title: 'Upgrade to Executive | Poker Leaderboards' }
          },
          {
            path: 'confirm-order',
            component: UpgradeConfirmationComponent,
            data: { title: 'Upgrade to Executive | Poker Leaderboards' }
          },
          {
            path: 'leaderboards',
            component: LeaderboardsComponent,
            data: { title: 'My Leaderboards | Poker Leaderboards' }
          },
          {
            path: 'tournaments',
            component: TournamentsComponent,
            data: { title: 'Tournaments | Poker Leaderboards' }
          },
          {
            path: 'tournaments/:id',
            component: TournamentDetailComponent,
            canDeactivate: [CanDeactivateGuard],
            data: { title: 'Tournament Details | Poker Leaderboards' }
          },
          {
            path: 'players',
            component: PlayersComponent,
            data: { title: 'Players | Poker Leaderboards' }
          },
          {
            path: 'players/:id',
            component: PlayerDetailComponent,
            data: { title: 'Player Detail | Poker Leaderboards' }
          },

          {
            path: 'profile',
            component: PlayerSettingComponent,
            data: { title: 'My Settings | Poker Leaderboards' }
          },

          {
            path: 'dealers',
            component: DealersComponent,
            data: { title: 'Dealers | Poker Leaderboards' }
          },
          {
            path: 'dealers/:id',
            component: DealerDetailComponent,
            data: { title: 'Dealer Details | Poker Leaderboards' }
          },

          {
            path: 'staff',
            component: StaffComponent,
            data: { title: 'Staff | Poker Leaderboards' }
          },
          {
            path: 'staff/:id',
            component: StaffDetailComponent,
            data: { title: 'Staff Details | Poker Leaderboards' }
          },

          {
            path: 'venues',
            component: VenuesComponent,
            data: { title: 'Venues | Poker Leaderboards' }
          },
          {
            path: 'venues/:id',
            component: VenueDetailComponent,
            data: { title: 'Venue Details | Poker Leaderboards' }
          },

          {
            path: 'seasons',
            component: SeasonsComponent,
            data: { title: 'Seasons | Poker Leaderboards' }
          },
          {
            path: 'seasons/:id',
            component: SeasonDetailComponent,
            data: { title: 'Season Details | Poker Leaderboards' }
          },

          {
            path: 'scoring',
            component: ScoringComponent,
            data: { title: 'Scoring Systems | Poker Leaderboards' }
          },
          {
            path: 'scoring/:id',
            component: ScoringDetailComponent,
            data: { title: 'Scoring System Details | Poker Leaderboards' }
          },

          {
            path: 'events',
            component: EventsComponent,
            data: { title: 'Events | Poker Leaderboards' }
          },
          {
            path: 'events/:id',
            component: EventDetailComponent,
            data: { title: 'Event Details | Poker Leaderboards' }
          },

          { path: 'ads', component: AdsComponent, data: { title: 'Ads | Poker Leaderboards' } },
          {
            path: 'ads/:id',
            component: AdDetailComponent,
            data: { title: 'Ad Details | Poker Leaderboards' }
          },

          {
            path: 'regions',
            component: RegionsComponent,
            data: { title: 'Regions | Poker Leaderboards' }
          },
          {
            path: 'regions/:id',
            component: RegionDetailComponent,
            data: { title: 'Region Details | Poker Leaderboards' }
          },
          {
            path: 'reports',
            component: ReportsComponent,
            data: { title: 'Reports | Poker Leaderboards' }
          },
          {
            path: 'reports/:id',
            component: ReportDetailComponent,
            data: { title: 'Report Details | Poker Leaderboards' }
          },
          {
            path: 'reports/:id/dealer-detail/:dealer_id',
            component: DealerDetailReportComponent,
            data: { title: 'Dealer Detail Report | Poker Leaderboards' }
          },
          {
            path: 'reports/:id/ads-detail',
            component: AdsDetailReportComponent,
            data: { title: 'Ads Detail Report | Poker Leaderboards' }
          },
          {
            path: 'reports/:id/payroll-detail/:user_id',
            component: PayrollDetailReportComponent,
            data: { title: 'Payroll Detail Report | Poker Leaderboards' }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecureRoutingModule {}
