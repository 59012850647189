import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { League } from '../models/league';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse } from './responses/generic.responses';
import { GetVenueDetailsResponse } from './responses/venue.responses';

@Injectable()
export class VenueDetailService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;

  constructor(private http: HttpClient, protected logger: NGXLogger) {
    super(logger);
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    const venueId = params.venueId;
    const seasonId = params.seasonId;
    delete params.venueId;
    delete params.seasonId;
    return this.getVenueDetails(venueId, seasonId, params).pipe(
      map(r => {
        return {
          data: r.results,
          pagination: r.pagination
        };
      })
    );
  }

  public getVenueDetails(
    venueId: UUID,
    seasonId: UUID,
    params: any
  ): Observable<GetVenueDetailsResponse> {
    this.logger.debug('Called getVenueDetails');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetVenueDetailsResponse | any>(
        `${environment.VENUES_API}/p/venue/${venueId}/season/${seasonId}/detail`,
        options
      )
      .pipe(tap(response => this.logger.debug('getVenues response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
