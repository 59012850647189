import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { LeagueAccountTypeForm } from '../../../models/forms/league-account-type.form';
import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { PermissionManager } from '../../../models/permission.manager';
import { UUID } from '../../../models/uuid';
import { AuthService } from '../../../services/auth.service';
import { LeagueService } from '../../../services/league.service';
import { LoaderService } from '../../../services/spinner.service';

@Component({
  selector: 'app-league-detail',
  templateUrl: './league-detail.component.html'
})
export class LeagueDetailComponent implements OnInit {
  myForm: FormGroup;
  submitted = false;
  model = new League();
  isAdmin = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: LeagueService,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionManager: PermissionManager
  ) {
    this.myForm = fb.group({
      name: '',
      account_type: '',
      number: '',
      created_at: '',
      updated_at: '',
      num_members: '',
      total_owed: '',
      due_date: '',
      User: fb.group({
        first_name: '',
        last_name: '',
        email: '',
        username: ''
      })
    });

    this.myForm.disable();
    this.isAdmin = permissionManager.isAdmin;
  }

  get league_logo() {
    if (this.model.LeagueSettings && this.model.LeagueSettings.logo) {
      return `${environment.AVATARS_API}/p/league/${
        this.model.id
      }/logo?buster=${new Date().getTime()}`;
    }

    return null;
  }

  loginAs() {
    if (
      confirm(
        `Are you sure you want to login as this user: ${this.model.User.first_name} ${this.model.User.last_name}?`
      )
    ) {
      this.authService
        .loginAsUser(
          this.model.owner_user_id.toString(),
          this.leagueStorage.getSelectedLeague().value.id.toString()
        )
        .subscribe(data => {
          this.permissionManager.maybeRedirect('/admin/dashboard', true);
        });
    }
  }

  ngOnInit() {
    const id: any = this.route.snapshot.paramMap.get('id');
    this.service.getLeague(<UUID>id).subscribe(response => {
      this.model = response.league;
      this.myForm.patchValue(this.model);
    });
  }

  public suspendLeague() {
    this._submit({ account_type: 'SUSPENDED' });
  }

  public unsuspendLeague() {
    this._submit({ account_type: 'FREE' });
  }

  public _submit(value: LeagueAccountTypeForm): void {
    if (
      confirm(`Are you sure you want to set this league's account type to ${value.account_type}?`)
    ) {
      if (!this.submitted) {
        // filter out all roles we didn't check
        this.loaderService.displayLoader(true);
        // submit to API

        this.service
          .updateLeagueAccountType(this.model.id, value)
          .pipe(finalize(() => this.loaderService.displayLoader(false)))
          .subscribe(
            data => {
              this.model.account_type = data.account_type;
              this.router.navigate(['/system/leagues/' + this.model.id]);
            },
            error => {
              console.error('err', error);
            }
          );
      }
    }
  }

  // onDelete() {
  //   if (
  //     confirm(`Are you sure you want to disassociate "${this.model.first_name} ${this.model.last_name}"
  //    from this league? (Their login will not be deleted)`)
  //   ) {
  //     this.loaderService.displayLoader(true);
  //     this.service
  //       .deleteStaff(this.model.id)
  //       .pipe(finalize(() => this.loaderService.displayLoader(false)))
  //       .subscribe(response => {
  //         this.router.navigate(['/admin/staff']);
  //       });
  //   }
  // }
}
