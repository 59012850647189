<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
    </div>
  </div>
</div>
<app-report-header [report]="report"></app-report-header>
  <div *ngFor="let league of reportData.leagues">
    <div class="col-12">
        <div class="card report-card">
          <div class="card-header">
            {{league.name}}
          </div>
          <div class="card-block breakdown-card">
            <div class="col-12">
            <table *ngIf="!report.param_is_summary">
              <tr class="report_header_row report_rows">
                <td>Date
                </td>
                <td>Venue Tournaments
                </td>
                <td># of Players
                </td>
                <td># of Tables
                </td>
                <td># of Bonus Tokens
                </td>
                <td># of Break Tokens
                </td>
              </tr>

              <tr class="report_item_row report_rows" *ngFor="let row of league.rows">
                <td>
                  <span>{{row.start_date | date}}</span>
                  <!--{0 :M/d/yyyy}-->
                </td>
                <td>
                  <span>{{row.venue}}</span>
                </td>
                <td>
                  <span>{{row.num_players | number}}</span>
                </td>
                <td>
                  <span>{{row.num_tables | number}}</span>
                </td>
                <td>
                  <span>{{row.num_bonus_chips_1 | number}}</span>
                </td>
                <td>
                  <span>{{row.num_bonus_chips_2 | number}}</span>
                </td>
              </tr>
              <tr class="report_footer_row report_rows">
                <td></td>
                <td># of Tournaments:
                  <span>{{league.total_tournaments | number}}</span>
                </td>
                <td>
                  <span>{{league.total_players | number}}</span>
                </td>
                <td>
                  <span>{{league.total_tables | number}}</span>
                </td>
                <td>
                  <span>{{league.total_bonus_chips_1 | number}}</span>
                </td>
                <td>
                  <span>{{league.total_bonus_chips_2 | number}}</span>
                </td>
              </tr>
            </table>

            <table *ngIf="report.param_is_summary">
              <tr class="report_header_row report_rows">
                <td>Venue Tournaments
                </td>
                <td># of Players
                </td>
                <td># of Tables
                </td>
                <td># of Bonus Tokens
                </td>
                <td># of Break Tokens
                </td>
              </tr>

              <tr class="report_item_row report_rows">
                <td>
                  <span>{{league.total_tournaments | number}}</span>
                </td>
                <td>
                  <span>{{league.total_players | number}}</span>
                </td>
                <td>
                  <span>{{league.total_tables | number}}</span>
                </td>
                <td>
                  <span>{{league.total_bonus_chips_1 | number}}</span>
                </td>
                <td>
                  <span>{{league.total_bonus_chips_2 | number}}</span>
                </td>
              </tr>
            </table>
          </div>

        </div>
        </div>
          </div>
  </div>
