import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { NglModule } from 'ng-lightning/ng-lightning';
import {
  AccordionModule,
  BsDatepickerModule,
  CollapseModule,
  PaginationModule
} from 'ngx-bootstrap';

import { SystemAuthGuard } from '../../_guards/system.auth.guard';
import { AuthInterceptor } from '../../_interceptors/auth.interceptor';
import { Ng2TableModule } from '../../components/ng2-table/ng2-table';
import { CustomModule } from '../../custom.module';
import { PipesModule } from '../../pipes.module';
import { SystemAdService } from '../../services/system-ads.service';
import { SecureModule } from '../secure.module';

import { SystemAdDetailComponent } from './ad-detail/system-ad-detail.component';
import { SystemAdsComponent } from './ads/system-ads.component';
import { LeaguesComponent } from './leagues/leagues.component';
import { LeagueDetailComponent } from './league-detail/league-detail.component';
import { SystemMessageDetailComponent } from './message-detail/message-detail.component';
import { SystemMessagesComponent } from './messages/messages.component';
import { SystemStatsComponent } from './stats/system-stats.component';
import { SystemRoutingModule } from './system-routing.module';
@NgModule({
  declarations: [
    LeaguesComponent,
    LeagueDetailComponent,
    SystemStatsComponent,
    SystemMessagesComponent,
    SystemMessageDetailComponent,
    SystemAdsComponent,
    SystemAdDetailComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NglModule,
    CustomModule,
    AccordionModule,
    BrowserModule,
    BsDatepickerModule,
    CollapseModule,
    CommonModule,
    FormsModule,
    Ng2TableModule,
    NguiAutoCompleteModule,
    PaginationModule,
    PipesModule,
    ReactiveFormsModule,
    SystemRoutingModule,
    SecureModule
  ],
  providers: [
    SystemAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    SystemAdService
  ],
  entryComponents: []
})
export class SystemModule {}
