import { Component, OnDestroy, OnInit } from '@angular/core';

import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { SystemAdService } from '../../../services/system-ads.service';

import { YesNoPipe } from '../../../_pipes/yes-no.pipe';
import { EditLinkTableCellComponent } from '../../../components/edit-link.cell.component';
import { PageableComponent } from '../../../components/pageable.component';

@Component({
  selector: 'app-system-ads',
  templateUrl: './system-ads.component.html'
})
export class SystemAdsComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();

  constructor(protected leagueStorage: LeagueStorage, protected adService: SystemAdService) {
    super(adService, leagueStorage);
    this.setColumns([
      {
        title: 'Name',
        name: 'name',
        sort: 'asc',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Ad Text',
        name: 'ad_text'
      },
      {
        title: 'Ad Location',
        name: 'location',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Active',
        name: 'is_active',
        filtering: {
          filterString: 'true',
          boolean: true
        },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
