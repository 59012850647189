import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { finalize } from 'rxjs/operators';

import { UniqueNameValidator } from '../../../_validators/unique-name.validator';
import { ChangeLeagueNameForm } from '../../../models/forms/change-league-name.form';
import { LeagueStorage } from '../../../models/league.storage';
import { AuthService } from '../../../services/auth.service';
import { LeagueService } from '../../../services/league.service';
import { LoaderService } from '../../../services/spinner.service';

@Component({
  selector: 'app-dialog-newleague',
  templateUrl: './new-league.dialog.component.html',
  styleUrls: ['./new-league.dialog.component.css']
})
export class NewLeagueDialogComponent implements OnInit {
  myForm: FormGroup;
  submitted = false;
  constructor(
    private dialogRef: MatDialogRef<NewLeagueDialogComponent>,
    private uniqueNameValidator: UniqueNameValidator,
    private fb: FormBuilder,
    private service: LeagueService,
    private leagueStorage: LeagueStorage,
    private authService: AuthService,
    private loaderService: LoaderService
  ) {
    this.myForm = fb.group({
      name: ['', Validators.required, uniqueNameValidator.create().bind(this)]
    });
  }

  get name(): FormControl {
    return this.myForm.get('name') as FormControl;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {}

  save(value: ChangeLeagueNameForm) {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      this.service
        .createLeague(value)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(data => {
          this.dialogRef.close();
          this.leagueStorage.addLeague(data.league);

          // refresh the token so we get an updated permissions payload
          return this.authService.refresh().subscribe();
        });
    }
  }
}
