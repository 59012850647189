
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CompareValidator } from '../../_validators/compare.validator';
import { DateValidator } from '../../_validators/date.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { SeasonForm } from '../../models/forms/season.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Season } from '../../models/season';
import { UUID } from '../../models/uuid';
import { SeasonService } from '../../services/season.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  selector: 'app-season-detail',
  templateUrl: './season-detail.component.html',
  styleUrls: ['./season-detail.component.css']
})
export class SeasonDetailComponent implements OnInit, OnDestroy {
  busy: Subscription;
  isNewModel = true;
  _propertySubscription: Subscription;
  myForm: FormGroup;
  minDate = new Date('1900-01-01');
  maxDate = new Date('9999-12-31');
  dateFormat = '';
  submitted = false;
  model = new Season();
  league: League;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: SeasonService,
    private dateValidator: DateValidator,
    private compareValidator: CompareValidator,
    private uniqueNameValidator: UniqueNameValidator,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute
  ) {
    this.dateFormat = environment.DATEPICKER_FORMAT;
    this.myForm = fb.group(
      {
        id: '',
        name: ['', Validators.required, uniqueNameValidator.create().bind(this)],
        start_date: ['', dateValidator.create({ allowNulls: true })],
        end_date: ['', dateValidator.create({ allowNulls: true })],
        is_active: true,
        exclude_from_points: false
      },
      {
        validator: compareValidator.create('start_date', 'end_date', 'startLTEEnd', {
          type: 'date',
          operator: 'lte',
          allowNulls: true
        })
      }
    );
  }

  // handy access to form fields
  get name(): any {
    return this.myForm.get('name');
  }
  get start_date(): any {
    return this.myForm.get('start_date');
  }
  get end_date(): any {
    return this.myForm.get('end_date');
  }
  get is_active(): any {
    return this.myForm.get('is_active');
  }
  get exclude_from_points(): any {
    return this.myForm.get('exclude_from_points');
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getSeason();
      }
    });
  }

  getSeason(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getSeason(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = response.season; // only used in the Name check, to allow the original name to work.
        this.resetForm(this.model);
      });
    }
  }

  public submitAndClose(value: SeasonForm) {
    this._submit(value, (model: Season) => {
      this.router.navigate(['/admin/seasons']);
    });
  }

  public submit(value: SeasonForm) {
    this._submit(value, (model: Season) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/seasons/' + model.id]);
    });
  }

  private resetForm(season: Season) {
    this.myForm.reset();
    this.myForm.setValue(new SeasonForm(season), { onlySelf: true });
  }

  public _submit(value: SeasonForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createSeason(value)
        : this.service.updateSeason(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.season);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.name
        }? Any data that depends on this record INCLUDING TOURNAMENTS will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteSeason(this.model.id).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/seasons']);
        });
    }
  }
}
