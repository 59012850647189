
import {finalize} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';
import { Dealer } from '../../models/dealer';
import { DealerForm } from '../../models/forms/dealer.form';
import { MergeDealerForm } from '../../models/forms/merge-dealer.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { UUID } from '../../models/uuid';
import { AlertService } from '../../services/common/alert.service';
import { DealerTournamentService } from '../../services/dealer-tournament.service';
import { DealerService } from '../../services/dealer.service';
import { LoaderService } from '../../services/spinner.service';
import { formatDuration } from '../../util/parse.duration';

@Component({
  selector: 'app-dealer-detail',
  templateUrl: './dealer-detail.component.html',
  styleUrls: ['./dealer-detail.component.css']
})
export class DealerDetailComponent extends PageableComponent implements OnInit, OnDestroy {
  isNewModel = true;
  datePipe: DatePipe = new DatePipe('en-US');
  _propertySubscription: Subscription;
  myForm: FormGroup;
  mergeForm: FormGroup;
  submitted = false;
  model = new Dealer();
  league: League;
  showMerge = false;
  allDealers: Dealer[] = [];

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: DealerService,
    protected dealerTournamentService: DealerTournamentService,
    private uniqueNameValidator: UniqueNameValidator,
    protected leagueStorage: LeagueStorage,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    super(dealerTournamentService, leagueStorage);
    this.myForm = fb.group({
      id: '',
      name: ['', Validators.required, uniqueNameValidator.create().bind(this)],
      is_active: true
    });
    this.mergeForm = fb.group({
      id: '',
      merge_dealer: ['', Validators.required]
    });

    this.setColumns([
      {
        title: 'Venue',
        name: 'Tournament.Venue.name',
        filtering: false,
        component: {
          class: EditLinkTableCellComponent,
          data: {
            navigateTo: row => ['/admin', 'tournaments', row.tournament_id]
          }
        }
      },
      {
        title: 'Season',
        name: 'Tournament.Season.name',
        filtering: false
      },
      {
        title: 'Date',
        name: 'Tournament.start_date',
        filtering: false,
        sort: 'desc',
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Name',
        name: 'Tournament.name',
        filtering: false
      },
      {
        title: 'Time',
        name: 'time_in_seconds',
        filtering: false,
        render: (value, row) => {
          return formatDuration(value);
        }
      }
    ]);
  }

  // handy access to form fields
  get name(): any {
    return this.myForm.get('name');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    console.log('destroying dealer-detail component');
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getDealer();
      }
    });
  }

  getDealer(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getDealer(<UUID>id).subscribe(response => {
        this.setDefaultParams({ dealer_id: id });
        this.isNewModel = false;
        this.model = response.dealer; // only used in the Name check, to allow the original name to work.
        this.service
          .getDealers({
            select: 'id,name',
            pageSize: 100000
          })
          .subscribe(dealersResponse => {
            this.allDealers = dealersResponse.dealers.filter(dealer => dealer.id !== this.model.id);
            this.allDealers.sort((a, b) => a.name.localeCompare(b.name));
          });
        this.resetForm(this.model);
      });
    }
  }

  public submitAndClose(value: DealerForm) {
    this._submit(value, (model: Dealer) => {
      this.router.navigate(['/admin/dealers']);
    });
  }

  public submit(value: DealerForm) {
    this._submit(value, (model: Dealer) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/dealers/' + model.id]);
    });
  }

  private resetForm(dealer: Dealer) {
    this.showMerge = false;
    this.mergeForm.reset();
    if (dealer) {
      this.mergeForm.patchValue({ id: dealer.id });
    }
    this.myForm.reset();
    this.myForm.setValue(new DealerForm(dealer), { onlySelf: true });
  }

  public _submit(value: DealerForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createDealer(value)
        : this.service.updateDealer(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.dealer);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  nameFormatter(data: any) {
    return data.name;
  }

  mergeDealer(value: MergeDealerForm) {
    if (value.merge_dealer) {
      value.merge_dealer_id = value.merge_dealer.id;
    }
    if (this.mergeForm.valid && !this.submitted) {
      if (
        confirm(
          `Are you sure you want to merge ${this.model.name} into ${value.merge_dealer.name}?`
        )
      ) {
        this.loaderService.displayLoader(true);
        // submit to API
        this.service
          .mergeDealer(value).pipe(
          finalize(() => this.loaderService.displayLoader(false)))
          .subscribe(
            response => {
              const oldDealer = this.model.name;
              this.model = response.dealer;
              const newDealer = this.model.name;
              this.resetForm(this.model);

              this.setDefaultParams({ dealer_id: this.model.id });
              this.router.navigate(['/admin/dealers/' + this.model.id]);
              const message = `Successfully merged ${oldDealer} into ${newDealer}`;
              this.alertService.success(message);
            },
            error => {
              console.error('err', error);
            }
          );
      }
    }

    return false;
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.name
        }? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteDealer(this.model.id).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/dealers']);
        });
    }
  }
}
