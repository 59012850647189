import { Component, Input, OnChanges } from '@angular/core';

import { Report } from '../../../models/report';
import { TournamentReport } from '../../../services/responses/report.responses';

@Component({
  selector: 'app-tournament-report',
  templateUrl: './tournament-report.component.html'
})
export class TournamentReportComponent implements OnChanges {
  @Input() report: Report;
  @Input() reportData: TournamentReport;

  constructor() {}

  ngOnChanges() {
    if (this.reportData) {
      this.reportData.leagues.forEach(league => {
        league.total_tournaments = 0;
        league.total_players = 0;
        league.total_tables = 0;
        league.total_bonus_chips_1 = 0;
        league.total_bonus_chips_2 = 0;
        league.rows.forEach(row => {
          league.total_tournaments += parseFloat(row.num_tournaments);
          league.total_players += parseFloat(row.num_players);
          league.total_tables += parseFloat(row.num_tables);
          league.total_bonus_chips_1 += parseFloat(row.num_bonus_chips_1);
          league.total_bonus_chips_2 += parseFloat(row.num_bonus_chips_2);
        });
      });
    }
  }
}
