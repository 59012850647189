import { Staff } from '../staff';
import { UUID } from '../uuid';
export class StaffForm {
  public id: UUID;
  public first_name: string;
  public last_name: UUID;
  public email: string;
  public username: string;
  public password: string;
  public show_in_listing: boolean;
  public roles: {
    role_id: UUID;
    is_checked: boolean;
  }[];

  constructor(model: Staff) {
    this.id = model.id;
    this.first_name = model.first_name;
    this.last_name = model.last_name;
    this.email = model.email;
    this.username = model.username;
    this.password = '';
    this.show_in_listing = model.show_in_listing;
    this.roles = [];
  }
}
