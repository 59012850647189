import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { EventForm } from '../models/forms/event.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import {
  GetCriteriaInfoResponse,
  GetEventsResponse,
  GetEventResponse
} from './responses/event.responses';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';

@Injectable()
export class EventService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init) it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.EVENTS_API}/league/${this.league.id}/event/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getEvents(params).pipe(
      map(r => {
        return {
          data: r.events,
          pagination: r.pagination
        };
      })
    );
  }

  public getPublicEvent(eventId: UUID): Observable<GetEventResponse | any> {
    this.logger.debug('Called getPublicEvent');
    this.logger.debug('calling get event service');

    return this.http
      .get<GetEventResponse>(`${environment.EVENTS_API}/p/event/${eventId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getPublicEvent response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getCriteriaInfo(eventId: UUID): Observable<GetCriteriaInfoResponse | any> {
    this.logger.debug('Called getCriteriaInfo');
    this.logger.debug('calling get event service');

    return this.http
      .get<GetCriteriaInfoResponse>(
        `${environment.EVENTS_API}/p/event/${eventId}/criteria-info`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getCriteriaInfo response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getEvents(params: any): Observable<GetEventsResponse> {
    this.logger.debug('Called getEvents');
    if (this.league == null) {
      this.logger.debug('returning default events');
      return of(this.defaultResponse('events'));
    }
    this.logger.debug('calling get events service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetEventsResponse | any>(
        `${environment.EVENTS_API}/league/${this.league.id}/event`,
        options
      )
      .pipe(tap(response => this.logger.debug('getEvents response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getEvent(eventId: UUID): Observable<GetEventResponse> {
    this.logger.debug('Called getEvent');
    if (this.league == null) {
      this.logger.debug('returning default event');
      return of({ event: null });
    }

    this.logger.debug('calling get event service');
    return this.http
      .get<GetEventResponse | any>(
        `${environment.EVENTS_API}/league/${this.league.id}/event/${eventId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getEvent response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createEvent(value: EventForm): Observable<GetEventResponse | any> {
    this.logger.debug('Called createEvent');
    return this.http
      .post<GetEventResponse>(
        `${environment.EVENTS_API}/league/${this.league.id}/event`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createEvent response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateEvent(value: EventForm): Observable<GetEventResponse | any> {
    this.logger.debug('Called updateEvent');
    return this.http
      .patch<GetEventResponse>(
        `${environment.EVENTS_API}/league/${this.league.id}/event/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateEvent response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteEvent(eventId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteEvent');
    return this.http
      .delete<any>(
        `${environment.EVENTS_API}/league/${this.league.id}/event/${eventId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deleteEvent response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
