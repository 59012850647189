<div class="report-logic event-report">
  <div class="report-padding">
    <div class="report-header">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            {{event?.name}}
          </div>
          <div class="card-block">
            <div class="col-lg-4" style="padding-right:5% !important; margin-bottom: 10px;">
              <div class="report-row right">
                Event Date:
                <span class="report-item-text">{{event?.event_date | date}}</span>
              </div>
              <div class="report-row right">
                Start Qualify:
                <span class="report-item-text">{{event?.start_qualify | date}}</span>
              </div>
              <div class="report-row right">
                End Qualify:
                <span class="report-item-text">{{event?.end_qualify | date}}</span>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="report-row report-title" *ngIf="criteriaInfoArray">
                To qualify for this event you must meet one of the following criteria:
              </div>
              <div style="margin-top: 5px">
                <div *ngFor="let criteriaInfo of criteriaInfoArray; let i = index;">
                  <span>{{i + 1}}. {{criteriaInfo.text}}
                    <i *ngIf="!criteriaInfo.dateIndependent">[final after qualification period]</i>
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div class="card-footer">
            Event Details
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-header">Qualifying Players</div>
          <div class="card-block">
            <div class="col-md-12">
              <div class="report-row" *ngIf="criteriaInfoArray">
                <div *ngFor="let qualified of allQualified; let i = index">
                  <span>{{ i + 1 }}. {{ qualified.name }}</span>
                </div>
                <span *ngIf="allQualified.length == 0"
                  >No players have qualified at this time.</span
                >
              </div>
            </div>
          </div>
          <div class="card-footer">Listed players meet configured criteria.</div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">Qualifying Stats</div>
          <div class="card-block">
            <div class="col-md-12">
              <div class="report-row" *ngIf="criteriaInfoArray">
                <div *ngFor="let criteriaInfo of criteriaInfoArray">
                  <div id="lblQualified" *ngFor="let criteriaResult of criteriaInfo.results">
                    <div class="report-title">{{ criteriaInfo.text }}</div>
                    <i *ngIf="upcoming">[final after qualification period]</i>
                    <ol>
                      <li>
                        <span *ngIf="criteriaResult.venues"
                          >{{ criteriaResult.venues }} Qualifiers</span
                        >
                        <ol *ngIf="criteriaResult.rows">
                          <li *ngFor="let row of criteriaResult.rows; let j = index">
                            {{ j + 1 }}: {{ row.name }}: {{ row.criteria_result | number }}
                          </li>
                        </ol>

                        <span *ngIf="criteriaResult.rows.length == 0"
                          >No players qualified for this venue yet</span
                        >
                      </li>
                    </ol>

                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">Explanation of player qualifications.</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
  export interface CriteriaResult { criteria_result: number; id: UUID; name: string; } export interface CriteriaResults { venues?:
  Venue[]; // could be null in the case of a non-venue specific criteria. rows: CriteriaResult[]; } export interface CriteriaInfo
  { results: CriteriaResults[]; // qualified_rows: CriteriaResult[]; text: string; // overall explanation rank: number; threshold:
  number; // if the criteria_result is >= threshold, then they go into qualified_rows } export interface GetCriteriaInfoResponse
  { criteria_info: CriteriaInfo[]; }
-->
