import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

import { Event } from '../../models/event';
import { EventService } from '../../services/event.service';
import { CriteriaInfo, CriteriaResult } from '../../services/responses/event.responses';

@Component({
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {
  event: Event;
  criteriaInfoArray: CriteriaInfo[];
  upcoming = false;
  allQualified: CriteriaResult[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService
  ) {}

  ngOnInit() {
    const id: any = this.route.snapshot.paramMap.get('id');
    forkJoin([
      this.eventService.getPublicEvent(id),
      this.eventService.getCriteriaInfo(id)
    ]).subscribe(([eventResponse, criteriaInfoResponse]) => {
      this.event = eventResponse.event;
      this.criteriaInfoArray = criteriaInfoResponse.criteria_info;

      this.allQualified = _(this.criteriaInfoArray)
        .flatMap(x => x.results)
        .flatMap(x => x.rows)
        .filter(x => x.is_qualifying)
        .uniqBy('id')
        .sortBy('name')
        .value();

      this.upcoming = moment().isBefore(moment(this.event.end_qualify));
    });
  }
}
