<div class="col-12">
  <div class="card leaderboard-ad">
    <div class="card-block">
      <app-ad-spot [leagueId]="venue?.league_id" location="venue"> </app-ad-spot>
    </div>
  </div>
  <div class="card card-leaderboard-details">
    <div class="card-header leaderboard-card-head">
      <span class="leaderboard-card-leaguename">{{ venue?.name }}</span>
      <div class="leaderboard-card-head-type">Season</div>
      <div class="leaderboard-card-head-date">{{ season?.name }}</div>
    </div>
    <div class="card-block">
      <div class="leaderboard-gridview-logic venue-details-table">
        <div class="leaderboard-loading" *ngIf="loading">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <ng-table
          [config]="config"
          (tableChanged)="onChangeTable(config)"
          [rows]="rows"
          [columns]="columns"
          *ngIf="!loading"
        >
        </ng-table>
        <div *ngIf="config.paging" class="card-footer page-footer">
          <div class="gridview-paginator-logic">
            <div class="mat-paginator-page-goto">
              <div class="mat-paginator-page-goto-label">Page:</div>
              <mat-form-field>
                <mat-select [ngModel]="pageIndex + 1" (ngModelChange)="manualPageChanged($event)">
                  <mat-option *ngFor="let mp of manualPages" [value]="mp">{{ mp }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-paginator
              [length]="length"
              [pageSize]="itemsPerPage"
              [showFirstLastButtons]="true"
              [pageSizeOptions]="pageSizeOptions"
              [pageIndex]="pageIndex"
              (page)="onChangeTable(config, $event)"
              (numPages)="numPages = $event"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="leaderboard-brand-logic">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
