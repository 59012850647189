
import {finalize} from 'rxjs/operators';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';

import { CompareValidator } from '../../../_validators/compare.validator';
import { ReportForm } from '../../../models/forms/report.form';
import { ReportService } from '../../../services/report.service';
import { LoaderService } from '../../../services/spinner.service';

@Component({
  selector: 'app-dialog-newleague',
  templateUrl: './new-report.dialog.component.html',
  styleUrls: ['./new-report.dialog.component.css']
})
export class NewReportDialogComponent implements OnInit {
  myForm: FormGroup;
  submitted = false;
  constructor(
    private dialogRef: MatDialogRef<NewReportDialogComponent>,
    private compareValidator: CompareValidator,
    private fb: FormBuilder,
    private service: ReportService,
    private loaderService: LoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.myForm = fb.group(
      {
        report_type: ['payroll', Validators.required],
        param_league_id: '',
        start_date: [
          moment()
            .add(-1, 'week')
            .toDate(),
          Validators.required
        ],
        end_date: [moment().toDate(), Validators.required],
        param_venue_id: '', // used by: tournaments
        param_is_summary: false, // used by: tournaments, ads
        param_ad_location: '', // used by: ads
        param_ad_id: '' // used by: ads
      },
      {
        validator: compareValidator.create('start_date', 'end_date', 'startLTEEnd', {
          type: 'date',
          operator: 'lte',
          allowNulls: false
        })
      }
    );
  }

  // leagues: this.leagues,
  // venues: this.venues,
  // ads: this.ads,
  // league: this.league,
  // adLocations: AdService.AD_LOCATIONS

  get param_league_id(): FormControl {
    return this.myForm.get('param_league_id') as FormControl;
  }
  get report_type(): FormControl {
    return this.myForm.get('report_type') as FormControl;
  }
  get start_date(): FormControl {
    return this.myForm.get('start_date') as FormControl;
  }
  get end_date(): FormControl {
    return this.myForm.get('end_date') as FormControl;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.param_league_id.patchValue(this.data.league.id);
    this.param_league_id.markAsDirty();
  }

  save(value: ReportForm) {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);

      this.service
        .createReport(value).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(data => {
          this.dialogRef.close(data);
        });
    }
  }
}
