import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppStorage } from '../../models/app.storage';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';
import { AuthService } from '../../services/auth.service';
import { LeagueService } from '../../services/league.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit, OnDestroy {
  loading: boolean;
  leagues: League[] = [];
  selectedLeague: League;
  leagueSubscription: Subscription;
  selectedLeagueSubscription: Subscription;
  leagueIdSubscription: Subscription;
  canSeeProfile = false;
  canSeeAdminLeagueListing = false;
  canSeeAdminAds = false;
  canSeeAdminSystemMessages = false;
  canSeeAdminStats = false;
  showDefaultScoring = false;

  constructor(
    private loaderService: LoaderService,
    public storage: AppStorage,
    public leagueStorage: LeagueStorage,
    private leagueService: LeagueService,
    private authService: AuthService,
    private logger: NGXLogger,
    private router: Router,
    private permissionManager: PermissionManager
  ) {
    this.loading = false;
  }

  public ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.loading = val));
    // on init, go to get list of leagues and set them.

    this.leagueSubscription = this.leagueStorage.getLeagues().subscribe(leagues => {
      this.leagues = leagues;
    });

    this.selectedLeagueSubscription = this.leagueStorage.getSelectedLeague().subscribe(value => {
      this.selectedLeague = value;
      if (this.selectedLeague == null && this.leagues.length > 0) {
        const leagueId =
          (this.storage.leagueIds && this.storage.leagueIds[0]) || this.leagues[0].id;
        this.selectedLeague = this.leagues.find(x => x.id === leagueId);

        this.leagueStorage.setSelectedLeague(this.selectedLeague); // todotodo
      }
      if (this.selectedLeague) {
        this.leagueService.getLeagueSettings(this.selectedLeague.id).subscribe(data => {
          this.leagueStorage.setLeagueSettings(data.league_setting);
          this.showDefaultScoring = data.league_setting.show_default_scoring;
        });
      }

      this.canSeeProfile = this.permissionManager.isPlayer();
    });

    this.leagueIdSubscription = this.storage.leagueIdsObservable.subscribe(leagueIds => {
      let getLeagues = this.leagueService
        .getMyLeagues(leagueIds)
        .pipe(map(x => x.map(league => league.league)));
      const previousLeague = this.storage.previousInfo && this.storage.previousInfo.leagueId;

      if (this.permissionManager.isAdmin) {
        getLeagues = this.leagueService
          .getLeagues({
            slim: true,
            pageSize: 100000
          })
          .pipe(map(x => x.leagues));
      }
      getLeagues.subscribe(response => {
        // set the selected league to be the first hit on the leagues they actually own
        // (this solves where Admin might be logged in that can see everything)
        // todo: try to use the last selected league
        const ownedLeagueId =
          this.storage.leagueIds && this.storage.leagueIds.length > 0 && this.storage.leagueIds[0];
        let currentLeagueId = this.leagueStorage.getCurrentLeagueId();

        if (previousLeague) {
          currentLeagueId = previousLeague;
        }

        this.leagueStorage.setLeagues(response, currentLeagueId, ownedLeagueId);
        this.canSeeAdminLeagueListing = this.permissionManager.isAdmin;
        this.canSeeAdminAds = this.permissionManager.isAdmin;
        this.canSeeAdminSystemMessages = this.permissionManager.isAdmin;
        this.canSeeAdminStats = this.permissionManager.isAdmin;
      });
    });
  }

  hasPreviousLogin() {
    return this.storage.previousInfo && !!this.storage.previousInfo.refresh;
  }

  restoreUser() {
    this.authService.restoreUser().subscribe(data => {
      this.storage.previousInfo = undefined;
      this.permissionManager.maybeRedirect('/admin/dashboard', true);
    });
  }

  onLeagueChanged() {
    this.leagueStorage.setSelectedLeague(this.selectedLeague);
    // this.leagues.forEach(league => {
    //   if (league.id === leagueId) {
    //     this.leagueStorage.setSelectedLeague(league);
    //   }
    // });
  }

  compareLeagues(a, b) {
    return (a === b && a == null) || (a && b && a.id === b.id);
  }

  public ngOnDestroy() {
    this.selectedLeagueSubscription.unsubscribe();
    this.leagueSubscription.unsubscribe();
    this.leagueIdSubscription.unsubscribe();
  }

  public logout(): void {
    // fire off logout request, this is mostly just a courtesy to the server
    // so it can delete the token.
    this.authService.logout().subscribe();

    // don't need to wait for a response.
    this.storage.clear();
    this.leagueStorage.clear();
    this.router.navigate(['/login']);
  }
}
