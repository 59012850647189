import { LeagueSettings } from './league-setting';
import { User } from './user';
import { UUID } from './uuid';
export class League {
  public id: UUID;
  public name: string;
  public owner_user_id: UUID;
  public account_type: string;
  public number: string;
  public created_at: Date;
  public updated_at: Date;
  public num_members: number;
  public total_owed: number;
  public due_date: Date;
  public User?: User;
  public LeagueSettings?: LeagueSettings;
}
