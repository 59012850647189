import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { throwError as observableThrowError, Observable, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { GenericPageComponent } from '../../generic-page.component';
import { ForgotPasswordForm } from '../../models/forms/forgot-password.form';
import { PermissionManager } from '../../models/permission.manager';
import { AuthService } from '../../services/auth.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent extends GenericPageComponent implements OnInit, OnDestroy {
  public myForm: FormGroup;
  public loggedIn: boolean;
  public submitted = false;
  public message = '';
  loaderSubscription: Subscription;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private permissionManager: PermissionManager
  ) {
    super();
    this.myForm = fb.group({
      username: ['', [Validators.required]]
    });
  }

  public ngOnInit() {
    this.loaderSubscription = this.loaderService.loaderStatus.subscribe(
      (val: boolean) => (this.submitted = val)
    );
    this.loggedIn = this.authService.userLoggedIn();
    if (this.loggedIn) {
      this.permissionManager.maybeRedirect('/admin/dashboard', true);
    }
  }

  public ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

  public submit(value: ForgotPasswordForm): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      this.authService
        .forgotPassword(value)
        .pipe(
          catchError(error => {
            this.message = error.errorMessage;
            return observableThrowError(error);
          }),
          finalize(() => this.loaderService.displayLoader(false))
        )
        .subscribe(data => {
          // Page redirect when getting response
          this.message = '';
          this.router.navigate(['/verify']);
        });
    }
  }
}
