import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '../layouts/login/login.component';
import { PopupComponent } from '../layouts/popup/popup.component';

import { EventComponent } from './event/event.component';
import { ExecutiveTermsComponent } from './executive-terms/executive-terms.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { VerifyTokenComponent } from './forgot-password/verify-token.component';
import { ClassicLeaderboardComponent } from './leaderboards/classic/classic';
import { MainEventLeaderboardComponent } from './leaderboards/main-event/main-event';
import { MembershipLeaderboardComponent } from './leaderboards/membership/membership';
import { QuartzAvatarLeaderboardComponent } from './leaderboards/quartz-avatar/quartz-avatar';
import { QuartzLeaderboardComponent } from './leaderboards/quartz/quartz';
import { RecentWinnersLeaderboardComponent } from './leaderboards/recent-winners/recent-winners';
import { StatsPreviewComponent } from './player-profile/stats-preview/stats-preview';
import { GenericPlayerProfileComponent } from './player-profile/unverified/player-profile.component';
import { VerifiedPlayerProfileComponent } from './player-profile/verified/player-profile.component';
import { PlayerSignupComponent } from './player-signup/player-signup.component';
import { PointsCalculatorComponent } from './points-calculator/points-calculator.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SignupComponent } from './signup/signup.component';
import { StartComponent } from './start/start.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { VenueDetailComponent } from './venue-detail/venue-detail.component';
import { AdIframeComponent } from './ad-iframe/ad-iframe';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: '',
    component: LoginComponent,
    children: [
      { path: 'start', component: StartComponent },
      { path: 'login', component: UserLoginComponent },
      { path: 'player-signup/:player_id', component: PlayerSignupComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'verify', component: VerifyTokenComponent }
    ]
  },
  {
    path: '',
    component: PopupComponent,
    children: [
      { path: 'banner', component: AdIframeComponent },
      { path: 'privacypolicy', component: PrivacyComponent },
      { path: 'stats-preview', component: StatsPreviewComponent },
      { path: 'executive-terms', component: ExecutiveTermsComponent },
      { path: 'event/:id', component: EventComponent },
      { path: 'venue-detail', component: VenueDetailComponent },
      {
        path: 'leaderboard/recent-winners/:league_id',
        component: RecentWinnersLeaderboardComponent
      },
      { path: 'leaderboard/main-event/:league_id', component: MainEventLeaderboardComponent },
      { path: 'leaderboard/membership/:league_id', component: MembershipLeaderboardComponent },
      { path: 'leaderboard/classic/:league_id', component: ClassicLeaderboardComponent },
      { path: 'leaderboard/quartz/:league_id', component: QuartzLeaderboardComponent },
      { path: 'leaderboard/quartz-avatar/:league_id', component: QuartzAvatarLeaderboardComponent },
      {
        path: 'player-profile/verified/:name/:player_id',
        component: VerifiedPlayerProfileComponent
      },
      {
        path: 'player-profile/unverified/:name/:player_id',
        component: GenericPlayerProfileComponent
      },
      {
        path: 'points-calculator',
        component: PointsCalculatorComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule {}
