
import {catchError, map, tap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';





import { environment } from '../../environments/environment';
import { PlayerSignupForm } from '../models/forms/player-signup.form';
import { SignupForm } from '../models/forms/signup.form';
import { UnlinkPlayerForm } from '../models/forms/unlink-player.form';

import { GenericService } from './generic.service';
import { TokenResponse } from './responses/auth.responses';
import { NameExistsResponse } from './responses/generic.responses';

@Injectable()
export class SignupService extends GenericService {
  constructor(private http: HttpClient, protected logger: NGXLogger) {
    super(logger);
  }

  public checkNameTaken(name: string): Observable<boolean> {
    return this.http
      .post<NameExistsResponse>(
        `${environment.LEAGUES_API}/p/league/exists`,
        { name },
        this.httpOptions
      ).pipe(
      map(r => r.exists));
  }

  public checkEmailTaken(email: string): Observable<boolean> {
    return this.http
      .post(`${environment.AUTHENTICATION_API}/p/user/exists`, { username: email }).pipe(
      map((r: NameExistsResponse) => r.exists));
  }

  public signup(value: SignupForm): Observable<TokenResponse | any> {
    return this.http
      .post<TokenResponse>(`${environment.AUTHENTICATION_API}/p/signup`, value, this.httpOptions).pipe(
      tap(response => this.logger.debug('signup response:', response)),
      catchError(this.handleError.bind(this)),);
  }

  public playerSignup(value: PlayerSignupForm): Observable<TokenResponse | any> {
    return this.http
      .post<TokenResponse>(
        `${environment.AUTHENTICATION_API}/p/player-signup`,
        value,
        this.httpOptions
      ).pipe(
      tap(response => this.logger.debug('signup response:', response)),
      catchError(this.handleError.bind(this)),);
  }

  public playerSignupWithUser(value: PlayerSignupForm): Observable<TokenResponse | any> {
    return this.http
      .post<TokenResponse>(
        `${environment.AUTHENTICATION_API}/league/${value.league_id}/player/${
          value.player_id
        }/signup`,
        {},
        this.httpOptions
      ).pipe(
      tap(response => this.logger.debug('signupWithUser response:', response)),
      catchError(this.handleError.bind(this)),);

    // path: '/league/:league_id/player/:player_id/signup';
  }

  public removePlayerFromUser(value: UnlinkPlayerForm): Observable<TokenResponse | any> {
    return this.http
      .delete<TokenResponse>(
        `${environment.AUTHENTICATION_API}/league/${value.league_id}/user/${value.user_id}/player`,
        this.httpOptions
      ).pipe(
      tap(response => this.logger.debug('removePlayerFromUser response:', response)),
      catchError(this.handleError.bind(this)),);

    // path: '/league/:league_id/player/:player_id/signup';
  }
}
