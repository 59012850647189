<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/tournaments">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button mat-menu-item [disabled]="isNewModel" id="btnDelete" type="submit" (click)="onDelete()">
          <mat-icon>delete</mat-icon> Remove Tournament
        </button>
      </mat-menu>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSaveContinue" type="submit"
        class="action-save-button" (click)="submit(myForm.value)">
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSave" type="submit"
        class="action-saveclose-button" (click)="submitAndClose(myForm.value)">
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
      <button mat-button id="btnTop" type="submit" class="action-to-top-btn" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon> Top
      </button>
    </div>
  </div>
</div>
<form id="form1" [formGroup]="myForm" #form="ngForm">
  <input type="hidden" formControlName="id" />

  <mat-card class="gridview-editor-logic tournaments-card">
    <mat-card-content>
      <mat-tab-group class="demo-tab-group">
        <mat-tab>
          <ng-template mat-tab-label>
            Venue
            <i id="tabicon-venue" class="tabicon fas fa-circle" [class.tab-complete]="tab_venue.valid"></i>
          </ng-template>
          <div class="gridview-editor-content" formGroupName="tab_venue">
            <div class="col-lg-6 touredit-col">
              <div class="card">
                <div class="card-header">
                  Tournament Information
                </div>
                <div class="card-block">
                  <div class="panelrow col-md-4 date-selector">
                    <mat-form-field class="editor-mat-field">
                      <input required matInput formControlName="start_date" [min]="minDate" [max]="maxDate"
                        [matDatepicker]="picker_start_date" placeholder="Date" (click)="picker_start_date.open()"
                        autocomplete="off">
                      <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
                      <mat-datepicker #picker_start_date></mat-datepicker>
                      <mat-error
                        *ngIf="(start_date.hasError('date') || start_date.hasError('required')) && !start_date.pristine">
                        Valid start date required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="panelrow col-md-8">
                    <mat-form-field class="editor-mat-field">
                      <mat-select required #venuesList placeholder="Venue" formControlName="venue_id"
                        (selectionChange)="venueChanged(venue_id.value)">
                        <mat-option *ngFor="let venue of venues" [value]="venue.id">{{venue.name}}
                        </mat-option>
                        <mat-error *ngIf="venue_id.hasError('required') && !venue_id.pristine">
                          Please select a venue.
                        </mat-error>
                      </mat-select>
                      <mat-hint>Select a venue.</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="panelrow col-md-6">
                    <mat-form-field class="editor-mat-field">
                      <mat-select required #seasonsList placeholder="Season" formControlName="season_id">
                        <mat-option *ngFor="let season of venueSeasons" [value]="season.id">{{season.name}}
                        </mat-option>
                        <mat-error *ngIf="season_id.hasError('required') && !season_id.pristine">
                          Please select a season.
                        </mat-error>
                      </mat-select>
                      <mat-hint>Select a season.</mat-hint>
                    </mat-form-field>

                  </div>

                  <div class="panelrow col-md-6">
                    <mat-form-field class="editor-mat-field">
                      <mat-select required placeholder="Type" formControlName="tournament_type">
                        <mat-option *ngFor="let type of tournamentTypes" [value]="type.code">{{type.name}}
                        </mat-option>
                        <mat-error *ngIf="tournament_type.hasError('required') && !tournament_type.pristine">
                          Please select a tournament type.
                        </mat-error>
                      </mat-select>
                      <mat-hint>Select tournament type.</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="panelrow col-md-8">

                    <mat-form-field class="editor-mat-field">
                      <input matInput formControlName="name" placeholder="Tournament Name">
                      <mat-hint>(optional) - Date will be used otherwise.</mat-hint>
                    </mat-form-field>
                  </div>

                  <div class="panelrow col-md-4">
                    <mat-form-field class="editor-mat-field">
                      <input matInput formControlName="num_tables" placeholder="Table Count">
                      <mat-error *ngIf="num_tables.hasError('numeric') && !num_tables.pristine">
                        <i class="fas fa-times-circle"></i> Positive number required.
                      </mat-error>
                      <mat-hint>Please enter # of tables.</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="panelrow col-md-6">
                    <mat-form-field class="editor-mat-field">
                      <input matInput formControlName="bonus_chips_1" placeholder="Bonus Tokens">
                      <mat-error *ngIf="bonus_chips_1.hasError('numeric') && !bonus_chips_1.pristine">
                        <i class="fas fa-times-circle"></i> Positive number required.
                      </mat-error>
                      <mat-hint># of tokens.</mat-hint>
                    </mat-form-field>
                  </div>
                  <div class="panelrow col-md-6">
                    <mat-form-field class="editor-mat-field">
                      <input matInput formControlName="bonus_chips_2" placeholder="Break Tokens">
                      <mat-error *ngIf="bonus_chips_2.hasError('numeric') && !bonus_chips_2.pristine">
                        <i class="fas fa-times-circle"></i> Positive number required.
                      </mat-error>
                      <mat-hint># of tokens.</mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  Venue Payment
                </div>
                <div class="card-block">
                  <mat-radio-group class="editor-mat-field" formControlName="payment_type">
                    <div class="panelrow col-md-6">
                      <div class="inputheader payment-type">
                        <mat-radio-button [value]="'Full Payment'" class="card-btn-radio">Full Payment
                        </mat-radio-button>
                      </div>
                      <mat-form-field class="editor-mat-field">
                        <input matInput formControlName="venue_cost" (change)="amountOwedChanged()"
                          placeholder="Payment Amount">
                        <span matPrefix>$&nbsp;</span>
                        <mat-error *ngIf="venue_cost.hasError('numeric') && !venue_cost.pristine">
                          <i class="fas fa-times-circle"></i> Positive number required.
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="panelrow col-md-6 nonpayment-section">
                      <div class="inputheader payment-type">
                        <mat-radio-button [value]="'Non Payment'" class="card-btn-radio">Non Payment</mat-radio-button>
                      </div>
                      <mat-form-field class="editor-mat-field">
                        <mat-select #reasonsList placeholder="Reason" formControlName="reason">
                          <mat-option *ngFor="let reason of reasons" [value]="reason.toLowerCase()">{{reason}}
                          </mat-option>

                        </mat-select>
                        <mat-error
                          *ngIf="tab_venue.hasError('reasonExists') && (!payment_type.pristine || !reason.pristine)">
                          Must select a reason if not paying in full.
                        </mat-error>
                        <mat-hint>Required if not full payment.</mat-hint>
                      </mat-form-field>
                    </div>
                  </mat-radio-group>
                  <div class="panelrow col-md-8">


                    <mat-form-field class="editor-mat-field">
                      <mat-select #paymentMethodsList placeholder="Payment Method" formControlName="payment_method">
                        <mat-option [value]="null">Payment Method</mat-option>
                        <mat-option *ngFor="let method of paymentMethods" [value]="method.toLowerCase()">{{method}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="payment_method.hasError('required') && !payment_method.pristine">
                        Please select a payment method.
                      </mat-error>
                      <mat-error
                        *ngIf="tab_venue.hasError('checkExists') && (!payment_method.pristine || !check_number.pristine)">
                        Must enter check # if paying by check
                      </mat-error>
                      <mat-hint>Required if not full payment.</mat-hint>
                    </mat-form-field>

                  </div>
                  <div class="panelrow col-md-4">
                    <mat-form-field class="editor-mat-field">
                      <input matInput formControlName="check_number" placeholder="Check #">
                    </mat-form-field>
                  </div>

                  <div class="panelrow col-md-12">
                    <mat-form-field class="editor-mat-field">
                      <textarea rows="5" matInput formControlName="notes" placeholder="Tournament Notes"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div class="card-footer editor-footer text-muted">
                  <mat-slide-toggle formControlName="is_paperwork_signed">Paperwork Signed</mat-slide-toggle>
                </div>
              </div>
            </div>
            <div class="col-lg-6 touredit-col">
              <div class="card">
                <div class="card-header">
                  Accounting
                </div>
                <div class="card-block">

                  <div class="col-lg-6">
                    <div class="panelrow">
                      <div class="switch-toggle">
                        <div class="toggle-label">Paperwork Received:</div>
                        <mat-slide-toggle formControlName="is_paperwork_received"></mat-slide-toggle>
                      </div>
                    </div>
                    <div class="panelrow">
                      <div class="switch-toggle">
                        <div class="toggle-label">Payment Received:</div>
                        <mat-slide-toggle formControlName="is_collected"></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="panelrow">
                      <div class="switch-toggle">
                        <div class="toggle-label">Payment Deposited:</div>
                        <mat-slide-toggle formControlName="is_payment_deposited"></mat-slide-toggle>
                      </div>
                    </div>
                    <div class="panelrow">
                      <div class="switch-toggle">
                        <div class="toggle-label">Tournament Verified:</div>
                        <mat-slide-toggle formControlName="is_closed"></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="canManageExpenses || (staff_expenses.length > 0)">
                <div class="card-header">Staff Expenses</div>
                <div class="card-block items-block">
                  <div class="col-md-12">
                    <div class="itemsrow" formArrayName="staff_expenses">
                      <div class="itemrow" [formGroupName]="i"
                        *ngFor="let staff of staff_expenses.controls; let i=index">
                        <div class="row">
                          <div class="panelrow col-md-5">
                            <input type="hidden" formControlName="level" />
                            <mat-form-field class="editor-mat-field">
                              <mat-select placeholder="Role" formControlName="role_id"
                                (selectionChange)="roleChanged(staff.get('role_id').value)">
                                <mat-option *ngFor="let role of allRoles" [value]="role.id">{{role.name}}
                                </mat-option>
                              </mat-select>
                              <mat-hint>Select Role</mat-hint>
                            </mat-form-field>
                          </div>

                          <div class="panelrow col-md-5">
                            <input type="hidden" formControlName="level" />
                            <mat-form-field class="editor-mat-field">
                              <mat-select placeholder="User" formControlName="user_id"
                                (selectionChange)="roleChanged(staff.get('role_id').value)">
                                <mat-option *ngFor="let user of usersByRole[staff.get('role_id').value]"
                                  [value]="user.id">{{user.first_name}}
                                  {{user.last_name}}
                                </mat-option>
                              </mat-select>
                              <mat-hint>Select User</mat-hint>
                            </mat-form-field>
                          </div>

                          <div class="panelrow col-md-4">
                            <mat-form-field class="editor-mat-field">
                              <input matInput formControlName="payout_percent" placeholder="Payout Percent"
                                (change)="percentChanged(staff.get('payout_percent'), staff.get('payout_amount'))">
                              <span matPrefix>%&nbsp;</span>
                              <mat-error
                                *ngIf="staff.get('payout_percent').hasError('required') && !staff.get('payout_percent').pristine">
                                <i class="fas fa-times-circle"></i> Payout percent is required
                              </mat-error>
                              <mat-error
                                *ngIf="staff.get('payout_percent').hasError('numeric') && !staff.get('payout_percent').pristine">
                                <i class="fas fa-times-circle"></i> Valid positive number required.
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="panelrow col-md-4">
                            <mat-form-field class="editor-mat-field">
                              <input matInput formControlName="payout_amount" placeholder="Payout Amount"
                                (change)="amountChanged(staff.get('payout_amount'), staff.get('payout_percent'))">
                              <span matPrefix>$&nbsp;</span>
                              <mat-error
                                *ngIf="staff.get('payout_amount').hasError('required') && !staff.get('payout_amount').pristine">
                                <i class="fas fa-times-circle"></i> Payout amount is required.
                              </mat-error>
                              <mat-error
                                *ngIf="staff.get('payout_amount').hasError('numeric') && !staff.get('payout_amount').pristine">
                                <i class="fas fa-times-circle"></i> Valid positive number required.
                              </mat-error>
                            </mat-form-field>
                          </div>

                          <div title="Remove" class="position-remove" *ngIf="canManageExpenses">
                            <div class="panelrow">
                              <button mat-icon-button (click)="removeStaff(i)">
                                <mat-icon aria-label="Remove Position">highlight_off</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer editor-footer text-muted" *ngIf="canManageExpenses">
                  <button mat-raised-button color="primary" class="gridview-editor-btn" (click)="addStaff()">
                    <i class="fas fa-plus"></i> Add Staff Expense</button>
                </div>
              </div>
              <div class="card" *ngIf="canManageExpenses">
                <div class="card-header">Item Expenses</div>
                <div class="card-block items-block">
                  <div class="col-md-12">
                    <div class="itemsrow" formArrayName="item_expenses">
                      <div class="itemrow" [formGroupName]="i" *ngFor="let item of item_expenses.controls; let i=index">

                        <div class="panelrow col-md-10">
                          <mat-form-field class="editor-mat-field">
                            <input type="hidden" formControlName="level" />
                            <input matInput formControlName="label" placeholder="Expense Item Name">
                            <mat-error *ngIf="item.get('label').hasError('required') && !item.get('label').pristine">
                              Expense name is required
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="panelrow col-md-4">
                          <mat-form-field class="editor-mat-field">
                            <input matInput formControlName="payout_percent" placeholder="Payout Percent"
                              (change)="percentChanged(item.get('payout_percent'), item.get('payout_amount'))">
                            <span matPrefix>%&nbsp;</span>
                            <mat-error
                              *ngIf="item.get('payout_percent').hasError('required') && !item.get('payout_percent').pristine">
                              Payout percent is required.
                            </mat-error>
                            <mat-error
                              *ngIf="item.get('payout_percent').hasError('numeric') && !item.get('payout_percent').pristine">
                              Must be a valid positive number.
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div class="panelrow col-md-4">
                          <mat-form-field class="editor-mat-field">
                            <input matInput formControlName="payout_amount" placeholder="Payout Amount"
                              (change)="amountChanged(item.get('payout_amount'), item.get('payout_percent'))">
                            <span matPrefix>$&nbsp;</span>
                            <mat-error
                              *ngIf="item.get('payout_amount').hasError('required') && !item.get('payout_amount').pristine">
                              Payout amount is required.
                            </mat-error>
                            <mat-error
                              *ngIf="item.get('payout_amount').hasError('numeric') && !item.get('payout_amount').pristine">
                              Must be a valid positive number.
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div title="Remove" class="position-remove">
                          <div class="panelrow">
                            <button mat-icon-button (click)="removeItem(i)">
                              <mat-icon aria-label="Remove Position">highlight_off</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="card-footer editor-footer text-muted">
                  <button mat-raised-button color="primary" class="gridview-editor-btn" (click)="addItem()">
                    <i class="fas fa-plus"></i> Add Item Expense</button>
                </div>
              </div>
              <div id="selector_totals" class="card total-card" *ngIf="canManageExpenses">
                <div class="card-block">
                  <div class="totalrow">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="total-row-title">
                          Totals
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="panelrow totalpercent">
                          <input disabled="disabled" [value]="totalPercent | percent: '1.1-1'" />
                        </div>


                      </div>
                      <div class="col-sm-4">
                        <div class="panelrow totalamount">
                          <input disabled="disabled" [value]="totalAmount | currency: 'USD':'symbol-narrow':'1.2-2'">
                        </div>

                      </div>
                      <div class="col-sm-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Leaderboard
            <i id="tabicon-leaderboard" class="tabicon fas fa-circle" [class.tab-complete]="tab_leaderboard.valid"></i>
          </ng-template>
          <div class="gridview-editor-content" formGroupName="tab_leaderboard">
            <div class="col-lg-6 touredit-col">
              <div class="card">
                <div class="card-header">
                  Leaderboard
                </div>
                <div class="card-block">
                  <div class="tournament-players-tab tab-logic">
                    <div class="panelrow top-section">
                      <div class="col-md-5">
                        <div class="panelrow col-md-6">
                          <mat-form-field class="editor-mat-field">
                            <input matInput formControlName="num_entries" placeholder="# Players Total"
                              (focus)="numEntriesClicked()" (focusout)="numEntriesUpdated()"
                              (change)="numEntriesUpdated()">
                            <mat-error *ngIf="num_entries.hasError('numeric') && !num_entries.pristine">
                              <i class="fas fa-times-circle"></i> Players total must be a valid integer greater than 0
                            </mat-error>
                          </mat-form-field>
                        </div>
                        <div class="panelrow col-md-6">
                          <mat-form-field class="editor-mat-field">
                            <input matInput [disabled]="selectedScoring" (change)="setPaid($event.target.value)"
                              [value]="placesPaid" placeholder="# Players Paid">

                          </mat-form-field>
                        </div>
                      </div>



                      <div class="col-md-6">

                        <div class="panelrow col-md-12">
                          <mat-form-field class="editor-mat-field">
                            <mat-select #scoringList placeholder="Scoring Systems" formControlName="scoring_id"
                              (selectionChange)="scoringChanged(false)">
                              <ng-container *ngFor="let scoring of scorings">
                                <mat-option *ngIf="!scoring.league_id" [value]="scoring.id">APC Default</mat-option>
                              </ng-container>
                              <ng-container *ngFor="let scoring of scorings">
                                <mat-option *ngIf="scoring.league_id" [value]="scoring.id">{{scoring.name}}</mat-option>
                              </ng-container>
                              <mat-option value="" *ngIf="league?.id !== 'b895b284-01d1-4958-aef2-c99f0cc0e8d6'">Default
                              </mat-option>
                            </mat-select>
                            <mat-hint>Select a scoring system.</mat-hint>
                          </mat-form-field>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="tournament-players-table-logic">
                    <table formArrayName="tournament_players" class="tournament-leaderboard-editor">
                      <tr class="hide-element" style="background-color: #e0e0e0; font-weight: bold">
                        <td style="text-align: center;">#
                        </td>
                        <td style="text-align: center;">Player
                        </td>

                      </tr>

                      <tr class="tournament-players-row" [formGroupName]="i"
                        *ngFor="let tournamentPlayer of tournament_players.controls; let i=index">
                        <td style="text-align: center; vertical-align: middle;" class="tournament-players-place">
                          <input type="hidden" formControlName="finish_position" [value]="i+1" /> {{ i+1 }}
                        </td>
                        <td class="tournament-players-name-column">
                          <mat-form-field class="editor-mat-field leaderboard-input">
                            <input matInput auto-complete min-chars="2" [source]="searchPlayers"
                              [list-formatter]="nameFormatter" match-formatted="true" display-property-name="name"
                              css="tournament-players-name" select-value-of="name" max-num-list="10"
                              formControlName="name" style="width:90%" (valueChanged)="playerSelected($event, i)"
                              placeholder="Player Name">

                            <mat-error
                              *ngIf="tournamentPlayer.get('name').hasError('uniqueBy') && !tournamentPlayer.get('name').pristine">
                              Player name must be unique.
                            </mat-error>
                          </mat-form-field>
                        </td>
                        <td>
                          <mat-form-field class="editor-mat-field leaderboard-input">
                            <input matInput formControlName="points_awarded" placeholder="Points Awarded">
                            <mat-error
                              *ngIf="tournamentPlayer.get('points_awarded').hasError('numeric') && !tournamentPlayer.get('points_awarded').pristine">
                              Number required.
                            </mat-error>
                          </mat-form-field>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="card-footer text-muted">
                  <mat-slide-toggle formControlName="exclude_from_points">Exclude From Points</mat-slide-toggle>

                </div>
              </div>
            </div>
            <div class="col-lg-6 touredit-col">
              <div class="card">
                <div class="card-header">
                  Recap
                </div>
                <div class="card-block">
                  <div class="panelrow col-md-12">
                    <mat-form-field class="editor-mat-field">
                      <textarea rows="5" matInput formControlName="player_notes" placeholder="Recap details"></textarea>
                    </mat-form-field>
                  </div>

                </div>

              </div>

              <div class="card">
                <div class="card-header">
                  Recap Story
                </div>
                <div class="card-block">
                  <div class="panelrow col-md-12">
                    <div class="recap-display-logic">
                      <div class="recap-display-content">
                        <div class="recap-row" #formattedRecap>
                          {{selectedVenue?.name}} - {{start_date?.value | date:'fullDate'}}
                          <br />
                          <br /> Thanks to my dealers:
                          <span *ngFor="let dealer of dealers?.controls;let isLast=last">
                            {{dealer.get('name').value}}{{isLast
                            ? '' : ', '}}</span>
                          <br />
                          <br />
                          {{num_entries.value}} Players
                          <br />
                          <br />
                          <span class="multi-break">{{player_notes.value}}</span>
                          <br />
                          <br /> Top Finishers
                          <ul>
                            <li *ngFor="let tournamentPlayer of tournament_players?.controls | slice:0:10; let i=index">
                              {{i+1}}.
                              {{tournamentPlayer.get('name').value}}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="card-footer editor-footer text-muted">
                  <button mat-raised-button ngxClipboard
                    [cbContent]="formattedRecap.innerText || formattedRecap.textContent" [class.copied]="recapCopied"
                    (cbOnSuccess)="onRecapCopied()" class="action-copyrecap-button">
                    <mat-icon>content_copy</mat-icon>
                  </button>
                  <div class="recap-display-description">
                    Copy to share Recap Story.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            Dealers
            <i id="tabicon-leaderboard" class="tabicon fas fa-circle" [class.tab-complete]="tab_dealers.valid"></i>
          </ng-template>
          <div class="gridview-editor-content" formGroupName="tab_dealers">
            <div class="col-lg-12 touredit-col dealer-col">
              <div class="card">
                <div class="card-header">
                  Dealers
                  <div class="card-info">
                    <mat-icon [matMenuTriggerFor]="menu">help</mat-icon>
                    <mat-menu #menu="matMenu">
                      <app-dealer-help></app-dealer-help>
                    </mat-menu>
                  </div>
                </div>
                <div class="card-block">
                  <div class="tournament-players-tab tab-logic">
                    <div class="panelrow top-section-dealers">
                      <div class="col-md-7">
                        <div class="panelrow col-md-12">
                          <mat-form-field class="editor-mat-field">
                            <input matInput formControlName="num_dealers" placeholder="# of Dealers"
                              (change)="dealersUpdated(num_dealers.value)">
                            <mat-hint>Enter # to create dealer list.</mat-hint>
                            <mat-error *ngIf="num_dealers.hasError('numeric') && !num_dealers.pristine">
                              <i class="fas fa-times-circle"></i> # of dealers must be a valid integer greater than 0
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>

                    <div class="tournament-dealers-block-logic" formArrayName="dealers">

                      <div class="tournament-dealers-logic" [formGroupName]="i"
                        *ngFor="let dealer of dealers.controls; let i=index">
                        <div class="tournament-dealers-row col-md-6">
                          <div class="col-md-12 dealer-input">
                            <mat-form-field class="editor-mat-field">
                              <input required matInput auto-complete [source]="filteredDealers"
                                [list-formatter]="nameFormatter" match-formatted="true" display-property-name="name"
                                select-value-of="name" css="tournament-players-name" max-num-list="5"
                                formControlName="name" (valueChanged)="dealerSelected($event, i)"
                                (change)="dealerSelected($event.target.value, i)" placeholder="Dealer Name">
                              <mat-error
                                *ngIf="dealer.get('name').hasError('required') && !dealer.get('name').pristine">
                                <i class="fas fa-times-circle"></i> Dealer name is required.
                              </mat-error>
                              <mat-error
                                *ngIf="dealer.get('name').hasError('uniqueBy') && !dealer.get('name').pristine">
                                <i class="fas fa-times-circle"></i> places paid must be a less than or equal to # of
                                players
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="tournament-dealers-row col-md-5">
                          <div class="col-md-12 dealer-input">
                            <input formControlName="time_in_seconds" type="hidden">
                            <mat-form-field class="editor-mat-field">
                              <input matInput formControlName="time" placeholder="Duration"
                                class="numericText dealer-time-input"
                                (change)="durationChanged(dealer.get('time'), dealer.get('time_in_seconds'))">
                              <mat-error
                                *ngIf="dealer.get('time').hasError('duration') && !dealer.get('time').pristine">
                                <i class="fas fa-times-circle"></i> Duration must be a valid time interval.
                              </mat-error>
                              <mat-hint>ex: 1h 30m</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
                <div class="card-footer text-muted">
                  <i class="far fa-clock"></i> Total Time = {{ totalDealerTime }}
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>








  <table style="clear: both; margin: 10px auto 0;">
    <tr id="trReopen">
      <td colspan="4"></td>
    </tr>
  </table>

</form>
