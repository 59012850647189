import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { League } from '../../models/league';
import { LeagueBilling } from '../../models/league.billing';
import { LeagueStorage } from '../../models/league.storage';
import { PaymentsService } from '../../services/payments.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade-confirmation.component.html',
  styleUrls: ['./upgrade-confirmation.component.css']
})
export class UpgradeConfirmationComponent implements OnInit {
  submitted = false;
  league: League;
  _propertySubscription: Subscription;
  billing: LeagueBilling;
  startDate: Date = moment().toDate();
  endDate: Date = moment()
    .add(1, 'year')
    .toDate();
  cost: number;
  itemDescription: string;
  upgrading = true;
  constructor(
    private loaderService: LoaderService,
    private paymentsService: PaymentsService,
    private router: Router,
    private route: ActivatedRoute,
    private leagueStorage: LeagueStorage
  ) {}

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    // go check if they even CAN upgrade (they can't if they have a past due balance, or if they are already an exec account)
    this.cost = environment.EXECUTIVE_COST;
    this.itemDescription = environment.EXECUTIVE_ITEM;
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (league != null && league.account_type !== 'EXEMPT') {
        this.paymentsService.getLeagueBilling(this.league.id).subscribe(billingData => {
          this.billing = billingData.billing;
          this.startDate = this.billing.due_date;
          this.endDate = this.billing.future_due_date;
          this.cost = environment.EXECUTIVE_RENEWAL_COST;
          this.itemDescription = environment.EXECUTIVE_RENEWAL_ITEM;
          this.upgrading = false;
        });
      } else {
        this.billing = null;
      }
    });
  }

  cancelOrder() {
    this.router.navigate(['/admin']);
  }

  confirmOrder() {
    if (this.league) {
      this.loaderService.displayLoader(true);
      const params = {
        payer_id: this.route.snapshot.queryParamMap.get('PayerID'),
        token: this.route.snapshot.queryParamMap.get('token')
      };

      this.paymentsService
        .completeOrder(params)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(data => {
          this.league.account_type = 'EXECUTIVE';
          this.leagueStorage.setSelectedLeague(this.league);
          this.router.navigate(['/admin']);
        });
    }
  }
}
