import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Venue } from '../../models/venue';
import { VenueService } from '../../services/venue.service';
import { genericPopup } from '../../util/window.functions';

@Component({
  selector: 'app-leaderboards',
  templateUrl: './leaderboards.component.html',
  styleUrls: ['./leaderboards.component.css']
})
export class LeaderboardsComponent implements OnInit, OnDestroy {
  league: League;
  _propertySubscription: Subscription;
  collapsePaginated = false;
  collapseNonpaginated = true;
  collapseLifetime = true;
  collapseRecentWinners = true;
  collapseMembership = true;
  collapseMainEvent = true;
  collapseHosted = true;
  venues: Venue[] = [];
  selectedVenue: Venue;

  copiedCss: { [key: string]: boolean } = {
    quartz: false,
    'quartz-avatar': false,
    classic: false,
    'quartz-nonpaged': false,
    'classic-nonpaged': false,
    lifetime: false,
    'recent-winners': false,
    membership: false,
    'main-event': false
  };

  leaderboards: { [key: string]: string } = {
    quartz: '',
    'quartz-avatar': '',
    classic: '',
    'quartz-nonpaged': '',
    'classic-nonpaged': '',
    lifetime: '',
    'recent-winners': '',
    membership: '',
    'main-event': ''
  };

  constructor(
    private leagueStorage: LeagueStorage,
    private location: Location,
    private router: Router,
    private venueService: VenueService
  ) {}

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      this.venueService
        .getVenues({
          select: 'id,name,region_id',
          'order_by[name]': 'asc',
          pageSize: 100000
        })
        .subscribe(venueResponse => {
          this.venues = venueResponse.venues;
        });

      this.recalculateLeaderboardCodes();
    });
  }

  compareModels(a, b) {
    return (a === b && a === '') || (a === b && a === null) || (a && b && a.id === b.id);
  }

  recalculateLeaderboardCodes() {
    if (this.league) {
      Object.keys(this.leaderboards).forEach(key => {
        this.leaderboards[key] = this.generateIframeCode(key);
      });
    }
  }

  public textCopied(type) {
    this.copiedCss[type] = true;
    setTimeout(() => (this.copiedCss[type] = false), 1000);
  }

  private generateLeaderboardUrl(key: string) {
    // the "nonpaged" part is just a keyword so we can add the right params.
    // we don't actually want it as part of the url
    // similarly, "lifetime" is actually just a special case of "quartz"
    const urlKey = key.replace('-nonpaged', '').replace('lifetime', 'quartz');
    const url = this.router.createUrlTree(['/leaderboard', urlKey, this.league.id]);
    // const url = this.location.prepareExternalUrl(`/leaderboard/${key}/${this.league.id}?ps=25`);

    let pageSize = 25;
    // some leaderboard-specific tweaks
    let additionalParams = '';
    switch (key) {
      case 'classic':
      case 'quartz':
      case 'quartz-avatar':
        additionalParams += '&s=latest';
        break;
      case 'quartz-nonpaged':
      case 'classic-nonpaged':
        additionalParams += '&s=latest';
        pageSize = 999999;
        break;
      case 'lifetime':
        additionalParams += '&s=all';
        break;
      default:
        break;
    }

    if (this.selectedVenue) {
      additionalParams += '&v=' + this.selectedVenue.id;
    }

    return `${window.location.origin}${url}?ps=${pageSize}${additionalParams}`;
  }

  private generateIframeCode(key: string) {
    const url = this.router.createUrlTree(['/leaderboard', key, this.league.id]);

    let width = 410;
    switch (key) {
      case 'recent-winners':
        width = 250;
        break;
      case 'membership':
      case 'main-event':
        width = 450;
        break;
    }

    return `<iframe src="${this.generateLeaderboardUrl(
      key
    )}" width="${width}" height="800px" frameborder="0"></iframe>`;
  }

  public openLeaderboard(type: string, paginated = true) {
    genericPopup(this.generateLeaderboardUrl(type));
  }

  toggleClassBar(event) {
    event.target.parentElement.classList.toggle('isopen'); // To toggle
  }
  toggleClassBtn(event) {
    event.target.parentElement.parentElement.classList.toggle('isopen'); // To toggle
  }

  togglelb(toggleVar: string) {
    this[toggleVar] = !this[toggleVar];
  }
}
