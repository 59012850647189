
import {finalize} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { RegionForm } from '../../models/forms/region.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Region } from '../../models/region';
import { UUID } from '../../models/uuid';
import { AlertService } from '../../services/common/alert.service';
import { RegionService } from '../../services/region.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  selector: 'app-region-detail',
  templateUrl: './region-detail.component.html'
})
export class RegionDetailComponent implements OnInit, OnDestroy {
  isNewModel = true;
  datePipe: DatePipe = new DatePipe('en-US');
  _propertySubscription: Subscription;
  myForm: FormGroup;
  mergeForm: FormGroup;
  submitted = false;
  model = new Region();
  league: League;
  showMerge = false;
  allRegions: Region[] = [];

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: RegionService,
    private uniqueNameValidator: UniqueNameValidator,
    protected leagueStorage: LeagueStorage,
    private route: ActivatedRoute,
    private alertService: AlertService
  ) {
    this.myForm = fb.group({
      id: '',
      name: ['', Validators.required, uniqueNameValidator.create().bind(this)],
      is_active: true
    });
    this.mergeForm = fb.group({
      id: '',
      merge_region: ['', Validators.required]
    });
  }

  // handy access to form fields
  get name(): any {
    return this.myForm.get('name');
  }

  ngOnDestroy() {
    console.log('destroying region-detail component');
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getRegion();
      }
    });
  }

  getRegion(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getRegion(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = response.region; // only used in the Name check, to allow the original name to work.
        this.service
          .getRegions({
            select: 'id,name',
            pageSize: 100000
          })
          .subscribe(regionsResponse => {
            this.allRegions = regionsResponse.regions.filter(region => region.id !== this.model.id);
            this.allRegions.sort((a, b) => a.name.localeCompare(b.name));
          });
        this.resetForm(this.model);
      });
    }
  }

  public submitAndClose(value: RegionForm) {
    this._submit(value, (model: Region) => {
      this.router.navigate(['/admin/regions']);
    });
  }

  public submit(value: RegionForm) {
    this._submit(value, (model: Region) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/regions/' + model.id]);
    });
  }

  private resetForm(region: Region) {
    this.showMerge = false;
    this.mergeForm.reset();
    if (region) {
      this.mergeForm.patchValue({ id: region.id });
    }
    this.myForm.reset();
    this.myForm.setValue(new RegionForm(region), { onlySelf: true });
  }

  public _submit(value: RegionForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createRegion(value)
        : this.service.updateRegion(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.region);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  nameFormatter(data: any) {
    return data.name;
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.name
        }? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteRegion(this.model.id).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/regions']);
        });
    }
  }
}
