<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/players">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button
          id="btnMerge"
          [disabled]="isNewModel"
          type="submit"
          (click)="showMerge = true"
          mat-menu-item
        >
          <mat-icon>merge_type</mat-icon> Merge Player
        </button>
        <span class="menu-sep"></span>
        <button
          mat-menu-item
          [disabled]="isNewModel"
          id="btnDelete"
          type="submit"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> Remove Player
        </button>
      </mat-menu>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSaveContinue"
        type="submit"
        class="action-save-button"
        (click)="submit(myForm.value)"
      >
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSave"
        type="submit"
        class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)"
      >
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>
<div class="col-md-12">
  <div *ngIf="showMerge" style="width:100%;">
    <form [formGroup]="mergeForm">
      <input type="hidden" formControlName="id" />
      <div class="card merge-card show-overflow">
        <div class="card-header">Merge Player</div>
        <div class="card-block">
          <div class="panelrow col-md-8">
            <mat-form-field class="editor-mat-field">
              <input
                required
                matInput
                formControlName="merge_player"
                placeholder="Select player to merge."
                auto-complete
                [accept-user-input]="false"
                min-chars="2"
                [source]="service.searchPlayers"
                [list-formatter]="nameFormatter"
                [match-formatted]="true"
                display-property-name="name"
                css="tournament-players-name"
                max-num-list="10"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="card-footer editor-footer text-muted">
          <button
            mat-raised-button
            color="primary"
            class="gridview-editor-btn"
            [disabled]="mergeForm.invalid || submitted"
            (click)="mergePlayer(mergeForm.value)"
            id="ctl00_ContentPlaceHolder1_btnMerge"
          >
            <mat-icon>merge_type</mat-icon> Merge Player
          </button>
        </div>
      </div>
    </form>
  </div>

  <form id="form1" [formGroup]="myForm">
    <input type="hidden" formControlName="id" />
    <!-- eventually expose this as a user-selectable value -->
    <input type="hidden" formControlName="is_active" value="1" />
    <div class="card">
      <div class="card-header">
        <span class="card-header-edit-label">{{ isNewModel ? 'Create' : 'Edit' }} Player</span>
        {{ name.value }}
      </div>
      <div class="card-block">
        <div class="editpanel-column left-edit col-lg-8">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="name" placeholder="Name" />
              <mat-error *ngIf="name.hasError('required') && !name.pristine">
                <i class="fas fa-times-circle"></i> Player name is required.
              </mat-error>
              <mat-error
                *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)"
              >
                <i class="fas fa-times-circle"></i> Player name already exists.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="email" placeholder="Email" />
              <mat-error *ngIf="name.hasError('email') && !name.pristine">
                Must be a valid email
              </mat-error>
              <mat-error
                *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)"
              >
                Player Name already exists
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <textarea rows="5" matInput formControlName="notes" placeholder="Notes"></textarea>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12" *ngIf="model.is_verified">
            This player has verified their account.
            <a [routerLink]="['/admin', 'staff', model.user_id]"
              >Click here to manage their user record</a
            >
            <br />
            <button
              mat-raised-button
              color="primary"
              class="gridview-editor-btn"
              (click)="removePlayerAssociation()"
              id="ctl00_ContentPlaceHolder1_btnMerge"
            >
              <mat-icon>merge_type</mat-icon> Delete User Association
            </button>
          </div>
        </div>
        <div class="editpanel-column col-lg-4">
          <div class="card">
            <div class="card-header"><div>Membership</div></div>
            <div class="card-block">
              <div class="card-newplayer-membership-isvip">
                <div class="panelrow col-md-12">
                  <div class="switch-toggle">
                    <mat-slide-toggle formControlName="is_membership"
                      >Include Player</mat-slide-toggle
                    >
                  </div>
                </div>
              </div>
              <div class="panelrow col-md-10">
                <mat-form-field class="editor-mat-field">
                  <input
                    matInput
                    formControlName="membership_type"
                    placeholder="Membership Label"
                  />
                  <mat-hint>i.e 'VIP'</mat-hint>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-12"><h3>Membership Dates</h3></div>

              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">
                  <input
                    matInput
                    formControlName="start_date"
                    [min]="minDate"
                    [max]="maxDate"
                    [matDatepicker]="picker_start_date"
                    placeholder="Start Date"
                    (click)="picker_start_date.open()"
                    autocomplete="off"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker_start_date"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker_start_date></mat-datepicker>
                  <mat-error *ngIf="start_date.hasError('date') && !start_date.pristine">
                    Valid start date required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">
                  <input
                    matInput
                    formControlName="end_date"
                    [min]="minDate"
                    [max]="maxDate"
                    [matDatepicker]="picker_end_date"
                    placeholder="End Date"
                    (click)="picker_end_date.open()"
                    autocomplete="off"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
                  <mat-datepicker #picker_end_date></mat-datepicker>
                  <mat-error *ngIf="end_date.hasError('date') && !end_date.pristine">
                    Valid end date required.
                  </mat-error>
                  <mat-error *ngIf="myForm.hasError('startLTEEnd') && !end_date.pristine">
                    Expiration Date must be after Start Date.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="card-footer text-muted">
              <mat-slide-toggle formControlName="is_invited_main_event"
                >Main Event Invite</mat-slide-toggle
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="col-lg-12" *ngIf="!isNewModel">
  <div class="card">
    <div class="card-header">Activity</div>
    <div class="card-block card-admin">
      <div class="gridview-logic grid-playeractivity">
        <div class="gridview-padding">
          <ng-table
            [config]="config"
            (tableChanged)="onChangeTable(config)"
            [rows]="rows"
            [columns]="columns"
          >
          </ng-table>
        </div>
      </div>
    </div>
    <div *ngIf="config.paging" class="card-footer page-footer">
      <div class="gridview-paginator-logic">
        <div class="mat-paginator-page-goto">
          <div class="mat-paginator-page-goto-label">Page:</div>
          <mat-form-field>
            <mat-select [ngModel]="pageIndex + 1" (ngModelChange)="manualPageChanged($event)">
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{ mp }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator
          [length]="length"
          [pageSize]="itemsPerPage"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex"
          (page)="onChangeTable(config, $event)"
          (numPages)="numPages = $event"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
