import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { PermissionManager } from '../models/permission.manager';

@Injectable()
export class SystemAuthGuard implements CanActivate {
  constructor(protected router: Router, private permissionManager: PermissionManager) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.permissionManager.isAdmin) {
      return true;
    }

    // not an admin, redirect to standard dashboard.
    this.permissionManager.maybeRedirect('/admin/dashboard', true);
    return false;
  }
}
