import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { LoginAsTableCellComponent } from '../../components/login-as.cell.component';
import { PageableComponent } from '../../components/pageable.component';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  _propertySubscription: Subscription;
  canCreateStaff = false;
  showAll = false;

  constructor(
    protected leagueStorage: LeagueStorage,
    protected staffService: UserService,
    private permissionManager: PermissionManager
  ) {
    super(staffService, leagueStorage);
    const columns: any[] = [
      {
        title: 'First Name',
        name: 'first_name',
        sort: 'asc',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Last Name',
        name: 'last_name',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Username',
        name: 'username',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Email',
        name: 'email',
        filtering: { filterString: '', placeholder: 'Filter' }
      }
    ];
    if (this.permissionManager.isAdmin) {
      columns.push({
        title: null,
        name: 'id',
        component: LoginAsTableCellComponent,
        sort: false,
        filtering: false
      });
    }
    this.setColumns(columns);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    super.ngOnInit();
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.canCreateStaff = this.permissionManager.hasPermission('create staff');
    });
  }

  public showAllChanged(isChecked) {
    this.setDefaultParams(isChecked ? { show_all: true } : {});
  }

  public onCellClick(data: any): any {
    // if (
    //   data.column === 'first_name' &&
    //   (this.permissionManager.hasPermission('update staff') ||
    //     this.permissionManager.hasPermission('get staff'))
    // ) {
    //   this.router.navigate([data.row.id], { relativeTo: this.activatedRoute });
    // }
  }
}
