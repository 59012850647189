import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dealer-help',
  templateUrl: './dealer-help.component.html',
  styleUrls: ['./dealer-help.component.css']
})
export class DealerHelpComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
