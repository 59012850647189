<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/staff">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button
          mat-menu-item
          [disabled]="isNewModel"
          id="btnDelete"
          type="submit"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> Remove Staff
        </button>
      </mat-menu>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSaveContinue"
        type="submit"
        class="action-save-button"
        (click)="submit(myForm.value)"
      >
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSave"
        type="submit"
        class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)"
      >
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>

<form id="form1" [formGroup]="myForm">
  <input type="hidden" formControlName="id" />
  <div class="col-md-12">
    <div class="card ">
      <div class="card-header">
        <span class="card-header-edit-label">{{ isNewModel ? 'Create' : 'Edit' }} Staff</span>
        {{ firstName.value }}
        {{ lastName.value }}
      </div>
      <div class="card-block">
        <div class="row">
          <div class="editpanel-column left-edit col-lg-8">
            <div class="panelrow col-md-12">
              <mat-form-field class="editor-mat-field">
                <input required matInput type="text" formControlName="username" placeholder="Username" autocomplete="off" />
                <mat-error *ngIf="username.hasError('required') && !username.pristine">
                  Username is required
                </mat-error>

                <mat-error
                  *ngIf="
                    username.hasError('nameTaken') &&
                    (isNewModel || username.value != model.username)
                  "
                >
                  Username already taken
                </mat-error>
              </mat-form-field>
            </div>
            <div class="panelrow col-md-12">
              <mat-form-field class="editor-mat-field">
                <input
                  matInput
                  formControlName="password"
                  type="text"
                  placeholder="Password (blank unless updating)"
                  autocomplete="off"
                />
                <mat-error *ngIf="password.hasError('minlength') && !password.pristine">
                  Password must be at least 8 characters
                </mat-error>
              </mat-form-field>
            </div>
            <div class="panelrow col-md-6">
              <mat-form-field class="editor-mat-field">
                <input required matInput formControlName="first_name" placeholder="First Name" />
                <mat-error *ngIf="firstName.hasError('required') && !firstName.pristine">
                  First Name is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="panelrow col-md-6">
              <mat-form-field class="editor-mat-field">
                <input matInput formControlName="last_name" placeholder="Last Name" />
              </mat-form-field>
            </div>
            <div class="panelrow col-md-12">
              <mat-form-field class="editor-mat-field">
                <input required matInput formControlName="email" placeholder="Email" />
                <mat-error *ngIf="email.hasError('required') && !email.pristine">
                  Email is required
                </mat-error>
                <mat-error *ngIf="email.hasError('email') && !email.pristine">
                  Invalid email
                </mat-error>
                <mat-error
                  *ngIf="email.hasError('emailTaken') && (isNewModel || email.value != model.email)"
                >
                  Email already taken
                </mat-error>
              </mat-form-field>
            </div>

            <div class="panelrow col-md-12">
              <mat-slide-toggle formControlName="show_in_listing"
                >Show in listing</mat-slide-toggle
              >
              <br />Whether to show user by default in staff listing page.
            </div>
            <div class="panelrow col-md-12 " *ngIf="isAdmin && !isNewModel">
              <div class="loginas-title">
                System Admin
              </div>
              <button mat-button type="submit" class="loginas" (click)="loginAs()">
                <mat-icon>exit_to_app</mat-icon> Login as {{ firstName.value }} {{ lastName.value }}
              </button>
            </div>
          </div>

          <div class="editpanel-column col-lg-4">
            <div class="card card-default">
              <div class="card-header">
                <div>Staff Roles</div>
              </div>
              <div class="card-block">
                <table id="editStaffRoles" formArrayName="roles">
                  <tr *ngFor="let grp of groups">
                    <td>
                      <div class="group-header">
                        {{ grp.description }}
                      </div>
                      <table>
                        <tr [formGroupName]="i" *ngFor="let role of allRoles; let i = index">
                          <td id="itemNameCell" *ngIf="grp.group === role.group">
                            <input type="hidden" formControlName="role_id" [value]="role.id" />
                            <mat-checkbox formControlName="is_checked">{{
                              role.name
                            }}</mat-checkbox>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
</div>
    </div>
  </div>
</form>
