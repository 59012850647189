import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NgTableFilteringDirective } from './table/ng-table-filtering.directive';
import { NgTablePagingDirective } from './table/ng-table-paging.directive';
import { NgTableSortingDirective } from './table/ng-table-sorting.directive';
import { NgTableComponent } from './table/ng-table.component';
import { TableCellComponent } from './table/table-cell.component';

@NgModule({
  imports: [CommonModule, MatCheckboxModule],
  declarations: [
    NgTableComponent,
    NgTableFilteringDirective,
    NgTablePagingDirective,
    NgTableSortingDirective,
    TableCellComponent
  ],
  exports: [
    NgTableComponent,
    NgTableFilteringDirective,
    NgTablePagingDirective,
    NgTableSortingDirective,
    MatCheckboxModule
  ],
  entryComponents: [TableCellComponent]
})
export class Ng2TableModule {}
