<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad"></div>
  </div>
</div>
<div class="col-md-12">
  <div class="card">
    <div class="card-header"><mat-icon>show_chart</mat-icon> System Stats</div>
    <div class="card-block system-stat-block">
      <div class="panelrow">
        <div class="inputheader">Free Leagues:</div>
        <span>{{ leagueStats?.free | number }}</span>
      </div>
      <div class="panelrow">
        <div class="inputheader">Exempt Leagues:</div>
        <span>{{ leagueStats?.exempt | number }}</span>
      </div>
      <div class="panelrow">
        <div class="inputheader">Paid Leagues:</div>
        <span>{{ leagueStats?.executive | number }}</span>
      </div>
      <div class="panelrow">
        <div class="inputheader">Total Leagues:</div>
        <span>{{ leagueStats?.total | number }}</span>
      </div>
      <div class="panelrow">
        <div class="inputheader">Total Players:</div>
        <span>{{ playerStats?.total | number }}</span>
      </div>
      <div class="panelrow">
        <div class="inputheader">Verified Players:</div>
        <span>{{ playerStats?.verified | number }}</span>
      </div>
    </div>
  </div>
</div>
