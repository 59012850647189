import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { League } from '../../models/league';
import { LeagueSettings } from '../../models/league-setting';
import { LeagueStorage } from '../../models/league.storage';
import { PaymentsService } from '../../services/payments.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.css']
})
export class UpgradeComponent implements OnInit, OnDestroy {
  league: League;
  settings: LeagueSettings;
  _propertySubscription: Subscription;
  _leagueSettingsSubscription: Subscription;
  submitted = false;

  constructor(
    private loaderService: LoaderService,
    private paymentsService: PaymentsService,
    private leagueStorage: LeagueStorage,
    private router: Router
  ) {}

  ngOnInit() {
    // todo go check if they even CAN upgrade (they can't if they have a past due balance, or if they are already an exec account)

    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });

    this._leagueSettingsSubscription = this.leagueStorage
      .getLeagueSettings()
      .subscribe(settings => {
        this.settings = settings;
      });
  }

  ngOnDestroy() {
    if (this._leagueSettingsSubscription) {
      this._leagueSettingsSubscription.unsubscribe();
    }
    if (this._propertySubscription) {
      this._propertySubscription.unsubscribe();
    }
  }
  handleTrial() {
    if (this.league) {
      this.loaderService.displayLoader(true);
      this.paymentsService
        .startTrial({})
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe((data: any) => {
          this.league.account_type = 'EXECUTIVE';
          this.leagueStorage.setSelectedLeague(this.league);
          this.router.navigate(['/admin']);
        });
    }
  }

  handleUpgrade() {
    if (this.league) {
      this.loaderService.displayLoader(true);
      this.paymentsService
        .startCheckout({
          cancel_url: `${window.location.origin}${this.router.createUrlTree([
            '/admin',
            'dashboard'
          ])}`,
          return_url: `${window.location.origin}${this.router.createUrlTree([
            '/admin',
            'confirm-order'
          ])}`
        })
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe((data: any) => {
          if (data.redirectTo) {
            // this.router.navigateByUrl(data.redirectTo);
            window.location.href = data.redirectTo;
          }
        });
    }
  }
}
