import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { UniqueEmailValidator } from '../../_validators/unique-email.validator';
import { GenericPageComponent } from '../../generic-page.component';
import { AppStorage } from '../../models/app.storage';
import { PlayerSignupForm } from '../../models/forms/player-signup.form';
import { League } from '../../models/league';
import { PermissionManager } from '../../models/permission.manager';
import { Player } from '../../models/player';
import { AuthService } from '../../services/auth.service';
import { LeagueService } from '../../services/league.service';
import { PlayerService } from '../../services/player.service';
import { SignupService } from '../../services/signup.service';
import { LoaderService } from '../../services/spinner.service';
import { TokenService } from '../../services/token.service';

@Component({
  templateUrl: './player-signup.component.html',
  styleUrls: ['./player-signup.component.css']
})
export class PlayerSignupComponent extends GenericPageComponent implements OnInit {
  public myForm: FormGroup;
  errorMessage: string;
  existingEmail = false;

  showForm = true;
  profileExists = false;
  showControls = true;

  public submitted = false;
  player: Player;
  league: League;
  loading = false;

  constructor(
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private service: SignupService,
    private leagueService: LeagueService,
    private playerService: PlayerService,
    private emailValidator: UniqueEmailValidator,
    private storage: AppStorage,
    private permissionManager: PermissionManager,
    private authService: AuthService,
    private tokenService: TokenService
  ) {
    super();
    this.myForm = fb.group({
      email: [
        '',
        [Validators.required, Validators.email],
        emailValidator
          .create('email', {
            callback: this.receiveEmailResult.bind(this),
            ignoreResult: true
          })
          .bind(this)
      ],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      player_id: ['', Validators.required],
      league_id: ['', Validators.required],
      name: ['', Validators.required]
    });
  }
  get email(): FormControl {
    return this.myForm.get('email') as FormControl;
  }
  get password(): FormControl {
    return this.myForm.get('password') as FormControl;
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this.errorMessage = null;
    this.profileExists = false;
    this.loading = true;
    const playerId: any = this.route.snapshot.paramMap.get('player_id');
    this.playerService
      .getPublicPlayer(playerId, {
        includeSettings: true
      })
      .pipe(
        catchError(err => {
          this.errorMessage =
            'Error, invalid member id specified.  Did you modify a url to get here?';
          return observableThrowError(err);
        })
      )
      .subscribe(playerResponse => {
        if (playerResponse.player.is_verified) {
          this.errorMessage = 'You are already signed up.';
        } else {
          this.player = playerResponse.player;
          this.leagueService.getPublicLeague(this.player.league_id).subscribe(leagueResponse => {
            this.league = leagueResponse.league;

            if (this.storage.token) {
              // player is already logged in
              const currentPlayerId = this.permissionManager.getPlayerInLeague(
                this.league.id.toString()
              );

              if (currentPlayerId) {
                // player is logged in AND already has a player in this league.
                // having more than one is illegal.
                this.profileExists = true;
                this.showForm = false;
              } else {
                this.showControls = false;
                this.email.setValidators([]);
                this.password.setValidators([]);
                this.email.setAsyncValidators([]);
                this.password.setAsyncValidators([]);
              }
            }
            this.myForm.patchValue({
              player_id: this.player.id,
              league_id: this.league.id,
              name: this.player.name
            });
            this.loading = false;
          });
        }
      });
  }

  receiveEmailResult(value) {
    this.existingEmail = !!value;
  }

  playerSignup(value: PlayerSignupForm): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      this.errorMessage = null;
      value.user_id = value.email;
      // submit to API

      if (this.existingEmail) {
        this.authService
          .login(value)
          .pipe(
            catchError(error => {
              // disable loader on catch; but if it succeeds we'll handle this later.
              this.loaderService.displayLoader(false);
              this.errorMessage = error.errorMessage;
              return observableThrowError(error);
            })
          )
          .subscribe(data => {
            // token data has already been assigned.  now call the signup enpoint.
            this.handleAssociation(this.service.playerSignupWithUser(value));
          });
      } else {
        const endpoint = this.storage.token
          ? this.service.playerSignupWithUser(value)
          : this.service.playerSignup(value);
        this.handleAssociation(endpoint);
      }
    }
  }

  handleAssociation(endpoint) {
    endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
      data => {
        // Page redirect when getting response
        // this.applyToken(data);

        this.tokenService.handleAuthResponse(data);

        this.permissionManager.maybeRedirect('/admin/dashboard', true);
      },
      error => {
        console.error('err', error);
      }
    );
  }

  // applyToken(data) {
  //   this.storage.token = data.token;
  //   this.storage.tokenExpiresAt = data.payload.exp;
  //   this.storage.tokenPayload = data.payload;
  //   this.storage.leagueIds = data.payload.leagues;
  //   this.storage.refresh = data.refresh;
  // }
}
