import { empty, forkJoin, Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import {
  GetLeaguesResponse,
  GetLeagueColorsResponse,
  GetLeagueResponse,
  GetLeagueSettingsResponse,
  GetLeagueStatsResponse,
  GetLeagueAccountTypeResponse
} from './responses/league.responses';

@Injectable()
export class LeagueService extends GenericService implements PageableService {
  constructor(private http: HttpClient, protected logger: NGXLogger) {
    super(logger);
  }

  public checkNameTaken(name: string): Observable<boolean> {
    this.logger.debug('calling checkNameTaken');
    /*
    if (currentLeague != null && currentLeague.name === name) {
      return of(false);
    }
*/
    return this.http
      .post<NameExistsResponse>(
        `${environment.LEAGUES_API}/p/league/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getLeagues(params).pipe(
      map(r => {
        return {
          data: r.leagues,
          pagination: r.pagination
        };
      })
    );
  }

  public getLeagues(params: any = {}): Observable<GetLeaguesResponse | any> {
    this.logger.debug('calling getLeagues');
    this.logger.debug('calling get players service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetLeaguesResponse>(`${environment.LEAGUES_API}/league`, options)
      .pipe(tap(response => this.logger.debug('getLeagues response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPublicLeagues(params: any): Observable<GetLeaguesResponse | any> {
    this.logger.debug('Called getPublicLeagues');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetLeaguesResponse>(`${environment.LEAGUES_API}/p/league`, options)
      .pipe(tap(response => this.logger.debug('getPublicLeagues response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPublicLeague(leagueId: UUID): Observable<GetLeagueResponse | any> {
    this.logger.debug('Called getPublicLeague');

    return this.http
      .get<GetLeagueResponse>(`${environment.LEAGUES_API}/p/league/${leagueId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getPublicLeague response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getLeague(leagueId: UUID): Observable<GetLeagueResponse | any> {
    this.logger.debug('calling getLeague + ' + leagueId);
    return this.http
      .get<GetLeagueResponse>(`${environment.LEAGUES_API}/league/${leagueId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getLeague response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createLeague(value: any): Observable<GetLeagueResponse | any> {
    value.create_league_user = true;
    this.logger.debug('Called createLeague');
    return this.http
      .post<GetLeagueResponse>(`${environment.LEAGUES_API}/league`, value, this.httpOptions)
      .pipe(tap(response => this.logger.debug('createLeague response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateLeague(leagueId: UUID, value: any): Observable<GetLeagueResponse | any> {
    this.logger.debug('Called updateLeague');
    return this.http
      .patch<GetLeagueResponse>(
        `${environment.LEAGUES_API}/league/${leagueId}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateLeague response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateLeagueAccountType(leagueId: UUID, value: any): Observable<GetLeagueAccountTypeResponse | any> {
    this.logger.debug('Called updateLeagueAccountType');
    return this.http
      .put<GetLeagueAccountTypeResponse>(
        `${environment.LEAGUES_API}/league/${leagueId}/account_type`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateLeagueAccountType response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public uploadlogo(leagueId: UUID, file: File): Observable<any> {
    this.logger.debug('Called uploadlogo');

    const formData = new FormData();
    formData.append('logo', file, 'testrelativepath');

    return this.http
      .put<any>(`${environment.LEAGUES_API}/league/${leagueId}/logo`, formData, {})
      .pipe(tap(response => this.logger.debug('uploadlogo response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getLeagueColors(leagueId: UUID): Observable<GetLeagueColorsResponse> {
    this.logger.debug('Called getLeagueColors');

    return this.http
      .get<any>(`${environment.LEAGUES_API}/p/league/${leagueId}/colors`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getPublicLeagueSettings response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getLeagueSettings(leagueId: UUID): Observable<GetLeagueSettingsResponse> {
    this.logger.debug('Called getLeagueSettings');

    return this.http
      .get<any>(`${environment.LEAGUES_API}/league/${leagueId}/settings`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getLeagueSettings response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateLeagueSettings(
    leagueId: UUID,
    value: any
  ): Observable<{} | GetLeagueSettingsResponse[]> {
    this.logger.debug('Called updateLeagueSettings');
    const executiveLeagueSettings = ['show_system_ads'];
    const normalValue = _.omit(value, executiveLeagueSettings);
    const executiveValue = _.pick(value, executiveLeagueSettings);

    const obs = [];

    if (Object.keys(normalValue).length > 0) {
      obs.push(
        this.http.patch<any>(
          `${environment.LEAGUES_API}/league/${leagueId}/settings`,
          normalValue,
          this.httpOptions
        )
      );
    }

    if (Object.keys(executiveValue).length > 0) {
      obs.push(
        this.http.patch<any>(
          `${environment.LEAGUES_API}/league/${leagueId}/settings/executive`,
          executiveValue,
          this.httpOptions
        )
      );
    }

    if (obs.length > 0) {
      return forkJoin(obs)
        .pipe(
          tap(response => this.logger.debug('updateLeagueSettings (normal) response:', response))
        )
        .pipe(catchError(this.handleError.bind(this)));
    } else {
      return empty();
    }
  }

  /**
   * Retrieves the league info the user has access to as defined by what was in "leagues" localStorage
   */
  public getMyLeagues(leagueIds: UUID[]): Observable<GetLeagueResponse[] | any> {
    this.logger.debug('Called getMyLeagues', leagueIds);
    const observables: Array<Observable<GetLeagueResponse>> = [];
    leagueIds.forEach(leagueId => {
      observables.push(this.getLeague(leagueId));
    });
    return forkJoin(observables)
      .pipe(tap(response => this.logger.debug('getMyLeagues response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getLeagueStats(): Observable<GetLeagueStatsResponse | any> {
    this.logger.debug('calling getLeagueStats');
    return this.http
      .get<GetLeagueStatsResponse>(
        `${environment.LEAGUES_API}/system/league/stats`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getLeagueStats response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
