import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AppStorage } from '../models/app.storage';
import { ForgotPasswordForm } from '../models/forms/forgot-password.form';
import { LoginForm } from '../models/forms/login.form';
import { VerifyTokenForm } from '../models/forms/verify-token.form';
import { PermissionManager } from '../models/permission.manager';

import { GenericService } from './generic.service';
import { LogoutResponse, TokenResponse } from './responses/auth.responses';

@Injectable()
export class TokenService extends GenericService {
  constructor(
    protected http: HttpClient,
    private storage: AppStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
  }

  public handleAuthResponse(data: TokenResponse): void {
    this.storage.token = data.token;
    this.storage.tokenExpiresAt = data.payload.exp;

    // todo: hack!
    // set the payload to have some other values inside data

    data.payload.leagues = data.leagues;
    data.payload.permissions = data.permissions;
    data.payload.playersByLeague = data.playersByLeague;

    this.storage.tokenPayload = data.payload;
    this.storage.leagueIds = data.payload.leagues;
    if (data.refresh) {
      this.storage.refresh = data.refresh;
    }
  }
}
