import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { YesNoPipe } from '../../_pipes/yes-no.pipe';
import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { TableCellComponent } from '../../components/ng2-table/components/table/table-cell.component';
import { PageableComponent } from '../../components/pageable.component';
import { League } from '../../models/league';
import { LeagueSettings } from '../../models/league-setting';
import { LeagueStorage } from '../../models/league.storage';
import { LeagueService } from '../../services/league.service';
import { ScoringService } from '../../services/scoring.service';

@Component({
  selector: 'app-scoring',
  templateUrl: './scoring.component.html',
  styleUrls: ['./scoring.component.css']
})
export class ScoringComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();
  showDefaultScoring = false;
  settings: LeagueSettings;
  _leagueSettingsSubscription: Subscription;

  constructor(
    protected leagueStorage: LeagueStorage,
    protected scoringService: ScoringService,
    private leagueService: LeagueService
  ) {
    super(scoringService, leagueStorage);
    this.setColumns([
      {
        title: 'Name',
        name: 'name',
        sort: 'asc',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Total Points',
        name: 'total_points',
        filtering: { filterString: '', placeholder: 'Filter' }
      },

      {
        title: 'Positions Paid',
        name: 'positions_paid',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Active',
        name: 'is_active',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._leagueSettingsSubscription) {
      this._leagueSettingsSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this._leagueSettingsSubscription = this.leagueStorage
      .getLeagueSettings()
      .subscribe(settings => {
        this.settings = settings;
        if (this.league && this.settings) {
          this.showDefaultScoring = this.settings.show_default_scoring;
        } else {
          this.showDefaultScoring = false;
        }
      });
  }

  showDefaultScoringChanged(value) {
    this.leagueService
      .updateLeagueSettings(this.league.id, { show_default_scoring: value })
      .subscribe(data => {
        this.leagueStorage.setLeagueSettings(data[0].league_setting);
      });
  }
}
