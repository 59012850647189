import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { YesNoPipe } from '../../../_pipes/yes-no.pipe';
import { EditLinkTableCellComponent } from '../../../components/edit-link.cell.component';
import { PageableComponent } from '../../../components/pageable.component';
import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { SystemMessagesService } from '../../../services/system.messages.service';

@Component({
  selector: 'app-system-messages',
  templateUrl: './messages.component.html'
})
export class SystemMessagesComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(
    protected leagueStorage: LeagueStorage,
    protected messageService: SystemMessagesService
  ) {
    super(messageService, leagueStorage);
    this.setColumns([
      {
        title: 'Title',
        name: 'title',
        filtering: { filterString: '', placeholder: 'Filter by title' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Level',
        name: 'severity',
        filtering: { filterString: '' }
      },
      {
        title: 'Active',
        name: 'is_active',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      },
      {
        title: 'Dismissable',
        name: 'is_dismissable',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      },
      {
        title: 'Expires',
        name: 'expires_at',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Email Sent',
        name: 'last_email_sent_at',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
