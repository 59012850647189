<app-report-header [report]="report"></app-report-header>
  <div *ngFor="let league of reportData.leagues">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
        {{league.name}}
        </div>
        <div class="card-block breakdown-card">
          <div class="col-12">
              <table *ngIf="!report.param_is_summary">
                <!-- NON summary -->
                <tr class="report_header_row report_rows">
                  <td>Date
                  </td>
                  <td>Ad Location
                  </td>
                  <td>Impressions
                  </td>
                  <td>Clicks
                  </td>
                  <td>% Clicks
                  </td>
                  <td>Details
                  </td>
                </tr>
                <tr class="report_item_row report_rows" *ngFor="let row of league.rows">
                  <td>
                    <span>{{row.impression_date | date}}</span>
                  </td>
                  <td>
                    {{ row.location }}
                  </td>
                  <td>
                    {{ row.impressions | number }}
                  </td>
                  <td>
                    {{ row.clicks | number }}
                  </td>
                  <td>
                    {{ row.clicks / row.impressions | percent:'1.2-2' }}
                  </td>
                  <td>
                    <a [routerLink]="['ads-detail']" [queryParams]="{ param_date: row.impression_date, param_ad_location: row.location, param_ad_id: report.param_ad_id, param_league_id: report.param_league_id }">
                      Drill Down</a>
                  </td>
                </tr>

                <tr class="report_footer_row report_rows">
                  <td colspan="2"></td>
                  <td>
                    {{ league.total_impressions | number }}
                  </td>
                  <td>
                    {{ league.total_clicks | number }}
                  </td>
                  <td>
                    {{ league.total_clicks / league.total_impressions | percent:'1.2-2' }}
                  </td>
                  <td></td>
                </tr>
              </table>
              <table *ngIf="report.param_is_summary">
                <!-- SUMMARY -->
                <tr class="report_header_row report_rows">
                  <td>Ad Location
                  </td>
                  <td>Impressions
                  </td>
                  <td>Clicks
                  </td>
                  <td>% Clicks
                  </td>
                </tr>
                <tr class="report_item_row report_rows" *ngFor="let row of league.rows">
                  <td>
                    {{ row.location }}
                  </td>
                  <td>
                    {{ row.impressions | number }}
                  </td>
                  <td>
                    {{ row.clicks | number }}
                  </td>
                  <td>
                    {{ row.clicks / row.impressions | percent:'1.2-2' }}
                  </td>
                </tr>

                <tr class="report_footer_row report_rows">
                  <td>Total Ads Impressions
                  </td>
                  <td>
                    {{ league.total_impressions | number }}
                  </td>
                  <td>
                    {{ league.total_clicks | number }}
                  </td>
                  <td>
                    {{ league.total_clicks / league.total_impressions | percent:'1.2-2' }}
                  </td>
                </tr>
              </table>
        </div>
      </div>
      </div>
</div>
  </div>
