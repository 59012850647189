import { empty, throwError, BehaviorSubject, Observable } from 'rxjs';

import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { NGXLogger } from '../../../node_modules/ngx-logger';
import { AppStorage } from '../models/app.storage';
import { LeagueStorage } from '../models/league.storage';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  constructor(
    private authService: AuthService,
    private router: Router,
    private storage: AppStorage,
    private leagueStorage: LeagueStorage,
    private logger: NGXLogger
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.url.includes('/p/')) {
      return next.handle(this.addAuthHeader(req));
    }
    return next.handle(this.addAuthHeader(req)).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this.handle400Error(error);
            case 401:
              return this.handle401Error(req, next, error);
            default:
              this.logger.debug('throwing, default error', error);
              return throwError(error);
          }
        } else {
          this.logger.debug('throwing, not an httperrorresponse', error);
          return throwError(error);
        }
      })
    );
  }

  hasToken(): boolean {
    return !!this.storage.token;
  }

  addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.storage.token;
    if (this.hasToken()) {
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token
        }
      });
    }
    return request;
  }

  handle400Error(error) {
    return throwError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler, err) {
    if (!this.hasToken()) {
      // got a 401, and no token.  user didn't login right.
      throw err;
    }
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      this.tokenSubject.next(null);
      return this.authService.refresh().pipe(
        switchMap(
          data => {
            this.tokenSubject.next(data);
            return next.handle(this.addAuthHeader(req));
          }
          /*,
          err => {
            console.log('error calling add category' + JSON.stringify(err));
            // return this.logoutUser();
          };*/
        ),
        finalize(() => {
          this.isRefreshingToken = false;
        }),
        catchError(error => {
          this.logger.debug('in catch() handler of switchMap', error);
          if (error instanceof HttpErrorResponse) {
            if ((<HttpErrorResponse>error).status === 401) {
              // failure to refresh, logout.
              this.logger.debug('got a 401 trying to refresh, logout user.');
              return this.logoutUser(error);
            }
          }

          // some other error, just rethrow it.
          throw error;
        })
      );
    } else {
      // we are currently trying to refresh the token
      // wait for valid token
      return this.tokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          this.logger.debug(
            'after successful refresh, switching map with token (might be bogus)',
            token
          );
          return next.handle(this.addAuthHeader(req));
        })
      );
    }
  }

  logoutUser(error) {
    const params = { queryParams: { msg: undefined } };
    if (error && error.errorMessage) {
      params.queryParams.msg = error.errorMessage;
    } else {
      params.queryParams.msg = error.message || error || 'Unknown';
    }

    this.logger.debug('Logging user out', params);
    this.authService.logout();
    this.storage.clear();
    this.leagueStorage.clear();
    this.router.navigate(['login']);
    this.router.navigate(['/login'], params);
    return empty();
  }
}
