import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { YesNoPipe } from '../../_pipes/yes-no.pipe';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { PlayerService } from '../../services/player.service';

import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';

@Component({
  selector: 'app-players',
  templateUrl: './players.component.html',
  styleUrls: ['./players.component.css']
})
export class PlayersComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();

  @ViewChild('downloadZipLink') private downloadZipLink: ElementRef;
  constructor(
    protected leagueStorage: LeagueStorage,
    protected playerService: PlayerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(playerService, leagueStorage);

    this.setColumns([
      {
        title: 'Name',
        name: 'Player.name',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        sort: 'asc',
        component: EditLinkTableCellComponent
      },
      {
        title: 'Tournaments',
        name: 'num_tournaments',
        filtering: { filterString: '', placeholder: 'Filter' },
        render: (value, row) => {
          return parseInt(value, 10).toLocaleString('en');
        }
      },
      {
        title: 'Verified',
        name: 'is_verified',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      },
      {
        title: 'Active',
        name: 'is_active',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  async export() {
    const data = await this.playerService.exportPlayers({});
    const url = window.URL.createObjectURL(data.body);

    const link = this.downloadZipLink.nativeElement;
    link.href = url;
    link.download = 'PlayerExport.csv';
    link.click();

    window.URL.revokeObjectURL(url);
  }
}
