
import {finalize} from 'rxjs/operators';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileSystemDirectoryEntry, FileSystemFileEntry, UploadEvent } from 'ngx-file-drop';
import { Subscription ,  forkJoin } from 'rxjs';

import { MatDialog, MatDialogConfig } from '@angular/material';

import { CustomizeTipsDialogComponent } from '../../components/dialogs/customize-tips/customize-tips.dialog.component';

import { environment } from '../../../environments/environment';
import { CompareValidator } from '../../_validators/compare.validator';
import { AppStorage } from '../../models/app.storage';
import { PlayerSettingForm } from '../../models/forms/player-setting.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Player } from '../../models/player';
import { PlayerSetting } from '../../models/player-setting';
import { PlayerService } from '../../services/player.service';
import { LoaderService } from '../../services/spinner.service';
@Component({
  selector: 'app-player-setting',
  templateUrl: './player-setting.component.html',
  styleUrls: ['./player-setting.component.css']
})
export class PlayerSettingComponent implements OnInit, OnDestroy {
  avatar_url: string;
  public file: File;
  _propertySubscription: Subscription;
  myForm: FormGroup;
  submitted = false;
  league: League;
  player: Player;
  hasPlayer = true;
  fileMessage = '';

  constructor(
    private loaderService: LoaderService,
    private leagueStorage: LeagueStorage,
    private appStorage: AppStorage,
    private router: Router,
    private fb: FormBuilder,
    private service: PlayerService,
    private compareValidator: CompareValidator,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.myForm = fb.group({
      player_id: '',
      border_color: ['', Validators.required],
      header_text_color: ['', Validators.required],
      header_background_color: ['', Validators.required],
      row_text_color: ['', Validators.required],
      alt_row_text_color: ['', Validators.required],
      row_background_color: ['', Validators.required],
      alt_row_background_color: ['', Validators.required],
      background_color: ['', Validators.required]
    });
  }

  // handy access to form fields
  get border_color(): FormControl {
    return this.myForm.get('border_color') as FormControl;
  }
  get header_text_color(): FormControl {
    return this.myForm.get('header_text_color') as FormControl;
  }
  get header_background_color(): FormControl {
    return this.myForm.get('header_background_color') as FormControl;
  }
  get row_text_color(): FormControl {
    return this.myForm.get('row_text_color') as FormControl;
  }
  get alt_row_text_color(): FormControl {
    return this.myForm.get('alt_row_text_color') as FormControl;
  }
  get row_background_color(): FormControl {
    return this.myForm.get('row_background_color') as FormControl;
  }
  get alt_row_background_color(): FormControl {
    return this.myForm.get('alt_row_background_color') as FormControl;
  }
  get background_color(): FormControl {
    return this.myForm.get('background_color') as FormControl;
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getPlayer();
      }
    });
  }

  getPlayer(): void {
    const playerId = this.appStorage.tokenPayload.playersByLeague[this.league.id.toString()];
    if (playerId) {
      this.loaderService.displayLoader(true);
      this.service
        .getMyPlayer({ includeSettings: true }).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.player = response.player;
          this.resetForm(this.player.PlayerSetting);
        });
    } else {
      this.hasPlayer = false;
    }
  }

  setDefaults() {
    this.border_color.setValue('#ccccc2');
    this.header_text_color.setValue('#66665c');
    this.header_background_color.setValue('#d7d7ce');
    this.row_text_color.setValue('#000000');
    this.alt_row_text_color.setValue('#000000');
    this.row_background_color.setValue('#ffffff');
    this.alt_row_background_color.setValue('#efefe5');
    this.background_color.setValue('#323366');

    this.border_color.markAsDirty();
    this.header_text_color.markAsDirty();
    this.header_background_color.markAsDirty();
    this.row_text_color.markAsDirty();
    this.alt_row_text_color.markAsDirty();
    this.row_background_color.markAsDirty();
    this.alt_row_background_color.markAsDirty();
    this.background_color.markAsDirty();
  }

  setColor(control: FormControl, color: any) {
    control.setValue(color);
    control.markAsDirty();
  }

  private resetForm(playerSetting: PlayerSetting) {
    this.myForm.reset();
    this.myForm.setValue(new PlayerSettingForm(playerSetting), { onlySelf: true });

    this.avatar_url = playerSetting.avatar_url
      ? `/assets/images/${playerSetting.avatar_url}`
      : `${environment.AVATARS_API}/p/player/${
          playerSetting.player_id
        }/avatar?buster=${new Date().getTime()}`;
  }

  validateFile(file: File) {
    this.fileMessage = '';
    if (file.size < 2000000) {
      this.file = file;
      this.myForm.markAsDirty();
    } else {
      this.fileMessage = 'Image is too large. Max size is 2MB.';
    }
    this.cd.detectChanges();
  }

  fileSelected(files: FileList) {
    if (files && files.length > 0) {
      this.validateFile(files[0]);
    }
  }

  public dropped(event: UploadEvent) {
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        // this.file = droppedFile;
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          this.validateFile(file);
        });
      }
    }
  }

  public submit(value: PlayerSettingForm): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);

      // submit to API
      const observables = [this.service.updatePlayerSettings(value)];
      if (this.file) {
        observables.push(this.service.uploadAvatar(this.file));
      }

      forkJoin(observables).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(
          ([settingsResponse, avatarResponse]) => {
            // Page redirect when getting response
            this.player.PlayerSetting = settingsResponse.player_setting;
            this.file = null;
            this.resetForm(this.player.PlayerSetting);
          },
          error => {
            console.error('err', error);
          }
        );
    }
  }
  openCustomizeTips() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    this.dialog.open(CustomizeTipsDialogComponent, dialogConfig);
  }
}
