// common components that should be imported to both Secure and Public modules.

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatNativeDateModule, MatPaginator, MatPaginatorModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { NglModule } from 'ng-lightning/ng-lightning';
import { ClipboardModule } from 'ngx-clipboard';
import { ColorPickerModule } from 'ngx-color-picker';
import { FileDropModule } from 'ngx-file-drop';

import { RouterModule } from '../../node_modules/@angular/router';

import { AdSpotComponent } from './components/ad-spot/ad-spot.component';
import { DealerHelpComponent } from './components/dealer-help/dealer-help.component';
import { EditLinkTableCellComponent } from './components/edit-link.cell.component';
import { FilterInstructionsComponent } from './components/filter-instructions/filter-instructions.component';
import { PlayerProfileLinkComponent } from './components/player-profile-link/player-profile-link.component';
import { AlertComponent } from './directives/alert/alert.component';
import { CollapseAnimationDirective } from './directives/collapse-animation.directive';
import { MessagesComponent } from './directives/messages/messages.component';
import { SelectTextDirective } from './directives/select-text.directive';
import { PermissionManager } from './models/permission.manager';
import { PipesModule } from './pipes.module';
import { AdIframeComponent } from './secure/ads/ad-iframe.component';
import { EventIframeComponent } from './secure/events/event-iframe.component';
import { AlertService } from './services/common/alert.service';
import { SystemMessagesService } from './services/system.messages.service';
import { VenueDetailService } from './services/venue-detail.service';

@NgModule({
  declarations: [
    AdSpotComponent,
    PlayerProfileLinkComponent,
    FilterInstructionsComponent,
    DealerHelpComponent,
    SelectTextDirective,
    CollapseAnimationDirective,
    EditLinkTableCellComponent,
    EventIframeComponent,
    AdIframeComponent,
    AlertComponent,
    MessagesComponent
  ],
  providers: [PermissionManager, VenueDetailService, AlertService, SystemMessagesService],
  imports: [
    NglModule,
    BrowserAnimationsModule,
    ClipboardModule,
    ColorPickerModule,
    CommonModule,
    FileDropModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PipesModule,
    RouterModule
  ],
  exports: [
    AlertComponent,
    MessagesComponent,
    NglModule,
    AdSpotComponent,
    ColorPickerModule,
    PlayerProfileLinkComponent,
    FileDropModule,
    FilterInstructionsComponent,
    DealerHelpComponent,
    SelectTextDirective,
    CollapseAnimationDirective,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatCardModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,

    ClipboardModule
  ],
  entryComponents: [EditLinkTableCellComponent, EventIframeComponent, AdIframeComponent]
})
export class CustomModule {}
