<div class="col-md-12">
  <div class="loginlogo">
    <img src="/assets/images/login/logo.png" width="100%" height="auto" alt="Poker Leaderboards" />
  </div>
  <form id="form1" [formGroup]="myForm" (ngSubmit)="playerSignup(myForm.value)">
    <input formControlName="player_id" type="hidden" />
    <input formControlName="league_id" type="hidden" />
    <input formControlName="name" type="hidden" />
    <div *ngIf="!showControls">
      <input [formControl]="myForm.controls['email']" type="hidden" />
      <input [formControl]="myForm.controls['password']" type="hidden" />
    </div>
    <div class="card text-center">
      <div class="card-header">
        Verify Player Account
        <div class="card-info">
          <mat-icon [matMenuTriggerFor]="menu">help</mat-icon>
          <mat-menu #menu="matMenu">
            <ul class="verify-help-list">
              <li class="verify-help-item">
                <span class="list_number">1.</span>
                Enter your chosen email and password. If you already have an account, just enter
                your existing credentials.
              </li>
              <li class="verify-help-item">
                <span class="list_number">2.</span>
                Verify all your details are correct.if incorrect,
                <a href="https://support.fullquality.com">click here</a>.
              </li>
              <li class="verify-help-item">
                <span class="list_number">3.</span>
                Click Verify.
              </li>
              <li class="verify-help-item">
                <span class="list_number">4.</span>
                Check your email to complete the verification process.
              </li>
            </ul>
          </mat-menu>
        </div>
      </div>
      <div id="member_login">
        <div class="card-block">
          <div *ngIf="errorMessage" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
          <div *ngIf="profileExists" class="alert alert-danger" role="alert">
            You already have a player defined in this league. Click here to view your profile:
            <a routerLink="/admin/profile" title="My Profile">My Profile</a>
            If you think this is a mistake, please contact the league administrator.
          </div>

          <div *ngIf="!loading && showForm">
            <div class="player_signup">
              <div class="signup_prompt"></div>
              <div class="signin_form">
                <div class="player-signup-info panelrow col-md-12">
                  <div class="login-label">
                    League:
                  </div>
                  <div class="login-value">
                    {{ league?.name }}
                  </div>
                  <div class="login-label">
                    Name:
                  </div>
                  <div class="login-value">
                    {{ player?.name }}
                  </div>
                </div>
                <div *ngIf="showControls">
                  <div class="panelrow col-md-12">
                    <mat-form-field class="editor-mat-field">
                      <input
                        required
                        #email="ngForm"
                        matInput
                        [formControl]="myForm.controls['email']"
                        placeholder="Email"
                      />
                      <mat-hint>Usually your email address.</mat-hint>
                      <mat-error *ngIf="email.hasError('required') && !email.pristine">
                        Email is required.
                      </mat-error>
                      <mat-error *ngIf="email.hasError('email') && !email.pristine">
                        Email is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="panelrow col-md-12">
                    <mat-form-field class="editor-mat-field">
                      <input
                        required
                        #password="ngForm"
                        matInput
                        [formControl]="myForm.controls['password']"
                        placeholder="Password"
                        type="password"
                      />
                      <mat-hint *ngIf="loginMsg" class="error-hint">
                        <i class="fas fa-exclamation-triangle"></i> {{ loginMsg }}.</mat-hint
                      >
                      <mat-error *ngIf="password.hasError('required') && !password.pristine">
                        Password is required.
                      </mat-error>
                      <mat-error *ngIf="password.hasError('minlength') && !password.pristine">
                        Password must be at least 8 characters.
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngIf="!showControls">
                  <span
                    >You are already logged in. This player will be associated with your account
                    when you click Verify.</span
                  >
                </div>
              </div>
              <br />

              <span *ngIf="existingEmail"
                >This email already exists. Login with your credentials to verify this player with
                your account.</span
              >

              <button
                mat-raised-button
                *ngIf="existingEmail"
                [disabled]="!myForm.valid || submitted"
                type="submit"
                color="primary"
                class="button gridview-editor-btn loginbtn loginsignup"
              >
                Login and Verify
              </button>
              <button
                mat-raised-button
                *ngIf="!existingEmail"
                [disabled]="!myForm.valid || submitted"
                type="submit"
                color="primary"
                class="button gridview-editor-btn loginbtn loginsignup"
              >
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="loginprivacy">
    We value your
    <a style="cursor: pointer;" (click)="openPrivacy()">privacy</a>
  </div>
</div>
