import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { empty, forkJoin } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { DateValidator } from '../../../_validators/date.validator';
import { MessageForm } from '../../../models/forms/message.form';
import { League } from '../../../models/league';
import { Message } from '../../../models/message';
import { Role } from '../../../models/role';
import { UUID } from '../../../models/uuid';
import { LeagueService } from '../../../services/league.service';
import { RoleService } from '../../../services/role.service';
import { LoaderService } from '../../../services/spinner.service';
import { SystemMessagesService } from '../../../services/system.messages.service';

@Component({
  selector: 'app-system-message-detail',
  templateUrl: './message-detail.component.html'
})
export class SystemMessageDetailComponent implements OnInit, OnDestroy {
  isNewModel = true;
  myForm: FormGroup;
  submitted = false;
  model = new Message();
  levels = SystemMessagesService.LEVELS;
  minDate = new Date();
  maxDate = new Date('9999-12-31');

  roles: Role[];
  leagues: League[];

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: SystemMessagesService,
    private dateValidator: DateValidator,
    private route: ActivatedRoute,
    private leagueService: LeagueService,
    private roleService: RoleService,
    private logger: NGXLogger
  ) {
    this.myForm = fb.group({
      id: '',
      title: ['', Validators.required],
      message: ['', Validators.required],
      severity: ['info', Validators.required],
      expires_at: [
        moment()
          .add(1, 'week')
          .toDate(),
        dateValidator.create({ allowNulls: false })
      ],
      param_league_ids: [[]],
      param_league_types: [[]],
      param_role_ids: [[]],
      is_dismissable: true,
      is_active: true,
      last_email_sent_at: null
    });
  }

  // handy access to form fields
  get title(): FormControl {
    return this.myForm.get('title') as FormControl;
  }
  get message(): FormControl {
    return this.myForm.get('message') as FormControl;
  }
  get is_active(): FormControl {
    return this.myForm.get('is_active') as FormControl;
  }
  get is_dismissable(): FormControl {
    return this.myForm.get('is_dismissable') as FormControl;
  }
  get severity(): FormControl {
    return this.myForm.get('severity') as FormControl;
  }
  get expires_at(): FormControl {
    return this.myForm.get('expires_at') as FormControl;
  }
  get param_league_id(): FormControl {
    return this.myForm.get('param_league_id') as FormControl;
  }
  get param_league_type(): FormControl {
    return this.myForm.get('param_league_type') as FormControl;
  }
  get param_role_id(): FormControl {
    return this.myForm.get('param_role_id') as FormControl;
  }

  ngOnDestroy() {}

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this.getMessage();
    forkJoin([
      this.roleService.getPrivilegedRoles({
        select: 'id,name',
        pageSize: 100000
      }),
      this.leagueService.getLeagues({
        pageSize: 100000,
        select: 'id,name',
        'order_by[name]': 'asc'
      })
    ])
      .pipe(
        switchMap(([rolesResponse, leaguesResponse]) => {
          this.logger.debug('received response from all services', rolesResponse, leaguesResponse);
          this.roles = rolesResponse.roles;
          this.leagues = leaguesResponse.leagues;
          return empty();
        })
      )
      .pipe(finalize(() => this.loaderService.displayLoader(false)))
      .subscribe();
  }

  getMessage(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getMessage(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = response.message;
        this.resetForm(this.model);
      });
    }
  }

  public submitAndClose(value: MessageForm) {
    this._submit(value, (model: Message) => {
      this.router.navigate(['/system/messages']);
    });
  }

  public submit(value: MessageForm) {
    this._submit(value, (model: Message) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/system/messages/' + model.id]);
    });
  }

  private resetForm(message: Message) {
    this.myForm.reset();
    this.myForm.setValue(new MessageForm(message), { onlySelf: true });
  }

  public _submit(value: MessageForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createMessage(value)
        : this.service.updateMessage(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.message);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  public sendEmail(id: UUID): void {
    this.loaderService.displayLoader(true);
    // submit to API
    this.service
      .sendEmail(id)
      .pipe(finalize(() => this.loaderService.displayLoader(false)))
      .subscribe(response => {
        this.model = response.message;
        this.resetForm(this.model);
      });
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.title
        }? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteMessage(this.model.id)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/system/messages']);
        });
    }
  }
}
