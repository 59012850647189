import { Moment } from 'moment';

import { PlayerSetting } from './player-setting';
import { UUID } from './uuid';
export class Player {
  public id: UUID;
  public name: string;
  public email?: string;
  public is_active: boolean;
  public notes?: string;
  public num_tournaments?: number;
  public membership_type?: string;
  public is_membership: boolean;
  public is_12_month_package: boolean;
  public is_first_6_month_package: boolean;
  public is_second_6_month_package: boolean;
  public start_date?: Moment;
  public end_date?: Moment;
  public qualified_date?: Moment;
  public is_invited_main_event: boolean;
  public is_verified: boolean;
  public league_id: UUID;
  public user_id: UUID;
  public PlayerSetting: PlayerSetting;
}
