<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
    </div>
  </div>
</div>
<app-report-header [report]="report"></app-report-header>
<div *ngFor="let league of reportData.leagues">

  <div class="report_item col-md-4">
    <div class="card">
      <div class="card-header">
        Staff Payroll
      </div>
      <div class="card-block breakdown-card">
        <div class="col-12">
        <table>
          <tr *ngFor="let payrollUser of league.staff_expenses">
            <td>
              <a [routerLink]="['payroll-detail', payrollUser.id]">{{payrollUser.name}}</a>- {{payrollUser.amount | currency}}
            </td>
          </tr>
          <tr>
            <td>Total Staff Payroll: {{league.total_staff_payroll | currency}}
              <br />
              <br /> Total Sales: {{league.total_sales | currency}} ( {{league.total_verified_sales | currency}} Verified)
            </td>
          </tr>
        </table>
      </div>
    </div>
    </div>
  </div>
  <div class="report_item col-md-4">
    <div class="card">
      <div class="card-header">
        Positions
      </div>
      <div class="card-block breakdown-card">
      <div class="col-12">
        <div *ngFor="let byRole of league.staff_role_expenses">
          <div class="report-heading-two">
            {{byRole.name}}s
          </div>
          <table cellspacing="0" cellpadding="0" border="0">
            <tbody>
              <tr *ngFor="let user of byRole.users">
                <td>
                  {{user.name}}
                </td>
                <td>&nbsp;&nbsp;
                </td>
                <td align="right">
                  {{user.amount | currency}}
                </td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </div>
    </div>
    </div>


  </div>
  <div class="report_item col-md-4">
    <div class="card">
      <div class="card-header">
        Other Items
      </div>
      <div class="card-block breakdown-card">
        <div class="col-12">
        <!-- report expenses-->
        <table>
          <tr *ngFor="let item of league.item_expenses">
            <td>
              {{item.name}}
            </td>
            <td>&nbsp;&nbsp;
            </td>
            <td>
              {{item.amount | currency}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    </div>
  </div>


    <div class="col-12">
      <div class="card report-card">
        <div class="card-header">
        Staff Payroll Breakdown
        </div>
        <div class="card-block">
              <!-- rpt tournaments -->
              <div class="col-md-12" *ngFor="let tournament of league.Tournaments">
                <div class="card">
                  <div class="col-12">
                  <div class="card-header">
                    {{tournament.start_date | date}} | {{tournament.Venue.name}} | {{tournament.Venue.venue_number}} | {{tournament.venue_cost
                    | currency}}
                  </div>
                  <div class="card-block breakdown-card">
                    <table >
                      <tr *ngFor="let staff of tournament.staff_expenses">
                        <td>
                          {{staff.abbreviation}}
                          <span *ngIf="staff.abbreviation">:</span> {{ staff.User.name }}
                        </td>
                        <td>&nbsp;&nbsp;
                        </td>
                        <td align="right">
                          {{staff.payout_amount | currency}}
                        </td>
                        <td>&nbsp;&nbsp;
                        </td>
                        <td align="right">
                          {{staff.payout_percent / 100 | percent}}
                        </td>
                      </tr>
                      <tr *ngFor="let item of tournament.item_expenses">
                        <td>
                          {{item.label}}
                        </td>
                        <td>&nbsp;&nbsp;
                        </td>
                        <td align="right">
                          {{item.payout_amount | currency}}
                        </td>
                        <td>&nbsp;&nbsp;
                        </td>
                        <td align="right">
                          {{item.payout_percent / 100 | percent}}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5">&nbsp;
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                </div>
              </div>
        </div>
      </div>
    </div>
      </div>
