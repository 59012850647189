import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { DealerService } from '../../services/dealer.service';

import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.css']
})
export class DealersComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;

  constructor(
    protected leagueStorage: LeagueStorage,
    protected dealerService: DealerService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(dealerService, leagueStorage);
    this.setColumns([
      {
        title: 'Name',
        name: 'name',
        sort: 'asc',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
