<div *ngIf="model">
  <div [ngSwitch]="model.report_type">
    <div *ngSwitchCase="'tournament'">
      <app-tournament-report [report]="model" [reportData]="data">
      </app-tournament-report>
    </div>
    <div *ngSwitchCase="'ads'">
      <app-ads-report [report]="model" [reportData]="data">
      </app-ads-report>
    </div>
    <div *ngSwitchCase="'dealer'">
      <app-dealer-report [report]="model" [reportData]="data">
      </app-dealer-report>
    </div>
    <div *ngSwitchCase="'payroll'">
      <app-payroll-report [report]="model" [reportData]="data">
      </app-payroll-report>
    </div>
    <div *ngSwitchDefault>Unknown (report if you see this)...{{model.report_type}}</div>
  </div>
</div>
