import { PlayerSetting } from '../player-setting';
import { UUID } from '../uuid';
export class PlayerSettingForm {
  public player_id: UUID;
  public border_color: string;
  public header_text_color: string;
  public header_background_color: string;
  public row_text_color: string;
  public alt_row_text_color: string;
  public row_background_color: string;
  public alt_row_background_color: string;
  public background_color: string;

  constructor(model: PlayerSetting) {
    this.player_id = model.player_id;
    this.border_color = model.border_color;
    this.header_text_color = model.header_text_color;
    this.header_background_color = model.header_background_color;
    this.row_text_color = model.row_text_color;
    this.alt_row_text_color = model.alt_row_text_color;
    this.row_background_color = model.row_background_color;
    this.alt_row_background_color = model.alt_row_background_color;
    this.background_color = model.background_color;
  }
}
