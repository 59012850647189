<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/ads">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button
          mat-menu-item
          [disabled]="isNewModel"
          id="btnDelete"
          type="submit"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> Remove Ad
        </button>
      </mat-menu>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSaveContinue"
        type="submit"
        class="action-save-button"
        (click)="submit(myForm.value)"
      >
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSave"
        type="submit"
        class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)"
      >
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>

<form [formGroup]="myForm">
  <input type="hidden" formControlName="id" />
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <span class="card-header-edit-label">{{ isNewModel ? 'Create' : 'Edit' }} League Ad</span>
        {{ name.value }}
      </div>
      <div class="card-block">
        <div class="panelrow col-md-6">
          <mat-form-field class="editor-mat-field">
            <input required matInput formControlName="name" placeholder="Name" />
            <mat-error *ngIf="name.hasError('required') && !name.pristine">
              Name is required.
            </mat-error>
            <mat-error
              *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)"
            >
              Name already exists.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="panelrow col-md-6">
          <mat-form-field>
            <mat-select
              required
              class="season-select"
              formControlName="location"
              placeholder="Ad Locations"
              multiple
            >
              <mat-option *ngFor="let location of locations" [value]="location.location">{{
                location.description
              }}</mat-option>
            </mat-select>
            <mat-hint>Select location(s) to display ad.</mat-hint>
          </mat-form-field>
        </div>
        <div class="panelrow col-md-12">
          <mat-form-field class="editor-mat-field">
            <textarea
              required
              rows="5"
              matInput
              formControlName="ad_text"
              placeholder="Ad Text"
            ></textarea>
            <mat-error *ngIf="ad_text.hasError('required') && !ad_text.pristine">
              Ad text is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="card-footer text-muted">
        <mat-slide-toggle formControlName="is_active">Enabled</mat-slide-toggle>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        Ad Preview
      </div>
      <div class="card-block">
        <div class="col-md-12">
          <div class="panelrow">
            <div [innerHtml]="ad_text.value"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
