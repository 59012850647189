import { Event } from '../event';
import { UUID } from '../uuid';
export class EventForm {
  public id: UUID;
  public name: string;
  public is_active: boolean;
  public announce_date: Date;
  public event_date: Date;
  public start_qualify: Date;
  public end_qualify: Date;
  public event_criteria: {
    type: string;
    criteria: number;
    rank: number;
    coverage_values: string[];
  }[];
  constructor(model: Event) {
    this.id = model.id;
    this.name = model.name;

    this.announce_date =
      model.announce_date == null ? null : new Date(model.announce_date.toString());
    this.event_date = model.event_date == null ? null : new Date(model.event_date.toString());
    this.start_qualify =
      model.start_qualify == null ? null : new Date(model.start_qualify.toString());
    this.end_qualify = model.end_qualify == null ? null : new Date(model.end_qualify.toString());

    /*
    const c1 = new EventCriteria();
    c1.type = 'p';
    c1.criteria = 1;
    c1.coverage = 'sv';
    c1.specific_venue_ids = '8995dd4d-072c-4d77-b34e-a625506aec1a,cc8ee64b-dc32-4644-8004-d39d8491322c';
    c1.rank = 1;

    const c2 = new EventCriteria();
    c2.type = 'w';
    c2.criteria = 2;
    c2.coverage = 'o';
    c2.specific_venue_ids = '8995dd4d-072c-4d77-b34e-a625506aec1a,cc8ee64b-dc32-4644-8004-d39d8491322c';
    c2.rank = 2;
    const c3 = new EventCriteria();
    c3.type = '#';
    c3.criteria = 3;
    c3.coverage = 'v';
    c3.specific_venue_ids = '8995dd4d-072c-4d77-b34e-a625506aec1a,cc8ee64b-dc32-4644-8004-d39d8491322c';
    c3.rank = 3;
    model.event_criteria = [c1, c2, c3];
    */

    this.event_criteria = [];
    if (model.EventCriteria && model.EventCriteria.length > 0) {
      this.event_criteria = model.EventCriteria.map(criteria => {
        let values = [];
        if (criteria.coverage === 'sv') {
          if (criteria.specific_venue_ids) {
            values = criteria.specific_venue_ids.split(',');
          }
        } else {
          values = [criteria.coverage];
        }

        return {
          type: criteria.type,
          criteria: criteria.criteria,
          rank: criteria.rank,
          coverage_values: values,
          specific_venue_ids: criteria.specific_venue_ids,
          coverage: criteria.coverage
        };
      });
    }
  }
}
