import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filter-instructions',
  templateUrl: './filter-instructions.component.html',
  styleUrls: ['./filter-instructions.component.css']
})
export class FilterInstructionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
