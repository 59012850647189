<div class="col-12">
  <div class="card leaderboard-ad">
    <div class="card-block">
      <app-ad-spot [leagueId]="leagueId" location="leaderboard"> </app-ad-spot>
    </div>
  </div>
  <div class="card card-leaderboard-details classic-leaderboard">
    <div class="card-block">
      <div class="classic-header" style="font-size: 12px">
        <div class="classic-header-padding">
          <table class="lbHeader">
            <tr>
              <th>SEASON:</th>
              <td>
                <select
                  [(ngModel)]="selectedSeason"
                  (change)="seasonChanged()"
                  [compareWith]="compareModels"
                >
                  <option *ngFor="let season of seasons" [ngValue]="season">{{
                    season.name
                  }}</option>
                  <option value="">All Seasons</option>
                </select>
                {{ safeSelectedSeason?.start_date || '01-01-1970' | date: 'mediumDate' }} -
                {{ (safeSelectedSeason?.end_date | date: 'mediumDate') || 'present' }}
              </td>
            </tr>
            <tr>
              <th>VENUE:</th>
              <td>
                <select
                  [(ngModel)]="selectedVenue"
                  (change)="venueChanged()"
                  [compareWith]="compareModels"
                >
                  <option value="">-- All Venues --</option>
                  <option *ngFor="let venue of venues" [ngValue]="venue">{{ venue.name }}</option>
                </select>
                &nbsp;&nbsp;
                <button
                  *ngIf="safeSelectedVenue && safeSelectedSeason"
                  (click)="venueDetailClicked()"
                >
                  Details
                </button>
              </td>
            </tr>
            <tr id="tr_playerSearch">
              <th style="height: 20px;">SEARCH:</th>
              <td>
                <input
                  [(ngModel)]="searchPlayer"
                  (change)="searchChanged()"
                  placeholder="Search players"
                  type="text"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="leaderboard-gridview-logic">
        <div class="leaderboard-loading" *ngIf="loading">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <div *ngIf="records.length == 0 && !loading" class="leaderboard-notice">
          No Points found for selected Season/Venue
        </div>
        <table class="lbGridDefault grid-classiclb" id="grdLeaderBoard" *ngIf="!loading">
          <thead>
            <tr class="lbHeaderRow" *ngIf="records.length > 0 && !loading">
              <th scope="col" style="border-color:#CCCCC2;">
                <a (click)="sortColumn('ranking')" style="color:#66665C;">POS</a>
              </th>
              <th align="left" scope="col" style="border-color:#CCCCC2;">
                <a (click)="sortColumn('name')" style="color:#66665C;">NAME</a>
              </th>
              <th align="right" scope="col" style="border-color:#CCCCC2;width:50px;">
                <a (click)="sortColumn('points_awarded')" style="color:#66665C;">POINTS</a>
              </th>
              <th align="center" scope="col" style="border-color:#CCCCC2;width:50px;">
                <a (click)="sortColumn('wins')" style="color:#66665C;">WINS</a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let record of records; let i = index"
              class="lbMainRow"
              [class.lbGridDefaultAltRow]="i % 2 == 1"
            >
              <td align="right" style="border-color:#CCCCC2;width:35px;">{{ record.ranking }}</td>
              <td style="border-color:#CCCCC2;">
                <app-player-profile-link
                  [player]="record"
                  [season_id]="record.season_id"
                ></app-player-profile-link>
              </td>
              <td align="right" style="border-color:#CCCCC2;">
                {{ record.points_awarded | number: '1.2-2' }}
              </td>
              <td align="center" style="border-color:#CCCCC2;">{{ record.wins }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="config.paging" class="card-footer page-footer">
      <div class="gridview-paginator-logic">
        <div class="mat-paginator-page-goto">
          <mat-form-field>
            <mat-select [ngModel]="pageIndex + 1" (ngModelChange)="manualPageChanged($event)">
              <mat-option [value]="1">Page</mat-option>
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{ mp }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator
          [length]="length"
          [pageSize]="itemsPerPage"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex"
          (page)="pageChanged($event)"
          (numPages)="numPages = $event"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="leaderboard-brand-logic">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
