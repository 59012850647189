<app-report-header [report]="report"></app-report-header>

<div class="col-12">
  <div class="card">
    <div class="card-header">
    Ad Report Drilldown
    </div>
    <div class="card-block">
      <div class="col-12">
        <table>
          <tr class="report_header_row report_rows">
            <td>Date
            </td>
            <td>Ad Location
            </td>
            <td>Impressions
            </td>
            <td>Clicks
            </td>
            <td>% Clicks
            </td>
            <td>
              Ad Name
            </td>
            <td>Ad Text
            </td>
          </tr>

          <tr class="report_item_row report_rows" *ngFor="let row of data.rows">
            <td>
              <span>{{row.impression_date | date}}</span>
            </td>
            <td>
              <span>{{ row.location }}</span>
            </td>
            <td>
              <span>{{ row.impressions | number }}</span>
            </td>
            <td>
              <span>{{ row.clicks | number }}</span>
            </td>
            <td>
              <span> {{ row.clicks / row.impressions | percent:'1.2-2' }}</span>
            </td>
            <td>
              <span> {{ row.ad_name }}</span>
            </td>
            <td>
              <div [innerHtml]="row.ad_text"></div>
            </td>
          </tr>

          <tr class="report_footer_row report_rows">
            <td colspan="2"></td>
            <td>
              {{ data.total_impressions | number }}
            </td>
            <td>
              {{ data.total_clicks | number }}
            </td>
            <td>
              {{ data.total_clicks / data.total_impressions | percent:'1.2-2' }}
            </td>
            <td colspan="2"></td>
          </tr>

        </table>
    </div>
  </div>
  </div>
</div>
