import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { YesNoPipe } from '../../../_pipes/yes-no.pipe';
import { EditLinkTableCellComponent } from '../../../components/edit-link.cell.component';
import { LoginAsTableCellComponent } from '../../../components/login-as.cell.component';
import { PageableComponent } from '../../../components/pageable.component';
import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { LeagueService } from '../../../services/league.service';

@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html'
})
export class LeaguesComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();
  datePipe: DatePipe = new DatePipe('en-US');
  currencyPipe: CurrencyPipe = new CurrencyPipe('en-US');

  constructor(protected leagueStorage: LeagueStorage, protected leagueService: LeagueService) {
    super(leagueService, leagueStorage);
    this.setColumns([
      {
        title: 'Name',
        name: 'League.name',
        filtering: { filterString: '', placeholder: 'Filter by league' },
        sort: 'asc',
        sortIndex: 2,
        component: EditLinkTableCellComponent
      },
      {
        title: 'Players',
        name: 'num_members',
        filtering: false,
        render: (value, row) => {
          return parseInt(value, 10).toLocaleString('en');
        }
      },
      {
        title: 'Date',
        name: 'created_at',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Type',
        name: 'account_type',
        filtering: { filterString: '', placeholder: 'Filter by account type' },
        sort: 'asc',
        sortIndex: 1
      },
      {
        title: 'Owner, First',
        name: 'User.first_name',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Owner, Last',
        name: 'User.last_name',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Email',
        name: 'User.email',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Last Login',
        name: 'User.last_login_date',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: '$ owed',
        name: 'total_owed',
        filtering: false,
        render: (value, row) => {
          return this.currencyPipe.transform(value);
        }
      },
      {
        title: 'Due date',
        name: 'due_date',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Trial?',
        name: 'LeagueSettings.trial_used',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      },
      {
        title: null,
        name: 'id',
        component: LoginAsTableCellComponent,
        sort: false,
        filtering: false
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
    this.setDefaultParams({ adminPage: true });
  }
}
