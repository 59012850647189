<app-report-header [report]="report"></app-report-header>
  <div *ngFor="let league of reportData.leagues">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
            {{league.name}}
            </div>
            <div class="card-block breakdown-card">
              <div class="col-12">
              <table>
                <tr *ngFor="let row of league.rows">
                  <td>
                    <a [routerLink]="['dealer-detail', row.dealer_id]">{{row.dealer}}</a> - {{row.time | number:'1.2-2'}}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="card-footer">
            Total Hours: {{league.total_time | number:'1.2-2'}}
          </div>
          </div>
    </div>
  </div>
