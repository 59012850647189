import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { League } from '../../../models/league';
import { Player } from '../../../models/player';
import { Season } from '../../../models/season';
import { UUID } from '../../../models/uuid';
import { LeaderboardService } from '../../../services/leaderboard.service';
import { LeagueService } from '../../../services/league.service';
import { PlayerService } from '../../../services/player.service';
import { SeasonService } from '../../../services/season.service';

@Component({
  templateUrl: './player-profile.component.html',
  styleUrls: ['./player-profile.component.css']
})
export class VerifiedPlayerProfileComponent implements OnInit {
  loading = true;
  avgFinish = 0.0;
  avgPoints = 0.0;
  numTournaments = 0;
  player: Player;
  selectedSeason: Season;
  seasons: Season[];
  league_logo: string;
  avatar_url: string;
  tournaments: any[];
  playerId;
  leagueId;
  league: League;
  lifetimeStats;
  seasonStats;
  venueTournaments;
  private queryTerms = new Subject<any>();
  termsSubscription: Subscription;
  currentUrl: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private leaderboardService: LeaderboardService,
    private playerService: PlayerService,
    private seasonService: SeasonService,
    private leagueService: LeagueService
  ) {}

  ngOnInit() {
    this.playerId = this.route.snapshot.paramMap.get('player_id');
    let seasonId: any = this.route.snapshot.queryParamMap.get('season_id');
    seasonId = UUID.isGuid(seasonId) ? seasonId : null;
    this.currentUrl = encodeURIComponent(window.location.href);
    if (this.playerId) {
      this.playerService
        .getPublicPlayer(this.playerId, {
          includeSettings: true
        })
        .subscribe(playerResponse => {
          this.player = playerResponse.player;
          this.league_logo = `${environment.AVATARS_API}/p/league/${this.player.league_id}/logo`;
          const sources = [
            this.leaderboardService.getLeaderboard(this.player.league_id, {
              player_id: this.player.id
            }),
            this.seasonService.getPublicSeasons(this.player.league_id),
            this.leagueService.getPublicLeague(this.player.league_id)
          ];

          /*
          if (seasonId) {
            sources.push(this.leaderboardService.getLeaderboard(this.player.league_id, { player_id: this.player.id, season_id: seasonId }))
            sources.push(this.leaderboardService.getPlayerVenueStats(this.player.league_id, seasonId, this.player.id))
          }
          */
          forkJoin(sources).subscribe(
            ([
              lifetimeStatsResponse,
              seasonsListResponse,
              leagueResponse /*, seasonStatsResponse, venueRecordsResponse */
            ]) => {
              // set the avatar url dynamically
              // if they have a random one, it comes from the assets/images dir
              // otherwise it comes from the API
              this.avatar_url = this.player.PlayerSetting.avatar_url
                ? `/assets/images/${this.player.PlayerSetting.avatar_url}`
                : `${environment.AVATARS_API}/p/player/${this.player.id}/avatar`;

              this.seasons = seasonsListResponse.seasons;
              this.setSelectedSeason(seasonId);

              this.lifetimeStats =
                lifetimeStatsResponse.records.length === 1
                  ? lifetimeStatsResponse.records[0]
                  : null;

              this.league = leagueResponse.league;
            }
          );
        });

      this.termsSubscription = this.queryTerms
        .pipe(
          // wait 200ms after each keystroke before considering the term
          debounceTime(200),

          // ignore new term if same as previous term
          distinctUntilChanged(),

          // switch to new search observable each time the term changes
          switchMap((params: any) => {
            this.loading = true;
            return forkJoin([
              this.leaderboardService.getLeaderboard(params.league_id, {
                player_id: params.player_id,
                season_id: params.season_id
              }),
              this.leaderboardService.getPlayerVenueStats(
                params.league_id,
                params.season_id,
                params.player_id
              ),
              this.leaderboardService.getPlayerTournaments(params.player_id, {
                season_id: params.season_id
              })
            ]).pipe(finalize(() => (this.loading = false)));
          })
        )
        .subscribe(([seasonStatsResponse, venueRecordsResponse, tournamentsResponse]) => {
          this.seasonStats =
            seasonStatsResponse.records.length === 1 ? seasonStatsResponse.records[0] : null;
          this.venueTournaments = venueRecordsResponse.records;
          this.tournaments = tournamentsResponse.records;
        });
    }
  }

  setSelectedSeason(seasonId: string) {
    this.selectedSeason = this.seasons.find(x => x.id.toString() === seasonId) || this.seasons[0];

    this.seasonChanged();
  }

  seasonChanged() {
    this.queryTerms.next({
      league_id: this.player.league_id,
      player_id: this.player.id,
      season_id: this.selectedSeason.id
    });
  }

  compareModels(a, b) {
    return (a === b && a == null) || (a && b && a.id === b.id);
  }
}
