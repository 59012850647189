<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/scoring">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button
          mat-menu-item
          [disabled]="isNewModel || model.league_id == null"
          id="btnDelete"
          type="submit"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> Remove Scoring System
        </button>
      </mat-menu>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSaveContinue"
        type="submit"
        class="action-save-button"
        (click)="submit(myForm.value)"
      >
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSave"
        type="submit"
        class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)"
      >
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>
<form id="form1" [formGroup]="myForm">
  <input type="hidden" formControlName="id" />
  <!-- eventually expose this as a user-selectable value -->
  <input type="hidden" formControlName="is_active" value="1" />
  <div class="col-md-12">
    <div class="card ">
      <div class="card-header">
        <span class="card-header-edit-label"
          >{{ isNewModel ? 'Create' : 'Edit' }} Scoring System</span
        >
        {{ name.value }}
      </div>
      <div class="card-block">
        <div *ngIf="isLeagueScoring" class="league-scoring-wrapper">
          <div class="editpanel-column left-edit col-lg-7">
            <div class="panelrow col-md-12">
              <mat-form-field class="editor-mat-field">
                <input
                  required
                  matInput
                  [attr.disabled]="!isLeagueScoring"
                  formControlName="name"
                  placeholder="Name"
                />
                <mat-error *ngIf="name.hasError('required') && !name.pristine">
                  Name is required.
                </mat-error>
                <mat-error
                  *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)"
                >
                  Name already exists.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="panelrow col-md-12">
              <div class="inputheader">Payout Type:</div>
              <mat-radio-group formControlName="payout_type">
                <div class="panelrow col-md-6">
                  <mat-radio-button type="radio" [value]="0" f class="card-btn-radio"
                    >Actual Points</mat-radio-button
                  >
                </div>
                <div class="panelrow col-md-6">
                  <mat-radio-button type="radio" [value]="1" class="card-btn-radio"
                    >Percentage of Total Points</mat-radio-button
                  >
                </div>
              </mat-radio-group>
            </div>

            <div class="panelrow col-md-6">
              <mat-form-field class="editor-mat-field">
                <input
                  required
                  matInput
                  formControlName="total_points"
                  placeholder="Total Points"
                />
                <mat-error *ngIf="total_points.hasError('required') && !total_points.pristine">
                  Total Points is required.
                </mat-error>
                <mat-error *ngIf="total_points.hasError('numeric') && !total_points.pristine">
                  Total Points must be a valid number greater than 0.
                </mat-error>
              </mat-form-field>
            </div>

            <div class="panelrow col-md-3">
              <mat-form-field class="editor-mat-field">
                <input
                  required
                  matInput
                  formControlName="positions_paid"
                  placeholder="Positions Paid"
                  (change)="changePositionsPaid($event.target.value)"
                />
                <mat-error *ngIf="positions_paid.hasError('required') && !positions_paid.pristine">
                  Positions Paid is required.
                </mat-error>
                <mat-error *ngIf="positions_paid.hasError('numeric') && !positions_paid.pristine">
                  Valid integer between 1 and 1000.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="editpanel-scoring col-lg-5">
            <div class="card">
              <div class="card-header">Positions</div>
              <div class="card-block">
                <div class="gridview-logic" formArrayName="payouts">
                  <div class="tablerow-edit">
                    <div class="tablecolumn-place tablecolumn-header">Place</div>
                    <div class="tablecolumn-amount tablecolumn-header">Points</div>
                  </div>

                  <div
                    class="tablerow-edit"
                    [formGroupName]="i"
                    *ngFor="let payout of myForm.get('payouts')['controls']; let i = index"
                  >
                    <div class="tablecolumn-place">{{ i + 1 }}</div>
                    <div class="tablecolumn-amount">
                      <mat-form-field class="editor-mat-field">
                        <input type="hidden" formControlName="finish_position" [value]="i + 1" />
                        <input
                          required
                          placeholder="Enter points (or %)"
                          matInput
                          formControlName="points"
                          class="numericText scoring-amount"
                        />
                        <mat-error
                          *ngIf="
                            payout.get('points').hasError('numeric') &&
                            !payout.get('points').pristine
                          "
                        >
                          Points must be a valid number
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!isLeagueScoring" class="panelrow col-md-12">
          <div class="apc-league-scoring-logic">
            <div class="apc-league-scoring-h1">
              The APC Default Scoring System calculates points according to the following
              guidelines:
            </div>
            <div class="apc-league-scoring-h2">
              First, it calculates the number of places paid according to the following rules:
            </div>
            <div class="apc-league-scoring-content">
              <ol>
                <li>2nd chance: Winner-take-all format with 10 players.</li>
                <li>Heads up: 8 places paid.</li>
                <li>40+ players: 20 places paid.</li>
                <li>All others: Places paid = total number of players / 2 (rounded up).</li>
              </ol>
            </div>
            <div class="apc-league-scoring-h2">
              Next, it calculates the total number of points to award.
            </div>
            <div class="apc-league-scoring-content">
              <p>
                Standard tournaments, Venue Championships, Last Chance, Headsup, and Turbo
                tournaments all begin with a base points formula of: (numplayers^.5) * 200 Turbos
                are then multiplied by a factor of 70% of the base points. (that is, turbos receive
                30% less points).
              </p>
              <p>If it's a second chance tournament, then only 20 total points are available.</p>
              <div class="apc-league-scoring-h2">
                Finally, the payout percentages are awarded according to the following table
                (stopping at the max places paid for that particular tournament).
              </div>
              <p>
                If there are < 20 places paid, then the players are paid in a round-robin style
                manner, starting with first place through the the last-place finisher, then
                beginning with first place again at the next payout. For instance, with a tournament
                of 10 players, all ten players would first receive their points due according to
                their finish position.
              </p>
              <p>
                Next, first place would receive the next available payout, which is 11th place at
                1.4 additional %. 2nd place would then receive an additional 1.3% from 12th place,
                and so on, until the payout structure is exhausted.
              </p>
              <br />
              <div class="col-md-6">
                <div class="card ">
                  <div class="card-header">Default</div>
                  <div class="card-block">
                    <div class="col-md-12">
                      <ol>
                        <li>1 -> 30%</li>
                        <li>2 -> 20%</li>
                        <li>3 -> 10%</li>
                        <li>4 -> 8%</li>
                        <li>5 -> 7%</li>
                        <li>6 -> 5%</li>
                        <li>7 -> 4%</li>
                        <li>8 -> 3%</li>
                        <li>9 -> 2%</li>
                        <li>10 -> 1.5%</li>
                        <li>11 -> 1.4%</li>
                        <li>12 -> 1.3%</li>
                        <li>13 -> 1.2%</li>
                        <li>14 -> 1.1%</li>
                        <li>15 -> 1.0%</li>
                        <li>16 -> 0.9%</li>
                        <li>17 -> 0.8%</li>
                        <li>18 -> 0.7%</li>
                        <li>19 -> 0.6%</li>
                        <li>20 -> 0.5%</li>
                      </ol>
                    </div>
                  </div>
                  <div class="card-footer">Place % of total points</div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card ">
                  <div class="card-header">Headsup Tournaments</div>
                  <div class="card-block">
                    <div class="col-md-12">
                      <div class="apc-league-scoring-h4">
                        Note that a special case is made for headsup tournaments, according to the
                        following table:
                      </div>
                      <ol>
                        <li>1 -> 30%</li>
                        <li>2 -> 20%</li>
                        <li>3 -> 9%</li>
                        <li>4 -> 9%</li>
                        <li>5 -> 4.75%</li>
                        <li>6 -> 4.75%</li>
                        <li>7 -> 4.75%</li>
                        <li>8 -> 4.75%</li>
                      </ol>
                    </div>
                  </div>
                  <div class="card-footer">Place % of total points.</div>
                </div>
              </div>
              <p>
                Note that a special case is made for headsup tournaments, according to the following
                table: Place % of total points 1 30% 2 20% 3 & 4 9% 5, 6, 7, & 8 4.75%
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
