import { defaults } from 'lodash';

export interface PopupOptions {
  width?: number;
  height?: number;
  scrollbars?: boolean;
}

/**
 * Returns the number of seconds represented by the input string.
 *
 * Input is pretty forgiving:
 * 1d 30m 45s
 * 1 day, 2 hours
 * 3hours20minutes
 *
 * If the input can't be parsed at all, it will just return 0.
 *
 * @param input free-form input string of days/hours/minutes
 * @returns Number of seconds represented by the input string.
 */
export function genericPopup(href, options?: PopupOptions) {
  options = defaults(options, { width: 550, height: 800, scrollbars: true });

  const params =
    'width=' +
    options.width +
    ', height=' +
    options.height +
    ', scrollbars=' +
    (options.scrollbars ? 'yes' : 'no') +
    ', resizable, status';
  return window.open(href, '', params);
}

export function scrollComponentToTop(selector) {
  const contentContainer = document.querySelector(selector) || window;
  if (contentContainer['scroll']) {
    contentContainer.scroll(0, 0); // { top: 0 });
  } else if (contentContainer['scrollTo']) {
    contentContainer.scrollTo(0, 0);
  } else if (contentContainer['scrollTop']) {
    contentContainer['scrollTop'] = 0;
  } else {
    // do nothing
  }
}
