<!--

  public banner_text_color: string;
  public banner_background_color_inside: string;
  public banner_background_color_outside: string;

-->
<div class="col-12" [style.background-color]="leagueSettings?.background_color">
  <div class="card leaderboard-ad">
    <div class="card-block">
      <app-ad-spot [leagueId]="leagueId" location="leaderboard"> </app-ad-spot>
    </div>
  </div>
  <div class="card card-leaderboard-details">
    <div
      class="card-header leaderboard-card-head"
      [style.background]="
        'radial-gradient(ellipse at center, ' +
        leagueSettings?.banner_background_color_inside +
        ' 0, ' +
        leagueSettings?.banner_background_color_outside +
        ' 100%)'
      "
      [style.color]="leagueSettings?.banner_text_color"
    >
      <span class="leaderboard-card-leaguename">{{ league?.name }}</span>
      <div class="leaderboard-card-head-type">League Leaderboard</div>
      <div class="leaderboard-card-head-date" *ngIf="safeSelectedSeason != null">
        {{ safeSelectedSeason.start_date || '01-01-1970' | date: 'mediumDate' }} -
        {{ (safeSelectedSeason.end_date | date: 'mediumDate') || 'present' }}
      </div>
      <br />
      <div class="leaderboard-card-selection-logic">
        <div class="panelrow col-6">
          <mat-form-field class="editor-mat-field">
            <mat-select
              [(ngModel)]="selectedSeason"
              placeholder="Season"
              (selectionChange)="seasonChanged()"
              [compareWith]="compareModels"
            >
              <mat-option *ngFor="let season of seasons" [value]="season"
                >{{ season.name }}
              </mat-option>
              <mat-option value="">All Seasons</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="panelrow col-6">
          <mat-form-field class="editor-mat-field">
            <mat-select
              [(ngModel)]="selectedVenue"
              placeholder="Venue"
              (selectionChange)="venueChanged()"
              [compareWith]="compareModels"
            >
              <mat-option value="">All Venues</mat-option>
              <mat-option *ngFor="let venue of filteredVenues" [value]="venue">{{
                venue.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <button
            class="leaderboard-details-btn"
            *ngIf="safeSelectedVenue && safeSelectedSeason"
            (click)="venueDetailClicked()"
          >
            Details
          </button>
        </div>


        <div class="panelrow rowadjust col-8" *ngIf="regions.length > 0">
          <mat-form-field class="editor-mat-field">
            <mat-select
              [(ngModel)]="selectedRegion"
              placeholder="Region"
              (selectionChange)="regionChanged()"
              [compareWith]="compareModels"
              ><mat-option value="">All Regions</mat-option>
              <mat-option *ngFor="let region of filteredRegions" [value]="region"
                >{{ region.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="panelrow col-8">
          <mat-form-field class="editor-mat-field">
            <input
              matInput
              [(ngModel)]="searchPlayer"
              (change)="searchChanged()"
              placeholder="Search players"
            />
          </mat-form-field>
        </div>
        <div class="panelrow rowadjust col-4">
          <div class="leaderboard-user-actions-logic">
            <a
              class="leaderboard-profile-edit"
              target="_blank"
              href="/login"
              [style.background-color]="leagueSettings?.button_background_color"
              [style.color]="leagueSettings?.button_text_color"
            >
              <i class="fa fa-user"></i>Player Login</a
            >
            <a
              class="leaderboard-recent"
              (click)="openRecentWinners()"
              [style.background-color]="leagueSettings?.button_background_color"
              [style.color]="leagueSettings?.button_text_color"
              >Recent Winners</a
            >
          </div>
        </div>

      </div>
    </div>
    <div class="card-block">
      <div class="leaderboard-gridview-logic">
        <div class="leaderboard-loading" *ngIf="loading">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <div *ngIf="records.length == 0 && !loading" class="leaderboard-notice">
          No Points found for selected Season/Venue
        </div>
        <table class="leaderboardquartz-lbGridDefault" rules="all" border="1" *ngIf="!loading">
          <tbody>
            <tr
              [style.color]="leagueSettings?.header_text_color"
              [style.background-color]="leagueSettings?.header_background_color"
              [style.border-bottom-color]="leagueSettings?.border_color"
            >
              <th
                (click)="sortColumn('ranking')"
                class="leaderboard-header"
                scope="col"
                style="width:15%;"
              >
                POS
              </th>
              <th
                (click)="sortColumn('name')"
                class="leaderboard-header header-left"
                scope="col"
                style="width:55%;"
              >
                NAME
              </th>
              <th (click)="sortColumn('points_awarded')" class="leaderboard-header" scope="col">
                POINTS
              </th>
              <th
                (click)="sortColumn('wins')"
                class="leaderboard-header"
                valign="middle"
                scope="col"
              >
                WINS
              </th>
            </tr>
            <tr
              class="leaderboardquartz-lbMainRow"
              *ngFor="let record of records; let i = index"
              [style.background-color]="
                i % 2 == 0
                  ? leagueSettings?.row_background_color
                  : leagueSettings?.alt_row_background_color
              "
              [style.color]="
                i % 2 == 0 ? leagueSettings?.row_text_color : leagueSettings?.alt_row_text_color
              "
              [style.border-bottom-color]="leagueSettings?.border_color"
            >
              <td align="right" style="width:50px;">{{ record.ranking }}</td>
              <td>
                <app-player-profile-link
                  [player]="record"
                  [season_id]="record.season_id"
                ></app-player-profile-link>
              </td>
              <td align="right" valign="middle">{{ record.points_awarded | number: '1.2-2' }}</td>
              <td align="center" valign="middle">{{ record.wins }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      *ngIf="config.paging"
      class="card-footer page-footer"
      [style.background-color]="leagueSettings?.footer_background_color"
    >
      <div class="gridview-paginator-logic">
        <div class="mat-paginator-page-goto">
          <mat-form-field>
            <mat-select [ngModel]="pageIndex + 1" (ngModelChange)="manualPageChanged($event)">
              <mat-option [value]="1">Page</mat-option>
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{ mp }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator
          [length]="length"
          [pageSize]="itemsPerPage"
          [showFirstLastButtons]="true"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex"
          (page)="pageChanged($event)"
          (numPages)="numPages = $event"
          [style.color]="leagueSettings?.footer_text_color"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="leaderboard-brand-logic">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
