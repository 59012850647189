export class TournamentType {
  public code: string;
  public name: string;

  constructor(init?: Partial<TournamentType>) {
    Object.assign(this, init);
  }

  static fromCode(code: String): TournamentType {
    TournamentTypesList.forEach(type => {
      if (type.code === code) {
        return type;
      }
    });

    throw new Error('code "' + code + '" not found.');
  }
}

export const Standard = new TournamentType({
  code: 'ST',
  name: 'Standard'
});

export const HeadsUp = new TournamentType({
  code: 'HU',
  name: 'Heads Up'
});

export const PreGame = new TournamentType({
  code: 'TT',
  name: 'Pre Game'
});

export const SecondChance = new TournamentType({
  code: '2C',
  name: 'Post Game'
});

export const LastChance = new TournamentType({
  code: 'LC',
  name: 'Last Chance'
});

export const VenueChampionship = new TournamentType({
  code: 'VC',
  name: 'Venue Championship'
});

export const GrandChampionship = new TournamentType({
  code: 'GC',
  name: 'Grand Championship'
});

export const MonthlyChallenge = new TournamentType({
  code: 'M5',
  name: 'Monthly'
});

export const AllStar = new TournamentType({
  code: 'AS',
  name: 'All-Star'
});

export const MainEvent = new TournamentType({
  code: 'ME',
  name: 'Main Event'
});

export const TournamentTypesList: TournamentType[] = [
  Standard,
  HeadsUp,
  PreGame,
  SecondChance,
  LastChance,
  VenueChampionship,
  GrandChampionship,
  MonthlyChallenge,
  AllStar,
  MainEvent
];
