<div class="alerts-logic">
<div
  role="alert"
  *ngFor="let message of messages"
  class="alert alert-{{ message.severity }}"
  [ngClass]="{
    'alert-dismissable': message.is_dismissable
  }"
>
  <div [innerHTML]="message?.message | rawHtml"></div>
  <a *ngIf="message.is_dismissable" class="close" (click)="removeMessage(message)">&times;</a>
</div>
</div>
