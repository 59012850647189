<div class="col-md-12">
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">
          Review
        </div>
        <div class="card-block">
          <div class="paragraph">
            Review the information below and click the "Confirm Order" button to complete the transaction
            <span *ngIf="upgrading">and upgrade your account</span>
            .
          </div>
          <p class="paragraph">
            <span class="bold">Name:</span> {{league.name}}
          </p>
          <p class="paragraph">
            <span class="bold">Order:</span>
            {{itemDescription}}
          </p>
          <p class="paragraph">
            {{startDate | date}} - {{endDate | date}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card">
        <div class="card-header">
          Pay Now
        </div>
        <div class="card-block">
          <div class="panelrow">
            <span class="confirm-amount">{{cost | currency: 'USD':'symbol-narrow':'1.2-2'}}</span>
          </div>
          <div class="panelrow">
            <div class="row">
              <div class="col-sm-7">
                <button [disabled]="submitted" (click)="confirmOrder()" class="btn btn-success confirm-btn" id="btnSubmitOrder">Confirm Order</button>
              </div>
              <div class="col-sm-5">
                <button (click)="cancelOrder()" class="btn btn-warning-outline confirm-btn" id="btnCancelOrder">Cancel Order</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>