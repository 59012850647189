<div class="col-md-12 noselect">
  <div class="loginlogo">
    <img name="Logo" src="/assets/images/login/logo.png" width="100%" height="auto" alt="" />
  </div>
  <form id="loginForm" [formGroup]="myForm" (ngSubmit)="submit(myForm.value)">
    <div class="card text-center">
      <div id="member_login">
        <div class="card-block">
          <div class="panelrow col-md-12">
            <div class="caption" *ngIf="message">
              <span>{{message}}</span>
            </div>
            <div class="forgetpass">
              Enter your username and we'll email you a password reset link.
            </div>
            <mat-form-field class="editor-mat-field">
              <input required #username="ngForm" matInput [formControl]="myForm.controls['username']" placeholder="Username">
              <mat-hint>Usually your email address.</mat-hint>
              <mat-error *ngIf="username.hasError('required') && !username.pristine">
                Username is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <button mat-raised-button color="primary" class="loginsignup loginbtn gridview-editor-btn" [disabled]="!myForm.valid || submitted"
              type="submit">
              Send Reset
            </button>
          </div>
        </div>
        <div class="card-footer">
          <div class="login-footer">
            <a routerLink="/login" class="forget_pass forgetpass">Login</a> |
            <a class="create-account" routerLink="/signup">Create a league</a>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="loginprivacy">We value your
    <a style="cursor: pointer;" (click)="openPrivacy()">privacy</a>
  </div>
</div>
