<div class="col-12">
  <div class="card card-leaderboard-details">
    <div class="card-header">
      Membership
    </div>
    <div class="card-block card-admin">
      <div class="gridview-logic grid-membership">
        <div class="gridview-padding">
          <ng-table [config]="config" (tableChanged)="onChangeTable(config)" [rows]="rows" [columns]="columns">
          </ng-table>
        </div>
      </div>
    </div>
    <div *ngIf="config.paging" class="card-footer page-footer">
      <div class="gridview-paginator-logic">
        <div class="mat-paginator-page-goto">
          <mat-form-field>
            <mat-select [ngModel]="pageIndex+1" (ngModelChange)="manualPageChanged($event)">
              <mat-option [value]="1">Page</mat-option>
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{mp}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator [length]="length" [pageSize]="itemsPerPage" [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex" (page)="onChangeTable(config, $event)" (numPages)="numPages = $event">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="leaderboard-brand-logic">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
