import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { of, timer as observableTimer, Observable } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

@Injectable()
/**
 * This assumes it will be bound to a component (this.seasonValidator.create().bind(this))
 * This component MUST have a model property, and a service property
 */
export class UniqueNameValidator {
  constructor() {}
  public create(propertyName: string = 'name') {
    return function(control: FormControl): Observable<ValidationErrors> {
      return observableTimer(300).pipe(
        distinctUntilChanged(),
        switchMap(() => {
          const incomingValue = control.value || '';
          const existingValue = (this.model ? this.model[propertyName] : '') || '';
          if (incomingValue.trim().toLowerCase() !== existingValue.toLowerCase()) {
            return this.service
              .checkNameTaken(control.value.trim())
              .map(result => (result ? { nameTaken: true } : null));
          } else {
            return of(null);
          }
        })
      );
    };
  }
}
