<div class="col-lg-12">
  <div class="card terms-card">
    <div class="card-header">Executive Account Terms</div>
    <div class="card-block">
      <div class="col-md-12">
        <div class="executiveterms-logic">
          <h4 class="card-title leaderboard-card-title">Signup Date</h4>
          The date the Executive Membership order is processed (example: 01/01/2016).
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Anniversary Date</h4>
          365 days after the 'Order Date' (01/01/2017) OR 365 days after the previous ‘Anniversary Date’ (for leagues that have Renewed
          multiple times) (01/01/2018, etc.).
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">End Date</h4>
          364 days after the 'Order Date' (12/31/2016), the day before the ‘Grace Period’ starts if league hasn’t Renewed yet.
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Next End Date</h4>
          364 days after the ‘Anniversary Date’ (12/31/2016), the day before the ‘Grace Period’ starts if league hasn’t Renewed yet.
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Warning Period</h4>
          44 days, starting 30 days before 'Anniversary Date' through ‘Grace Period’ (12/1/2016 - 1/15/2017) if the league hasn’t Renewed
          yet.
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Grace Period</h4>
          14 days, starting at 'Anniversary Date' to day before 'Downgrade to Free Date' (01/01/2017 - 1/15/2017) the last days the
          Executive Account will work without renewal payment.
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Grace Period Message</h4>
          Your Poker Leaderboards Executive Account is now in the 14 day renewal grace period. Your account will be downgraded to a
          Free account once the grace period expires. Please pay your outstanding balance to keep your Executive Account
          features active.
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Downgrade To Free Date</h4>
          15 days after 'Anniversary Date' (1/16/2017) This immediately downgrades the league to the free account. To upgrade again
          the league pays the yearly fee.
          <br />
          <br />
        </div>
      </div>
    </div>

  </div>

</div>