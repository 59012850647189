import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ReportForm } from '../models/forms/report.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse } from './responses/generic.responses';
import {
  GetReportsResponse,
  GetReportResponse,
  RunAdsDetailReportResponse,
  RunDealerDetailReportResponse,
  RunPayrollDetailReportResponse,
  RunReportResponse
} from './responses/report.responses';

@Injectable()
export class ReportService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getReports(params).pipe(
      map(r => {
        return {
          data: r.reports,
          pagination: r.pagination
        };
      })
    );
  }

  public getReports(params: any): Observable<GetReportsResponse> {
    this.logger.debug('Called getReports');
    if (this.league == null) {
      this.logger.debug('returning default reports');
      return of(this.defaultResponse('reports'));
    }
    this.logger.debug('calling get reports service');

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetReportsResponse | any>(
        `${environment.REPORTS_API}/league/${this.league.id}/report`,
        options
      )
      .pipe(tap(response => this.logger.debug('getReports response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getReport(reportId: UUID): Observable<GetReportResponse> {
    this.logger.debug('Called getReport');
    if (this.league == null) {
      this.logger.debug('returning default report');
      return of({ report: null });
    }

    this.logger.debug('calling get report service');
    return this.http
      .get<GetReportResponse | any>(
        `${environment.REPORTS_API}/league/${this.league.id}/report/${reportId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getReport response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createReport(value: ReportForm): Observable<GetReportResponse | any> {
    this.logger.debug('Called createReport');
    return this.http
      .post<GetReportResponse>(
        `${environment.REPORTS_API}/league/${this.league.id}/report`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createReport response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteReport(reportId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteReport');
    return this.http
      .delete<any>(
        `${environment.REPORTS_API}/league/${this.league.id}/report/${reportId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deleteReport response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public runReport(reportId: UUID): Observable<RunReportResponse> {
    this.logger.debug('Called runReport');
    if (this.league == null) {
      this.logger.debug('returning default report');
      return of({ report: null, data: null });
    }

    this.logger.debug('calling get report service');
    return this.http
      .get<RunReportResponse | any>(
        `${environment.REPORTS_API}/league/${this.league.id}/report/${reportId}/run`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('runReport response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public runAdsDetailReport(reportId: UUID, params: any): Observable<RunAdsDetailReportResponse> {
    this.logger.debug('Called runAdsDetailReport');
    if (this.league == null) {
      this.logger.debug('returning default report');
      return of({ report: null, data: null });
    }

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    this.logger.debug('calling get report service with params', params);
    return this.http
      .get<RunAdsDetailReportResponse | any>(
        `${environment.REPORTS_API}/league/${this.league.id}/report/${reportId}/ads-details/run`,
        options
      )
      .pipe(tap(response => this.logger.debug('runAdsDetailReport response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public runDealerDetailReport(
    reportId: UUID,
    dealerId: UUID
  ): Observable<RunDealerDetailReportResponse> {
    this.logger.debug('Called runDealerDetailReport');
    if (this.league == null) {
      this.logger.debug('returning default report');
      return of({ report: null, data: null });
    }

    this.logger.debug('calling get report service');
    return this.http
      .get<RunDealerDetailReportResponse | any>(
        `${environment.REPORTS_API}/league/${
          this.league.id
        }/report/${reportId}/dealer-details/${dealerId}/run`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('runDealerDetailReport response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public runPayrollDetailReport(
    reportId: UUID,
    userId: UUID
  ): Observable<RunPayrollDetailReportResponse> {
    this.logger.debug('Called runPayrollDetailReport');
    if (this.league == null) {
      this.logger.debug('returning default report');
      return of({ report: null, data: null });
    }

    this.logger.debug('calling get report service');
    return this.http
      .get<RunPayrollDetailReportResponse | any>(
        `${environment.REPORTS_API}/league/${
          this.league.id
        }/report/${reportId}/payroll-details/${userId}/run`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('runPayrollDetailReport response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
