import { Message } from '../message';
import { UUID } from '../uuid';

export class MessageForm {
  public id: UUID;
  public title: string;
  public message: string;
  public severity: string;
  public is_dismissable: boolean;
  public is_active: boolean;
  public param_league_ids?: UUID[];
  public param_league_types?: string[];
  public param_role_ids?: UUID[];
  public expires_at?: Date;
  public last_email_sent_at?: Date;
  constructor(model: Message) {
    this.id = model.id;
    this.title = model.title;
    this.message = model.message;
    this.severity = model.severity;
    this.is_dismissable = model.is_dismissable;
    this.is_active = model.is_active;
    this.param_league_ids = model.param_league_ids;
    this.param_league_types = model.param_league_types;
    this.param_role_ids = model.param_role_ids;
    this.expires_at = model.expires_at == null ? null : new Date(model.expires_at.toString());
    this.last_email_sent_at =
      model.last_email_sent_at == null ? null : new Date(model.last_email_sent_at.toString());
  }
}
