<div class="col-md-12 noselect">
  <div class="loginlogo">
    <a routerLink="/">
      <img src="/assets/images/login/logo.png" width="100%" height="auto" alt="Poker Leaderboards" />
    </a>
  </div>
  <div class="card signupcard text-center">
    <div class="card-header">
      Create a League!
    </div>
    <div class="card-block">
      <div class="col-md-12">
        <div id="membership">
          <div class="signupinputlogic">
            <form ngNoForm action="http://www.aweber.com/scripts/addlead.pl" method="post">
              <input type="hidden" value="234597" name="meta_web_form_id" />
              <input type="hidden" value="" name="meta_split_id" />
              <input type="hidden" value="pokerleaguesoft" name="unit" />
              <input type="hidden" value="http://www.aweber.com/form/thankyou_vo.html" name="redirect" />
              <input type="hidden" value="" name="meta_redirect_onlist" />
              <input type="hidden" value="pls1" name="meta_adtracking" />
              <input type="hidden" value="1" name="meta_message" />
              <input type="hidden" value="from" name="meta_required" />
              <input type="hidden" value="0" name="meta_forward_vars" />
              <div class="logininputlogic">
                <div class="login-label">
                  <i class="fa fa-user"></i>Name:</div>
              </div>
              <input type="text" class="textbox" id="name" name="name" />
              <br />

              <div class="login-label">
                <i class="fa fa-envelope"></i>Email:</div>

              <input type="text" class="textbox" id="email" name="from" />
              <input type="submit" value="Sign Up" class="button signupbtn" name="submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
      <div class="plbfree">
        <a routerLink="/">Poker Leaderboards</a> is free to use.</div>
    </div>
  </div>
  <div class="loginprivacy">We value your
    <a style="cursor: pointer;" (click)="openPrivacy()">privacy</a>
  </div>
</div>
