import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../../_guards/auth.guard';
import { SystemAuthGuard } from '../../_guards/system.auth.guard';
import { AdminComponent } from '../../layouts/admin/admin.component';
import { DashboardComponent } from '../dashboard/dashboard.component';

import { SystemAdDetailComponent } from './ad-detail/system-ad-detail.component';
import { SystemAdsComponent } from './ads/system-ads.component';
import { LeaguesComponent } from './leagues/leagues.component';
import { LeagueDetailComponent } from './league-detail/league-detail.component';
import { SystemMessageDetailComponent } from './message-detail/message-detail.component';
import { SystemMessagesComponent } from './messages/messages.component';
import { SystemStatsComponent } from './stats/system-stats.component';

const routes: Routes = [
  {
    path: 'system',
    component: AdminComponent,
    canActivate: [AuthGuard, SystemAuthGuard],
    children: [
      { path: '', redirectTo: 'leagues', pathMatch: 'full' },
      {
        path: 'leagues',
        component: LeaguesComponent,
        data: { title: 'Leagues | Poker Leaderboards' }
      },
      {
        path: 'leagues/:id',
        component: LeagueDetailComponent,
        data: { title: 'Season Details | Poker Leaderboards' }
      },
      {
        path: 'ads',
        component: SystemAdsComponent,
        data: { title: 'System Ads | Poker Leaderboards' }
      },
      {
        path: 'ads/:id',
        component: SystemAdDetailComponent,
        data: { title: 'System Ad Details | Poker Leaderboards' }
      },
      {
        path: 'messages',
        component: SystemMessagesComponent,
        data: { title: 'System Messages | Poker Leaderboards' }
      },
      {
        path: 'messages/:id',
        component: SystemMessageDetailComponent,
        data: { title: 'System Message Details | Poker Leaderboards' }
      },
      {
        path: 'stats',
        component: SystemStatsComponent,
        data: { title: 'System Stats | Poker Leaderboards' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SystemRoutingModule {}
