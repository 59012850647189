import { Component, OnDestroy, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { YesNoPipe } from '../../../_pipes/yes-no.pipe';
import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { LeagueService } from '../../../services/league.service';
import { PlayerService } from '../../../services/player.service';
import { GetLeagueStatsResponse } from '../../../services/responses/league.responses';
import { GetPlayerStatsResponse } from '../../../services/responses/player.responses';

@Component({
  selector: 'app-system-stats',
  templateUrl: './system-stats.component.html'
})
export class SystemStatsComponent implements OnInit, OnDestroy {
  leagueStats: GetLeagueStatsResponse;
  playerStats: GetPlayerStatsResponse;

  constructor(
    protected leagueStorage: LeagueStorage,
    protected leagueService: LeagueService,
    private playerService: PlayerService
  ) {
    forkJoin([this.leagueService.getLeagueStats(), this.playerService.getPlayerStats()]).subscribe(
      ([leagueStats, playerStats]) => {
        this.leagueStats = leagueStats;
        this.playerStats = playerStats;
      }
    );
  }

  ngOnDestroy() {}

  ngOnInit() {}
}
