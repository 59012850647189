<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/system/leagues">
          <mat-icon>close</mat-icon> Close
        </button>
        <!-- <span class="menu-sep"></span>
        <button mat-menu-item id="btnDelete" type="submit">
          <mat-icon>delete</mat-icon> Remove League
        </button> -->
      </mat-menu>
      <ng-container *ngIf="model.account_type !== 'EXEMPT'">
        <ng-container
          *ngIf="model.account_type === 'SUSPENDED'; then unsuspend; else suspend"
        ></ng-container>
        <ng-template #suspend>
          <button
            mat-button
            class="action-saveclose-button"
            (click)="suspendLeague()"
            type="submit"
          >
            <mat-icon>close</mat-icon> Suspend League
          </button>
        </ng-template>
        <ng-template #unsuspend>
          <button mat-button class="action-save-button" (click)="unsuspendLeague()" type="submit">
            <mat-icon>cloud_done</mat-icon> Unsuspend League
          </button>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>

<div class="col-md-12">
  <form [formGroup]="myForm">
    <div class="card">
      <div class="card-header">
        <span class="card-header-edit-label">View League</span>
        {{ model.name }}
      </div>
      <div class="card-block">
        <div class="editpanel-column left-edit col-lg-8">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="name" placeholder="League name" />
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="account_type" placeholder="Account Type" />
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="number" placeholder="Number" />
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input matInput formControlName="num_members" placeholder="# of members" />
            </mat-form-field>
          </div>

          <div class="dashboard-leage-details-input-logo">
            <img src="{{ league_logo }}" class="league-logo-preview" *ngIf="league_logo" />
          </div>
          <div class="panelrow col-md-12 " *ngIf="isAdmin">
            <div class="loginas-title">
              System Admin
            </div>
            <button mat-button type="submit" class="loginas" (click)="loginAs()">
              <mat-icon>exit_to_app</mat-icon> Login as {{ model.User?.first_name }}
              {{ model.User?.last_name }}
            </button>
          </div>
        </div>
        <div class="editpanel-column col-lg-4">
          <div class="card" formGroupName="User">
            <div class="card-header"><div>League Owner</div></div>
            <div class="card-block">
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input matInput formControlName="first_name" placeholder="First name" />
                </mat-form-field>
              </div>
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input matInput formControlName="last_name" placeholder="Last name" />
                </mat-form-field>
              </div>
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input matInput formControlName="username" placeholder="Username" />
                </mat-form-field>
              </div>
              <div class="panelrow col-md-12">
                <mat-form-field class="editor-mat-field">
                  <input matInput formControlName="email" placeholder="Email" />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="editpanel-column col-lg-4">
          <div class="card">
            <div class="card-header"><div>Info</div></div>
            <div class="card-block">
              <div class="panelrow col-md-12"><h3>Finances</h3></div>
              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">
                  <input
                    matInput
                    formControlName="due_date"
                    [matDatepicker]="picker_due_date"
                    placeholder="Due Date"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker_due_date"></mat-datepicker-toggle>
                  <mat-datepicker #picker_due_date></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">
                  <input matInput formControlName="total_owed" placeholder="Total Owed" />
                </mat-form-field>
              </div>
            </div>
            <div class="card-block">
              <div class="panelrow col-md-12"><h3>Dates</h3></div>

              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">
                  <input
                    matInput
                    formControlName="created_at"
                    [matDatepicker]="picker_created_at"
                    placeholder="Created Date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker_created_at"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker_created_at></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">
                  <input
                    matInput
                    formControlName="updated_at"
                    [matDatepicker]="picker_updated_at"
                    placeholder="Updated Date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker_updated_at"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker_updated_at></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
