import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CapitalizePipe } from './_pipes/capitalize.pipe';
import { RawHtmlPipe } from './_pipes/raw-html.pipe';
import { YesNoPipe } from './_pipes/yes-no.pipe';

@NgModule({
  declarations: [CapitalizePipe, YesNoPipe, RawHtmlPipe],
  imports: [CommonModule],
  exports: [CapitalizePipe, YesNoPipe, RawHtmlPipe]
})
export class PipesModule {}
