import { Payout } from './payout';
import { UUID } from './uuid';
export class Scoring {
  public id: UUID;
  public league_id: UUID;
  public name: string;
  public total_points: number;
  public positions_paid: number;
  public is_active = true;
  public payouts: Payout[];
}
