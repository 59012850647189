import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { CompareValidator } from '../../_validators/compare.validator';
import { UniqueEmailValidator } from '../../_validators/unique-email.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { GenericPageComponent } from '../../generic-page.component';
import { AppStorage } from '../../models/app.storage';
import { SignupForm } from '../../models/forms/signup.form';
import { PermissionManager } from '../../models/permission.manager';
import { SignupService } from '../../services/signup.service';
import { LoaderService } from '../../services/spinner.service';
import { TokenService } from '../../services/token.service';

@Component({
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent extends GenericPageComponent implements OnInit {
  public myForm: FormGroup;

  public submitted = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: SignupService,
    private emailValidator: UniqueEmailValidator,
    private uniqueNameValidator: UniqueNameValidator,
    private compareValidator: CompareValidator,
    private storage: AppStorage,
    private permissionManager: PermissionManager,
    private tokenService: TokenService
  ) {
    super();
    this.myForm = fb.group(
      {
        name: [
          '',
          Validators.compose([Validators.required]),
          uniqueNameValidator.create().bind(this)
        ],
        first_name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email], emailValidator.create().bind(this)],
        password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
        password_verify: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
        last_name: ['', Validators.required]
      },
      {
        validator: compareValidator.create('password', 'password_verify', 'passwordMustMatch', {
          operator: 'eq'
        })
      }
    );
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
  }

  public signup(value: SignupForm): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      this.service
        .signup(value)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(
          data => {
            // Page redirect when getting response

            this.tokenService.handleAuthResponse(data);
            // this.storage.token = data.token;
            // this.storage.tokenExpiresAt = data.payload.exp;
            // this.storage.tokenPayload = data.payload;
            // this.storage.leagueIds = data.payload.leagues;
            // this.storage.refresh = data.refresh;

            this.permissionManager.maybeRedirect('/admin/dashboard', true);
          },
          error => {
            console.error('err', error);
          }
        );
    }
  }
}
