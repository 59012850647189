import { Component } from '@angular/core';

import { TableCellComponent } from './ng2-table/components/table/table-cell.component';

@Component({
  selector: 'app-edit-link-cell',
  template: `
    <a [routerLink]="url">
      <i class="table-edit-btn fas fa-edit"></i>
      <span [innerHTML]="renderValue"></span>
    </a>`
})
export class EditLinkTableCellComponent extends TableCellComponent {
  // this is needed because we try to only make one component factory per component type
  // after compilation we lose the class name.  This lets us uniquely identify
  // the type of class we are creating.
  static componentName = 'EditLinkTableCellComponent';
  navigateTo: string | Array<string> | Function;
  url: string | Array<string>;
  // if (
  //   data.column === 'first_name' &&
  //   (this.permissionManager.hasPermission('update staff') ||
  //     this.permissionManager.hasPermission('get staff'))
  // ) {
  //   this.router.navigate([data.row.id], { relativeTo: this.activatedRoute });
  // }
  constructor() {
    super();
  }

  onChanges() {
    if (!this.data.navigateTo) {
      // assume it's the id
      this.data.navigateTo = this.row && this.row.id;
    }

    if (typeof this.data.navigateTo === 'function') {
      this.url = this.data.navigateTo(this.row);
    } else {
      this.url = this.data.navigateTo;
    }
  }
}
