<div class="playerprofile-ad">
  <app-ad-spot [leagueId]="player?.league_id" location="player">
  </app-ad-spot>
</div>
<div class="player-profile-logic" *ngIf="player">
  <div class="player-profile-main">
    <div class="player-profile-areyou">
      Are you {{player.name}}?
    </div>
    <div class="verify-profile-link">
      <button routerLink="/player-signup/{{playerId}}" mat-button class="action-verify-button">
        Verify your player account</button>
    </div>
    <div class="verify-profile-footer">
      to see your
      <a class="statpreviewlink" (click)="openPreview()">detailed stats!</a>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header profile-avatar-card" [ngStyle]="{'background-image': 'url(' + avatar_url + ')'}">
              <span></span>
            </div>
            <div class="card-block">
              <div class="avatar-card-logic">
                <div class="profile-avatar-logic">
                  <img class="profile-avatar" src="{{avatar_url}}" title="{{player.name}}'s avatar" />
                </div>
                <div class="avatar-card-name">
                  {{player.name}}
                </div>
                <div class="profile-name-effect">
                  <span></span>{{player.name}}</div>
                <div class="profile-quickstats-logic">
                  <div class="profile-quickstats-column col-12">
                    <div class="quickstat-season-name quickstat quickstat-size-4">
                      <span class="quickstat-label">Season</span> {{season?.name || '--ALL SEASONS--'}}
                    </div>
                    <div class="quickstat-finish-average quickstat quickstat-size-4">
                      <span class="quickstat-label">Avg Finish Position</span> {{avgFinish | number: '1.2-2'}}
                    </div>
                    <div class="quickstat-tournament-count quickstat quickstat-size-4">
                      <span class="quickstat-label">Tournaments</span> {{numTournaments | number }}
                    </div>
                    <div class="quickstat-points-average quickstat quickstat-size-4">
                      <span class="quickstat-label">Avg Points</span> {{avgPoints | number: '1.2-2'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer text-muted">
              <a class="profile-viewleaderboard" [routerLink]="['/leaderboard', 'quartz', player.league_id]" [queryParams]="{ s: 'latest' }"
                target="_blank">View Leaderboard</a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              Tournaments
            </div>
            <div class="card-block playerprofile-card-block">
              <div class="gridview-logic grid-profile-tournament">
                <table class="profilegrid-tourstats">
                  <tbody>
                    <tr style="color:#fff;background-color:#606080;">
                      <th class="profilegrid-column-number" scope="col">#</th>
                      <th class="profilegrid-column-venue" scope="col">VENUE</th>
                      <th class="profilegrid-column-points" scope="col">POINTS</th>
                    </tr>
                    <tr class="gridRow" *ngFor="let record of tournaments; let i = index" [class.gridRowAlt]="i%2 == 1">
                      <td class="profilegrid-column-number">{{record.finish_position | number}}</td>
                      <td class="profilegrid-column-venue">
                        <a
                          [routerLink]="['/leaderboard', 'quartz', player.league_id]"
                          [queryParams]="{ v: record.Tournament.Venue.id, s: 'latest' }"
                          target="_blank"
                        >{{ record.Tournament.Venue.name }}</a>
                      </td>
                      <td class="profilegrid-column-points">{{record.points_awarded | number }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer profile-footer text-muted">
              <span class="small_text italic">
                <i class="fas fa-info-circle"></i> Only tournaments where points were scored are shown.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="playerprofile-footer">
  <div class="profileun-footer-link">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
