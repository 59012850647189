<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button id="btnReset" type="submit" class="action-reset-button" (click)="setDefaults()">
        <mat-icon>settings_backup_restore</mat-icon> Defaults
      </button>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSave" type="submit"
        class="action-save-button" (click)="submit(myForm.value)">
        <mat-icon>cloud_done</mat-icon> Save Profile
      </button>
    </div>
  </div>
</div>

<div *ngIf="!hasPlayer">
  You don't have a player in this league.
</div>

<form [formGroup]="myForm" *ngIf="player">
  <input type="hidden" formControlName="player_id" />

  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        Customize Profile
        <span class="profile-instructions-button" (click)="openCustomizeTips()">
          <i class="fas fa-info"></i> Customizing Tips</span>
      </div>
      <div class="card-block">
        <div *ngIf="message" class="panelrow col-md-12">
          {{ message }}
        </div>
        <div class="panelrow col-md-12">
          <div class="dashboard-panel-heading-sub">Player Name</div>
          <div class="dashboard-panel-heading">
            {{ player?.name }}
          </div>
          <div style="display: inline-block;">
            <app-player-profile-link [player]="player" linkText="View Player Profile"></app-player-profile-link>
          </div>
        </div>
        <div class="panelrow col-md-12">
          <div class="dashboard-panel-heading-sub">
            Profile Image
          </div>
          <img [src]="avatar_url" style="width:150px" />
        </div>
        <div *ngIf="fileMessage" class="alert alert-danger ">
          {{fileMessage}}
        </div>
        <div class="panelrow col-md-12 file-drop-logic">
          <file-drop customstyle="my-drop-zone" (onFileDrop)="dropped($event)">
            <span class="drop-zone-content">
              <div class="col-5 drop-zone-info" title="Drop new image file here to upload.">
                <mat-icon>arrow_drop_down_circle</mat-icon> Drop New Image
              </div>
              <div class="col-7">
                <label for="files" class="file-input-btn">Select a file</label>
                <input id="files" style="display: none" type="file" (change)="fileSelected($event.target.files)" />
              </div>
            </span>
          </file-drop>

          <div></div>
          <div *ngIf="file" class="pending-file-logic">
            <div class="pending-file-icon">
              <i class="far fa-check-circle"></i>
            </div>
            <div class="pending-file-content">
              <div class="profile-panel-heading-sub">
                Pending upload
              </div>
              {{ file.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <span class="small_text italic">Click 'Save Profile' to complete changes.</span>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card show-overflow profile-colors">
      <div class="card-header">
        Custom Profile Colors
      </div>
      <div class="card-block">
        <div class="panelrow col-md-12">
          <div id="sample_player_form" class="profile-sample-form-logic"
            [style.background-color]="background_color.value">
            <table class="custom-profile-grid leaderboardquartz-lbGridDefault" cellspacing="0" cellpadding="2"
              rules="all" border="1" id="grdLeaderBoardQuartz" style="font-size:12px;border-collapse:collapse;">
              <tbody>
                <tr [style.color]="header_text_color.value" [style.background-color]="header_background_color.value">
                  <th [style.border-color]="border_color.value" class="leaderboard-header" scope="col">
                    col1
                  </th>
                  <th [style.border-color]="border_color.value" class="leaderboard-header" scope="col">
                    col2
                  </th>
                  <th [style.border-color]="border_color.value" class="leaderboard-header" scope="col">
                    col3
                  </th>
                </tr>
                <tr class="leaderboardquartz-lbMainRow" [style.background-color]="
                    i % 2 == 0 ? row_background_color.value : alt_row_background_color.value
                  " [style.color]="i % 2 == 0 ? row_text_color.value : alt_row_text_color.value"
                  *ngFor="let record of [1, 2, 3]; let i = index" [class.lbGridDefaultAltRow]="i % 2 == 1">
                  <td [style.border-color]="border_color.value" align="right" style="width:50px;">
                    value 1 {{ record }}
                  </td>
                  <td [style.border-color]="border_color.value" align="right" style="width:50px;">
                    value 2 {{ record }}
                  </td>
                  <td [style.border-color]="border_color.value" align="right" style="width:50px;">
                    value 3 {{ record }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Grid lines:
            </div>

            <input [colorPicker]="border_color.value" [style.background]="border_color.value"
              (colorPickerChange)="setColor(border_color, $event)" readonly="readonly" class="color_picker" />
            <div *ngIf="border_color.hasError('required') && !border_color.pristine" class="alert alert-danger ">
              Grid line color is required
            </div>
          </div>

          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Header Text:
            </div>

            <input [colorPicker]="header_text_color.value" [style.background]="header_text_color.value"
              (colorPickerChange)="setColor(header_text_color, $event)" readonly="readonly" class="color_picker" />
            <div *ngIf="header_text_color.hasError('required') && !header_text_color.pristine"
              class="alert alert-danger ">
              Header text color is required
            </div>
          </div>

          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Header Background:
            </div>

            <input [colorPicker]="header_background_color.value" [style.background]="header_background_color.value"
              (colorPickerChange)="setColor(header_background_color, $event)" readonly="readonly"
              class="color_picker" />
            <div *ngIf="
                header_background_color.hasError('required') && !header_background_color.pristine
              " class="alert alert-danger ">
              Header background color is required
            </div>
          </div>

          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Row Text:
            </div>

            <input [colorPicker]="row_text_color.value" [style.background]="row_text_color.value"
              (colorPickerChange)="setColor(row_text_color, $event)" readonly="readonly" class="color_picker" />
            <div *ngIf="row_text_color.hasError('required') && !row_text_color.pristine" class="alert alert-danger ">
              Row text color is required
            </div>
          </div>

          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Alternating Row Text:
            </div>
            <input [colorPicker]="alt_row_text_color.value" [style.background]="alt_row_text_color.value"
              (colorPickerChange)="setColor(alt_row_text_color, $event)" readonly="readonly" class="color_picker" />
            <div *ngIf="alt_row_text_color.hasError('required') && !alt_row_text_color.pristine"
              class="alert alert-danger ">
              Alternating row text color is required
            </div>
          </div>

          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Row Background:
            </div>
            <input [colorPicker]="row_background_color.value" [style.background]="row_background_color.value"
              (colorPickerChange)="setColor(row_background_color, $event)" readonly="readonly" class="color_picker" />
            <div *ngIf="row_background_color.hasError('required') && !row_background_color.pristine"
              class="alert alert-danger ">
              Row background color is required
            </div>
          </div>
          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Alternating Row Background:
            </div>
            <input [colorPicker]="alt_row_background_color.value" [style.background]="alt_row_background_color.value"
              (colorPickerChange)="setColor(alt_row_background_color, $event)" readonly="readonly"
              class="color_picker" />
            <div *ngIf="
                alt_row_background_color.hasError('required') && !alt_row_background_color.pristine
              " class="alert alert-danger ">
              Alternating row background color is required
            </div>
          </div>
          <div class="col-6 profile-colorpicker-section">
            <div class="profile-panel-heading-sub">
              Background:
            </div>
            <input [colorPicker]="background_color.value" [style.background]="background_color.value"
              (colorPickerChange)="setColor(background_color, $event)" readonly="readonly" class="color_picker" />
            <div *ngIf="background_color.hasError('required') && !background_color.pristine"
              class="alert alert-danger ">
              Background color is required
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
