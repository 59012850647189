import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { GetLeagueBillingResponse } from './responses/league.responses';

@Injectable()
export class PaymentsService extends GenericService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  private returnUrl() {}

  private cancelUrl() {}

  public startTrial(params: any): Observable<any> {
    this.logger.debug('Called startTrial');
    if (this.league == null) {
      this.logger.debug('returning default startTrial response');
      return of(null);
    }

    return this.http
      .post<any>(
        `${environment.PAYMENTS_API}/league/${this.league.id}/membership/trial`,
        params,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('startTrial response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public startCheckout(params: any): Observable<any> {
    this.logger.debug('Called startCheckout');
    if (this.league == null) {
      this.logger.debug('returning default startCheckout response');
      return of(null);
    }

    // should return a 202 on success
    // we should look at the Location header to determine where to send the user.
    return this.http
      .post<any>(
        `${environment.PAYMENTS_API}/league/${this.league.id}/membership/start`,
        params,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('startCheckout response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public completeOrder(params: any): Observable<any> {
    this.logger.debug('Called completeOrder');
    if (this.league == null) {
      this.logger.debug('returning default completeOrder response');
      return of(null);
    }

    return this.http
      .post<any>(
        `${environment.PAYMENTS_API}/league/${this.league.id}/membership/complete`,
        params,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('completeOrder response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public downgrade(): Observable<any> {
    this.logger.debug('Called downgrade');
    if (this.league == null) {
      this.logger.debug('returning default downgrade response');
      return of(null);
    }

    return this.http
      .post<any>(
        `${environment.PAYMENTS_API}/league/${this.league.id}/downgrade`,
        {},
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('downgrade response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getLeagueBilling(leagueId: UUID): Observable<GetLeagueBillingResponse | any> {
    this.logger.debug('calling getLeagueBilling + ' + leagueId);
    return this.http
      .get<GetLeagueBillingResponse>(
        `${environment.PAYMENTS_API}/league/${leagueId}/billing`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getLeagueBilling response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
