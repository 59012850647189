import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { TournamentForm } from '../models/forms/tournament.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import {
  GetTournamentsResponse,
  GetTournamentResponse,
  GetTournamentSummaryResponse
} from './responses/tournament.responses';

@Injectable()
export class TournamentService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init) it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/tournament/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getTournaments(params).pipe(
      map(r => {
        return {
          data: r.tournaments,
          pagination: r.pagination
        };
      })
    );
  }

  public getTournamentSummary(): Observable<GetTournamentSummaryResponse> {
    this.logger.debug('Called getTournamentSummary');
    if (this.league == null) {
      this.logger.debug('returning default tournaments');
      return of({ summary: { last_month_tournaments: 0, current_month_tournaments: 0 } });
    }
    this.logger.debug('calling get tournaments service');

    return this.http
      .get<GetTournamentSummaryResponse | any>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/tournament/summary`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getTournamentSummary response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getTournaments(params: any): Observable<GetTournamentsResponse> {
    this.logger.debug('Called getTournaments');
    if (this.league == null) {
      this.logger.debug('returning default tournaments');
      return of(this.defaultResponse('tournaments'));
    }
    this.logger.debug('calling get tournaments service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetTournamentsResponse | any>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/tournament`,
        options
      )
      .pipe(tap(response => this.logger.debug('getTournaments response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getTournament(tournamentId: UUID): Observable<GetTournamentResponse> {
    this.logger.debug('Called getTournament');
    if (this.league == null) {
      this.logger.debug('returning default tournament');
      return of({ tournament: null });
    }

    this.logger.debug('calling get tournament service');
    return this.http
      .get<GetTournamentResponse | any>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/tournament/${tournamentId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getTournament response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createTournament(value: TournamentForm): Observable<GetTournamentResponse | any> {
    this.logger.debug('Called createTournament');
    return this.http
      .post<GetTournamentResponse>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/tournament`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createTournament response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateTournament(value: TournamentForm): Observable<GetTournamentResponse | any> {
    this.logger.debug('Called updateTournament');
    return this.http
      .patch<GetTournamentResponse>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/tournament/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateTournament response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteTournament(tournamentId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteTournament');
    return this.http
      .delete<any>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/tournament/${tournamentId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deleteTournament response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
