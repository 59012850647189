import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { YesNoPipe } from '../../_pipes/yes-no.pipe';
import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { TournamentService } from '../../services/tournament.service';

@Component({
  selector: 'app-tournament',
  templateUrl: './tournaments.component.html',
  styleUrls: ['./tournaments.component.css']
})
export class TournamentsComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(
    protected leagueStorage: LeagueStorage,
    protected tournamentService: TournamentService
  ) {
    super(tournamentService, leagueStorage);
    this.setColumns([
      {
        title: 'Venue',
        name: 'Venue.name',
        filtering: { filterString: '', placeholder: 'Filter by venue' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Date',
        name: 'start_date',
        sort: 'desc',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Name',
        name: 'Tournament.name',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Season',
        name: 'Season.name',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Excluded',
        name: 'Tournament.exclude_from_points',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      },
      {
        title: 'Verified',
        name: 'is_closed',
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
