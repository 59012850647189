import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { LeagueStorage } from '../models/league.storage';
import { PermissionManager } from '../models/permission.manager';
import { AuthService } from '../services/auth.service';

import { TableCellComponent } from './ng2-table/components/table/table-cell.component';

@Component({
  selector: 'app-edit-link-cell',
  template: `
    <a (click)="loginAs()">
      <i class="table-edit-btn fas fa-sign-in-alt" style="color:red;"></i>
      <span></span>
    </a>
  `
})
export class LoginAsTableCellComponent extends TableCellComponent {
  // this is needed because we try to only make one component factory per component type
  // after compilation we lose the class name.  This lets us uniquely identify
  // the type of class we are creating.
  static componentName = 'LoginAsTableCellComponent';
  // if (
  //   data.column === 'first_name' &&
  //   (this.permissionManager.hasPermission('update staff') ||
  //     this.permissionManager.hasPermission('get staff'))
  // ) {
  //   this.router.navigate([data.row.id], { relativeTo: this.activatedRoute });
  // }
  constructor(
    private authService: AuthService,
    private router: Router,
    private leagueStorage: LeagueStorage,
    private permissionManager: PermissionManager
  ) {
    super();
  }

  onChanges() {}

  loginAs() {
    let firstName;
    let lastName;
    let id;
    if (this.row.owner_user_id) {
      firstName = this.row.User.first_name;
      lastName = this.row.User.last_name;
      id = this.row.owner_user_id;
    } else {
      firstName = this.row.first_name;
      lastName = this.row.last_name;
      id = this.row.id;
    }
    if (confirm(`Are you sure you want to login as this user: ${firstName} ${lastName}?`)) {
      this.authService
        .loginAsUser(id, this.leagueStorage.getSelectedLeague().value.id.toString())
        .subscribe(data => {
          this.permissionManager.maybeRedirect('/admin/dashboard', true);
        });
    }
  }
}
