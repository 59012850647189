
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CompareValidator } from '../../_validators/compare.validator';
import { DateValidator } from '../../_validators/date.validator';
import { NumericValidator } from '../../_validators/numeric.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { Event } from '../../models/event';
import { EventForm } from '../../models/forms/event.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { UUID } from '../../models/uuid';
import { Venue } from '../../models/venue';
import { EventService } from '../../services/event.service';
import { LoaderService } from '../../services/spinner.service';
import { VenueService } from '../../services/venue.service';

function getCoverageType(c: FormControl) {
  const value: Array<string> = c.value;
  const hasOverall = !!value.find(x => x === 'o');
  const hasAny = !!value.find(x => x === 'v');
  const hasSpecific = !!value.find(x => x !== 'v' && x !== 'o');

  // if a specific venue was selected, we can't have either overall or has any
  if (hasSpecific && (hasOverall || hasAny)) {
    return null;
  }

  // only one of overall or hasAny
  if (hasOverall && hasAny) {
    return null;
  }

  // success.  now just return the type.
  if (hasSpecific) {
    return 'sv';
  } else if (hasAny) {
    return 'v';
  } else {
    return 'o';
  }
}

function validateCoverage(c: FormControl) {
  const type = getCoverageType(c);

  // if a specific venue was selected, we can't have either overall or has any
  return type
    ? null
    : {
        coverage: true
      };
}

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit, OnDestroy {
  isNewModel = true;
  _propertySubscription: Subscription;
  myForm: FormGroup;
  minDate = new Date('1900-01-01');
  maxDate = new Date('9999-12-31');
  dateFormat = '';
  submitted = false;
  model: Event;
  league: League;
  venues: Venue[];

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: EventService,
    private venueService: VenueService,
    private dateValidator: DateValidator,
    private compareValidator: CompareValidator,
    private uniqueNameValidator: UniqueNameValidator,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute,
    private numericValidator: NumericValidator
  ) {
    this.dateFormat = environment.DATEPICKER_FORMAT;
    this.myForm = fb.group(
      {
        id: '',
        name: ['', Validators.required, uniqueNameValidator.create().bind(this)],
        announce_date: [
          '',
          Validators.compose([Validators.required, dateValidator.create({ allowNulls: false })])
        ],
        event_date: ['', dateValidator.create({ allowNulls: true })],
        start_qualify: [
          '',
          Validators.compose([Validators.required, dateValidator.create({ allowNulls: false })])
        ],
        end_qualify: [
          '',
          Validators.compose([Validators.required, dateValidator.create({ allowNulls: false })])
        ],
        event_criteria: fb.array([])
      },
      {
        validator: compareValidator.create('start_qualify', 'end_qualify', 'startLTEEnd', {
          type: 'date',
          operator: 'lte',
          allowNulls: false
        })
      }
    );
  }

  // handy access to form fields
  get name(): any {
    return this.myForm.get('name');
  }
  get announce_date(): any {
    return this.myForm.get('announce_date');
  }
  get event_date(): any {
    return this.myForm.get('event_date');
  }
  get start_qualify(): any {
    return this.myForm.get('start_qualify');
  }
  get end_qualify(): any {
    return this.myForm.get('end_qualify');
  }
  get event_criteria(): FormArray {
    return this.myForm.get('event_criteria') as FormArray;
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getEvent();
      }
    });
  }

  getEvent(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getEvent(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = response.event; // only used in the Name check, to allow the original name to work.
        this.loadDependentServices();
      });
    } else {
      this.loadDependentServices();
    }
  }

  loadDependentServices() {
    this.venueService
      .getVenues({
        select: 'id,name',
        ...(this.model ? { event_id: this.model.id } : {}),
        'order_by[name]': 'asc',
        pageSize: 100000
      })
      .subscribe(venueResponse => {
        this.venues = venueResponse.venues;
        this.resetForm(this.model);
      });
  }

  addCriteria() {
    const control = this.event_criteria;
    const properties = {
      rank: control.length,
      type: ['', Validators.required],
      criteria: [
        '',
        Validators.compose([
          Validators.required,
          this.numericValidator.create({ min: 0, integerOnly: true })
        ])
      ],
      coverage_values: [[''], Validators.compose([Validators.required, validateCoverage])],
      coverage: '',
      specific_venue_ids: ''
    };

    const record = this.fb.group(properties);

    this.rerankEventCriteria();

    control.push(record);

    return record;
  }

  rerankEventCriteria() {
    let i = 0;
    this.event_criteria.controls.forEach(criteria => {
      criteria.patchValue({ rank: i });
      i++;
    });
  }

  removeCriteria(index: number) {
    this.event_criteria.removeAt(index);
    this.event_criteria.markAsDirty();
    this.rerankEventCriteria();
  }

  selectedCoverageChanged(criteria: FormGroup) {
    const coverage = criteria.get('coverage_values') as FormControl;
    if (!coverage.errors) {
      const type = getCoverageType(coverage);
      criteria.patchValue({
        coverage: type,
        specific_venue_ids: type === 'sv' ? coverage.value : null
      });
    }
  }

  public submitAndClose(value: EventForm) {
    this._submit(value, (model: Event) => {
      this.router.navigate(['/admin/events']);
    });
  }

  public submit(value: EventForm) {
    this._submit(value, (model: Event) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/events/' + model.id]);
    });
  }

  private resetForm(event: Event) {
    if (event) {
      this.clearFormArray(this.event_criteria);
      this.myForm.reset();

      const formValue = new EventForm(event);

      // reset staff expenses.
      formValue.event_criteria.forEach(criteria => {
        this.addCriteria();
      });
      this.myForm.setValue(new EventForm(event), { onlySelf: true });
    }
  }

  private clearFormArray(control: FormArray) {
    while (control.length !== 0) {
      control.removeAt(0);
    }
  }

  public _submit(value: EventForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createEvent(value)
        : this.service.updateEvent(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.event);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.name
        }? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteEvent(this.model.id).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/events']);
        });
    }
  }
}
