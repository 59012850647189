import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';

import { parseDuration } from '../util/parse.duration';
interface Options {
  allowNulls?: boolean;
}
@Injectable()
export class DurationValidator {
  constructor() {}
  public create(options?: Options): ValidatorFn {
    if (!options) {
      options = {};
    }

    if (options.allowNulls === undefined || options.allowNulls === null) {
      options.allowNulls = false;
    }

    return (c: FormControl): { [s: string]: boolean } => {
      const val = c.value;

      // allow nulls
      if (options.allowNulls && !val) {
        return null;
      }

      const value = parseDuration(val);

      if (value === 0) {
        return { duration: true };
      }

      return null;
    };
  }
}
