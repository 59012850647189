<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
    </div>
  </div>
</div>
<div class="col-12">
<div class="card report-details-card center">
    <div class="card-block">
      <div class="col-12">
        <div class="report_parameters">
          <span class="report-header-title">{{ data?.dealer.name }}</span>
        </div>
        <div class="report_header_main">
          <h4>
            <span>Dealer Report</span>
          </h4>
          <div class="report_range">
            <span>{{ model?.start_date | date }} to {{ model?.end_date | date }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
    Dealer Details
    </div>
    <div class="card-block breakdown-card">
      <div class="col-12">
        <table>
          <thead>
            <tr class="report_header_row report_rows">
              <td style="text-align: left; width: 15%;" scope="col">Date</td>
              <td style="text-align: left; width: 70%;" scope="col">Venue</td>
              <td style="text-align: left; width: 15%;" scope="col">Hours</td>
            </tr>
            </thead>
            <tbody>
            <tr class="report_item_row report_rows" *ngFor="let row of data?.tournaments">
              <td style="text-align: left;">{{ row.start_date | date}}</td>
              <td style="text-align: left;">{{ row.venue }}</td>
              <td style="text-align: left;">{{ row.time | number:'1.2-2'}}</td>
            </tr>
          </tbody>
        </table>
    </div>
  </div>
  <div class="card-footer">
    Total Hours: {{ data?.total_time | number:'1.2-2' }}
  </div>
  </div>
</div>
