import { Moment } from 'moment';

import { DealerTimecard } from './dealer.timecard';
import { TournamentExpense } from './tournament.expense';
import { TournamentPlayer } from './tournament.player';
import { UUID } from './uuid';
import { Venue } from './venue';
export class Tournament {
  public id: UUID;
  public name: string;
  public start_date: Moment;
  public Venue?: Venue;
  public venue_id: UUID;
  public season_id: UUID;
  public scoring_id: UUID;
  public tournament_type: string;
  public num_tables: number;
  public bonus_chips_1: number;
  public bonus_chips_2: number;
  public payment_type: string;
  public payment_method: string;
  public venue_cost: number | any;
  public reason: string;
  public notes: string;
  public check_number: string;
  public is_paperwork_signed: boolean;
  public is_paperwork_received: boolean;
  public is_collected: boolean;
  public is_payment_deposited: boolean;
  public is_closed: boolean;
  public num_entries: number;
  public exclude_from_points: boolean;
  public player_notes: string;
  public TournamentExpenses: TournamentExpense[];
  public staff_expenses?: {
    user_id: string;
    role_id: string;
    User?: { id: string; name: string };
    abbreviation?: string;
    payout_percent: any;
    payout_amount: any;
  }[];
  public item_expenses?: {
    label: string;
    payout_percent: any;
    payout_amount: any;
  }[];
  public TournamentPlayers: TournamentPlayer[];
  public DealerTimecards: DealerTimecard[];
}
