import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
interface Options {
  allowNulls?: boolean;
}
@Injectable()
export class DateValidator {
  constructor() {}
  public create(options?: Options): ValidatorFn {
    if (!options) {
      options = {};
    }
    if (options.allowNulls === undefined || options.allowNulls === null) {
      options.allowNulls = false;
    }

    return (c: FormControl): { [s: string]: boolean } => {
      if (options.allowNulls && !c.value) {
        return null;
      }

      const normalizedValue = moment.isMoment(c.value) ? c.value : moment(c.value);

      return normalizedValue.isValid() ? null : { date: true };
    };
  }
}
