import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppStorage } from '../../models/app.storage';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.css']
})
export class AdminMenuComponent implements OnInit, OnDestroy {
  @Input() sidenav: any;
  league: League;
  _propertySubscription: Subscription;
  canSeeDashboard = false;
  canSeeLeaderboards = false;
  canSeeTournaments = false;
  canSeePlayers = false;
  canSeeDealers = false;
  canSeeStaff = false;
  canSeeVenues = false;
  canSeeSeasons = false;
  canSeeScoring = false;
  canSeeEvents = false;
  canSeeAds = false;
  canSeeReports = false;
  canSeeRegions = false;
  constructor(
    private permissionManager: PermissionManager,
    private leagueStorage: LeagueStorage,
    private appStorage: AppStorage,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;

      this.canSeeDashboard = this.permissionManager.hasPermission('get dashboard');
      this.canSeeLeaderboards = this.permissionManager.hasPermission('get leaderboards');
      this.canSeeTournaments = this.permissionManager.hasPermissionOrOwnPermission(
        'get all tournaments'
      );
      this.canSeePlayers = this.permissionManager.hasPermission('get all players');
      this.canSeeDealers = this.permissionManager.hasPermission('get all dealers');
      this.canSeeStaff = this.permissionManager.hasPermission('get all staff');
      this.canSeeVenues = this.permissionManager.hasPermission('get all venues');
      this.canSeeSeasons = this.permissionManager.hasPermission('get all seasons');
      this.canSeeScoring = this.permissionManager.hasPermission('get all scorings');
      this.canSeeEvents = this.permissionManager.hasPermission('get all events');
      this.canSeeAds =
        this.permissionManager.hasPermission('get all ads') && this.permissionManager.isExecutive();
      this.canSeeReports =
        this.permissionManager.hasPermission('get all reports') &&
        this.permissionManager.isExecutive();
      this.canSeeRegions =
        this.permissionManager.hasPermission('get all regions') &&
        this.permissionManager.isExecutive();
      // check if I should redirect now.
    });
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }
}
