import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer
} from '@angular/core';
import { defaults } from 'lodash';

// import {setProperty} from 'angular2/ts/src/core/forms/directives/shared';
function setProperty(
  renderer: Renderer,
  elementRef: ElementRef,
  propName: string,
  propValue: any
): void {
  renderer.setElementProperty(elementRef, propName, propValue);
}

@Directive({ selector: '[ngTableFiltering]' })
export class NgTableFilteringDirective implements OnChanges {
  @Input() public ngTableFiltering: any = {};

  @Output() public tableChanged: EventEmitter<any> = new EventEmitter();

  @Input()
  public get config(): any {
    return this.ngTableFiltering;
  }

  public set config(value: any) {
    this.ngTableFiltering = value;
  }

  private element: ElementRef;
  private renderer: Renderer;

  @HostListener('input', ['$event.target'])
  public onChangeFilter(event: any): void {
    if (this.ngTableFiltering.boolean) {
      if (this.ngTableFiltering.isIndeterminate) {
        this.ngTableFiltering.isIndeterminate = false;
        this.ngTableFiltering.isChecked = true;
        this.ngTableFiltering.filterString = 'true';
      } else if (this.ngTableFiltering.isChecked) {
        this.ngTableFiltering.isIndeterminate = false;
        this.ngTableFiltering.isChecked = false;
        this.ngTableFiltering.filterString = 'false';
      } else {
        this.ngTableFiltering.isIndeterminate = true;
        this.ngTableFiltering.filterString = '';
      }
    } else {
      this.ngTableFiltering.filterString = event.value;
    }
    // notify we made changes
    this.cdRef.detectChanges();
    this.tableChanged.emit({ filtering: this.ngTableFiltering });
  }

  ngOnChanges(changes: any) {
    if (changes.ngTableFiltering.firstChange) {
      this.ngTableFiltering = defaults(this.ngTableFiltering, {
        isIndeterminate: true,
        isChecked: false,
        filterString: ''
      });
      if (this.ngTableFiltering.boolean) {
        switch (this.ngTableFiltering.filterString) {
          case 'true':
            this.ngTableFiltering.isIndeterminate = false;
            this.ngTableFiltering.isChecked = true;
            break;
          case 'false':
            // note: there is a bug here, if you actually want "false"
            // to be a default, then it will apply the default
            // but the visual cue will be indeterminate (no check),
            // rather than a negative (line) check.
            // needs more debugging.  Don't know why it isn't taking effect
            this.ngTableFiltering.isIndeterminate = false;
            this.ngTableFiltering.isChecked = false;
            break;
        }
      }
      // notify we made changes
      this.cdRef.detectChanges();
    }
  }

  public constructor(element: ElementRef, renderer: Renderer, private cdRef: ChangeDetectorRef) {
    this.element = element;
    this.renderer = renderer;
    // Set default value for filter
    setProperty(this.renderer, this.element, 'value', this.ngTableFiltering.filterString);
  }
}
