
    <div class="copy-to-clipboard">
      <input
        selectOnClick
        readonly
        style="width: 100%;"
        ngxClipboard
        [cbContent]="iframe"
        [value]="iframe"
        (cbOnSuccess)="onCopy()"
      />
      <div *ngIf="copied" class="copied-message">Copied!</div>
    </div>
  