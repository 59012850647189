<div *ngIf="player">
  <div class="playerprofile-ad">
    <app-ad-spot [leagueId]="player?.league_id" location="player 1"> </app-ad-spot>
  </div>
  <div
    class="player-profile-logic"
    [style.background-color]="player?.PlayerSetting.background_color"
  >
    <div class="player-profile-main">
      <div class="customize-profile-link">
        <button [routerLink]="['/admin', 'profile']" mat-button class="action-verify-button">
          Customize Profile
        </button>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div
                class="card-header profile-avatar-card"
                [ngStyle]="{ 'background-image': 'url(' + avatar_url + ')' }"
              >
                <span></span>
              </div>
              <div class="card-block">
                <div class="avatar-card-logic">
                  <div class="profile-avatar-logic">
                    <img
                      class="profile-avatar"
                      src="{{ avatar_url }}"
                      title="{{ player.name }}'s avatar"
                    />
                  </div>
                  <div class="avatar-card-name">
                    {{ player.name }}
                  </div>
                  <div class="profile-name-effect"><span></span>{{ player.name }}</div>
                  <div *ngIf="league" class="avatar-league-name">
                    {{ league.name }}
                  </div>
                  <div class="avatar-card-leagueseason">
                    <div class="col-12">
                      <div
                        class="avatar-card-leaguelogo"
                        [ngStyle]="{ 'background-image': 'url(' + league_logo + ')' }"
                        *ngIf="league_logo"
                      ></div>
                      <div class="avatar-card-select">
                        <mat-form-field class="editor-mat-field">
                          <mat-select
                            [(ngModel)]="selectedSeason"
                            placeholder="Season"
                            (selectionChange)="seasonChanged()"
                            [compareWith]="compareModels"
                          >
                            <mat-option *ngFor="let season of seasons" [value]="season"
                              >{{ season.name }}
                            </mat-option>
                          </mat-select>
                          <mat-hint>Select Season</mat-hint>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="avatar-card-quickstats">
                    <div class="leaderboard-loading" *ngIf="loading">
                      <i class="fas fa-spinner fa-pulse"></i>
                    </div>
                    <div class="profile-quickstats-logic" *ngIf="!loading">
                      <div class="col-7">
                        <div *ngIf="seasonStats" class="profile-quickstats-column">
                          <div class="quickstat-season-name quickstat quickstat-size-1">
                            <span class="quickstat-label">Leaderboard Rank</span>
                            {{ seasonStats.ranking | number }}
                          </div>
                          <div class="quickstat-tournament-count quickstat quickstat-size-2">
                            <span class="quickstat-label">Wins / Tournaments</span>
                            {{ seasonStats.wins | number }} /
                            {{ seasonStats.num_tournaments | number }}
                          </div>
                          <div class="quickstat-tournament-count quickstat quickstat-size-3">
                            <span class="quickstat-label">Total Points</span>
                            {{ seasonStats.points_awarded | number: '1.2-2' }}
                          </div>
                        </div>
                      </div>
                      <div class="col-5">
                        <div *ngIf="seasonStats" class="profile-quickstats-column">
                          <div class="quickstat-tournament-count quickstat right quickstat-size-3">
                            <span class="quickstat-label">Avg Points</span>
                            {{ seasonStats.avg_points | number: '1.2-2' }}
                          </div>
                          <div class="quickstat-finish-avg right quickstat quickstat-size-3">
                            <span class="quickstat-label">Avg Finish</span>
                            {{ seasonStats.avg_finish | number: '1.2-2' }}
                          </div>
                        </div>

                        <div class="quickstat-finish-avg right quickstat">
                          <div class="profile-sharing-logic">
                            <div class="profile-sharing-title">Share!</div>
                            <div class="profile-sharing-block">
                              <div class="profile-sharing-icon">
                                <a
                                  href="https://twitter.com/intent/tweet?text=My%20Poker%20Stats&url={{
                                    currentUrl
                                  }}&hashtags=pokerleaderboard"
                                >
                                  <i class="fab fa-twitter-square"></i>
                                </a>
                              </div>
                            </div>
                            <div class="profile-sharing-block">
                              <div class="profile-sharing-icon">
                                <a
                                  href="https://www.facebook.com/sharer/sharer.php?u={{
                                    currentUrl
                                  }}"
                                >
                                  <i class="fab fa-facebook-square"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer text-muted">
                <span *ngIf="seasonStats" class="small_text italic">
                  <i class="fas fa-info-circle"></i> Stats generated:
                  {{ seasonStats.generated_at | date: 'medium' }}
                </span>
                <a
                  class="profile-viewleaderboard"
                  [routerLink]="['/leaderboard', 'quartz', player.league_id]"
                  [queryParams]="{ s: selectedSeason?.id }"
                  target="_blank"
                  >View Leaderboard</a
                >
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                Venue Stats
              </div>
              <div class="card-block playerprofile-card-block">
                <div class="gridview-logic grid-profile-tournament">
                  <div class="leaderboard-loading" *ngIf="loading">
                    <i class="fas fa-spinner fa-pulse"></i>
                  </div>
                  <div *ngIf="!loading">
                    <div
                      class="leaderboard-notice"
                      *ngIf="!venueTournaments || venueTournaments.length === 0"
                    >
                      No venues found for the selected season.
                    </div>
                  </div>

                  <div *ngIf="venueTournaments && venueTournaments.length > 0 && !loading">
                    <table
                      class="custom-profile-grid profilegrid-venuestats"
                      cellspacing="0"
                      cellpadding="2"
                      rules="all"
                      border="1"
                      id="grdVenueStats"
                      [style.border-color]="player?.PlayerSetting.border_color"
                      style="font-size:14px"
                    >
                      <tbody>
                        <tr
                          [style.border-color]="player?.PlayerSetting.border_color"
                          [style.background-color]="player?.PlayerSetting.header_background_color"
                        >
                          <th
                            class="profilegrid-column-number"
                            scope="col"
                            [style.border-color]="player?.PlayerSetting.border_color"
                          >
                            <a href="#" [style.color]="player?.PlayerSetting.header_text_color"
                              >#</a
                            >
                          </th>
                          <th
                            class="profilegrid-column-venue"
                            scope="col"
                            [style.border-color]="player?.PlayerSetting.border_color"
                          >
                            <a href="#" [style.color]="player?.PlayerSetting.header_text_color"
                              >VENUE</a
                            >
                          </th>
                          <th
                            class="profilegrid-column-points"
                            scope="col"
                            [style.border-color]="player?.PlayerSetting.border_color"
                          >
                            <a href="#" [style.color]="player?.PlayerSetting.header_text_color"
                              >POINTS</a
                            >
                          </th>
                        </tr>
                        <tr
                          *ngFor="let venueRecord of venueTournaments; let i = index"
                          class="gridRow"
                          [class.gridRowAlt]="i % 2 == 1"
                          [style.background-color]="
                            i % 2 == 0
                              ? player?.PlayerSetting.row_background_color
                              : player?.PlayerSetting.alt_row_background_color
                          "
                          [style.color]="
                            i % 2 == 0
                              ? player?.PlayerSetting.row_text_color
                              : player?.PlayerSetting.alt_row_text_color
                          "
                        >
                          <td
                            class="profilegrid-column-number"
                            [style.border-color]="player?.PlayerSetting.border_color"
                            align="center"
                            style="width:10%;"
                          >
                            {{ venueRecord.ranking | number }}
                          </td>
                          <td
                            class="profilegrid-column-venue"
                            [style.border-color]="player?.PlayerSetting.border_color"
                            align="left"
                          >
                            <a
                              [routerLink]="['/leaderboard', 'quartz', player.league_id]"
                              [queryParams]="{ s: selectedSeason?.id, v: venueRecord.venue_id }"
                              target="_blank"
                              >{{ venueRecord.venue_name }}</a
                            >
                          </td>
                          <td
                            class="profilegrid-column-points"
                            [style.border-color]="player?.PlayerSetting.border_color"
                            align="center"
                            style="width:10%;"
                          >
                            {{ venueRecord.points_awarded | number: '1.2-2' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer profile-footer text-muted"></div>
            </div>
            <div class="card">
              <div class="card-header">
                Tournament Stats
              </div>
              <div class="card-block playerprofile-card-block">
                <div class="gridview-logic grid-profile-tournament">
                  <div class="leaderboard-loading" *ngIf="loading">
                    <i class="fas fa-spinner fa-pulse"></i>
                  </div>
                  <div *ngIf="!loading">
                    <div
                      class="leaderboard-notice"
                      *ngIf="!tournaments || tournaments.length === 0"
                    >
                      No tournaments found for the selected season.
                    </div>
                  </div>
                  <div *ngIf="tournaments && tournaments.length > 0 && !loading">
                    <table
                      class="custom-profile-grid profilegrid-tourstats"
                      [style.border-color]="player?.PlayerSetting.border_color"
                      style="font-size:14px;border-collapse:collapse;"
                    >
                      <tbody>
                        <tr
                          [style.border-color]="player?.PlayerSetting.border_color"
                          [style.background-color]="player?.PlayerSetting.header_background_color"
                        >
                          <th
                            class="profilegrid-column-number"
                            scope="col"
                            [style.border-color]="player?.PlayerSetting.border_color"
                          >
                            <a href="#" [style.color]="player?.PlayerSetting.header_text_color"
                              >#</a
                            >
                          </th>
                          <th
                            class="profilegrid-column-venue"
                            scope="col"
                            [style.border-color]="player?.PlayerSetting.border_color"
                          >
                            <a href="#" [style.color]="player?.PlayerSetting.header_text_color"
                              >VENUE</a
                            >
                          </th>
                          <th
                            class="profilegrid-column-points"
                            scope="col"
                            [style.border-color]="player?.PlayerSetting.border_color"
                          >
                            <a href="#" [style.color]="player?.PlayerSetting.header_text_color"
                              >POINTS</a
                            >
                          </th>
                          <th
                            class="profilegrid-column-date"
                            scope="col"
                            [style.border-color]="player?.PlayerSetting.border_color"
                          >
                            <a href="#" [style.color]="player?.PlayerSetting.header_text_color"
                              >DATE</a
                            >
                          </th>
                        </tr>
                        <tr
                          *ngFor="let record of tournaments; let i = index"
                          class="gridRow"
                          [class.gridRowAlt]="i % 2 == 1"
                          [style.background-color]="
                            i % 2 == 0
                              ? player?.PlayerSetting.row_background_color
                              : player?.PlayerSetting.alt_row_background_color
                          "
                          [style.color]="
                            i % 2 == 0
                              ? player?.PlayerSetting.row_text_color
                              : player?.PlayerSetting.alt_row_text_color
                          "
                        >
                          <td
                            class="profilegrid-column-number"
                            [style.border-color]="player?.PlayerSetting.border_color"
                            align="center"
                          >
                            {{ record.finish_position | number }}
                          </td>
                          <td
                            class="profilegrid-column-venue"
                            [style.border-color]="player?.PlayerSetting.border_color"
                            align="left"
                          >
                            <a
                              [routerLink]="['/leaderboard', 'quartz', player.league_id]"
                              [queryParams]="{ s: selectedSeason?.id, v: record.Tournament.Venue.id }"
                              target="_blank"
                            >{{ record.Tournament.Venue.name }}</a
                          >
                          </td>
                          <td
                            class="profilegrid-column-points"
                            [style.border-color]="player?.PlayerSetting.border_color"
                            align="center"
                          >
                            {{ record.points_awarded | number: '1.2-2' }}
                          </td>
                          <td
                            class="profilegrid-column-date"
                            [style.border-color]="player?.PlayerSetting.border_color"
                            align="center"
                          >
                            {{ record.Tournament.start_date | date }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer profile-footer text-muted">
                <span class="small_text italic">
                  <i class="fas fa-info-circle"></i> Only tournaments where points were scored are
                  shown.</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <app-ad-spot [leagueId]="player?.league_id" location="player 3"> </app-ad-spot>

            <app-ad-spot [leagueId]="player?.league_id" location="player 4"> </app-ad-spot>
            <div class="card">
              <div class="card-header">
                Lifetime Stats
              </div>
              <div class="card-block">
                <div class="profile-row">
                  <div class="quickstats col-sm-6">
                    <ul>
                      <li>
                        <span class="quickstats_label">Leaderboard Rank:</span>
                        {{ lifetimeStats?.ranking | number }}
                      </li>
                      <li>
                        <span class="quickstats_label">Total Tournaments:</span>
                        {{ lifetimeStats?.num_tournaments | number }}
                      </li>
                      <li>
                        <span class="quickstats_label">Total Points:</span>
                        {{ lifetimeStats?.points_awarded | number: '1.2-2' }}
                      </li>
                      <li>
                        <span class="quickstats_label">Total Wins:</span>
                        {{ lifetimeStats?.wins | number }}
                      </li>
                      <li>
                        <span class="quickstats_label">Avg Finish Position:</span>
                        {{ lifetimeStats?.avg_finish | number: '1.2-2' }}
                      </li>
                      <li>
                        <span class="quickstats_label">Avg Points:</span>
                        {{ lifetimeStats?.avg_points | number: '1.2-2' }}
                      </li>
                      <li>
                        <a
                          class="profile-viewleaderboard"
                          style="float:left; margin-top:10px;"
                          [routerLink]="['/leaderboard', 'quartz', player.league_id]"
                          [queryParams]="{ s: 'all' }"
                          target="_blank"
                          >Lifetime Leaderboard</a
                        >
                      </li>
                    </ul>
                  </div>
                  <div id="profile_sponsors_main" class="col-sm-6">
                    <span class="italic">Sponsors</span>
                    <div class="s_cont">
                      <app-ad-spot [leagueId]="player?.league_id" location="player 5">
                      </app-ad-spot>
                    </div>
                    <div class="s_cont">
                      <app-ad-spot [leagueId]="player?.league_id" location="player 6">
                      </app-ad-spot>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer text-muted">
                <span class="small_text italic">
                  <i class="fas fa-info-circle"></i> Lifetime stats last generated:
                  {{ lifetimeStats?.generated_at | date: 'medium' }}.
                </span>
              </div>
            </div>
            <app-ad-spot [leagueId]="player?.league_id" location="player 7"> </app-ad-spot>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="playerprofile-footer">
  <div class="profile-footer-link">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
  <app-ad-spot [leagueId]="player?.league_id" location="player 8"> </app-ad-spot>
</div>
