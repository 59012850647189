
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { Report } from '../../../models/report';
import { UUID } from '../../../models/uuid';
import { ReportService } from '../../../services/report.service';
import { PayrollDetailReport } from '../../../services/responses/report.responses';
import { LoaderService } from '../../../services/spinner.service';

@Component({
  selector: 'app-payroll-detail-report',
  templateUrl: './payroll-detail-report.component.html'
})
export class PayrollDetailReportComponent implements OnInit, OnDestroy {
  isNewModel = true;
  _propertySubscription: Subscription;
  submitted = false;
  model: Report;
  league: League;
  data: PayrollDetailReport;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private service: ReportService,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getReportDetails();
      }
    });
  }

  getReportDetails(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    const userId: any = this.route.snapshot.paramMap.get('user_id');
    if (id !== 'new') {
      this.loaderService.displayLoader(true);
      this.service
        .runPayrollDetailReport(id, userId).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.isNewModel = false;
          this.model = response.report;
          this.data = response.data;
          if (this.data) {
            this.data.total_pay = 0;
            this.data.tournaments.forEach(row => {
              this.data.total_pay += parseFloat(row.amount_owed);
            });
          }
        });
    }
  }
}
