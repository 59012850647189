<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/regions">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>

        <button
          mat-menu-item
          [disabled]="isNewModel"
          id="btnDelete"
          type="submit"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> Remove Region
        </button>
      </mat-menu>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSaveContinue"
        type="submit"
        class="action-save-button"
        (click)="submit(myForm.value)"
      >
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSave"
        type="submit"
        class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)"
      >
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>
<div class="col-md-12">
  <form [formGroup]="myForm">
    <input type="hidden" formControlName="id" />
    <!-- eventually expose this as a user-selectable value -->
    <input type="hidden" formControlName="is_active" value="1" />
    <div class="card ">
      <div class="card-header">
        <span class="card-header-edit-label">{{ isNewModel ? 'Create' : 'Edit' }} Region</span>
        {{ name.value }}
      </div>
      <div class="card-block">
        <div class="editpanel-column left-edit col-lg-8">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="name" placeholder="Name" />
              <mat-error *ngIf="name.hasError('required') && !name.pristine">
                Name is required
              </mat-error>
              <mat-error
                *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)"
              >
                Name already exists
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="editpanel-column col-lg-4" *ngIf="false">
          <div class="card">
            <div class="card-header">Region Settings</div>
            <div class="card-block">
              <div class="switch-toggle">
                <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
