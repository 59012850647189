import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { YesNoPipe } from '../../_pipes/yes-no.pipe';
import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';
import { League } from '../../models/league';
import { LeagueSettings } from '../../models/league-setting';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';
import { AdService } from '../../services/ad.service';
import { LeagueService } from '../../services/league.service';

import { AdIframeComponent } from './ad-iframe.component';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent extends PageableComponent implements OnInit, OnDestroy {
  yesNoPipe: YesNoPipe = new YesNoPipe();
  showSystemAds = true;
  canChangeSystemAds = false;
  settings: LeagueSettings;
  _leagueSettingsSubscription: Subscription;

  constructor(
    protected leagueStorage: LeagueStorage,
    protected adService: AdService,
    private permissionManager: PermissionManager,
    private leagueService: LeagueService
  ) {
    super(adService, leagueStorage);
    this.setColumns([
      {
        title: 'Name',
        name: 'name',
        sort: 'asc',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Ad Text',
        name: 'ad_text'
      },
      {
        title: 'Ad Location',
        name: 'location',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Active',
        name: 'is_active',
        filtering: { filterString: 'true', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      },
      {
        title: 'IFrame Code',
        name: 'iframe',
        sort: false,
        filtering: false,
        component: AdIframeComponent
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this._leagueSettingsSubscription) {
      this._leagueSettingsSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    super.ngOnInit();

    this._leagueSettingsSubscription = this.leagueStorage
      .getLeagueSettings()
      .subscribe(settings => {
        this.settings = settings;
      });

    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(_ => {
      this.canChangeSystemAds = this.permissionManager.isExecutive();
      if (this.canChangeSystemAds && this.settings) {
        this.showSystemAds = this.settings.show_system_ads;
      } else {
        this.showSystemAds = false;
      }
    });
  }

  showSystemAdsChanged(value) {
    if (this.canChangeSystemAds) {
      this.leagueService
        .updateLeagueSettings(this.league.id, { show_system_ads: value })
        .subscribe(data => {});
    }
  }
}
