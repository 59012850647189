import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LeaderboardService } from '../../../services/leaderboard.service';
import { LeagueService } from '../../../services/league.service';
import { SeasonService } from '../../../services/season.service';
import { VenueService } from '../../../services/venue.service';

import { RegionService } from '../../../services/region.service';
import { BaseLeaderboardDirective } from '../base-leaderboard';

@Component({
  templateUrl: './quartz-avatar.html',
  styleUrls: ['./quartz-avatar.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuartzAvatarLeaderboardComponent extends BaseLeaderboardDirective
  implements OnInit, OnDestroy {
  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected service: LeaderboardService,
    protected seasonService: SeasonService,
    protected leagueService: LeagueService,
    protected venueService: VenueService,
    protected regionService: RegionService
  ) {
    super(router, route, service, seasonService, leagueService, venueService, regionService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
