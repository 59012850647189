<div *ngIf="report" class="col-12">
  <div class="secondmenulogic">
    <div class="secondmenuwrap">
      <div class="secondmenupad">
      </div>
    </div>
  </div>
  <div class="card report-details-card center">
    <div class="card-block">
      <div class="col-12">
        <div class="report_parameters">
          <span class="report-header-title">{{report.ParamLeague?.name || 'All Leagues'}}</span>
          <span *ngIf="report.param_venue_id">| {{report.ParamVenue.name}}</span>
          <span *ngIf="report.param_ad_location"> | {{report.param_ad_location}}</span>
          <span *ngIf="report.param_ad_id"> | {{report.ParamAd.name}}</span>
          <span *ngIf="report.param_is_summary"> | Summary</span>
        </div>
        <div class="report_header_main">
          <h4>
            <span>
              <span>{{report.report_type | capitalize}}</span> Report</span>
          </h4>
          <div class="report_range">
            <span>{{report.start_date | date}} to {{report.end_date | date}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
