import { Moment } from 'moment';

import { UUID } from './uuid';
export class Season {
  public id: UUID;
  public name: string;
  public start_date?: Moment;
  public end_date?: Moment;
  public is_active = true;
  public exclude_from_points = false;
}

export class SeasonName {
  name: string;
}
