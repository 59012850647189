
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { League } from '../../../models/league';
import { LeagueStorage } from '../../../models/league.storage';
import { Report } from '../../../models/report';
import { UUID } from '../../../models/uuid';
import { ReportService } from '../../../services/report.service';
import { AdsDetailReport } from '../../../services/responses/report.responses';
import { LoaderService } from '../../../services/spinner.service';

@Component({
  selector: 'app-ads-detail-report',
  templateUrl: './ads-detail-report.component.html'
})
export class AdsDetailReportComponent implements OnInit, OnDestroy {
  isNewModel = true;
  _propertySubscription: Subscription;
  submitted = false;
  report: Report;
  league: League;
  data: AdsDetailReport;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private service: ReportService,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getReportDetails();
      }
    });
  }

  getReportDetails(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    const params: any = {
      param_date: this.route.snapshot.queryParamMap.get('param_date') || '',
      param_ad_location: this.route.snapshot.queryParamMap.get('param_ad_location') || '',
      param_ad_id: this.route.snapshot.queryParamMap.get('param_ad_id') || '',
      param_league_id: this.route.snapshot.queryParamMap.get('param_league_id') || ''
    };

    if (id !== 'new') {
      this.loaderService.displayLoader(true);
      this.service
        .runAdsDetailReport(id, params).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.isNewModel = false;
          this.report = response.report;
          this.data = response.data;
          if (this.data) {
            this.data.total_impressions = 0;
            this.data.total_clicks = 0;
            this.data.rows.forEach(row => {
              this.data.total_clicks += parseFloat(row.clicks);
              this.data.total_impressions += parseFloat(row.impressions);
            });
          }
        });
    }
  }
}
