import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
// import { RoleForm } from '../models/forms/role.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse } from './responses/generic.responses';
import {
  GetRolesResponse,
  GetRoleResponse,
  GetUserRolesResponse
} from './responses/role.responses';

@Injectable()
export class RoleService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getRoles(params).pipe(
      map(r => {
        return {
          data: r.roles,
          pagination: r.pagination
        };
      })
    );
  }

  public getPrivilegedRoles(params: any = {}): Observable<GetRolesResponse | any> {
    this.logger.debug('Called getPrivilegedRoles');

    this.logger.debug('calling get roles service');

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetRolesResponse>(`${environment.AUTHORIZATION_API}/permissions/role`, options)
      .pipe(tap(response => this.logger.debug('getPrivilegedRoles response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getRoles(params: any = {}): Observable<GetRolesResponse | any> {
    this.logger.debug('Called getRoles');
    if (this.league == null) {
      this.logger.debug('returning default roles');
      return of(this.defaultResponse('roles'));
    }

    this.logger.debug('calling get roles service');

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetRolesResponse>(
        `${environment.AUTHORIZATION_API}/permissions/league/${this.league.id}/role`,
        options
      )
      .pipe(tap(response => this.logger.debug('getRoles response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getRole(roleId: UUID): Observable<GetRoleResponse | any> {
    this.logger.debug('Called getRole');
    if (this.league == null) {
      this.logger.debug('returning default role');
      return of({ role: null });
    }

    this.logger.debug('calling get role service');
    return this.http
      .get<GetRoleResponse>(
        `${environment.AUTHORIZATION_API}/permissions/league/${this.league.id}/role/${roleId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getRole response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getUserRoles(userId: UUID): Observable<GetUserRolesResponse | any> {
    this.logger.debug('Called getUserRoles');
    if (this.league == null) {
      this.logger.debug('returning default user roles');
      return of(this.defaultResponse('userRoles'));
    }

    this.logger.debug('calling get user roles service');

    return this.http
      .get<GetUserRolesResponse>(
        `${environment.AUTHORIZATION_API}/permissions/league/${this.league.id}/user/${userId}/role`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getUserRoles response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
