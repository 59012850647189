import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'lodash';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

import { LeaderboardRecord } from '../../models/leaderboard-record';
import { League } from '../../models/league';
import { LeagueSettings } from '../../models/league-setting';
import { Region } from '../../models/region';
import { Season } from '../../models/season';
import { UUID } from '../../models/uuid';
import { Venue } from '../../models/venue';
import { LeaderboardService } from '../../services/leaderboard.service';
import { LeagueService } from '../../services/league.service';
import { RegionService } from '../../services/region.service';
import { GetLeaderboardResponse } from '../../services/responses/leaderboard.responses';
import { SeasonService } from '../../services/season.service';
import { VenueService } from '../../services/venue.service';
import { genericPopup } from '../../util/window.functions';

@Directive({
  selector: 'app-base-leaderboard'
})
export class BaseLeaderboardDirective implements OnInit, OnDestroy {
  records: LeaderboardRecord[] = [];
  venues: Venue[] = [];
  public leagueId: UUID;
  public league: League;
  public leagueSettings: LeagueSettings;
  seasons: Season[] = [];
  regions: Region[] = [];
  filteredRegions: Region[] = [];
  filteredVenues: Venue[] = [];
  routeSubscription: Subscription;
  termsSubscription: Subscription;
  private queryTerms = new Subject<any>();
  loading = false;

  curSort = 'ranking';
  curDirection = 'asc';

  myStyles = {};

  public pageIndex = 0;
  public itemsPerPage = 25;
  public pageSizeOptions = [25, 50, 100, 250];
  public maxSize = 5;
  public numPages = 0;
  public length = 0;
  public manualPages = [];

  public config: any = {
    paging: true
  };

  selectedSeason: Season | string = '';
  selectedVenue: Venue | string = '';
  selectedRegion: Region | string = '';
  searchPlayer = '';

  get safeSelectedSeason(): Season {
    return this.selectedSeason ? <Season>this.selectedSeason : null;
  }
  get safeSelectedVenue(): Venue {
    return this.selectedVenue ? <Venue>this.selectedVenue : null;
  }

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected service: LeaderboardService,
    protected seasonService: SeasonService,
    protected leagueService: LeagueService,
    protected venueService: VenueService,
    protected regionService: RegionService
  ) {}

  openRecentWinners() {
    genericPopup('/leaderboard/recent-winners/' + this.route.snapshot.paramMap.get('league_id'), {
      width: 300
    });
  }

  sortColumn(column: string) {
    if (this.curSort !== column) {
      this.curDirection = 'asc';
      this.curSort = column;
    } else {
      this.curDirection = this.curDirection === 'desc' ? 'asc' : 'desc';
    }

    return this.patchQueryParams([
      { field: 'sort', value: this.curSort },
      { field: 'dir', value: this.curDirection }
    ]);
  }

  setSelectedSeason(seasonId: string) {
    if (seasonId === 'latest') {
      this.selectedSeason = this.seasons[0];
    } else {
      this.selectedSeason = this.seasons.find(x => x.id.toString() === seasonId) || '';
    }

    return this.seasonChanged();
  }

  setSelectedRegion(regionId: string) {
    this.selectedRegion = this.regions.find(x => x.id.toString() === regionId) || '';

    return this.regionChanged();
  }

  setSelectedVenue(venueId: string) {
    this.selectedVenue = this.venues.find(x => x.id.toString() === venueId) || '';

    return this.venueChanged();
  }

  ngOnDestroy() {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.termsSubscription) {
      this.termsSubscription.unsubscribe();
    }
  }

  compareModels(a, b) {
    return (a === b && a === '') || (a === b && a === null) || (a && b && a.id === b.id);
  }

  venueChanged() {
    // need to filter list of Regions to only those matching the venue.  if any.
    const venueRegion: UUID = this.selectedVenue ? (<Venue>this.selectedVenue).region_id : null;
    this.filteredRegions = this.regions.filter(x => !venueRegion || x.id === venueRegion);

    return this.patchQueryParams([
      {
        field: 'v',
        value: this.selectedVenue ? (<Venue>this.selectedVenue).id.toString() : null
      },
      {
        field: 'pg',
        value: '0'
      }
    ]);
  }

  seasonChanged() {
    return this.patchQueryParams([
      {
        field: 's',
        value: this.selectedSeason ? (<Season>this.selectedSeason).id.toString() : null
      },
      {
        field: 'pg',
        value: '0'
      }
    ]);
  }

  regionChanged() {
    // need to filter list of Venues to only those matching this region.  if any.
    const venueRegion: UUID = this.selectedRegion ? (<Region>this.selectedRegion).id : null;
    this.filteredVenues = this.venues.filter(x => !venueRegion || x.region_id === venueRegion);

    return this.patchQueryParams([
      {
        field: 'r',
        value: this.selectedRegion ? (<Region>this.selectedRegion).id.toString() : null
      },
      {
        field: 'pg',
        value: '0'
      }
    ]);
  }

  searchChanged() {
    return this.patchQueryParams([
      { field: 'p', value: this.searchPlayer },
      { field: 'pg', value: '0' }
    ]);
  }

  manualPageChanged(newPage) {
    this.pageIndex = newPage - 1;
    this.pageChanged();
  }

  pageChanged(page: any = { pageIndex: this.pageIndex, pageSize: this.itemsPerPage }) {
    return this.patchQueryParams([
      { field: 'ps', value: page.pageSize },
      { field: 'pg', value: page.pageIndex }
    ]);
  }

  patchQueryParams(fields: Array<{ field: string; value: string }>) {
    const param = {};
    if (!Array.isArray(fields)) {
      fields = [fields];
    }
    fields.forEach(pair => {
      param[pair.field] = pair.value;
    });

    return this.router.navigate(['.'], {
      queryParams: param,
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }

  venueDetailClicked() {
    this.router.navigate(['/venue-detail'], {
      queryParams: {
        venue_id: this.selectedVenue ? (<Venue>this.selectedVenue).id : null,
        season_id: this.selectedSeason ? (<Season>this.selectedSeason).id : null
      }
    });
  }

  showAll() {
    return this.patchQueryParams([{ field: 'sa', value: 'true' }]);
  }

  protected handleRouteChange(incoming) {
    this.leagueId = new UUID(this.route.snapshot.paramMap.get('league_id'));
    if (this.leagueId) {
      this.itemsPerPage = incoming.ps || 25;
      this.pageIndex = parseInt(incoming.pg, 10) || 0;
      if (incoming.sa && incoming.sa === 'true') {
        // this.showAll = true;
        this.pageIndex = 0;
        this.itemsPerPage = 9999999;
      }

      const params: any = this.buildParams(incoming);

      this.queryTerms.next(params);
    }
  }

  private buildParams(incoming) {
    const search: any = incoming.p || undefined;
    const sort: any = incoming.sort || undefined;
    const dir: any = incoming.dir || undefined;
    const params: any = {
      pageSize: this.itemsPerPage,
      page: this.pageIndex + 1
    };

    if (incoming.s) {
      params.season_id = incoming.s;
    }
    if (incoming.v) {
      params.venue_id = incoming.v;
    }
    if (incoming.r) {
      params.region_id = incoming.r;
    }
    if (search) {
      params.search = search;
    }

    if (sort) {
      params.sort = sort;
    }

    if (dir) {
      params.dir = dir;
    }

    return params;
  }

  private getLeaderboard(params): Observable<GetLeaderboardResponse> {
    this.loading = true;
    return this.service
      .getLeaderboard(this.leagueId, params)
      .pipe(finalize(() => (this.loading = false)));
  }

  public ngOnInit() {
    this.leagueId = new UUID(this.route.snapshot.paramMap.get('league_id'));

    this.routeSubscription = this.route.queryParams.subscribe(queryParams => {
      this.handleRouteChange(queryParams);
    });
    forkJoin(
      [
        this.leagueService.getPublicLeague(this.leagueId),
        this.leagueService.getLeagueColors(this.leagueId),
        this.seasonService.getPublicSeasons(this.leagueId, { pageSize: 1000 }),
        this.venueService.getPublicVenues(this.leagueId, { pageSize: 1000 }),
        this.regionService.getPublicRegions(this.leagueId, { pageSize: 1000 })
      ]
    ).subscribe(
      async ([
        leagueResponse,
        leagueSettingsResponse,
        seasonsResponse,
        venuesResponse,
        regionsResponse
      ]) => {
        this.leagueSettings = leagueSettingsResponse;

        this.seasons = seasonsResponse.seasons;
        this.venues = venuesResponse.venues;
        this.league = leagueResponse.league;
        this.regions = regionsResponse.regions;

        const venueId = this.route.snapshot.queryParamMap.get('v');
        const seasonId = this.route.snapshot.queryParamMap.get('s');
        const regionId = this.route.snapshot.queryParamMap.get('r');
        this.searchPlayer = this.route.snapshot.queryParamMap.get('p');

        await this.setSelectedSeason(seasonId);
        await this.setSelectedVenue(venueId);
        await this.setSelectedRegion(regionId);
        await this.searchChanged();

        const params: any = this.buildParams(this.route.snapshot.queryParams);

        // this handles the first page load
        return this.getLeaderboard(params).subscribe(this.handleLeaderboardResponse.bind(this));
      }
    );

    this.termsSubscription = this.queryTerms
      .pipe(
        // wait 200ms after each keystroke before considering the term
        debounceTime(200),

        // ignore new term if same as previous term
        distinctUntilChanged(),

        // switch to new search observable each time the term changes
        switchMap((params: any) => {
          const merged = merge({}, params, {});
          return this.getLeaderboard(merged);
        })
      )
      .subscribe(this.handleLeaderboardResponse.bind(this));
  }

  handleLeaderboardResponse(response) {
    this.records = response.records;

    this.records = response.records.map(record => {
      // set the avatar url dynamically
      // if they have a random one, it comes from the assets/images dir
      // otherwise it comes from the API
      record.avatar_url = record.avatar_url
        ? `/assets/images/${record.avatar_url}`
        : `${environment.AVATARS_API}/p/player/${record.id}/avatar`;
      return record;
    });

    // this.pageIndex = response.pagination.page;
    // this.numPages = response.pagination.pageCount;
    this.length = response.pagination.rowCount;
    // this.itemsPerPage = response.pagination.pageSize;

    const pagesArray = [];
    const target = Math.ceil(this.length / this.itemsPerPage);
    for (let i = 0; i < target; i++) {
      pagesArray.push(i + 1);
    }

    this.manualPages = pagesArray;
    // if the response would have some rows (length), but we didn't get anything, it
    // means we ran off the end of the list.  This can happen if we filter on a later
    // page but get less results back.
    // Back up to where we would actually get some data and try again
    if (this.records.length === 0 && this.length > 0) {
      // put us on the last page we'd get results for.
      this.pageIndex = Math.ceil(this.length / this.itemsPerPage) - 1;
      this.pageChanged();
    }
  }
}
