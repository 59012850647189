<ul id="nav">
  <li routerLinkActive="active" class="menu-dash nav-menu-item" *ngIf="canSeeDashboard">
    <button mat-button [routerLink]="['/admin', 'dashboard']" (click)="sidenav?.toggle()">
      Dashboard
    </button>
  </li>
  <li routerLinkActive="active" class="menu-leaderboards nav-menu-item" *ngIf="canSeeLeaderboards">
    <button mat-button [routerLink]="['/admin', 'leaderboards']" (click)="sidenav?.toggle()">
      Leaderboards
    </button>
  </li>
  <li routerLinkActive="active" class="menu-tournaments nav-menu-item" *ngIf="canSeeTournaments">
    <button mat-button [routerLink]="['/admin', 'tournaments']" (click)="sidenav?.toggle()">
      Tournaments
    </button>
  </li>
  <li routerLinkActive="active" class="menu-players nav-menu-item" *ngIf="canSeePlayers">
    <button mat-button [routerLink]="['/admin', 'players']" (click)="sidenav?.toggle()">
      Players
    </button>
  </li>
  <li routerLinkActive="active" class="menu-dealers nav-menu-item" *ngIf="canSeeDealers">
    <button mat-button [routerLink]="['/admin', 'dealers']" (click)="sidenav?.toggle()">
      Dealers
    </button>
  </li>
  <li routerLinkActive="active" class="menu-staff nav-menu-item" *ngIf="canSeeStaff">
    <button mat-button [routerLink]="['/admin', 'staff']" (click)="sidenav?.toggle()">Staff</button>
  </li>
  <li routerLinkActive="active" class="menu-venues nav-menu-item" *ngIf="canSeeVenues">
    <button mat-button [routerLink]="['/admin', 'venues']" (click)="sidenav?.toggle()">
      Venues
    </button>
  </li>
  <li routerLinkActive="active" class="menu-seasons nav-menu-item" *ngIf="canSeeSeasons">
    <button mat-button [routerLink]="['/admin', 'seasons']" (click)="sidenav?.toggle()">
      Seasons
    </button>
  </li>
  <li routerLinkActive="active" class="menu-scoring nav-menu-item" *ngIf="canSeeScoring">
    <button mat-button [routerLink]="['/admin', 'scoring']" (click)="sidenav?.toggle()">
      Scoring
    </button>
  </li>
  <li routerLinkActive="active" class="menu-events nav-menu-item" *ngIf="canSeeEvents">
    <button mat-button [routerLink]="['/admin', 'events']" (click)="sidenav?.toggle()">
      Events
    </button>
  </li>
  <li
    routerLinkActive="active"
    class="menu-ads nav-menu-item executive-start exe-menu"
    *ngIf="canSeeAds"
  >
    <button mat-button [routerLink]="['/admin', 'ads']" (click)="sidenav?.toggle()">Ads</button>
  </li>
  <li routerLinkActive="active" class="menu-regions nav-menu-item exe-menu" *ngIf="canSeeRegions">
    <button mat-button [routerLink]="['/admin', 'regions']" (click)="sidenav?.toggle()">
      Regions
    </button>
  </li>
  <li routerLinkActive="active" class="menu-reports nav-menu-item exe-menu" *ngIf="canSeeReports">
    <button mat-button [routerLink]="['/admin', 'reports']" (click)="sidenav?.toggle()">
      Reports
    </button>
  </li>
</ul>
<div class="clear"></div>
