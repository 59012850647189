import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { TokenPayload } from './token.payload';
import { UUID } from './uuid';

@Injectable()
export class AppStorage {
  public _tokenPayload: TokenPayload;
  public _token: string;
  public _refresh: string;
  public _previousInfo: {
    refresh: string;
    userId: string;
    leagueId: string;
  };
  public _leagueIds: UUID[];
  public _expiresAt: number;

  private _leagueIdsSubject: BehaviorSubject<UUID[]> = new BehaviorSubject(null);

  constructor() {}

  get tokenPayload(): TokenPayload {
    if (!this._tokenPayload) {
      this._tokenPayload = JSON.parse(this.getItem('token_payload'));
    }

    return this._tokenPayload;
  }

  set tokenPayload(value: TokenPayload) {
    this.setItem('token_payload', JSON.stringify(value));
    this._tokenPayload = value;
  }

  get refresh(): string {
    if (!this._refresh) {
      this._refresh = this.getItem('refresh');
    }

    return this._refresh;
  }

  set refresh(value: string) {
    this.setItem('refresh', value);
    this._refresh = value;
  }

  get token(): string {
    if (!this._token) {
      this._token = this.getItem('auth_token');
    }

    return this._token;
  }

  get previousInfo(): {
    refresh: string;
    userId: string;
    leagueId: string;
  } {
    if (!this._previousInfo) {
      this._previousInfo = JSON.parse(this.getItem('previousInfo'));
    }

    return this._previousInfo;
  }

  set previousInfo(value: { refresh: string; userId: string; leagueId: string }) {
    this.setItem('previousInfo', JSON.stringify(value));
    this._previousInfo = value;
  }

  set token(value: string) {
    this.setItem('auth_token', value);
    this._token = value;
  }

  get tokenExpiresAt(): number {
    if (!this._expiresAt) {
      this._expiresAt = +this.getItem('token_expires_at');
    }

    return this._expiresAt;
  }

  set tokenExpiresAt(value: number) {
    this.setItem('token_expires_at', '' + value);
    this._expiresAt = value;
  }

  get leagueIds(): UUID[] {
    if (!this._leagueIds) {
      this._leagueIds = JSON.parse(this.getItem('league_ids'));
    }

    return this._leagueIds;
  }

  get leagueIdsObservable(): BehaviorSubject<UUID[]> {
    if (this.leagueIds !== this._leagueIdsSubject.value) {
      this._leagueIdsSubject.next(this._leagueIds);
    }

    return this._leagueIdsSubject;
  }

  set leagueIds(value: UUID[]) {
    this.setItem('league_ids', JSON.stringify(value));
    this._leagueIds = value;
    this._leagueIdsSubject.next(value);
  }

  public clear(): void {
    this._tokenPayload = null;
    this._token = null;
    this._previousInfo = null;
    this._leagueIds = null;
    this._expiresAt = null;
    localStorage.clear();
  }

  protected getItem(key: string): string {
    const value = localStorage.getItem(key);
    return value;
  }

  protected setItem(key: string, value: string) {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }
}
