import { omit } from 'lodash';

import { UUID } from '../uuid';
import { Venue } from '../venue';
import { VenueExpense } from '../venue.expense';
export class VenueForm {
  public _model: Venue;
  public id: UUID;
  public region_id?: UUID;
  public name: string;
  public venue_number?: string;
  public amount_owed?: number;
  public is_active: boolean;
  public url?: string;
  public description?: string;
  public staff_expenses: {
    user_id: UUID;
    role_id: UUID;
    payout_percent: number;
    payout_amount: number;
  }[];
  public item_expenses: {
    label: string;
    payout_percent: number;
    payout_amount: number;
  }[];

  constructor(model: Venue) {
    this._model = model;
    this.id = model.id;
    this.region_id = model.region_id;
    this.name = model.name;
    this.venue_number = model.venue_number;
    this.amount_owed = model.amount_owed;
    this.is_active = model.is_active;
    this.url = model.url;
    this.description = model.description;
    this.staff_expenses = model.staff_expenses;
    this.item_expenses = model.item_expenses;
  }

  omitted() {
    // return this object without properties that should be ignored for setValue()
    // note also have to ignore this function
    const toRet = omit(this, ['omitted', '_model']);

    return toRet;
  }
}
