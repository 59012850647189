import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-customizetips',
  templateUrl: './customize-tips.dialog.component.html',
  styleUrls: ['./customize-tips.dialog.component.css']
})
export class CustomizeTipsDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CustomizeTipsDialogComponent>
  ) { }

  close() {
      this.dialogRef.close();
  }

  ngOnInit() {
  }

}
