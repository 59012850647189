import { formatDuration } from '../../util/parse.duration';
import { Tournament } from '../tournament';
import { UUID } from '../uuid';
export class TournamentForm {
  public id: UUID;
  public name: string;
  public start_date: Date;
  public venue_id: UUID;
  public season_id: UUID;
  public scoring_id: string;
  public tournament_type: string;
  public num_tables: number;
  public bonus_chips_1: number;
  public bonus_chips_2: number;
  public payment_type: string;
  public payment_method: string;
  public venue_cost: number;
  public reason: string;
  public notes: string;
  public check_number: string;
  public is_paperwork_signed: boolean;
  public is_paperwork_received: boolean;
  public is_collected: boolean;
  public is_payment_deposited: boolean;
  public is_closed: boolean;
  public num_entries: number;
  public exclude_from_points: boolean;
  public player_notes: string;
  public num_dealers: number;

  public staff_expenses: {
    user_id: UUID;
    role_id: UUID;
    payout_percent: number;
    payout_amount: number;
  }[];
  public item_expenses: {
    label: string;
    payout_percent: number;
    payout_amount: number;
  }[];

  public tournament_players: {
    player_id: UUID;
    name: string;
    points_awarded: number;
    finish_position: number;
  }[];

  public dealers: {
    dealer_id: UUID;
    name: string;
    time: string;
  }[];

  constructor(model: Tournament) {
    this.id = model.id;
    this.name = model.name;
    this.start_date = new Date(model.start_date.toString());
    this.venue_id = model.venue_id;
    this.season_id = model.season_id;
    this.scoring_id = model.scoring_id ? model.scoring_id.toString() : '';
    this.tournament_type = model.tournament_type;
    this.num_tables = model.num_tables;
    this.bonus_chips_1 = model.bonus_chips_1;
    this.bonus_chips_2 = model.bonus_chips_2;
    this.payment_type = model.payment_type;
    this.payment_method = model.payment_method ? model.payment_method.toLowerCase() : null;
    this.venue_cost = model.venue_cost;
    this.reason = model.reason;
    this.notes = model.notes;
    this.check_number = model.check_number;
    this.is_paperwork_signed = model.is_paperwork_signed;
    this.is_paperwork_received = model.is_paperwork_received;
    this.is_collected = model.is_collected;
    this.is_payment_deposited = model.is_payment_deposited;
    this.is_closed = model.is_closed;
    this.num_entries = model.num_entries;
    this.exclude_from_points = model.exclude_from_points;
    this.player_notes = model.player_notes;

    this.num_dealers = model.DealerTimecards.length;
    this.staff_expenses = [];
    this.item_expenses = [];
    this.tournament_players = [];
    this.dealers = [];

    if (model.TournamentPlayers && model.TournamentPlayers.length > 0) {
      this.tournament_players = model.TournamentPlayers.map(tp => ({
        player_id: tp.player_id,
        name: tp.Player.name,
        points_awarded: tp.points_awarded,
        finish_position: tp.finish_position
      }));

      this.tournament_players.sort((a, b) => a.finish_position - b.finish_position);
    }

    if (model.DealerTimecards && model.DealerTimecards.length > 0) {
      this.dealers = model.DealerTimecards.map(dc => ({
        dealer_id: dc.dealer_id,
        name: dc.Dealer.name,
        time: formatDuration(dc.time_in_seconds),
        time_in_seconds: dc.time_in_seconds
      }));
    }

    if (model.TournamentExpenses && model.TournamentExpenses.length > 0) {
      this.staff_expenses = model.TournamentExpenses.filter(expense => expense.user_id).map(
        expense => ({
          user_id: expense.user_id,
          role_id: expense.role_id,
          level: expense.level,
          payout_percent: expense.amount_percent,
          payout_amount: expense.amount_owed
        })
      );

      this.item_expenses = model.TournamentExpenses.filter(expense => !expense.user_id).map(
        expense => ({
          label: expense.label,
          level: expense.level,
          payout_percent: expense.amount_percent,
          payout_amount: expense.amount_owed
        })
      );
    }
  }
}
