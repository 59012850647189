import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { YesNoPipe } from '../../_pipes/yes-no.pipe';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { VenueService } from '../../services/venue.service';

import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';

@Component({
  selector: 'app-venues',
  templateUrl: './venues.component.html',
  styleUrls: ['./venues.component.css']
})
export class VenuesComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  yesNoPipe: YesNoPipe = new YesNoPipe();
  datePipe: DatePipe = new DatePipe('en-US');

  constructor(protected leagueStorage: LeagueStorage, protected venueService: VenueService) {
    super(venueService, leagueStorage);

    this.setColumns([
      {
        title: 'Name',
        name: 'name',
        sort: 'asc',
        sortIndex: 2,
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Venue Number',
        name: 'venue_number',
        filtering: { filterString: '', placeholder: 'Filter' }
      },
      {
        title: 'Created date',
        name: 'created_at',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Active',
        name: 'is_active',
        sort: 'desc',
        sortIndex: 1,
        filtering: { filterString: '', boolean: true },
        render: (value, row) => {
          return this.yesNoPipe.transform(value);
        }
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
