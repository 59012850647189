import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { MergePlayerForm } from '../models/forms/merge-player.form';
import { PlayerSettingForm } from '../models/forms/player-setting.form';
import { PlayerForm } from '../models/forms/player.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import {
  GetPlayersResponse,
  GetPlayerResponse,
  GetPlayerSettingResponse,
  GetPlayerStatsResponse
} from './responses/player.responses';

@Injectable()
export class PlayerService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  someprivateval = 'somedata';

  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init) it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/player/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getPlayers(params).pipe(
      map(r => {
        return {
          data: r.players,
          pagination: r.pagination
        };
      })
    );
  }

  public getPlayers = (params: any): Observable<GetPlayersResponse | any> => {
    this.logger.debug('Called getPlayers');
    if (this.league == null) {
      this.logger.debug('returning default players');
      return of(this.defaultResponse('players'));
    }
    this.logger.debug('calling get players service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetPlayersResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/player`,
        options
      )
      .pipe(tap(response => this.logger.debug('getPlayers response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  };

  public exportPlayers = (params: any): Promise<any> => {
    this.logger.debug('calling export players service');

    return this.http
      .get<Blob>(`${environment.PLAYERS_API}/league/${this.league.id}/player/export`, {
        observe: 'response' as 'body',
        responseType: 'blob' as 'json'
      })
      .toPromise();
    // .pipe(catchError(this.handleError.bind(this)));
  };

  public getPublicPlayer(playerId: UUID, params: any): Observable<GetPlayerResponse | any> {
    this.logger.debug('Called getPublicPlayer');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetPlayerResponse>(`${environment.PLAYERS_API}/p/player/${playerId}`, options)
      .pipe(tap(response => this.logger.debug('getPublicPlayer response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public searchPlayers = keyword => {
    const params = {};
    params['filters[Player.name]'] = keyword;
    params['order_by[Player.name]'] = 'asc';
    return this.getPlayers(params).pipe(
      map(response => {
        if (response && response.players) {
          return response.players;
        }

        return [];
      })
    );
  };

  public getMyPlayer(params: any = {}): Observable<GetPlayerResponse | any> {
    this.logger.debug('Called getMyPlayer');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetPlayerResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/my/player`,
        options
      )
      .pipe(tap(response => this.logger.debug('getMyPlayer response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPlayer(playerId: UUID, params: any = {}): Observable<GetPlayerResponse | any> {
    this.logger.debug('Called getPlayer');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetPlayerResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/player/${playerId}`,
        options
      )
      .pipe(tap(response => this.logger.debug('getPlayer response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createPlayer(value: PlayerForm): Observable<GetPlayerResponse | any> {
    this.logger.debug('Called createPlayer');
    return this.http
      .post<GetPlayerResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/player`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createPlayer response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updatePlayer(value: PlayerForm): Observable<GetPlayerResponse | any> {
    this.logger.debug('Called updatePlayer');
    return this.http
      .patch<GetPlayerResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/player/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updatePlayer response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public mergePlayer(value: MergePlayerForm): Observable<GetPlayerResponse | any> {
    this.logger.debug('Called mergePlayer');
    return this.http
      .post<GetPlayerResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/player/${value.id}/merge`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('mergePlayer response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deletePlayer(playerId: UUID): Observable<any | any> {
    this.logger.debug('Called deletePlayer');
    return this.http
      .delete<any>(
        `${environment.PLAYERS_API}/league/${this.league.id}/player/${playerId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deletePlayer response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updatePlayerSettings(
    value: PlayerSettingForm
  ): Observable<GetPlayerSettingResponse | any> {
    this.logger.debug('Called updatePlayerSettings');
    return this.http
      .put<GetPlayerSettingResponse>(
        `${environment.PLAYERS_API}/league/${this.league.id}/my/player/settings`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updatePlayerSettings response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public uploadAvatar(file: File): Observable<any> {
    this.logger.debug('Called uploadAvatar');

    const formData = new FormData();
    formData.append('avatar', file, 'testrelativepath');

    return this.http
      .put<any>(
        `${environment.PLAYERS_API}/league/${this.league.id}/my/player/avatar`,
        formData,
        {}
      )
      .pipe(tap(response => this.logger.debug('uploadAvatar response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPlayerStats(): Observable<GetPlayerStatsResponse | any> {
    this.logger.debug('Called getPlayerStats');
    return this.http
      .get<GetPlayerResponse>(`${environment.PLAYERS_API}/system/player/stats`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getPlayerStats response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
