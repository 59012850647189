import { Component, Input, OnChanges } from '@angular/core';

import { Report } from '../../../models/report';
import { AdsReport } from '../../../services/responses/report.responses';

@Component({
  selector: 'app-ads-report',
  templateUrl: './ads-report.component.html'
})
export class AdsReportComponent implements OnChanges {
  @Input() report: Report;
  @Input() reportData: AdsReport;

  constructor() {}

  ngOnChanges() {
    if (this.reportData) {
      this.reportData.leagues.forEach(league => {
        league.total_impressions = 0;
        league.total_clicks = 0;
        league.rows.forEach(row => {
          league.total_clicks += parseFloat(row.clicks);
          league.total_impressions += parseFloat(row.impressions);
        });
      });
    }
  }
}
