import { UUID } from './uuid';
import { VenueExpense } from './venue.expense';
export class Venue {
  constructor(init?: Partial<Venue>) {
    Object.assign(this, init);
  }
  public id: UUID;
  public league_id: UUID;
  public region_id?: UUID;
  public name: string;
  public venue_number?: string;
  public is_active: boolean;
  public amount_owed?: number;
  public url?: string;
  public description?: string;
  public VenueExpenses: VenueExpense[];

  get staff_expenses(): {
    user_id: UUID;
    role_id: UUID;
    payout_percent: number;
    payout_amount: number;
  }[] {
    if (this.VenueExpenses && this.VenueExpenses.length > 0) {
      return this.VenueExpenses.filter(expense => expense.user_id).map(expense => ({
        user_id: expense.user_id,
        role_id: expense.role_id,
        level: expense.level,
        payout_percent: expense.amount_percent,
        payout_amount: expense.amount_owed
      }));
    }

    return [];
  }
  get item_expenses(): {
    label: string;
    payout_percent: number;
    payout_amount: number;
  }[] {
    if (this.VenueExpenses && this.VenueExpenses.length > 0) {
      return this.VenueExpenses.filter(expense => !expense.user_id).map(expense => ({
        label: expense.label,
        level: expense.level,
        payout_percent: expense.amount_percent,
        payout_amount: expense.amount_owed
      }));
    }
    return [];
  }
}

export class VenueName {
  name: string;
}
