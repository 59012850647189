import { Component, Input } from '@angular/core';

import { Player } from '../../models/player';
import { genericPopup } from '../../util/window.functions';

@Component({
  templateUrl: './player-profile-link.component.html',
  styleUrls: ['./player-profile-link.component.css'],
  selector: 'app-player-profile-link'
})
export class PlayerProfileLinkComponent {
  @Input() season_id: string;
  @Input() player: Player;
  @Input() linkText?: string;

  constructor() {}

  getUrlSafeName(name: string) {
    return name.replace(/[\W]+/g, '-').toLowerCase();
  }

  get url(): string {
    const name =
      this.player && this.player.name ? this.getUrlSafeName(this.player.name) : 'unidentified';
    const type = this.player.is_verified ? 'verified' : 'unverified';
    return (
      '/player-profile/' +
      type +
      '/' +
      encodeURIComponent(name) +
      '/' +
      this.player.id +
      (this.season_id ? '?season_id=' + this.season_id : '')
    );
  }

  public navigatePlayer() {
    let width = 1280;
    let height = 720;
    if (this.player.is_verified) {
      width = 1280;
      height = 720;
    }
    genericPopup(this.url, { width, height });
    return false;
  }
}
