<div class="dialog-newleague-logic">
  <h2 mat-dialog-title>Profile Customizing Tips</h2>
  <mat-dialog-content>
    <div class="col-6">
          <div class="instructions-line">
            Square images are best, ideal image dimensions are 512px X 512px, images will be hard cropped from the center.
          </div>
          <br />
          <div class="instructions-line">
            <span style="font-weight: bold;">Upload avatar from your mobile device</span>
          <ul>
            <li>
              <span class="list_number">1.</span>
              Login to your profile page from your mobile device.
            </li>
            <li>
              <span class="list_number">2.</span>
              Click Browse, choose a image from your library or take a new photo for your profile.
            </li>
            <li>
              <span class="list_number">3.</span>
              Then click the “Save Profile” button.
            </li>
          </ul>
          <br />
          <div class="instructions-line">
            Confirm your image by clicking “View Player Profile Page”.
          </div>
          <br />
          <div class="instructions-line">
            <span style="font-weight: bold;">Upload from your laptop or desktop.</span>
          </div>
          <ul>
            <li>
              <span class="list_number">1.</span>
              If you don't have a image editor installed, visit
              <a target="_blank" href="https://croppola.com/">Croppola</a> for their online cropping tool.
            </li>
            <li>
              <span class="list_number">2.</span>
              Select the image you would like to use.
            </li>
            <li>
              <span class="list_number">3.</span>
              Square crop the image using the ideal dimensions.
            </li>
            <li>
              <span class="list_number">4.</span>
              Save as JPG, PNG
            </li>
            <li>
              <span class="list_number">5.</span>
              Login to your profile page from your desktop/laptop.
            </li>
            <li>
              <span class="list_number">6.</span>
              Click Browse and select the image from your computer
            </li>
            <li>
              <span class="list_number">7.</span>
              Click the “Save Profile” button.
            </li>
          </ul>
        </div>
</div>
<div class="col-6">
  <div id="sample_profile">
  <h4>Example Player Profile Page</h4>
  <img src="/assets/images/verified-profile-customize.jpg" alt="Sample Profile" />

</div>
</div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Ok</button>
  </mat-dialog-actions>
</div>
