import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppStorage } from '../models/app.storage';
import { ForgotPasswordForm } from '../models/forms/forgot-password.form';
import { LoginForm } from '../models/forms/login.form';
import { VerifyTokenForm } from '../models/forms/verify-token.form';
import { PermissionManager } from '../models/permission.manager';

import { GenericService } from './generic.service';
import { LogoutResponse, TokenResponse } from './responses/auth.responses';
import { TokenService } from './token.service';

@Injectable()
export class AuthService extends GenericService {
  constructor(
    protected http: HttpClient,
    private storage: AppStorage,
    private permissionManager: PermissionManager,
    private tokenService: TokenService,
    protected logger: NGXLogger
  ) {
    super(logger);
  }
  public userLoggedIn(): boolean {
    const authToken = this.storage.token;
    const refreshToken = this.storage.refresh;
    return authToken && refreshToken && refreshToken !== 'null';
  }

  public login(value: LoginForm): Observable<TokenResponse | any> {
    return this.http
      .post<TokenResponse>(
        `${environment.AUTHENTICATION_API}/p/auth/login`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('login response:', response)))
      .pipe(tap(this.handleLoginResponse.bind(this)))
      .pipe(map(data => data.token))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public logout(): Observable<LogoutResponse> {
    return this.http
      .post<LogoutResponse>(
        `${environment.AUTHENTICATION_API}/p/auth/logout`,
        {
          token: this.storage.refresh
        },
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('logout response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public loginAsUser(userId: string, previousLeagueId: string): Observable<string | any> {
    if (!this.permissionManager.isAdmin) {
      return observableThrowError(
        new Error('Unable to login as user because you are not an admin.')
      );
    }
    this.logger.debug('Attempting to login as user', userId);

    const oldRefreshToken = this.storage.refresh;
    const oldUserId = this.storage.tokenPayload.user.id.toString();

    const value = {
      user_id: userId,
      token: this.storage.refresh
    };

    return this.login(value).pipe(
      tap(() => {
        this.storage.previousInfo = {
          refresh: oldRefreshToken,
          userId: oldUserId,
          leagueId: previousLeagueId
        };
      })
    );
  }

  public restoreUser() {
    const value = {
      user_id: this.storage.previousInfo.userId,
      token: this.storage.previousInfo.refresh
    };
    return this.login(value).pipe(
      tap(() => {
        this.storage.refresh = this.storage.previousInfo.refresh;
      })
    );
  }

  public refresh(): Observable<string | any> {
    const value = {
      user_id: this.storage.tokenPayload.user.id.toString(),
      token: this.storage.refresh
    };
    return this.login(value);
  }

  public resetPassword(value: VerifyTokenForm): Observable<any> {
    return this.http
      .post<TokenResponse>(
        `${environment.AUTHENTICATION_API}/p/auth/reset`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('forgotPassword response:', response)))
      .pipe(tap(this.handleLoginResponse.bind(this)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  private handleLoginResponse(data) {
    this.tokenService.handleAuthResponse(data);
  }

  public forgotPassword(value: ForgotPasswordForm): Observable<any> {
    return this.http
      .post<TokenResponse>(
        `${environment.AUTHENTICATION_API}/p/auth/forgot_password`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('forgotPassword response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
