import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError as observableThrowError, Observable, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { GenericPageComponent } from '../../generic-page.component';
import { AppStorage } from '../../models/app.storage';
import { LoginForm } from '../../models/forms/login.form';
import { League } from '../../models/league';
import { PermissionManager } from '../../models/permission.manager';
import { AuthService } from '../../services/auth.service';
import { LeagueService } from '../../services/league.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent extends GenericPageComponent implements OnInit, OnDestroy {
  public myForm: FormGroup;
  public loggedIn: boolean;
  public submitted = false;
  public leagues: League[] = [];
  public returnUrl: string;
  public loginMsg = '';
  loaderSubscription: Subscription;

  constructor(
    private loaderService: LoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private leagueService: LeagueService,
    private storage: AppStorage,
    private permissionManager: PermissionManager
  ) {
    super();
    this.myForm = fb.group({
      user_id: ['', [Validators.required]],
      password: ['', Validators.required]
    });
  }

  public ngOnInit() {
    this.loaderSubscription = this.loaderService.loaderStatus.subscribe(
      (val: boolean) => (this.submitted = val)
    );
    this.loggedIn = this.authService.userLoggedIn();
    if (this.route.snapshot.queryParams.msg) {
      this.loginMsg = 'You were logged out because: ' + this.route.snapshot.queryParams.msg;
    }
    if (this.loggedIn) {
      this.permissionManager.maybeRedirect('/admin/dashboard', true);
    } else {
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/admin/dashboard';
    }
  }

  public ngOnDestroy() {
    this.loaderSubscription.unsubscribe();
  }

  public login(value: LoginForm): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      this.authService
        .login(value)
        .pipe(
          catchError(error => {
            this.loginMsg = error.errorMessage;
            return observableThrowError(error);
          }),
          finalize(() => this.loaderService.displayLoader(false))
        )
        .subscribe(data => {
          // Page redirect when getting response
          this.loginMsg = '';

          // check if they are a player, TD, or AM
          // those should redirect to appropriate places

          this.permissionManager.maybeRedirect(this.returnUrl, true);
        });
    }
  }
}
