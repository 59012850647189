
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

// import { ErrorResponse } from './responses/error.responses';

@Injectable()
export class GenericService {
  protected httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(protected logger: NGXLogger) {}

  protected handleError(error: Response | any): Observable<any> {
    // TODO: better job of transforming error for user consumption
    this.logger.debug('Raw error response', error);

    let message = '';
    if (error.error && error.error.message) {
      message = error.error.message;
    } else if (error.message) {
      message = error.message;
    } else {
      message = error;
    }

    error.errorMessage = message;

    this.logger.error('Failure:', message);
    return observableThrowError(error);
  }

  protected defaultResponse(name: string): any {
    const toRet = {
      data: [], // for paged data
      links: {},
      pagination: { page: 0, pageSize: 20, rowCount: 0, pageCount: 0 }
    };
    toRet[name] = [];
    return toRet;
  }
}
