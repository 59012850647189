import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LeagueService } from '../services/league.service';

// import { AppStorage } from './app.storage';
import { League } from './league';
import { LeagueSettings } from './league-setting';

@Injectable()
export class LeagueStorage {
  private _leagues: BehaviorSubject<League[]> = new BehaviorSubject([]);
  private _selectedLeague: BehaviorSubject<League> = new BehaviorSubject(null);
  private _leagueSettings: BehaviorSubject<LeagueSettings> = new BehaviorSubject(null);

  constructor(private leagueService: LeagueService) {}

  getLeagues(): BehaviorSubject<League[]> {
    return this._leagues;
  }

  addLeague(value: League) {
    const leagues = this._leagues.getValue();
    leagues.push(value);
    this.setLeagues(leagues);
    this.setSelectedLeague(value);
  }

  setLeagues(value: League[], selectedValue = null, defaultValue = null) {
    value.sort((a, b) => a.name.localeCompare(b.name));
    this._leagues.next(value);
    if (typeof selectedValue === 'number') {
      if (value.length > 0 && selectedValue >= 0 && selectedValue < value.length) {
        this.setSelectedLeague(value[selectedValue]);
      }
    } else if (
      ((selectedValue !== null && selectedValue !== undefined) || defaultValue != null) &&
      value.length > 0
    ) {
      let index = value.findIndex(x => x.id === selectedValue);
      if (index === -1) {
        index = value.findIndex(x => x.id === defaultValue);
        if (index === -1) {
          index = 0;
        }
      }

      this.setSelectedLeague(value[index]);
    }

    // this.setItem('leagues', JSON.stringify(value));
  }

  getCurrentLeagueId(): string {
    return this.getItem('_currentLeagueId');
  }

  getSelectedLeague(): BehaviorSubject<League> {
    const currentLeagueId = this.getItem('_currentLeagueId');
    if (!this._selectedLeague.value && currentLeagueId) {
      const league = this._leagues.value.find(x => x.id.toString() === currentLeagueId);
      this._selectedLeague.next(league);
    }

    return this._selectedLeague;
  }

  setSelectedLeague(value: League) {
    this.setItem('_currentLeagueId', value.id.toString());
    this._selectedLeague.next(value);
  }

  getLeagueSettings(): BehaviorSubject<LeagueSettings> {
    return this._leagueSettings;
    // if (!this._leagueSettings) {
    //   this._leagueSettings = JSON.parse(this.getItem('league_settings'));
    // }

    // return this._leagueSettings;
  }

  setLeagueSettings(value: LeagueSettings) {
    // this.setItem('league_settings', JSON.stringify(value));
    this._leagueSettings.next(value);
  }

  public clear(): void {
    // super.clear();
    this._leagues.next([]);
    this._selectedLeague.next(null);
    this._leagueSettings.next(null);
  }

  protected getItem(key: string): string {
    const value = localStorage.getItem(key);
    return value;
  }

  protected setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }
}
