<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/system/messages">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button
          mat-menu-item
          [disabled]="isNewModel"
          id="btnDelete"
          type="submit"
          (click)="onDelete()"
        >
          <mat-icon>delete</mat-icon> Remove Message
        </button>
      </mat-menu>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSaveContinue"
        type="submit"
        class="action-save-button"
        (click)="submit(myForm.value)"
      >
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button
        mat-button
        [disabled]="myForm.invalid || submitted || myForm.pristine"
        id="btnSave"
        type="submit"
        class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)"
      >
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>
<form id="form1" [formGroup]="myForm">
  <input type="hidden" formControlName="id" />
  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">
          <span class="card-header-edit-label">{{ isNewModel ? 'Create' : 'Edit' }} Message</span>
          {{ title.value }}
        </div>
        <div class="card-block">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="title" placeholder="Title" />
              <mat-error *ngIf="title.hasError('required') && !title.pristine">
                Title is required
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="card-footer text-muted">
          <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Message</div>
        <div class="card-block">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <textarea
                required
                rows="8"
                matInput
                formControlName="message"
                placeholder="Message text"
              ></textarea>
              <mat-error *ngIf="message.hasError('required')">
                Message is required.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card">
        <div class="card-header">Options</div>
        <div class="card-block">
          <div class="panelrow col-md-10">
            <mat-form-field>
              <mat-select
                required
                class="season-select"
                formControlName="severity"
                placeholder="Message style"
              >
                <mat-option *ngFor="let level of levels" [value]="level.class"
                  >{{ level.name }} - {{ level.description }}
                </mat-option>
              </mat-select>
              <mat-hint>Select message style</mat-hint>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-10">
            <mat-form-field class="editor-mat-field">
              <input
                matInput
                required
                formControlName="expires_at"
                [min]="minDate"
                [max]="maxDate"
                [matDatepicker]="picker_expires_at"
                placeholder="Expiration Date"
                (click)="picker_expires_at.open()"
                autocomplete="off"
              />
              <mat-datepicker-toggle matSuffix [for]="picker_expires_at"></mat-datepicker-toggle>
              <mat-datepicker #picker_expires_at></mat-datepicker>
              <mat-error *ngIf="expires_at.hasError('date') && !expires_at.pristine">
                Valid expiration date required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-10" *ngIf="!isNewModel">
            <button
              mat-button
              [disabled]="myForm.dirty"
              id="btnEmail"
              type="submit"
              class="action-saveclose-button"
              (click)="sendEmail(model.id)"
            >
              <mat-icon>exit_to_app</mat-icon> Send Email
            </button>
            <div *ngIf="model?.last_email_sent_at">
              Email last sent at:
              {{ model.last_email_sent_at | date: 'MM/dd/yyyy HH:mm:ss' }}
            </div>
            <div *ngIf="!model?.last_email_sent_at">
              No emails sent for this message
            </div>
          </div>
        </div>
        <div class="card-footer text-muted">
          <mat-slide-toggle formControlName="is_dismissable">User can close?</mat-slide-toggle
          ><br /><mat-hint>If unchecked, message shows until expired or inactivated.</mat-hint>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Target Users</div>
        <div class="card-block">
          <div class="panelrow col-md-10">
            <mat-form-field>
              <mat-select
                multiple
                class="season-select"
                formControlName="param_role_ids"
                placeholder="Role"
              >
                <mat-option *ngFor="let role of roles" [value]="role.id"
                  >{{ role.name }}
                </mat-option>
              </mat-select>
              <mat-hint>Filter by user role</mat-hint>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-10">
            <mat-form-field>
              <mat-select
                multiple
                class="season-select"
                formControlName="param_league_ids"
                placeholder="League"
              >
                <mat-option *ngFor="let league of leagues" [value]="league.id"
                  >{{ league.name }}
                </mat-option>
              </mat-select>
              <mat-hint>Filter by league</mat-hint>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-10">
            <mat-form-field>
              <mat-select
                multiple
                class="season-select"
                formControlName="param_league_types"
                placeholder="League type"
              >
                <mat-option value="EXECUTIVE">Executive </mat-option>
                <mat-option value="FREE">Free </mat-option
                ><mat-option value="EXEMPT">Exempt </mat-option>
              </mat-select>
              <mat-hint>Filter by league type</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
