
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { NumericValidator } from '../../_validators/numeric.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { ScoringForm } from '../../models/forms/scoring.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Report } from '../../models/report';
import { Scoring } from '../../models/scoring';
import { UUID } from '../../models/uuid';
import { ReportService } from '../../services/report.service';
import {
  AdsReport,
  DealerReport,
  PayrollReport,
  TournamentReport
} from '../../services/responses/report.responses';
import { LoaderService } from '../../services/spinner.service';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrls: ['./report-detail.component.css']
})
export class ReportDetailComponent implements OnInit, OnDestroy {
  isNewModel = true;
  _propertySubscription: Subscription;
  submitted = false;
  model: Report;
  league: League;
  data: PayrollReport | DealerReport | TournamentReport | AdsReport;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private service: ReportService,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getReport();
      }
    });
  }

  getReport(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.loaderService.displayLoader(true);
      this.service
        .runReport(<UUID>id).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.isNewModel = false;
          this.model = response.report;
          this.data = response.data;
        });
    }
  }
}
