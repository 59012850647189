import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { RegionForm } from '../models/forms/region.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import { GetRegionsResponse, GetRegionResponse } from './responses/region.responses';

@Injectable()
export class RegionService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init) it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.REGIONS_API}/league/${this.league.id}/region/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getRegions(params).pipe(
      map(r => {
        return {
          data: r.regions,
          pagination: r.pagination
        };
      })
    );
  }

  public getRegions(params: any): Observable<GetRegionsResponse> {
    this.logger.debug('Called getRegions');
    if (this.league == null) {
      this.logger.debug('returning default regions');
      return of(this.defaultResponse('regions'));
    }
    this.logger.debug('calling get regions service');

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetRegionsResponse | any>(
        `${environment.REGIONS_API}/league/${this.league.id}/region`,
        options
      )
      .pipe(tap(response => this.logger.debug('getRegions response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPublicRegions(leagueId: UUID, params: any = {}): Observable<GetRegionsResponse> {
    this.logger.debug('Called getPublicRegions');
    this.logger.debug('calling get regions service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetRegionsResponse | any>(
        `${environment.REGIONS_API}/p/league/${leagueId}/region`,
        options
      )
      .pipe(tap(response => this.logger.debug('getPublicRegions response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getRegion(regionId: UUID): Observable<GetRegionResponse | any> {
    this.logger.debug('Called getRegion');
    if (this.league == null) {
      this.logger.debug('returning default region');
      return of({ region: null });
    }

    this.logger.debug('calling get region service');
    return this.http
      .get<GetRegionResponse>(
        `${environment.REGIONS_API}/league/${this.league.id}/region/${regionId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getRegion response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createRegion(value: RegionForm): Observable<GetRegionResponse | any> {
    this.logger.debug('Called createRegion');
    return this.http
      .post<GetRegionResponse>(
        `${environment.REGIONS_API}/league/${this.league.id}/region`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createRegion response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateRegion(value: RegionForm): Observable<GetRegionResponse | any> {
    this.logger.debug('Called updateRegion');
    return this.http
      .patch<GetRegionResponse>(
        `${environment.REGIONS_API}/league/${this.league.id}/region/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateRegion response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteRegion(regionId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteRegion');
    return this.http
      .delete<any>(
        `${environment.REGIONS_API}/league/${this.league.id}/region/${regionId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deleteRegion response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
