import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { SeasonForm } from '../models/forms/season.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import { GetSeasonsResponse, GetSeasonResponse } from './responses/season.responses';

@Injectable()
export class SeasonService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init) it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.SEASONS_API}/league/${this.league.id}/season/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getSeasons(params).pipe(
      map(r => {
        return {
          data: r.seasons,
          pagination: r.pagination
        };
      })
    );
  }

  public getPublicSeasons(leagueId: UUID, params = {}): Observable<GetSeasonsResponse | any> {
    this.logger.debug('Called getPublicSeasons');
    this.logger.debug('calling get seasons service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetSeasonsResponse>(`${environment.SEASONS_API}/p/league/${leagueId}/season`, options)
      .pipe(tap(response => this.logger.debug('getPublicSeasons response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPublicSeason(seasonId: UUID): Observable<GetSeasonResponse | any> {
    this.logger.debug('Called getPublicSeason');
    this.logger.debug('calling get season service');

    return this.http
      .get<GetSeasonResponse>(`${environment.SEASONS_API}/p/season/${seasonId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('getPublicSeason response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getSeasons(params: any): Observable<GetSeasonsResponse> {
    this.logger.debug('Called getSeasons');
    if (this.league == null) {
      this.logger.debug('returning default seasons');
      return of(this.defaultResponse('seasons'));
    }
    this.logger.debug('calling get seasons service');

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetSeasonsResponse | any>(
        `${environment.SEASONS_API}/league/${this.league.id}/season`,
        options
      )
      .pipe(tap(response => this.logger.debug('getSeasons response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getSeason(seasonId: UUID): Observable<GetSeasonResponse> {
    this.logger.debug('Called getSeason');
    if (this.league == null) {
      this.logger.debug('returning default season');
      return of({ season: null });
    }

    this.logger.debug('calling get season service');
    return this.http
      .get<GetSeasonResponse | any>(
        `${environment.SEASONS_API}/league/${this.league.id}/season/${seasonId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getSeason response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createSeason(value: SeasonForm): Observable<GetSeasonResponse | any> {
    this.logger.debug('Called createSeason');
    return this.http
      .post<GetSeasonResponse>(
        `${environment.SEASONS_API}/league/${this.league.id}/season`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createSeason response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateSeason(value: SeasonForm): Observable<GetSeasonResponse | any> {
    this.logger.debug('Called updateSeason');
    return this.http
      .patch<GetSeasonResponse>(
        `${environment.SEASONS_API}/league/${this.league.id}/season/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateSeason response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteSeason(seasonId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteSeason');
    return this.http
      .delete<any>(
        `${environment.SEASONS_API}/league/${this.league.id}/season/${seasonId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deleteSeason response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
