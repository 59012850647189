import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AdForm } from '../models/forms/ad.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetAdsResponse, GetAdResponse } from './responses/ad.responses';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';

@Injectable()
export class AdService extends GenericService implements PageableService {
  public static AD_LOCATIONS: { name: string; description: string; location: string }[] = [
    { name: 'Admin', description: 'Admin pages - Top banner', location: 'admin' },
    { name: 'Leaderboard', description: 'Leaderboard pages - Top banner', location: 'leaderboard' },
    { name: 'Player', description: 'Unverified Profile page - Top banner', location: 'player' },
    { name: 'Venue', description: 'Venue Detail page - Top banner', location: 'venue' },
    { name: 'Player 1', description: 'Verified Profile - Top banner', location: 'player 1' },
    { name: 'Player 2', description: 'Verified Profile - player 2 (unused)', location: 'player 2' },
    { name: 'Player 3', description: 'Verified Profile - Top right (top)', location: 'player 3' },
    {
      name: 'Player 4',
      description: 'Verified Profile - Top right (bottom)',
      location: 'player 4'
    },
    {
      name: 'Player 5',
      description: 'Verified Profile - Lifetime stats (top)',
      location: 'player 5'
    },
    {
      name: 'Player 6',
      description: 'Verified Profile -  Lifetime stats (bottom)',
      location: 'player 6'
    },
    {
      name: 'Player 7',
      description: 'Verified Profile - Bottom right',
      location: 'player 7'
    },
    { name: 'Player 8', description: 'Verified Profile - Bottom banner', location: 'player 8' },
    { name: 'Winners', description: 'Winners page - Top banner', location: 'winners' }
  ];

  league: League;
  _propertySubscription: Subscription;

  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init), OR if this current ad's name is the same as name, it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.ADS_API}/league/${this.league.id}/ad/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getAds(params).pipe(
      map(r => {
        return {
          data: r.ads,
          pagination: r.pagination
        };
      })
    );
  }

  public getAds(params: any): Observable<GetAdsResponse | any> {
    this.logger.debug('Called getAds');
    if (this.league == null) {
      this.logger.debug('returning default ads');
      return of(this.defaultResponse('ads'));
    }
    this.logger.debug('calling get ads service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetAdsResponse>(`${environment.ADS_API}/league/${this.league.id}/ad`, options)
      .pipe(tap(response => this.logger.debug('getAds response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getAd(adId: UUID): Observable<GetAdResponse | any> {
    this.logger.debug('Called getAd');
    if (this.league == null) {
      this.logger.debug('returning default ad');
      return of({ ad: null });
    }

    this.logger.debug('calling get ad service');
    return this.http
      .get<GetAdResponse>(
        `${environment.ADS_API}/league/${this.league.id}/ad/${adId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public random(location: string, leagueId = null): Observable<GetAdResponse | any> {
    const defaultResponse = { ad: null };
    const lid = leagueId || (this.league ? this.league.id : null);
    if (lid == null) {
      return of(defaultResponse);
    }

    return this.http
      .get<GetAdResponse>(
        `${environment.ADS_API}/p/league/${lid}/ad/${location}/random`,
        this.httpOptions
      )
      .pipe(
        // ignore errors here
        catchError(() => of(null))
      );
  }

  public specificAd(adId: UUID, leagueId = null): Observable<GetAdResponse | any> {
    const defaultResponse = { ad: null };
    const lid = leagueId || (this.league ? this.league.id : null);
    if (lid == null) {
      return of(defaultResponse);
    }

    return this.http
      .get<GetAdResponse>(`${environment.ADS_API}/p/league/${lid}/ad/${adId}`, this.httpOptions)
      .pipe(
        // ignore errors here
        catchError(() => of(null))
      );
  }

  public clickAd(impressionId: UUID): void {
    // this is a fire-and-forget method
    this.logger.debug('Called clickAd');
    if (this.league == null) {
      this.logger.debug('returning default ad');
    }

    this.logger.debug('calling get ad service');
    this.http
      .post<void>(`${environment.ADS_API}/p/impression/${impressionId}`, {}, this.httpOptions)
      .pipe(tap(response => this.logger.debug('clickAd response:', response)))
      // ignore errors here
      .pipe(catchError(() => of(null)))
      .subscribe();
  }

  public createAd(value: AdForm): Observable<GetAdResponse | any> {
    this.logger.debug('Called createAd');
    return this.http
      .post<GetAdResponse>(
        `${environment.ADS_API}/league/${this.league.id}/ad`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateAd(value: AdForm): Observable<GetAdResponse | any> {
    this.logger.debug('Called updateAd');
    return this.http
      .patch<GetAdResponse>(
        `${environment.ADS_API}/league/${this.league.id}/ad/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteAd(adId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteAd');
    return this.http
      .delete<any>(`${environment.ADS_API}/league/${this.league.id}/ad/${adId}`, this.httpOptions)
      .pipe(tap(response => this.logger.debug('deleteAd response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
