import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

import { AppStorage } from './app.storage';
import { League } from './league';
import { LeagueStorage } from './league.storage';

@Injectable()
export class PermissionManager implements OnDestroy {
  league: League;
  _propertySubscription: Subscription;

  constructor(
    private leagueStorage: LeagueStorage,
    private appStorage: AppStorage,
    private router: Router
  ) {
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;

      this.maybeRedirect(this.router.url);
    });
  }

  maybeRedirect(route: string, doRedirect = false): boolean {
    console.log('checking maybeRedirectTo', route, doRedirect);
    let hasPermission = false;
    if (this.league) {
      if (route.startsWith('/admin/tournaments')) {
        // let them if they can getall tourneys
        hasPermission =
          this.hasPermission('get dashboard') ||
          this.hasPermissionOrOwnPermission('get all tournaments');
      } else if (route.startsWith('/admin/profile')) {
        // let them if they can getall tourneys
        hasPermission = this.isPlayer();
      } else {
        hasPermission = this.hasPermission('get dashboard');
      }
    } else {
      hasPermission = true;
    }

    let redirectTo = null;

    if (!hasPermission) {
      if (this.hasPermissionOrOwnPermission('get all tournaments')) {
        redirectTo = '/admin/tournaments';
      } else if (this.isPlayer()) {
        redirectTo = '/admin/profile';
      } else {
        // don't redirect here unless they aren't logged in
        if (!this.isLoggedIn) {
          redirectTo = '/login';
        }
      }
    } else if (doRedirect) {
      // user requested to go to specified route
      redirectTo = route;
    }

    if (redirectTo) {
      console.log('redirectingTo', redirectTo);
      // user does not have access to current route, must redirect.
      this.router.navigate([redirectTo]);
      return false;
    } else {
      // ok.
      return true;
    }
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  isLoggedIn() {
    const authToken = this.appStorage.token;
    const refreshToken = this.appStorage.refresh;
    return authToken && refreshToken && refreshToken !== 'null';
  }

  isLimitedAccount() {
    return !this.hasPermission('get dashboard');
  }

  hasPermissionOrOwnPermission(permission) {
    return this.hasPermission(permission) || this.hasPermission('SELF:' + permission);
  }

  hasPermission(permission: string) {
    if (this.league) {
      return (
        this.hasPermissionInLeague(permission, this.league.id.toString()) ||
        this.hasPermissionInLeague(permission, 'all')
      );
    }
  }

  hasPermissionInLeague(permission: string, leagueId: string) {
    if (leagueId && this.appStorage.tokenPayload && this.appStorage.tokenPayload.permissions) {
      return (
        leagueId in this.appStorage.tokenPayload.permissions &&
        this.appStorage.tokenPayload.permissions[leagueId].actions.includes(permission)
      );
    } else {
      return false;
    }
  }

  get isAdmin() {
    const response =
      this.appStorage.tokenPayload &&
      this.appStorage.tokenPayload.permissions &&
      'all' in this.appStorage.tokenPayload.permissions;

    return response;
  }

  isExecutive() {
    return this.league && ['EXEMPT', 'EXECUTIVE'].includes(this.league.account_type);
  }

  isPlayer() {
    if (this.league) {
      return this.isPlayerInLeague(this.league.id.toString());
    }
    return false;
  }

  isPlayerInLeague(leagueId) {
    const playerId = _.get(this.appStorage, ['tokenPayload', 'playersByLeague', leagueId], null);
    return !!playerId;
  }

  getPlayerInLeague(leagueId) {
    return _.get(this.appStorage, ['tokenPayload', 'playersByLeague', leagueId], null);
  }
}
