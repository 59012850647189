<div class="col-12">
  <div class="card card-leaderboard-details">
    <div class="card-header">
      Main Event Leaderboard
    </div>
    <div class="card-block">
      <div class="gridview-logic grid-mainevent">
        <div class="gridview-padding">
          <div class="leaderboard-loading" *ngIf="loading">
            <i class="fas fa-spinner fa-pulse"></i>
          </div>
          <table *ngIf="!loading" rules="all">
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let record of records; let i = index" class="lbMainRow" [class.lbGridDefaultAltRow]="i%2 == 1">
                <td>
                  {{record.position}}
                </td>
                <td>
                  <app-player-profile-link [player]="record"></app-player-profile-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div *ngIf="config.paging" class="card-footer page-footer">
      <div class="gridview-paginator-logic">
        <div class="mat-paginator-page-goto">
          <mat-form-field>
            <mat-select [ngModel]="pageIndex+1" (ngModelChange)="manualPageChanged($event)">
              <mat-option [value]="1">Page</mat-option>
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{mp}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator [length]="length" [pageSize]="itemsPerPage" [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex" (page)="pageChanged($event)" (numPages)="numPages = $event">
        </mat-paginator>
      </div>
    </div>
    <div *ngIf="records.length == 0 && !loading" style="font-weight: bold;">
      No Points found for selected Season/Venue
    </div>
  </div>
  <div class="leaderboard-brand-logic">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
