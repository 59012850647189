import { Component, Input } from '@angular/core';

import { Report } from '../../models/report';

@Component({
  selector: 'app-report-header',
  templateUrl: './report-header.component.html'
})
export class ReportHeaderComponent {
  @Input() report: Report;

  constructor() {}
}
