import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { StaffForm } from '../models/forms/staff.form';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { Staff } from '../models/staff';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse, NameExistsResponse } from './responses/generic.responses';
import {
  GetStaffsResponse,
  GetStaffResponse,
  GetStaffWithRoleResponse
} from './responses/staff.responses';

@Injectable()
export class UserService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public checkNameTaken(name: string): Observable<boolean> {
    // if the current league is null (could happen during init) it's fine.
    if (this.league == null) {
      return of(false);
    }

    return this.http
      .post<NameExistsResponse>(
        `${environment.USERS_API}/league/${this.league.id}/user/exists`,
        { name },
        this.httpOptions
      )
      .pipe(map(r => r.exists));
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getStaffs(params).pipe(
      map(r => {
        return {
          data: r.staffs,
          pagination: r.pagination
        };
      })
    );
  }

  public getStaffs(params: any): Observable<GetStaffsResponse> {
    this.logger.debug('Called getStaffs');
    if (this.league == null) {
      this.logger.debug('returning default staffs');
      return of(this.defaultResponse('staffs'));
    }
    this.logger.debug('calling get staffs service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetStaffsResponse | any>(
        `${environment.USERS_API}/league/${this.league.id}/user`,
        options
      )
      .pipe(tap(response => this.logger.debug('getStaffs response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getStaffWithRole(roleId: UUID): Observable<GetStaffWithRoleResponse> {
    this.logger.debug('Called getStaffWithRole');
    if (this.league == null) {
      this.logger.debug('returning default staff');
      return of({ role_id: roleId, staffs: [] });
    }

    this.logger.debug('calling getStaffWithRole service');
    return this.http
      .get<GetStaffWithRoleResponse | any>(
        `${environment.USERS_API}/league/${this.league.id}/userByRole/${roleId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getStaffWithRole response:', response)))
      .pipe(
        map(response => {
          response.staffs.sort((a: Staff, b: Staff) =>
            new Staff(a).full_name.localeCompare(new Staff(b).full_name)
          );

          return response;
        })
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getStaff(staffId: UUID): Observable<GetStaffResponse> {
    this.logger.debug('Called getStaff');
    if (this.league == null) {
      this.logger.debug('returning default staff');
      return of({ staff: null });
    }

    this.logger.debug('calling get staff service');
    return this.http
      .get<GetStaffResponse | any>(
        `${environment.USERS_API}/league/${this.league.id}/user/${staffId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('getStaff response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  // convenience method for calling getStaff multiple times
  public getMultipleStaff(staffIds: UUID[], params: any = {}): Observable<GetStaffsResponse> {
    this.logger.debug('Called getMultipleStaff');
    if (this.league == null) {
      this.logger.debug('returning default staff');
      return of(this.defaultResponse('staffs'));
    }

    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    this.logger.debug('calling get staff service');
    return this.http
      .post<GetStaffsResponse | any>(
        `${environment.USERS_API}/league/${this.league.id}/multi_user`,
        { userIds: staffIds },
        options
      )
      .pipe(tap(response => this.logger.debug('getMultipleStaff response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public createStaff(value: StaffForm): Observable<GetStaffResponse | any> {
    this.logger.debug('Called createStaff');
    return this.http
      .post<GetStaffResponse>(
        `${environment.USERS_API}/league/${this.league.id}/user`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('createStaff response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public updateStaff(value: StaffForm): Observable<GetStaffResponse | any> {
    this.logger.debug('Called updateStaff');
    return this.http
      .patch<GetStaffResponse>(
        `${environment.USERS_API}/league/${this.league.id}/user/${value.id}`,
        value,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('updateStaff response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public deleteStaff(staffId: UUID): Observable<any | any> {
    this.logger.debug('Called deleteStaff');
    return this.http
      .delete<any>(
        `${environment.USERS_API}/league/${this.league.id}/user/${staffId}`,
        this.httpOptions
      )
      .pipe(tap(response => this.logger.debug('deleteStaff response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
