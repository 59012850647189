import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
  public loaderStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading = false;

  displayLoader(value: boolean) {
    this.loading = value;
    this.loaderStatus.next(value);
  }
}
