import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse } from './responses/generic.responses';
import { GetPlayerTournamentDetailsResponse } from './responses/tournament.responses';

@Injectable()
export class PlayerTournamentService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    if (params.player_id) {
      const playerId = params.player_id;
      delete params.player_id;
      return this.getPlayerTournaments(playerId, params).pipe(
        map(r => {
          return {
            data: r.player_tournament_details,
            pagination: r.pagination
          };
        })
      );
    } else {
      return of(this.defaultResponse('player_tournament_details'));
    }
  }

  public getPlayerTournaments(
    playerId: UUID,
    params: any
  ): Observable<GetPlayerTournamentDetailsResponse | any> {
    this.logger.debug('Called getPlayerTournaments');
    if (this.league == null) {
      this.logger.debug('returning default tournaments');
      return of(this.defaultResponse('player_tournament_details'));
    }
    this.logger.debug('calling get player tournaments service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetPlayerTournamentDetailsResponse>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/player/${playerId}/tournament`,
        options
      )
      .pipe(tap(response => this.logger.debug('playerTournamentService:getPlayerTournaments response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
