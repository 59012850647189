<div class="col-md-12 noselect">
  <div class="loginlogo">
    <img name="Logo" src="/assets/images/login/logo.png" width="100%" height="auto" alt="" />
  </div>
  <form id="loginForm" [formGroup]="myForm" (ngSubmit)="login(myForm.value)">
    <div class="card text-center">
      <div id="member_login">
        <!-- user not logged in -->
        <!-- anonymous view -->
        <div class="card-block">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input
                required
                #email="ngForm"
                matInput
                [formControl]="myForm.controls['user_id']"
                placeholder="Username"
              />
              <mat-hint>Usually your email address.</mat-hint>
              <mat-error *ngIf="email.hasError('required') && !email.pristine">
                Email is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input
                required
                #password="ngForm"
                matInput
                [formControl]="myForm.controls['password']"
                placeholder="Password"
                type="password"
              />
              <mat-hint *ngIf="loginMsg" class="error-hint">
                <i class="fas fa-exclamation-triangle"></i> {{ loginMsg }}.</mat-hint
              >
              <mat-error *ngIf="myForm.controls['password'].hasError">
                Password is required.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <button
              mat-raised-button
              color="primary"
              class="loginsignup loginbtn gridview-editor-btn"
              [disabled]="!myForm.valid || submitted"
              type="submit"
            >
              LOGIN
            </button>
          </div>
        </div>
        <div class="card-footer">
          <div class="login-footer">
            <a routerLink="/forgot-password" class="forget_pass forgetpass">Forget password?</a> |
            <a class="create-account" routerLink="/signup">Create a league</a>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="loginprivacy">
    We value your <a style="cursor: pointer;" (click)="openPrivacy()">privacy</a>
  </div>
</div>
