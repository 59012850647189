import { Region } from '../region';
import { UUID } from '../uuid';
export class RegionForm {
  public id: UUID;
  public name: string;
  public is_active: boolean;
  constructor(model: Region) {
    this.id = model.id;
    this.name = model.name;
    this.is_active = model.is_active;
  }
}
