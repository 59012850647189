import { Component, Input } from '@angular/core';

import { SafeHtml } from '../../../../../../node_modules/@angular/platform-browser';
@Component({
  selector: 'app-some-test',
  template: `<div [innerHTML]="renderValue"></div>`
})
export class TableCellComponent {
  // this is needed because we try to only make one component factory per component type
  // after compilation we lose the class name.  This lets us uniquely identify
  // the type of class we are creating.
  static componentName = 'TableCellComponent';

  @Input() row: any;
  @Input() renderValue: string | SafeHtml;
  @Input() data: any = {};

  public onChanges() {}
}
