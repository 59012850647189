<div class="dialog-newleague-logic">
  <h2 mat-dialog-title>New Report</h2>
  <div class="mat-dialog-description">
    Create a new report
  </div>
  <form id="form1" [formGroup]="myForm">
    <mat-dialog-content *ngIf="data">

      <mat-form-field class="editor-mat-field">
        <mat-select required formControlName="report_type" placeholder="Report Type">
          <mat-option value="payroll">Payroll</mat-option>
          <mat-option value="tournament"> Tournaments</mat-option>
          <mat-option value="dealer"> Dealers</mat-option>
          <mat-option value="ads"> Ads</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-error *ngIf="report_type.hasError('required') && !report_type.pristine">
        Report type is required.
      </mat-error>

      <mat-form-field class="editor-mat-field" *ngIf="data.leagues?.length > 1">
        <mat-select formControlName="param_league_id" placeholder="League">
          <mat-option value="">-- All Leagues --</mat-option>
          <mat-option *ngFor="let paramLeague of data.leagues" [value]="paramLeague.id">{{ paramLeague.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="editor-mat-field">

        <input required matInput formControlName="start_date" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_start_date"
          placeholder="Start Date" (click)="picker_start_date.open()">
        <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
        <mat-datepicker #picker_start_date></mat-datepicker>
        <mat-error *ngIf="start_date.hasError('date') && !start_date.pristine">
          Valid start date required.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="editor-mat-field">
        <input required matInput formControlName="end_date" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_end_date"
          placeholder="End Date" (click)="picker_end_date.open()">
        <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
        <mat-datepicker #picker_end_date></mat-datepicker>
        <mat-error *ngIf="end_date.hasError('date') && !end_date.pristine">
          Valid end date required.
        </mat-error>
        <mat-error *ngIf="myForm.hasError('startLTEEnd') && !end_date.pristine">
          End Date must be after Start Date.
        </mat-error>
      </mat-form-field>
      <div *ngIf="report_type.value === 'tournament'">
        <mat-form-field class="editor-mat-field">
          <mat-select formControlName="param_venue_id">
            <mat-option value="">-- All Venues --</mat-option>
            <mat-option *ngFor="let paramVenue of data.venues" [value]="paramVenue.id">{{ paramVenue.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="report_type.value === 'ads'">
        <mat-form-field class="editor-mat-field">
          <mat-select formControlName="param_ad_location">
            <mat-option value="">-- All Types --</mat-option>
            <mat-option *ngFor="let location of data.adLocations" [value]="location.location">{{location.description}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="editor-mat-field">
          <mat-select formControlName="param_ad_id">
            <mat-option value="">-- All Ads --</mat-option>
            <mat-option *ngFor="let ad of data.ads" [value]="ad.id">{{ ad.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="['ads', 'tournament'].includes(report_type.value)">
        <mat-slide-toggle formControlName="param_is_summary">Summary</mat-slide-toggle>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="mat-raised-button" (click)="close()">Cancel</button>
      <button [disabled]="myForm.invalid || submitted || myForm.pristine" class="mat-raised-button" (click)="save(myForm.value)">Save</button>
    </mat-dialog-actions>
  </form>
</div>
