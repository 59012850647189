import { Component, OnInit } from '@angular/core';

import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-exeterms',
  templateUrl: './exec-terms.dialog.component.html',
  styleUrls: ['./exec-terms.dialog.component.css']
})
export class ExecTermsDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ExecTermsDialogComponent>) {}

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
