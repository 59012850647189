import { empty, of, Observable, Subject, Subscription } from 'rxjs';

import { OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';
import { PageableService } from '../services/pageable.service';
import { GetPagedResponse } from '../services/responses/generic.responses';

export abstract class PageableComponent implements OnInit, OnDestroy {
  league?: League;
  defaultParams: any = { includeInactive: true };
  _propertySubscription: Subscription;
  private _filterSubscription: Subscription;
  private queryTerms = new Subject<any>();
  protected queryParamObservable: Observable<GetPagedResponse | {}>;

  public rows: Array<any> = [];

  public pageIndex = 0;
  public itemsPerPage = 25;
  public pageSizeOptions = [25, 50, 100, 250, 500, 1000, 2500];
  public maxSize = 5;
  public numPages = 0;
  public length = 0;
  public manualPages = [];

  public columns: Array<any> = [];

  public config: any = {
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered'],
    paging: true
  };

  constructor(protected pageableService: PageableService, protected leagueStorage?: LeagueStorage) {
    this.queryParamObservable = this.queryTerms.pipe(
      // wait 200ms after each keystroke before considering the term
      debounceTime(200),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((params: any) => {
        const merged = _.merge({}, params, this.defaultParams);
        return this.pageableService.getPagedData(merged).pipe(
          // we've already caught and logged the exception, we don't care about exceptions here, just ignore them.
          catchError(() => empty())
        );
      })
    );
  }

  protected setDefaultParams(params: any): void {
    this.defaultParams = params;
    this.refreshTable();
  }

  protected setColumns(columns: Array<any>): void {
    this.columns = columns;
    this.config.sorting.columns = this.columns;
  }

  ngOnDestroy() {
    if (this._propertySubscription) {
      this._propertySubscription.unsubscribe();
    }
    if (this._filterSubscription) {
      this._filterSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    // listen for changes to params and only send to the service
    // once we stop receiving events(new params) for 300ms
    this._filterSubscription = this.queryParamObservable.subscribe((response: GetPagedResponse) => {
      // check and make sure we got a good response.  If we error'd, this will be null
      if (response) {
        this.rows = response.data;
        this.length = response.pagination.rowCount;

        const pagesArray = [];
        const target = Math.ceil(this.length / this.itemsPerPage);
        for (let i = 0; i < target; i++) {
          pagesArray.push(i + 1);
        }

        this.manualPages = pagesArray;

        // if the response would have some rows (length), but we didn't get anything, it
        // means we ran off the end of the list.  This can happen if we filter on a later
        // page but get less results back.
        // Back up to where we would actually get some data and try again
        if (this.rows.length === 0 && this.length > 0) {
          // put us on the last page we'd get results for.
          this.pageIndex = Math.ceil(this.length / this.itemsPerPage) - 1;
          this.refreshTable();
        }
      }
    });

    // if the user selects a different league listen for and fire an event for that.
    if (this.leagueStorage) {
      this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
        this.league = league;
        if (this.league != null) {
          this.onChangeTable(this.config);
        }
      });
    } else {
      // just run the onChangeTable
      this.onChangeTable(this.config);
    }
  }

  manualPageChanged(newPage) {
    this.pageIndex = newPage - 1;
    this.refreshTable();
  }

  refreshTable() {
    this.onChangeTable(this.config);
  }

  public onChangeTable(
    config: any,
    page: any = { pageIndex: this.pageIndex, pageSize: this.itemsPerPage }
  ): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    this.itemsPerPage = page.pageSize;
    this.pageIndex = page.pageIndex;

    const params = {};
    let curIndex = 0;
    _.cloneDeep(this.config.sorting.columns)
      .sort((a, b) => {
        if (!a.sortIndex && !b.sortIndex) {
          return 0;
        }
        if (!a.sortIndex && b.sortIndex) {
          return 1;
        }
        if (a.sortIndex && !b.sortIndex) {
          return -1;
        }

        return a.sortIndex - b.sortIndex;
      })
      .forEach((column: any) => {
        if (column.sort) {
          if (column.sortIndex) {
            params['order_by[' + column.sortIndex + '][' + column.name + ']'] = column.sort;
          } else {
            params['order_by[' + curIndex + '][' + column.name + ']'] = column.sort;
            curIndex++;
          }
        }

        if (column.filtering && column.filtering.filterString) {
          params['filters[' + column.name + ']'] = column.filtering.filterString;
        }
      });
    params['pageSize'] = this.itemsPerPage;
    params['page'] = this.pageIndex + 1;

    // shove this set of params into the observable queue.  It will be debounced and sent very soon!
    this.queryTerms.next(params);
  }
}
