import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Alert, AlertType } from '../../models/common/alert';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Message } from '../../models/message';
import { AlertService } from '../../services/common/alert.service';
import { GetMessagesResponse } from '../../services/responses/message.responses';
import { SystemMessagesService } from '../../services/system.messages.service';

@Component({
  moduleId: module.id,
  selector: 'system-messages',
  templateUrl: 'messages.component.html'
})
export class MessagesComponent implements OnInit {
  messages: Message[] = [];
  league: League;
  _propertySubscription: Subscription;

  constructor(
    private messageService: SystemMessagesService,
    private leagueStorage: LeagueStorage
  ) {}

  ngOnInit() {
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      this.messageService.getMyMessages().subscribe((messages: GetMessagesResponse) => {
        this.messages = messages.messages;
      });
    });
  }

  removeMessage(message: Message) {
    this.messageService.acknowledgeMessage(message.id).subscribe(() => {
      this.messages = this.messages.filter(x => x.id !== message.id);
    });
  }
}
