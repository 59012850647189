import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { defaults } from 'lodash';
import { omit } from 'lodash';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NumericValidator } from '../../_validators/numeric.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { VenueForm } from '../../models/forms/venue.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';
import { Region } from '../../models/region';
import { Role } from '../../models/role';
import { Staff } from '../../models/staff';
import { UUID } from '../../models/uuid';
import { Venue } from '../../models/venue';
import { RegionService } from '../../services/region.service';
import { GetStaffWithRoleResponse } from '../../services/responses/staff.responses';
import { RoleService } from '../../services/role.service';
import { LoaderService } from '../../services/spinner.service';
import { UserService } from '../../services/user.service';
import { VenueService } from '../../services/venue.service';

@Component({
  selector: 'app-venue-detail',
  templateUrl: './venue-detail.component.html',
  styleUrls: ['./venue-detail.component.css']
})
export class VenueDetailComponent implements OnInit, OnDestroy {
  totalAmount = 0;
  totalPercent = 0;
  isNewModel = true;
  _propertySubscription: Subscription;
  myForm: FormGroup;
  submitted = false;
  model = new Venue();
  league: League;
  allRoles: Role[] = [];
  regions: Region[] = [];
  usersByRole: { [key: string]: Staff[] } = {};
  isExecutive = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: VenueService,
    private roleService: RoleService,
    private regionService: RegionService,
    private userService: UserService,
    private uniqueNameValidator: UniqueNameValidator,
    private numericValidator: NumericValidator,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute,
    private permissionManager: PermissionManager
  ) {
    this.myForm = fb.group({
      id: '',
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(255)]),
        uniqueNameValidator.create('name').bind(this)
      ],
      venue_number: ['', Validators.compose([Validators.maxLength(50)])],
      is_active: true,
      url: ['', Validators.maxLength(1024)],
      description: '',
      amount_owed: [
        '150',
        numericValidator.create({ allowNulls: true, minInclusive: true, min: 0 })
      ],
      region_id: '',
      staff_expenses: fb.array([]),
      item_expenses: fb.array([])
    });
  }

  // handy access to form fields
  get name(): any {
    return this.myForm.get('name');
  }
  get venue_number(): any {
    return this.myForm.get('venue_number');
  }
  get is_active(): any {
    return this.myForm.get('is_active');
  }
  get url(): any {
    return this.myForm.get('url');
  }
  get description(): any {
    return this.myForm.get('description');
  }
  get amount_owed(): any {
    return this.myForm.get('amount_owed');
  }
  get staff_expenses(): FormArray {
    return this.myForm.get('staff_expenses') as FormArray;
  }
  get item_expenses(): FormArray {
    return this.myForm.get('item_expenses') as FormArray;
  }

  amountOwedChanged() {
    this.staff_expenses.controls.forEach(staff => {
      const amountField = staff.get('payout_amount') as FormControl;
      const percentField = staff.get('payout_percent') as FormControl;
      this.percentChanged(percentField, amountField, false);
    });

    this.item_expenses.controls.forEach(staff => {
      const amountField = staff.get('payout_amount') as FormControl;
      const percentField = staff.get('payout_percent') as FormControl;
      this.percentChanged(percentField, amountField, false);
    });

    this.recalculateTotals();
  }

  percentChanged(percentField: FormControl, amountField: FormControl, fireEvent = true) {
    this.staff_expenses.markAsDirty();
    if (this.amount_owed.valid && percentField.valid) {
      amountField.setValue(((percentField.value / 100) * this.amount_owed.value).toFixed(2));
    }

    if (fireEvent) {
      this.recalculateTotals();
    }
  }

  amountChanged(amountField: FormControl, percentField: FormControl, fireEvent = true) {
    if (this.amount_owed.valid && amountField.valid) {
      percentField.setValue(
        ((parseFloat(amountField.value) / parseFloat(this.amount_owed.value)) * 100).toFixed(1)
      );
    }

    if (fireEvent) {
      this.recalculateTotals();
    }
  }

  recalculateTotals() {
    const sum = [0, 0];

    const fn = (totals, formGroup) => {
      const amountField = formGroup.get('payout_amount') as FormControl;
      const percentField = formGroup.get('payout_percent') as FormControl;
      if (percentField.valid) {
        totals[0] += parseFloat(percentField.value) / 100;
      }
      if (amountField.valid) {
        totals[1] += parseFloat(amountField.value);
      }

      return totals;
    };

    this.staff_expenses.controls.reduce(fn, sum);
    this.item_expenses.controls.reduce(fn, sum);

    [this.totalPercent, this.totalAmount] = sum;
  }

  roleChanged(roleId: any): void {
    if (!this.usersByRole[roleId] || this.usersByRole[roleId].length === 0) {
      this.retrieveUsersByRole(roleId).subscribe(
        response => (this.usersByRole[roleId] = response.staffs)
      );
    }
  }

  retrieveUsersByRole(roleId: UUID): Observable<GetStaffWithRoleResponse> {
    return this.userService.getStaffWithRole(roleId);
  }

  addDefaultExpenses() {
    this.addStaff({
      role_id: this.allRoles.find(x => x.name === 'Tournament Director').id,
      payout_amount: 60,
      payout_percent: 40
    });
    this.addStaff({
      role_id: this.allRoles.find(x => x.name === 'Account Manager').id,
      payout_amount: 7.5,
      payout_percent: 5
    });
    this.addStaff({
      role_id: this.allRoles.find(x => x.name === 'Office Director').id,
      payout_amount: 3,
      payout_percent: 2
    });
    this.addItem({
      label: 'Equipment',
      payout_amount: 7.5,
      payout_percent: 5
    });
    this.addItem({
      label: 'Marketing',
      payout_amount: 7.5,
      payout_percent: 5
    });
    this.addItem({
      label: 'Prizes',
      payout_amount: 15,
      payout_percent: 10
    });
    this.addItem({
      label: 'Operations',
      payout_amount: 49.5,
      payout_percent: 33
    });
    this.recalculateTotals();
  }

  addStaff(props = {}) {
    defaults(props, { role_id: '', user_id: '', payout_percent: 0, payout_amount: 0 });
    const newRow = this.addRow(this.staff_expenses, {
      role_id: ['', Validators.required],
      user_id: ['', Validators.required]
    });
    if (props['role_id']) {
      this.roleChanged(props['role_id']);
    }
    newRow.patchValue(props, { onlySelf: true });
  }

  removeStaff(index: number) {
    this.removeRow(this.staff_expenses, index);
  }

  addItem(props = {}) {
    defaults(props, { label: '', payout_percent: 0, payout_amount: 0 });
    const newRow = this.addRow(this.item_expenses, {
      label: ['', Validators.compose([Validators.required, Validators.maxLength(25)])]
    });

    newRow.patchValue(props, { onlySelf: true });
  }

  removeItem(index: number) {
    this.removeRow(this.item_expenses, index);
  }

  private addRow(control: FormArray, properties: any = {}) {
    properties.level = control.length;
    properties.payout_percent = ['', this.numericValidator.create({ min: 0 })];
    properties.payout_amount = ['', this.numericValidator.create({ min: 0 })];
    const record = this.fb.group(properties);

    control.push(record);

    return record;
  }

  private removeRow(control: FormArray, index: number) {
    control.removeAt(index);
    control.markAsDirty();
    this.recalculateTotals();
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;

      this.isExecutive = this.permissionManager.isExecutive();
      if (this.league != null) {
        this.getVenue();
      }
    });
  }

  getVenue(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getVenue(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = new Venue(response.venue); // only used in the Name check, to allow the original name to work.
        this.loadDependentServices();
      });
    } else {
      this.loadDependentServices();
    }
  }

  private loadDependentServices() {
    const observableBatch = [
      this.roleService.getRoles({
        select: 'id,name'
      })
    ];
    if (this.isExecutive) {
      observableBatch.push(
        this.regionService.getRegions({
          select: 'id,name',
          'order_by[name]': 'asc'
        })
      );
    }
    forkJoin(observableBatch).subscribe(([rolesResponse, regionsResponse]) => {
      this.allRoles = rolesResponse.roles;
      if (regionsResponse) {
        this.regions = regionsResponse.regions;
      }

      if (!this.isNewModel) {
        this.resetForm(this.model);
      } else {
        this.addDefaultExpenses();
      }
    });
  }

  public submitAndClose(value: VenueForm) {
    this._submit(value, (model: Venue) => {
      this.router.navigate(['/admin/venues']);
    });
  }

  public submit(value: VenueForm) {
    this._submit(value, (model: Venue) => {
      this.model = new Venue(model);
      this.isNewModel = false;
      this.resetForm(this.model);
      this.router.navigate(['/admin/venues/' + this.model.id]);
    });
  }

  private resetForm(venue: Venue) {
    this.clearFormArray(this.staff_expenses);
    this.clearFormArray(this.item_expenses);
    this.myForm.reset();
    const formValue = new VenueForm(venue);

    // reset item expenses
    formValue.item_expenses.forEach(expense => {
      this.addItem();
    });

    // reset staff expenses.
    formValue.staff_expenses.forEach(expense => {
      this.addStaff();
    });

    // now set up staff expense dropdowns
    // this is harder, because the dropdowns are dynamic.
    // this part fires off requests to get users by all roles we need to retrieve,
    // joins them back together, then assigns the responses to the usersByRole array
    // for later display
    if (formValue.staff_expenses.length > 0) {
      forkJoin(
        formValue.staff_expenses.map(expense => this.retrieveUsersByRole(expense.role_id))
      ).subscribe(responses => {
        responses.forEach(
          response => (this.usersByRole[response.role_id.toString()] = response.staffs)
        );

        // finally, after doing everything, we can set all the form values
        this.myForm.setValue(formValue.omitted(), { onlySelf: true, emitEvent: true });

        this.recalculateTotals();
      });
    } else {
      // no staff, so just set the form value now
      this.myForm.setValue(formValue.omitted(), { emitEvent: true });
      this.recalculateTotals();
    }
  }

  public _submit(value: VenueForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createVenue(value)
        : this.service.updateVenue(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.venue);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  private clearFormArray(control: FormArray) {
    while (control.length !== 0) {
      control.removeAt(0);
    }
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${this.model.name}? Any data that depends on this record INCLUDING TOURNAMENTS will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteVenue(this.model.id)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/venues']);
        });
    }
  }
}
