<div class="filter-instructions-logic">
  <div class="filter-instructions-label">Dealer time entry</div>
  <div class="filter-instructions-section">
    Enter the total duration of time worked for each dealer. The entry accepts natural-language time units as well as shorthand.
  </div>
  <br/>
  <div class="filter-instructions-section">
    A number with no unit will be assumed to be in hours.
  </div>
  <br/>
  <div class="filter-instructions-section">
    Understood units are seconds (s), minutes (m), hours (h), and days (d)
  </div>
  <div class="filter-instructions-section">
    <h4>Examples:</h4>
    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "1h 30m"
      </div>
      <div class="filter-instructions-label">
        "75m"
      </div>
    </div>
    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "3 hours, 45 minutes"
      </div>
    </div>

    <div class="filter-instructions-item">
      <div class="filter-instructions-label">
        "4.5"
      </div>
      <div class="filter-instructions-content">
        4 and a half hours
      </div>
    </div>
  </div>
</div>
