<div class="privacypolicy-logic">
  <h2>Privacy Policy</h2>
  <p>Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how we
    collect, use, communicate and disclose and make use of personal information. The following outlines our privacy policy.
  </p>
  <ul>
    <li>Before or at the time of collecting personal information, we will identify the purposes for which information is being
      collected.</li>
    <li>We will collect and use personal information solely with the objective of fulfilling those purposes specified by us and
      for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.</li>
    <li>We will only retain personal information as long as necessary for the fulfillment of those purposes.</li>
    <li>We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of
      the individual concerned.</li>
    <li>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes,
      should be accurate, complete, and up-to-date.</li>
    <li>We protect personal information by the highest security safeguards against loss or theft, as well as unauthorized access,
      disclosure, copying, use or modification.</li>
  </ul>
  <br />
  <p>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality
    of users personal information is protected and maintained.</p>
  <br />
  <br />

  <h2>Security Policy</h2>

  <p>Pokerleaderboards software security team uses the latest in network, internet, and website security measures to ensure
    user data and information is safeguarded and your privacy is secure.</p>
  <br />
  <p>
    <strong>Host Security</strong>
  </p>
  <ul>
    <li>Log Filters</li>
    <li>Modern Caching models</li>
    <li>Rigorous update schedule</li>
    <li>Daily backups</li>
    <li>Fail2Ban</li>
    <li>Critical file protection</li>
  </ul>
  <br />
  <p>
    <strong>Website Security</strong>
  </p>
  <ul>
    <li>404 Monitor</li>
    <li>Redirect Monitor</li>
    <li>Login Captchas</li>
    <li>Rigorous update schedule</li>
    <li>Malicious IP tracker</li>
    <li>Daily Database backups</li>
    <li>Live Directory Compare</li>
  </ul>
  <br />
  <p>We are also prepared to block DDOS attacks, brute force logins, script injections and any other method used today to attack
    and compromise internet security. Our team also monitors industry news and keeps up to date with all the latest security
    measures being implemented by the top companies in the world.</p>
</div>
