import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of, Observable, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { League } from '../models/league';
import { LeagueStorage } from '../models/league.storage';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse } from './responses/generic.responses';
import { GetLeagueActivityResponse } from './responses/league.responses';

@Injectable()
export class LeagueActivityService extends GenericService implements PageableService {
  league: League;
  _propertySubscription: Subscription;
  constructor(
    private http: HttpClient,
    private leagueStorage: LeagueStorage,
    protected logger: NGXLogger
  ) {
    super(logger);
    this._propertySubscription = leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
    });
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getActivity(params).pipe(
      map(r => {
        return {
          data: r.activity,
          pagination: r.pagination
        };
      })
    );
  }

  public getActivity(params: any): Observable<GetLeagueActivityResponse> {
    this.logger.debug('Called getActivity');
    if (this.league == null) {
      this.logger.debug('returning default activity');
      return of(this.defaultResponse('activity'));
    }
    this.logger.debug('calling get league activity service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };

    return this.http
      .get<GetLeagueActivityResponse | any>(
        `${environment.TOURNAMENTS_API}/league/${this.league.id}/activity`,
        options
      )
      .pipe(tap(response => this.logger.debug('getActivity response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
