import { Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';

import { Report } from '../../../models/report';
import { PayrollReport } from '../../../services/responses/report.responses';

@Component({
  selector: 'app-payroll-report',
  templateUrl: './payroll-report.component.html'
})
export class PayrollReportComponent implements OnChanges {
  @Input() report: Report;
  @Input() reportData: PayrollReport;

  constructor() {}

  groupByProperty(arrOfThings, prop) {
    return _.reduce(
      arrOfThings,
      (o, value) => {
        o[value[prop]] = value;
      },
      {}
    );
  }

  getRoleAbbreviation(roleName) {
    switch (roleName) {
      case 'Tournament Director':
        return 'TD';
      case 'Office Director':
        return 'OD';
      case 'Account Manager':
        return 'AM';
      default:
        return '';
    }
  }

  ngOnChanges() {
    if (this.reportData) {
      this.reportData.leagues.forEach(league => {
        league.total_staff_payroll = 0;
        league.total_sales = 0;
        league.total_verified_sales = 0;
        league.staff_expenses_by_user = {};
        league.staff_expenses_by_role_and_user = {};
        league.staff_role_expenses = [];
        league.item_expenses_by_item = {};
        league.item_expenses = [];
        league.staff_expenses = [];
        league.Tournaments.forEach(row => {
          league.total_sales += parseFloat(row.venue_cost);
          league.total_verified_sales += /*row.venue_has_paid &&*/ row.is_paperwork_received
            ? parseFloat(row.venue_cost)
            : 0;
          row.Venue = league.venues[row.venue_id.toString()];
          row.staff_expenses = [];
          row.item_expenses = [];
          if (row.TournamentExpenses && row.TournamentExpenses.length > 0) {
            row.staff_expenses = row.TournamentExpenses.filter(expense => expense.user_id).map(
              expense => ({
                user_id: expense.user_id.toString(),
                role_id: expense.role_id.toString(),
                level: expense.level,
                payout_percent: expense.amount_percent,
                payout_amount: expense.amount_owed
              })
            );

            row.item_expenses = row.TournamentExpenses.filter(expense => !expense.user_id).map(
              expense => ({
                label: expense.label,
                payout_percent: expense.amount_percent,
                payout_amount: expense.amount_owed
              })
            );
          }

          row.staff_expenses.forEach(expense => {
            const { user_id, role_id } = expense;
            expense.User = league.users[user_id];
            if (expense.User) {
              expense.abbreviation = this.getRoleAbbreviation(this.reportData.roles[role_id].name);
              if (!league.staff_expenses_by_user[user_id]) {
                league.staff_expenses_by_user[user_id] = 0;
              }
              league.staff_expenses_by_user[user_id] += parseFloat(expense.payout_amount);
              if (!league.staff_expenses_by_role_and_user[role_id]) {
                league.staff_expenses_by_role_and_user[role_id] = {};
              }
              if (!league.staff_expenses_by_role_and_user[role_id][user_id]) {
                league.staff_expenses_by_role_and_user[role_id][user_id] = 0;
              }
              league.staff_expenses_by_role_and_user[role_id][user_id] += parseFloat(
                expense.payout_amount
              );
              league.total_staff_payroll += parseFloat(expense.payout_amount);
            }
          });
          row.staff_expenses = row.staff_expenses.filter(expense => !!expense.User);
          row.item_expenses.forEach(expense => {
            const key = expense.label;
            if (!league.item_expenses_by_item[key]) {
              league.item_expenses_by_item[key] = 0;
            }
            league.item_expenses_by_item[key] += parseFloat(expense.payout_amount);
          });

          // finally flatten the interim maps we made to arrays.
          league.staff_expenses = Object.keys(league.staff_expenses_by_user).map(userId => {
            return {
              amount: league.staff_expenses_by_user[userId],
              id: userId,
              name: league.users[userId].name
            };
          });
          league.staff_expenses.sort((a, b) => a.name.localeCompare(b.name));
          league.item_expenses = Object.keys(league.item_expenses_by_item).map(item => {
            return {
              amount: league.item_expenses_by_item[item],
              name: item
            };
          });
          league.item_expenses.sort((a, b) => a.name.localeCompare(b.name));

          league.staff_role_expenses = Object.keys(league.staff_expenses_by_role_and_user).map(
            roleId => {
              return {
                name: this.reportData.roles[roleId].name,
                users: Object.keys(league.staff_expenses_by_role_and_user[roleId])
                  .map(userId => {
                    return {
                      id: userId,
                      name: league.users[userId].name,
                      amount: league.staff_expenses_by_role_and_user[roleId][userId]
                    };
                  })
                  .sort((a, b) => a.name.localeCompare(b.name))
              };
            }
          );
        });
      });
    }
  }
}
