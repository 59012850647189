import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { empty, forkJoin, of, Observable, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { FormCanDeactivate } from '../../_guards/form-can-deactivate';
import { CompareValidator } from '../../_validators/compare.validator';
import { DateValidator } from '../../_validators/date.validator';
import { DurationValidator } from '../../_validators/duration.validator';
import { NumericValidator } from '../../_validators/numeric.validator';
import { UniqueValuesValidator } from '../../_validators/unique-values.validator';
import { AppStorage } from '../../models/app.storage';
import { Dealer } from '../../models/dealer';
import { TournamentForm } from '../../models/forms/tournament.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Payout } from '../../models/payout';
import { PermissionManager } from '../../models/permission.manager';
import { Player } from '../../models/player';
import { Role } from '../../models/role';
import { Scoring } from '../../models/scoring';
import { Season } from '../../models/season';
import { Staff } from '../../models/staff';
import { Tournament } from '../../models/tournament';
import {
  HeadsUp,
  LastChance,
  PreGame,
  SecondChance,
  Standard,
  TournamentType,
  TournamentTypesList,
  VenueChampionship
} from '../../models/tournament.type';
import { UUID } from '../../models/uuid';
import { Venue } from '../../models/venue';
import { DealerService } from '../../services/dealer.service';
import { PlayerService } from '../../services/player.service';
import {
  GetStaffsResponse,
  GetStaffWithRoleResponse
} from '../../services/responses/staff.responses';
import { RoleService } from '../../services/role.service';
import { ScoringService } from '../../services/scoring.service';
import { SeasonService } from '../../services/season.service';
import { LoaderService } from '../../services/spinner.service';
import { TournamentService } from '../../services/tournament.service';
import { UserService } from '../../services/user.service';
import { VenueService } from '../../services/venue.service';
import { formatDuration, parseDuration } from '../../util/parse.duration';
import { recalculateDefaultScoring } from '../../util/points-calculator';
import { scrollComponentToTop } from '../../util/window.functions';

@Component({
  selector: 'app-tournament-detail',
  templateUrl: './tournament-detail.component.html',
  styleUrls: ['./tournament-detail.component.css']
})
export class TournamentDetailComponent extends FormCanDeactivate implements OnInit, OnDestroy {
  oldEntries = 0;
  recapCopied = false;
  canManageExpenses = false;
  showDealerEdit: boolean[] = [];
  totalAmount = 0;
  totalPercent = 0;
  totalDealerTime = '0h';
  isNewModel = true;
  _propertySubscription: Subscription;
  @ViewChild('form') form: NgForm;
  myForm: FormGroup;
  minDate = new Date('1900-01-01');
  maxDate = new Date('9999-12-31');
  dateFormat = '';
  submitted = false;
  model = new Tournament();
  league: League;
  allRoles: Role[] = [];
  selectedVenue: Venue;
  selectedScoring: Scoring;
  venues: Venue[] = [];
  scorings: Scoring[] = [];
  allPlayers: Player[] = [];
  // filteredPlayers: Player[] = [];
  allSeasons: Season[] = [];
  usersByRole: { [key: string]: Staff[] } = {};
  existingStaff: Staff[];
  venueSeasons: Season[] = [];
  tournamentTypes: TournamentType[];
  allDealers: Dealer[] = [];
  filteredDealers: Dealer[] = [];
  reasons: string[] = ['Low Attendance', 'Excuse', 'Dispute', 'In Contract'];
  paymentMethods: string[] = ['Cash', 'Check', 'Paypal', 'Credit Card', 'Hold'];
  private _seasonsByVenue: { [key: string]: Season[] } = { '': [] };
  accountingCollapsed = true;
  placesPaid: number;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private dealerService: DealerService,
    private playerService: PlayerService,
    private roleService: RoleService,
    private scoringService: ScoringService,
    private seasonService: SeasonService,
    private service: TournamentService,
    private userService: UserService,
    private venueService: VenueService,
    private dateValidator: DateValidator,
    private durationValidator: DurationValidator,
    private numericValidator: NumericValidator,
    private compareValidator: CompareValidator,
    private uniqueValuesValidator: UniqueValuesValidator,
    private leagueStorage: LeagueStorage,
    private storage: AppStorage,
    private route: ActivatedRoute,
    private permissionManager: PermissionManager
  ) {
    super();
    this.dateFormat = environment.DATEPICKER_FORMAT;
    this.myForm = fb.group({
      id: '',
      tab_venue: fb.group(
        {
          start_date: ['', dateValidator.create()],
          venue_id: ['', Validators.required],
          season_id: ['', Validators.required],
          tournament_type: ['ST', Validators.required],
          name: ['', Validators.maxLength(255)],
          num_tables: [
            '',
            numericValidator.create({ allowNulls: true, min: 0, integerOnly: true })
          ],
          bonus_chips_1: [
            '',
            numericValidator.create({ allowNulls: true, min: 0, integerOnly: true })
          ],
          bonus_chips_2: [
            '',
            numericValidator.create({ allowNulls: true, min: 0, integerOnly: true })
          ],
          payment_type: 'Full Payment', // full vs non
          payment_method: '', // cash, check, etc
          venue_cost: [
            { value: '', disabled: !this.canManageExpenses },
            numericValidator.create({ allowNulls: true, min: 0, minInclusive: true })
          ],
          reason: '',
          notes: '',
          check_number: '',
          is_paperwork_signed: false,
          is_paperwork_received: false,
          is_collected: false,
          is_payment_deposited: false,
          is_closed: false,
          staff_expenses: fb.array([]),
          item_expenses: fb.array([])
        },
        {
          validator: Validators.compose([
            compareValidator.create('payment_method', 'check_number', 'checkExists', {
              operator: 'exists',
              value: 'check'
            }),
            compareValidator.create('payment_type', 'reason', 'reasonExists', {
              operator: 'exists',
              value: 'Non Payment'
            })
          ])
        }
      ),
      tab_leaderboard: fb.group({
        scoring_id: '',
        num_entries: [
          20,
          numericValidator.create({
            allowNulls: false,
            min: 1,
            minInclusive: true,
            integerOnly: true
          })
        ],
        tournament_players: fb.array([]),
        player_notes: '',
        exclude_from_points: false
      }),
      tab_dealers: fb.group({
        dealers: fb.array([], uniqueValuesValidator.create('name', { caseSensitive: true })),
        num_dealers: [
          '',
          numericValidator.create({
            allowNulls: true,
            min: 0,
            minInclusive: true,
            integerOnly: true
          })
        ]
      })
    });

    this.tournamentTypes = TournamentTypesList;
  }

  // handy access to form fields

  get tab_venue(): FormGroup {
    return this.myForm.get('tab_venue') as FormGroup;
  }
  get tab_leaderboard(): FormGroup {
    return this.myForm.get('tab_leaderboard') as FormGroup;
  }
  get tab_dealers(): FormGroup {
    return this.myForm.get('tab_dealers') as FormGroup;
  }
  get start_date(): FormControl {
    return this.tab_venue.get('start_date') as FormControl;
  }
  get venue_id(): FormControl {
    return this.tab_venue.get('venue_id') as FormControl;
  }
  get season_id(): FormControl {
    return this.tab_venue.get('season_id') as FormControl;
  }
  get tournament_type(): FormControl {
    return this.tab_venue.get('tournament_type') as FormControl;
  }
  get name(): any {
    return this.tab_venue.get('name');
  }
  get num_tables(): FormControl {
    return this.tab_venue.get('num_tables') as FormControl;
  }
  get bonus_chips_1(): FormControl {
    return this.tab_venue.get('bonus_chips_1') as FormControl;
  }
  get bonus_chips_2(): FormControl {
    return this.tab_venue.get('bonus_chips_2') as FormControl;
  }
  get venue_cost(): FormControl {
    return this.tab_venue.get('venue_cost') as FormControl;
  }
  get reason(): FormControl {
    return this.tab_venue.get('reason') as FormControl;
  }
  get payment_type(): FormControl {
    return this.tab_venue.get('payment_type') as FormControl;
  }
  get payment_method(): FormControl {
    return this.tab_venue.get('payment_method') as FormControl;
  }
  get check_number(): FormControl {
    return this.tab_venue.get('check_number') as FormControl;
  }
  get notes(): FormControl {
    return this.tab_venue.get('notes') as FormControl;
  }
  get is_paperwork_signed(): FormControl {
    return this.tab_venue.get('is_paperwork_signed') as FormControl;
  }
  get is_paperwork_received(): FormControl {
    return this.tab_venue.get('is_paperwork_received') as FormControl;
  }
  get is_collected(): FormControl {
    return this.tab_venue.get('is_collected') as FormControl;
  }
  get is_payment_deposited(): FormControl {
    return this.tab_venue.get('is_payment_deposited') as FormControl;
  }
  get is_closed(): FormControl {
    return this.tab_venue.get('is_closed') as FormControl;
  }
  get staff_expenses(): FormArray {
    return this.tab_venue.get('staff_expenses') as FormArray;
  }
  get item_expenses(): FormArray {
    return this.tab_venue.get('item_expenses') as FormArray;
  }
  get num_entries(): FormControl {
    return this.tab_leaderboard.get('num_entries') as FormControl;
  }
  get scoring_id(): FormControl {
    return this.tab_leaderboard.get('scoring_id') as FormControl;
  }
  get tournament_players(): FormArray {
    return this.tab_leaderboard.get('tournament_players') as FormArray;
  }
  get exclude_from_points(): FormControl {
    return this.tab_leaderboard.get('exclude_from_points') as FormControl;
  }
  get dealers(): FormArray {
    return this.tab_dealers.get('dealers') as FormArray;
  }
  get num_dealers(): FormControl {
    return this.tab_dealers.get('num_dealers') as FormControl;
  }
  get player_notes(): FormControl {
    return this.tab_leaderboard.get('player_notes') as FormControl;
  }

  selectedIndexChanged() {
    // deliberately empty
    // for some reason this needs to be wired up to the frontend control to detected when selectedIndex changes,
    // so we can change the button enabled/disabled state.
    // we don't have to actually do anything with the change event though.
  }

  numEntriesClicked() {
    this.oldEntries = this.num_entries.value;
    this.num_entries.setValue('');
  }

  durationChanged(time: FormControl, timeInMinutes: FormControl) {
    if (time.valid) {
      const value = parseDuration(time.value);
      timeInMinutes.setValue(value === 0 ? null : value);

      this.recalculateTime();
    }
  }

  amountOwedChanged() {
    this.staff_expenses.controls.forEach(staff => {
      const amountField = staff.get('payout_amount') as FormControl;
      const percentField = staff.get('payout_percent') as FormControl;
      this.percentChanged(percentField, amountField, false);
    });

    this.item_expenses.controls.forEach(staff => {
      const amountField = staff.get('payout_amount') as FormControl;
      const percentField = staff.get('payout_percent') as FormControl;
      this.percentChanged(percentField, amountField, false);
    });

    this.recalculateTotals();
  }

  percentChanged(percentField: FormControl, amountField: FormControl, fireEvent = true) {
    if (this.venue_cost.valid && percentField.valid) {
      amountField.setValue(((percentField.value / 100) * this.venue_cost.value).toFixed(2));
    }

    if (fireEvent) {
      this.recalculateTotals();
    }
  }

  amountChanged(amountField: FormControl, percentField: FormControl, fireEvent = true) {
    if (this.venue_cost.valid && amountField.valid) {
      percentField.setValue(
        ((parseFloat(amountField.value) / parseFloat(this.venue_cost.value)) * 100).toFixed(1)
      );
    }

    if (fireEvent) {
      this.recalculateTotals();
    }
  }

  onRecapCopied() {
    this.recapCopied = true;
    if (this.myForm.valid && !this.myForm.pristine) {
      // try to save
      this.submit(this.myForm.value);
    }
    setTimeout(() => (this.recapCopied = false), 1000);
  }

  recalculateTime() {
    const fn = (totals, dealer) => {
      const timeField = dealer.get('time_in_seconds') as FormControl;
      if (timeField.valid) {
        totals += parseFloat(timeField.value);
      }

      return totals;
    };

    const sum = this.dealers.controls.reduce(fn, 0);

    this.totalDealerTime = formatDuration(sum);
  }

  recalculateTotals() {
    const sum = [0, 0];

    const fn = (totals, formGroup) => {
      const amountField = formGroup.get('payout_amount') as FormControl;
      const percentField = formGroup.get('payout_percent') as FormControl;
      if (percentField.valid) {
        totals[0] += parseFloat(percentField.value) / 100;
      }
      if (amountField.valid) {
        totals[1] += parseFloat(amountField.value);
      }

      return totals;
    };

    this.staff_expenses.controls.reduce(fn, sum);
    this.item_expenses.controls.reduce(fn, sum);

    [this.totalPercent, this.totalAmount] = sum;
  }

  scoringChanged(initialSetup = true) {
    // console.log('IN SCORING CHANGED');
    let scoringId = this.scoring_id.value;
    if (!scoringId && this.scorings && this.scorings.length > 0) {
      // console.log('didnt find scoring');
      // if the default scoring system is in the list, pick that.  otherwise pick the first one.
      const scoring = this.scorings.find(x => x.league_id === null);
      if (scoring) {
        // console.log('i think we picked the default scoring system.');
        // we got the default scoring, but it's not a "real" scoring system, so ignore it.
        scoringId = scoring.id;
      } else {
        // pick the first one
        // scoringId = this.scorings[0].id;
        scoringId = '';
      }
      this.scoring_id.patchValue(scoringId);
    }
    if (scoringId) {
      // console.log('found scoring');
      this.selectedScoring = this.scorings.find(x => x.id === scoringId);
      if (this.selectedScoring.league_id) {
        // console.log('some other scoring selected', this.selectedScoring.id);
        this.setPaid(this.selectedScoring.positions_paid);
        if (!initialSetup) {
          this.tournament_players.patchValue(
            this.selectedScoring.payouts.map(payout => {
              let points = 0;
              // 0 for points, 1 for percent
              if (payout.payout_type === 0) {
                points = payout.points_awarded;
              } else {
                points = (payout.percent_points_awarded / 100) * this.selectedScoring.total_points;
              }
              return { points_awarded: points };
            })
          );
        }
      } else {
        this.setAPCDefaultScoring(initialSetup);
      }
    } else {
      // console.log('no scoring selected');
      this.selectedScoring = null;
      this.setDefaultScoring(initialSetup);
    }
  }

  setDefaultScoring(initialSetup = true) {
    // ??????
    // unhide
    // console.log('in setDefaultScoring');
    if (!this.placesPaid) {
      this.placesPaid = 10;
      this.setPaid(this.placesPaid);
    }
  }

  setAPCDefaultScoring(initialSetup = true) {
    // console.log('in setAPCDefaultScoring', initialSetup);
    if (!initialSetup || this.isNewModel) {
      // console.log('passed, calling recalc');
      // console.log(this.num_entries.value, this.tournament_type.value);
      const { numPlaces, payouts } = recalculateDefaultScoring(
        this.num_entries.value,
        this.tournament_type.value
      );

      this.setPaid(numPlaces);
      this.tournament_players.patchValue(payouts);
    } else {
      // console.log('ignoring because we\'re first setting up.');
    }
  }

  venueChanged(venueId: UUID, setExpenses = true) {
    if (venueId) {
      this.selectedVenue = this.venues.find(x => x.id === venueId);

      // reset the expenses to those given in the venue
      if (setExpenses) {
        this.resetExpenses(this.selectedVenue.staff_expenses, this.selectedVenue.item_expenses);
        this.venue_cost.setValue(this.selectedVenue.amount_owed);
      }
    }
  }

  roleChanged(roleId: any): void {
    if (!this.usersByRole[roleId] || this.usersByRole[roleId].length === 0) {
      this.retrieveUsersByRole(roleId).subscribe(
        response => (this.usersByRole[roleId] = response.staffs)
      );
    }
  }

  retrieveUsersByRole(roleId: UUID): Observable<GetStaffWithRoleResponse> {
    return this.userService.getStaffWithRole(roleId);
  }

  addStaff() {
    const newRow = this.addRow(this.staff_expenses, {
      role_id: [{ value: '', disabled: !this.canManageExpenses }, Validators.required],
      user_id: ['', Validators.required]
    });
  }

  removeStaff(index: number) {
    this.removeRow(this.staff_expenses, index);
  }

  addItem() {
    const newRow = this.addRow(this.item_expenses, {
      label: [
        { value: '', disabled: !this.canManageExpenses },
        Validators.compose([Validators.required, Validators.maxLength(25)])
      ]
    });
  }

  removeItem(index: number) {
    this.removeRow(this.item_expenses, index);
  }

  private addRow(control: FormArray, properties: any = {}) {
    properties.level = control.length;
    properties.payout_percent = [
      { value: '', disabled: !this.canManageExpenses },
      this.numericValidator.create({ min: 0 })
    ];
    properties.payout_amount = [
      { value: '', disabled: !this.canManageExpenses },
      this.numericValidator.create({ min: 0 })
    ];

    const record = this.fb.group(properties);

    control.push(record);

    return record;
  }

  private removeRow(control: FormArray, index: number) {
    control.removeAt(index);
    control.markAsDirty();
    this.recalculateTotals();
  }

  numEntriesUpdated() {
    if (this.num_entries.valid) {
      // is a scoring system selected (OTHER than the "default" manual one)
      if (this.selectedScoring) {
        // yes...is it the APC one? The APC one doesn't have a league
        if (!this.selectedScoring.league_id) {
          this.setAPCDefaultScoring(false);
        }
      }
    } else {
      if (this.oldEntries > 0) {
        this.num_entries.setValue(this.oldEntries);
        this.oldEntries = 0;
      }
    }
  }

  dealersUpdated(value: number) {
    if (this.num_dealers.valid) {
      this.setDealers(value);
    }
  }

  setPaid(desired) {
    // console.log('GOT EVENT', desired);
    this.placesPaid = desired;
    const numNow: number = this.tournament_players.length;

    if (numNow < desired) {
      // we have too few, add more until we reach the desired number
      for (let j: number = numNow; j < desired; j++) {
        const tournamentPlayer = this.fb.group({
          finish_position: [j + 1, this.numericValidator.create({ min: 1, integerOnly: true })],
          points_awarded: [0, this.numericValidator.create({ allowNulls: true })],
          name: ['', Validators.compose([Validators.maxLength(255)])]
        });

        this.tournament_players.push(tournamentPlayer);
      }
    } else if (numNow > desired) {
      // we have too many, remove from the end until we reach the desired number
      // const numToRemove = numNow - desired;
      for (let j: number = numNow; j > desired; j--) {
        this.tournament_players.removeAt(j - 1);
      }
    }
  }

  setDealers(desired) {
    const numNow: number = this.dealers.length;

    if (numNow < desired) {
      // we have too few, add more until we reach the desired number
      for (let j: number = numNow; j < desired; j++) {
        const dealer = this.fb.group({
          name: ['', Validators.required],
          time: ['', this.durationValidator.create({ allowNulls: true })],
          time_in_seconds: ''
        });

        this.dealers.push(dealer);
      }
    } else if (numNow > desired) {
      // we have too many, remove from the end until we reach the desired number
      // const numToRemove = numNow - desired;
      for (let j: number = numNow; j > desired; j--) {
        this.dealers.removeAt(j - 1);
      }
    }
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.canManageExpenses = this.permissionManager.hasPermissionInLeague(
          'manage all tournament expenses',
          this.league.id.toString()
        );
        this.getTournament();
      } else {
        this.canManageExpenses = false;
      }
    });
  }

  nameFormatter(data: any) {
    return data.name;
  }

  playerSelected(playerName: any, index) {
    // we actually selected a value, so should set player_id attribute.
    const selectedPlayers = this.tournament_players.controls.map(tp => tp.get('name').value);
  }

  public searchPlayers = keyword => {
    // const selectedPlayers = this.tournament_players.controls.map(tp => tp.get('name').value);
    return this.playerService.searchPlayers(keyword);
  }

  dealerSelected(dealerName: any, index) {
    const selectedDealers = this.dealers.controls.map(tp => tp.get('name').value);
    this.filteredDealers = this.allDealers.filter(dealer => !selectedDealers.includes(dealer.name));
  }

  getTournament(): void {
    const id: any = this.route.snapshot.paramMap.get('id');

    this.loaderService.displayLoader(true);
    let op;
    if (id !== 'new') {
      op = this.service.getTournament(<UUID>id).pipe(
        switchMap(response => {
          this.isNewModel = false;
          this.model = response.tournament; // only used in the Name check, to allow the original name to work.
          return this.loadDependentServices();
        })
      );
    } else {
      op = this.loadDependentServices();
    }

    op.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe();
  }

  private loadDependentServices() {
    return forkJoin([
      this.roleService.getRoles({
        select: 'id,name',
        'order_by[name]': 'asc',
        pageSize: 100000
      }),
      this.venueService.getVenues({
        select: 'id,name,amount_owed',
        includeExpenses: true,
        ...(this.isNewModel ? {} : { tournament_id: this.model.id }),
        'order_by[name]': 'asc',
        pageSize: 100000
      }),
      this.seasonService.getSeasons({
        select: 'id,name',
        ...(this.isNewModel ? {} : { tournament_id: this.model.id }),
        'order_by[created_at]': 'desc',
        pageSize: 100000
      }),
      this.scoringService.getScorings({
        select: 'id,name,total_points,positions_paid,league_id',
        includePayouts: true,
        ...(this.isNewModel ? {} : { tournament_id: this.model.id }),
        pageSize: 100000
      }),
      this.dealerService.getDealers({
        select: 'id,name',
        ...(this.isNewModel ? {} : { tournament_id: this.model.id }),
        pageSize: 100000
      })
    ]).pipe(
      switchMap(
        ([rolesResponse, venuesResponse, seasonsResponse, scoringsResponse, dealersResponse]) => {
          this.logger.debug(
            'received response from all services',
            rolesResponse,
            venuesResponse,
            seasonsResponse,
            scoringsResponse,
            dealersResponse
          );
          this.allRoles = rolesResponse.roles;
          this.venues = venuesResponse.venues.map(venue => new Venue(venue));
          // this.venues.sort((a: Venue, b: Venue): number => a.name.localeCompare(b.name));
          this.allSeasons = this.venueSeasons = seasonsResponse.seasons;
          // this.allSeasons.sort((a: Season, b: Season): number => a.name.localeCompare(b.name));
          this.scorings = scoringsResponse.scorings;
          this.scorings.forEach(scoring => {
            if (scoring.payouts) {
              scoring.payouts.sort(
                (a: Payout, b: Payout): number => a.finish_position - b.finish_position
              );
            }
          });

          this.allDealers = dealersResponse.dealers;
          // this.allDealers.sort((a: Dealer, b: Dealer): number => a.name.localeCompare(b.name));
          this.filteredDealers = this.allDealers;
          // this.allPlayers = playersResponse.players;
          // this.allPlayers.sort((a: Player, b: Player): number => a.name.localeCompare(b.name));
          // this.filteredPlayers = this.allPlayers;

          this.enableForm();
          if (!this.isNewModel) {
            this.resetForm(this.model);
          } else {
            // stuff to do if this is a new tourney
            // this.scoringChanged();
            if (this.venueSeasons.length > 0) {
              this.season_id.setValue(this.venueSeasons[0].id);
            }
          }
          this.scoringChanged();
          return empty();
        }
      )
    );
  }

  public scrollToTop() {
    scrollComponentToTop('.mat-sidenav-content');
  }

  public submitAndClose(value: TournamentForm) {
    this._submit(value, (model: Tournament) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/tournaments']);
    });
  }

  public submit(value: TournamentForm) {
    this._submit(value, (model: Tournament) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/tournaments/' + model.id]);
    });
  }

  private enableForm() {
    // enabling the form is needed particularly because of TDs
    // we disable the venue_cost field if they don't have canManageExpenses,
    // but at the time of form construction NO ONE has that permission.
    // so, turn it on here if they have the permission now
    // any statically constructed field (ie, not a venue expense or something) needs to
    // get enabled here.
    if (this.canManageExpenses) {
      this.venue_cost.enable();
    } else {
      this.venue_cost.disable();
    }
  }

  private resetExpenses(
    staffExpenses: {
      user_id: UUID;
      role_id: UUID;
      payout_percent: number;
      payout_amount: number;
    }[],
    itemExpenses: { label: string; payout_percent: number; payout_amount: number }[]
  ) {
    // resets all expenses to those given
    // used on form reset and when a venue is changed.
    this.clearFormArray(this.staff_expenses);
    this.clearFormArray(this.item_expenses);

    // reset item expenses
    itemExpenses.forEach(expense => {
      this.addItem();
    });

    // reset staff expenses.
    staffExpenses.forEach(expense => {
      this.addStaff();
    });

    // now set up staff expense dropdowns
    // this is harder, because the dropdowns are dynamic.
    // this part fires off requests to get users by all roles we need to retrieve,
    // joins them back together, then assigns the responses to the usersByRole array
    // for later display
    if (staffExpenses.length > 0) {
      // there's a bug here:
      // if a TD changes the user, then saves, the ONLY user that shows up is themselves,
      // because the existing staff member was...themselves.
      // really we ought to also show the default venue user, but that's a bit tough
      // ignore this corner case.
      // can be worked around by re-selecting the venue.
      const services: any[] = [
        this.userService.getMultipleStaff(
          staffExpenses.map(x => x.user_id),
          {
            select: 'id,name',
            'order_by[name]': 'asc',
            pageSize: 100000
          }
        )
      ];
      if (this.canManageExpenses) {
        services.push(...staffExpenses.map(expense => this.retrieveUsersByRole(expense.role_id)));
      } else {
        // this user does not have permission to manage others, so the only staff they will
        // see are their own user.  Put together a static response that just has their own info
        // which comes from their login token.
        const staff = new Staff(this.storage.tokenPayload.user);
        services.push(
          ...staffExpenses.map(expense => {
            const flatResponse: GetStaffWithRoleResponse = {
              role_id: expense.role_id,
              staffs: [staff]
            };
            return of(flatResponse);
          })
        );
      }

      forkJoin(services).subscribe(([existingUsersResponse, ...responses]) => {
        responses.forEach((response: GetStaffWithRoleResponse, index) => {
          // this is the userId that EXISTS right now in the expense list
          const userId = staffExpenses[index].user_id;

          // but, the staff returned didn't include this user
          if (!response.staffs.find(x => x.id === userId)) {
            // find them in the EXISTING user list, and add them to the staff list.
            const staffToAdd = (<GetStaffsResponse>existingUsersResponse).staffs.find(
              x => x.id === userId
            );
            response.staffs.push(staffToAdd);
          }
          this.usersByRole[response.role_id.toString()] = response.staffs;
        });

        // // finally, after doing everything, we can set all the form values
        this.tab_venue.patchValue({
          staff_expenses: staffExpenses,
          item_expenses: itemExpenses
        });
        this.recalculateTotals();
        this.recalculateTime();
      });
    } else {
      // // no staff, so just set the form value now
      this.tab_venue.patchValue({
        staff_expenses: staffExpenses,
        item_expenses: itemExpenses
      });
      this.recalculateTotals();
      this.recalculateTime();
    }
  }

  private resetForm(tournament: Tournament) {
    this.clearFormArray(this.tournament_players);
    this.clearFormArray(this.dealers);
    this.myForm.reset();
    const formValue = new TournamentForm(tournament);

    formValue.tournament_players.forEach(player => {
      // player.name = this.allPlayers.find(x => x.id === player.player_id).name;
      // now that we have the name, we don't need the id anymore
      delete player.player_id;
    });

    formValue.dealers.forEach(dealer => {
      // dealer.name = this.allDealers.find(x => x.id === dealer.dealer_id).name;
      // now that we have the name, we don't need the id anymore
      delete dealer.dealer_id;
    });

    this.venueChanged(formValue.venue_id, false); // don't update the expenses, we'll do that in a minute
    this.dealersUpdated(formValue.dealers.length);
    this.setPaid(formValue.tournament_players.length);
    this.resetExpenses(formValue.staff_expenses, formValue.item_expenses);
    this.myForm.patchValue(_.pick(formValue, ['id']), { emitEvent: true });

    // set the values for each tab
    [this.tab_dealers, this.tab_leaderboard, this.tab_venue].forEach(tab => {
      tab.setValue(_.pick(formValue, Object.keys(tab.controls)), { emitEvent: true });
    });
    this.scoringChanged();
  }

  public _submit(value: any, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      // k...first, we need to flatten all the tabs down to one object
      value = _.merge(value, value.tab_venue, value.tab_leaderboard, value.tab_dealers);
      value = _.omit(value, 'tab_venue', 'tab_leaderboard', 'tab_dealers');
      // test all staff expenses; if they don't have a role, assume it's the matching one from the model.

      if (!this.canManageExpenses && value.staff_expenses) {
        value.staff_expenses.forEach((expense, index) => {
          if (!expense.role_id) {
            expense.role_id = this.selectedVenue.staff_expenses[index].role_id;
          }
        });
      }

      // get only non-null tournament_players
      value.tournament_players = value.tournament_players.filter(x => x.name.trim() !== '');
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createTournament(value)
        : this.service.updateTournament(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.tournament);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  private clearFormArray(control: FormArray) {
    while (control.length !== 0) {
      control.removeAt(0);
    }
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${this.model.name}? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteTournament(this.model.id)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/tournaments']);
        });
    }
  }
}
