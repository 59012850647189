
import {filter} from 'rxjs/operators';
import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Ad } from '../../models/ad';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { UUID } from '../../models/uuid';
import { AdService } from '../../services/ad.service';

@Component({
  templateUrl: './ad-spot.component.html',
  styleUrls: ['./ad-spot.component.css'],
  selector: 'app-ad-spot'
})
export class AdSpotComponent implements OnInit, OnDestroy, OnChanges {
  @Input() location: string;
  _propertySubscription: Subscription;
  _pollSubscription: Subscription;
  @Input() leagueId = '';
  @Input() adId: UUID;
  ad: Ad;
  impressionId: UUID;
  whitelistedLeagues: string[] = ['9610710d-d435-4e82-afbe-2fd9df53dfcd'];

  constructor(
    private adService: AdService,
    protected leagueStorage: LeagueStorage,
    private router: Router
  ) {}

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
    this._pollSubscription.unsubscribe();
  }

  adClicked() {
    if (this.ad && this.impressionId) {
      this.adService.clickAd(this.impressionId);
    }
  }

  ngOnInit() {
    this.pollAd();
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      if (league) {
        this.leagueId = league.id.toString();
        this.pollAd();
      }
    });

    // change the ad anytime the user selects a new page
    this._pollSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((ev: any) => {
        this.pollAd();
      });
  }

  ngOnChanges(changed) {
    if (this.leagueId) {
      this.pollAd();
    }
  }

  pollAd() {
    const lid = this.leagueId && this.leagueId.toString();
    if (lid != null && !this.whitelistedLeagues.includes(lid)) {
      let adCall;
      if (this.adId) {
        adCall = this.adService.specificAd(this.adId, lid);
      } else {
        adCall = this.adService.random(this.location, lid);
      }
      adCall.subscribe(response => {
        if (response) {
          this.ad = response.ad;
          this.impressionId = response.impression_id;
        } else {
          this.ad = null;
          this.impressionId = null;
        }
      });
    }
  }
}
