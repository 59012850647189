import { Component } from '@angular/core';

import { TournamentType, TournamentTypesList } from '../../models/tournament.type';
import { recalculateDefaultScoring } from '../../util/points-calculator';

@Component({
  selector: 'app-points-calculator',
  templateUrl: './points-calculator.component.html'
})
export class PointsCalculatorComponent {
  tournamentTypes: TournamentType[];
  tournamentType = 'ST';
  numEntries = '10';
  numPaid: number;
  payouts: { place: number; points_awarded: number; payout_percent: number }[];

  constructor() {
    this.tournamentTypes = TournamentTypesList;
    this.setDefaultScoring();
  }

  tournamentTypeChanged() {
    this.setDefaultScoring();
  }

  numPlayersChanged(evt) {
    this.numEntries = evt;
    this.setDefaultScoring();
  }

  setDefaultScoring() {
    const { numPlaces, payouts } = recalculateDefaultScoring(this.numEntries, this.tournamentType);

    this.payouts = payouts;
    this.numPaid = numPlaces;

    // this.setPaid(numPlaces);
    // this.tournament_players.patchValue(pointsAwarded);
  }
}
