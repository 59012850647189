import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { UUID } from '../../../models/uuid';
import { LeaderboardService } from '../../../services/leaderboard.service';

@Component({
  templateUrl: './recent-winners.html',
  styleUrls: ['./recent-winners.css']
})
export class RecentWinnersLeaderboardComponent implements OnInit {
  records: any[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: LeaderboardService
  ) {}

  leagueId: string;
  venueId: string;

  public ngOnInit() {
    this.leagueId = this.route.snapshot.paramMap.get('league_id');
    this.venueId = this.route.snapshot.queryParamMap.get('v');
    const params: any = {};
    if (this.venueId) {
      params.venue_id = this.venueId;
    }
    return this.service.getRecentWinners(new UUID(this.leagueId), params).subscribe(response => {
      this.records = response.records.map(record => {
        // set the avatar url dynamically
        // if they have a random one, it comes from the assets/images dir
        // otherwise it comes from the API
        record.avatar_url = record.Player.PlayerSetting.avatar_url
          ? `/assets/images/${record.Player.PlayerSetting.avatar_url}`
          : `${environment.AVATARS_API}/p/player/${record.Player.id}/avatar`;
        return record;
      });
    });
  }
}
