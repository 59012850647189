import { FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';

interface Options {
  caseSensitive?: boolean;
}
/**
 * Validates a FormArray to only have unique values
 */
const isNil = (value: any): value is null | undefined => {
  return value === null || typeof value === 'undefined';
};

const isObject = (value: any): boolean => {
  return value && value.constructor === Object;
};

const isBlank = (value: any): boolean => {
  return (
    isNil(value) ||
    (isObject(value) && Object.keys(value).length === 0) ||
    value.toString().trim() === ''
  );
};

const isPresent = (value: any): boolean => {
  return !isBlank(value);
};

export class UniqueValuesValidator {
  constructor() {}

  public create(field: string = 'name', options?: Options): ValidatorFn {
    return (formArray: FormArray): { [s: string]: boolean } => {
      if (!(formArray instanceof FormArray)) {
        throw new Error('UniqueValuesValidator must operate on a FormArray');
      }

      if (!options) {
        options = {};
      }
      if (options.caseSensitive === undefined || options.caseSensitive === null) {
        options.caseSensitive = true;
      }

      const fields = _(formArray.controls)
        .flatMap(x => x.get(field))
        .value();
      fields.forEach(x => {
        if (x.hasError('uniqueBy')) {
          x.setErrors(null);
        }
      });
      const controls = formArray.controls.filter(formGroup =>
        isPresent(formGroup.get(field).value)
      );
      const uniqueObj = { uniqueBy: true };
      let found = false;

      if (controls.length > 1) {
        // clear existing errors
        for (let i = 0; i < controls.length; i++) {
          const formGroup = controls[i] as FormGroup;
          const val = formGroup.get(field).value;
          const mainValue = options.caseSensitive ? val.toLowerCase() : val;

          // now that we have the value, check if anyone else has this value.
          controls.forEach((group, index) => {
            if (i === index) {
              // Same group
              return;
            }

            const currControl = group.get(field);
            const currValue = options.caseSensitive
              ? currControl.value.toLowerCase()
              : currControl.value;
            let newErrors;

            // there's a match
            if (mainValue === currValue) {
              if (currControl.valid) {
                newErrors = uniqueObj;
              } else {
                newErrors = Object.assign(currControl.errors, uniqueObj);
              }

              currControl.setErrors(newErrors);
              found = true;
            }
          });
        }

        if (found) {
          // Set errors to whole formArray
          return uniqueObj;
        }
      }

      // Clean errors
      return null;
    };
  }
}
