import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { UUID } from '../models/uuid';

import { GenericService } from './generic.service';
import { PageableService } from './pageable.service';
import { GetPagedResponse } from './responses/generic.responses';
import { GetLeaderboardResponse } from './responses/leaderboard.responses';

@Injectable()
export class LeaderboardService extends GenericService implements PageableService {
  constructor(private http: HttpClient, protected logger: NGXLogger) {
    super(logger);
  }

  public getPagedData(params: any): Observable<GetPagedResponse> {
    return this.getLeaderboard(params).pipe(
      map(r => {
        return {
          data: r.records,
          pagination: r.pagination
        };
      })
    );
  }

  public getMembership(leagueId: UUID, params: any = {}): Observable<GetLeaderboardResponse | any> {
    this.logger.debug('Called getMembership');

    this.logger.debug('calling getMembership service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetLeaderboardResponse>(
        `${environment.LEADERBOARDS_API}/p/league/${leagueId}/membership`,
        options
      )
      .pipe(tap(response => this.logger.debug('getMembership response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getMainEvent(leagueId: UUID, params: any = {}): Observable<GetLeaderboardResponse | any> {
    this.logger.debug('Called getMainEvent');

    this.logger.debug('calling getMainEvent service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetLeaderboardResponse>(
        `${environment.LEADERBOARDS_API}/p/league/${leagueId}/main-event`,
        options
      )
      .pipe(tap(response => this.logger.debug('getMainEvent response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getRecentWinners(
    leagueId: UUID,
    params: any = {}
  ): Observable<GetLeaderboardResponse | any> {
    this.logger.debug('Called getRecentWinners');

    this.logger.debug('calling getRecentWinners service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetLeaderboardResponse>(
        `${environment.LEADERBOARDS_API}/p/league/${leagueId}/recent-winners`,
        options
      )
      .pipe(tap(response => this.logger.debug('getRecentWinners response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getLeaderboard(
    leagueId: UUID,
    params: any = {}
  ): Observable<GetLeaderboardResponse | any> {
    this.logger.debug('calling get leaderboard service');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<GetLeaderboardResponse>(
        `${environment.LEADERBOARDS_API}/p/league/${leagueId}/leaderboard`,
        options
      )
      .pipe(tap(response => this.logger.debug('getLeaderboard response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPlayerTournaments(playerId: UUID, params: any) {
    this.logger.debug('Called getPlayerTournaments');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({ fromObject: params })
    };
    return this.http
      .get<any>(`${environment.LEADERBOARDS_API}/p/player/${playerId}/leaderboard`, options)
      .pipe(tap(response => this.logger.debug('leaderboardService:getPlayerTournaments response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }

  public getPlayerVenueStats(leagueId: UUID, seasonId: UUID, playerId: UUID) {
    this.logger.debug('Called getPlayerTournaments');
    const options = {
      headers: this.httpOptions.headers,
      params: new HttpParams({
        fromObject: {
          season_id: seasonId.toString(),
          league_id: leagueId.toString()
        }
      })
    };
    return this.http
      .get<any>(`${environment.LEADERBOARDS_API}/p/player/${playerId}/venue-stats`, options)
      .pipe(tap(response => this.logger.debug('leaderboardService:getPlayerVenueStats response:', response)))
      .pipe(catchError(this.handleError.bind(this)));
  }
}
