<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
    </div>
  </div>
</div>
<div class="col-lg-6">
  <div class="card upgrade-card">
    <div class="card-header">Executive Account Upgrade</div>
    <div class="card-block">
      <div class="col-md-12">
        <div class="upgrade-features-logic">
          <p class="dashboard-panel-heading-sub">Executive accounts include all the free account features plus you get:
          </p>
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Ads Control</h4>
          <p>Ability to turn off Ads or post your own Ads.</p>
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Regions</h4>
          <p>Ability to have Regions for your league.</p>
          <br />
          <br />
          <h4 class="card-title leaderboard-card-title">Reports</h4>
          <p>Ability to run Payroll Reports, Tournament Reports, Ads Reports, Dealer Reports.</p>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-6">
  <div class="card upgrade-card">
    <div class="card-header">Pay with PayPal</div>
    <div class="card-block">
      <div class="col-md-12">
        <p>Click the PayPal button below to upgrade to an
          <a href="http://pokerleaguefranchise.com/poker-league-software/accounts/" target="_blank"
            ID="hlExecAccount">Executive Account</a>. PayPal and Credit Cards accepted. Thanks!<p>
            <div class="paypal_due ">
              <input [disabled]="submitted" type="image" (click)="handleUpgrade()"
                src="/assets/images/paypal-button.png" style="border: none" title="Paypal" />
            </div>
      </div>
    </div>
  </div>
</div>
<div class="col-lg-6">
  <div class="card upgrade-card">
    <div *ngIf="!settings?.trial_used">
      <div class="card-header">Free Trial Available</div>
      <div class="card-block">
        <div class="col-md-12">
          <p class="trial-content">
            Start your free trial of an Executive account to try these features in your league. No credit card required.
            <br /><br />
            Your account will revert to a free account after two weeks unless you decide to upgrade.
          </p>
          <button mat-button class="action-trial-button" (click)="handleTrial()">
            Start Your Trial
          </button>
        </div>
      </div>
    </div>


  </div>
</div>
