import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { NglModule } from 'ng-lightning/ng-lightning';
import {
  AccordionModule,
  BsDatepickerModule,
  CollapseModule,
  PaginationModule
} from 'ngx-bootstrap';

import { AuthGuard } from '../_guards/auth.guard';
import { CanDeactivateGuard } from '../_guards/can-deactivate.guard';
import { AuthInterceptor } from '../_interceptors/auth.interceptor';
import { DateValidator } from '../_validators/date.validator';
import { DurationValidator } from '../_validators/duration.validator';
import { NumericValidator } from '../_validators/numeric.validator';
import { UniqueNameValidator } from '../_validators/unique-name.validator';
import { UniqueValuesValidator } from '../_validators/unique-values.validator';
import { CustomizeTipsDialogComponent } from '../components/dialogs/customize-tips/customize-tips.dialog.component';
import { ExecTermsDialogComponent } from '../components/dialogs/exec-terms/exec-terms.dialog.component';
import { NewLeagueDialogComponent } from '../components/dialogs/new-league/new-league.dialog.component';
import { NewReportDialogComponent } from '../components/dialogs/new-report/new-report.dialog.component';
import { EditLinkTableCellComponent } from '../components/edit-link.cell.component';
import { LoginAsTableCellComponent } from '../components/login-as.cell.component';
import { Ng2TableModule } from '../components/ng2-table/ng2-table';
import { CustomModule } from '../custom.module';
import { AdminComponent } from '../layouts/admin/admin.component';
import { LeagueStorage } from '../models/league.storage';
import { PipesModule } from '../pipes.module';
import { AdService } from '../services/ad.service';
import { DealerTournamentService } from '../services/dealer-tournament.service';
import { DealerService } from '../services/dealer.service';
import { EventService } from '../services/event.service';
import { LeagueActivityService } from '../services/league-activity.service';
import { LeagueService } from '../services/league.service';
import { PaymentsService } from '../services/payments.service';
import { PlayerTournamentService } from '../services/player-tournament.service';
import { PlayerService } from '../services/player.service';
import { RegionService } from '../services/region.service';
import { ReportService } from '../services/report.service';
import { RoleService } from '../services/role.service';
import { ScoringService } from '../services/scoring.service';
import { SeasonService } from '../services/season.service';
import { TournamentService } from '../services/tournament.service';
import { UserService } from '../services/user.service';
import { VenueService } from '../services/venue.service';

import { AdDetailComponent } from './ad-detail/ad-detail.component';
import { AdminMenuComponent } from './admin-menu/admin-menu.component';
import { AdsComponent } from './ads/ads.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DealerDetailComponent } from './dealer-detail/dealer-detail.component';
import { DealersComponent } from './dealers/dealers.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { EventsComponent } from './events/events.component';
import { LeaderboardsComponent } from './leaderboards/leaderboards.component';
import { PlayerDetailComponent } from './player-detail/player-detail.component';
import { PlayerSettingComponent } from './player-profile/player-setting.component';
import { PlayersComponent } from './players/players.component';
import { RegionDetailComponent } from './region-detail/region-detail.component';
import { RegionsComponent } from './regions/regions.component';
import { AdsDetailReportComponent } from './report-detail/ads-report/ads-detail-report.component';
import { AdsReportComponent } from './report-detail/ads-report/ads-report.component';
import { DealerDetailReportComponent } from './report-detail/dealer-report/dealer-detail-report.component';
import { DealerReportComponent } from './report-detail/dealer-report/dealer-report.component';
import { PayrollDetailReportComponent } from './report-detail/payroll-report/payroll-detail-report.component';
import { PayrollReportComponent } from './report-detail/payroll-report/payroll-report.component';
import { ReportDetailComponent } from './report-detail/report-detail.component';
import { ReportHeaderComponent } from './report-detail/report-header.component';
import { TournamentReportComponent } from './report-detail/tournament-report/tournament-report.component';
import { ReportsComponent } from './reports/reports.component';
import { ScoringDetailComponent } from './scoring-detail/scoring-detail.component';
import { ScoringComponent } from './scoring/scoring.component';
import { SeasonDetailComponent } from './season-detail/season-detail.component';
import { SeasonsComponent } from './seasons/seasons.component';
import { SecureRoutingModule } from './secure-routing.module';
import { StaffDetailComponent } from './staff-detail/staff-detail.component';
import { StaffComponent } from './staff/staff.component';
import { TournamentDetailComponent } from './tournament-detail/tournament-detail.component';
import { TournamentsComponent } from './tournaments/tournaments.component';
import { UpgradeConfirmationComponent } from './upgrade-confirmation/upgrade-confirmation.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { VenueDetailComponent } from './venue-detail/venue-detail.component';
import { VenuesComponent } from './venues/venues.component';
@NgModule({
  declarations: [
    AdminComponent,
    AdminMenuComponent,
    AdsComponent,
    AdDetailComponent,
    DashboardComponent,
    DealersComponent,
    DealerDetailComponent,
    EventsComponent,
    EventDetailComponent,
    ExecTermsDialogComponent,
    CustomizeTipsDialogComponent,
    LeaderboardsComponent,
    NewLeagueDialogComponent,
    NewReportDialogComponent,
    PlayerDetailComponent,
    PlayersComponent,
    PlayerSettingComponent,
    TournamentsComponent,
    TournamentDetailComponent,
    RegionsComponent,
    RegionDetailComponent,
    ReportsComponent,
    ReportDetailComponent,
    ScoringComponent,
    ScoringDetailComponent,
    SeasonDetailComponent,
    SeasonsComponent,
    StaffComponent,
    StaffDetailComponent,
    UpgradeComponent,
    UpgradeConfirmationComponent,
    VenueDetailComponent,
    VenuesComponent,
    PayrollReportComponent,
    TournamentReportComponent,
    AdsReportComponent,
    DealerReportComponent,
    ReportHeaderComponent,
    AdsDetailReportComponent,
    DealerDetailReportComponent,
    PayrollDetailReportComponent,
    LoginAsTableCellComponent
  ],
  imports: [
    BrowserAnimationsModule,
    NglModule,
    CustomModule,
    AccordionModule,
    BrowserModule,
    BsDatepickerModule,
    CollapseModule,
    CommonModule,
    FormsModule,
    Ng2TableModule,
    NguiAutoCompleteModule,
    PaginationModule,
    PipesModule,
    ReactiveFormsModule,
    SecureRoutingModule
  ],
  providers: [
    AuthGuard,
    CanDeactivateGuard,
    AdService,
    DateValidator,
    DealerService,
    DealerTournamentService,
    DurationValidator,
    EventService,
    LeagueActivityService,
    LeagueService,
    LeagueStorage,
    NumericValidator,
    PaymentsService,
    PlayerService,
    RegionService,
    ReportService,
    RoleService,
    ScoringService,
    SeasonService,
    TournamentService,
    PlayerTournamentService,
    UniqueNameValidator,
    UniqueValuesValidator,
    UserService,
    VenueService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    ExecTermsDialogComponent,
    CustomizeTipsDialogComponent,
    NewLeagueDialogComponent,
    NewReportDialogComponent,
    LoginAsTableCellComponent
  ]
})
export class SecureModule {}
