
import {mergeMap, map, filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';




import { scrollComponentToTop } from './util/window.functions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {}
  public ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((ev: any) => {
      scrollComponentToTop('.mat-sidenav-content');
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),)
      .subscribe(event => this.titleService.setTitle(event.title));
  }
}
