import { Moment } from 'moment';

import { Role } from './role';
import { UUID } from './uuid';
export class Staff {
  constructor(init?: Partial<Staff>) {
    Object.assign(this, init);
  }
  public id: UUID;
  public first_name: string;
  public last_name: UUID;
  public email: string;
  public username: string;
  public created_at: Moment;
  public updated_at: Moment;
  public show_in_listing: boolean;
  public roles: Role[];
  get full_name() {
    return this.first_name + ' ' + this.last_name;
  }
}
