import { Ad } from '../ad';
import { UUID } from '../uuid';
export class AdForm {
  public id: UUID;
  public name: string;
  public is_active: boolean;
  public ad_text: string;
  public location: string;
  constructor(model: Ad) {
    this.id = model.id;
    this.name = model.name;
    this.is_active = model.is_active;
    this.ad_text = model.ad_text;
    this.location = model.location;
  }
}
