import { Component, Input, OnChanges } from '@angular/core';

import { Report } from '../../../models/report';
import { DealerReport } from '../../../services/responses/report.responses';

@Component({
  selector: 'app-dealer-report',
  templateUrl: './dealer-report.component.html'
})
export class DealerReportComponent implements OnChanges {
  @Input() report: Report;
  @Input() reportData: DealerReport;

  constructor() {}

  ngOnChanges() {
    if (this.reportData) {
      this.reportData.leagues.forEach(league => {
        league.total_time = 0;
        league.rows.forEach(row => {
          league.total_time += parseFloat(row.time);
        });
      });
    }
  }
}
