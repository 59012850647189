
import {finalize} from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { CompareValidator } from '../../_validators/compare.validator';
import { DateValidator } from '../../_validators/date.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';
import { MergePlayerForm } from '../../models/forms/merge-player.form';
import { PlayerForm } from '../../models/forms/player.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { Player } from '../../models/player';
import { UUID } from '../../models/uuid';
import { AlertService } from '../../services/common/alert.service';
import { PlayerTournamentService } from '../../services/player-tournament.service';
import { PlayerService } from '../../services/player.service';
import { SignupService } from '../../services/signup.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  selector: 'app-player-detail',
  templateUrl: './player-detail.component.html',
  styleUrls: ['./player-detail.component.css']
})
export class PlayerDetailComponent extends PageableComponent implements OnInit, OnDestroy {
  isNewModel = true;
  datePipe: DatePipe = new DatePipe('en-US');
  _propertySubscription: Subscription;
  myForm: FormGroup;
  mergeForm: FormGroup;
  minDate = new Date('1900-01-01');
  maxDate = new Date('9999-12-31');
  dateFormat = '';
  submitted = false;
  model = new Player();
  league: League;
  showMerge = false;

  constructor(
    private loaderService: LoaderService,
    protected leagueStorage: LeagueStorage,
    protected playerTournamentService: PlayerTournamentService,
    private router: Router,
    private fb: FormBuilder,
    public service: PlayerService,
    private dateValidator: DateValidator,
    private compareValidator: CompareValidator,
    private uniqueNameValidator: UniqueNameValidator,
    private route: ActivatedRoute,
    private signupService: SignupService,
    private alertService: AlertService
  ) {
    super(playerTournamentService, leagueStorage);
    this.dateFormat = environment.DATEPICKER_FORMAT;
    this.myForm = fb.group(
      {
        id: '',
        name: ['', Validators.required, uniqueNameValidator.create().bind(this)],
        email: ['' /*, Validators.email */], // built in email validator cannot handle optional field.
        notes: '',
        membership_type: 'VIP',
        start_date: ['', dateValidator.create({ allowNulls: true })],
        end_date: ['', dateValidator.create({ allowNulls: true })],
        is_active: true,
        is_membership: false,
        is_invited_main_event: false
      },
      {
        validator: compareValidator.create('start_date', 'end_date', 'startLTEEnd', {
          type: 'date',
          operator: 'lte',
          allowNulls: true
        })
      }
    );
    this.mergeForm = fb.group({
      id: '',
      merge_player: ['', Validators.required]
    });

    this.setColumns([
      {
        title: 'Venue',
        name: 'Tournament.Venue.name',
        filtering: false,
        component: {
          class: EditLinkTableCellComponent,
          data: {
            navigateTo: row => ['/admin', 'tournaments', row.tournament_id]
          }
        }
      },
      {
        title: 'Season',
        name: 'Tournament.Season.name',
        filtering: false
      },
      {
        title: 'Season Date',
        name: 'Tournament.Season.start_date',
        sortIndex: 1,
        filtering: false
      },
      {
        title: 'Date',
        name: 'Tournament.start_date',
        sort: 'desc',
        sortIndex: 2,
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Name',
        name: 'Tournament.name',
        filtering: false
      },
      {
        title: 'Points',
        name: 'points_awarded',
        filtering: false
      }
    ]);
  }

  // handy access to form fields
  get name(): FormControl {
    return this.myForm.get('name') as FormControl;
  }
  get email(): FormControl {
    return this.myForm.get('email') as FormControl;
  }
  get notes(): FormControl {
    return this.myForm.get('notes') as FormControl;
  }
  get membership_type(): FormControl {
    return this.myForm.get('membership_type') as FormControl;
  }
  get start_date(): FormControl {
    return this.myForm.get('start_date') as FormControl;
  }
  get end_date(): FormControl {
    return this.myForm.get('end_date') as FormControl;
  }
  get is_active(): FormControl {
    return this.myForm.get('is_active') as FormControl;
  }
  get is_membership(): FormControl {
    return this.myForm.get('is_membership') as FormControl;
  }
  get is_invited_main_event(): FormControl {
    return this.myForm.get('is_invited_main_event') as FormControl;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.getPlayer();
      }
    });
  }

  getPlayer(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getPlayer(<UUID>id).subscribe(response => {
        this.setDefaultParams({ player_id: id });
        this.isNewModel = false;
        this.model = response.player; // only used in the Name check, to allow the original name to work.
        this.resetForm(this.model);
      });
    }
  }

  public submitAndClose(value: PlayerForm) {
    this._submit(value, (model: Player) => {
      this.router.navigate(['/admin/players']);
    });
  }

  public submit(value: PlayerForm) {
    this._submit(value, (model: Player) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/players/' + model.id]);
    });
  }

  private resetForm(player: Player) {
    this.showMerge = false;
    this.mergeForm.reset();
    if (player) {
      this.mergeForm.patchValue({ id: player.id });
    }
    this.myForm.reset();
    this.myForm.setValue(new PlayerForm(player), { onlySelf: true });
  }

  public _submit(value: PlayerForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createPlayer(value)
        : this.service.updatePlayer(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response
          postAction(data.player);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  nameFormatter(data: any) {
    return data.name;
  }

  mergePlayer(value: MergePlayerForm) {
    if (value.merge_player) {
      value.merge_player_id = value.merge_player.id;
    }
    if (this.mergeForm.valid && !this.submitted) {
      if (
        confirm(
          `Are you sure you want to merge ${this.model.name} into ${value.merge_player.name}?`
        )
      ) {
        this.loaderService.displayLoader(true);
        // submit to API
        this.service
          .mergePlayer(value).pipe(
          finalize(() => this.loaderService.displayLoader(false)))
          .subscribe(
            response => {
              const oldPlayer = this.model.name;
              this.model = response.player;
              const newPlayer = this.model.name;
              this.resetForm(this.model);

              this.setDefaultParams({ player_id: this.model.id });
              this.router.navigate(['/admin/players/' + this.model.id]);
              const message = `Successfully merged ${oldPlayer} into ${newPlayer}`;
              this.alertService.success(message);
            },
            error => {
              console.error('err', error);
            }
          );
      }
    }

    return false;
  }

  onDelete() {
    if (
      confirm(
        `Are you sure you want to delete ${
          this.model.name
        }? Any data that depends on this record will be deleted.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deletePlayer(this.model.id).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/players']);
        });
    }
  }
  removePlayerAssociation() {
    if (
      confirm(
        `Are you sure you want to remove this user's player record ${
          this.model.name
        }? They will no longer be able to manage their player profile.`
      )
    ) {
      this.loaderService.displayLoader(true);
      this.signupService
        .removePlayerFromUser({
          league_id: this.model.league_id,
          user_id: this.model.user_id
        }).pipe(
        finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/players']);
        });
    }
  }
}
