import { Season } from '../season';
import { UUID } from '../uuid';
export class SeasonForm {
  public id: UUID;
  public name: string;
  public start_date: Date;
  public end_date: Date;
  public is_active: boolean;
  public exclude_from_points: boolean;
  constructor(model: Season) {
    this.id = model.id;
    this.name = model.name;
    this.start_date = model.start_date == null ? null : new Date(model.start_date.toString());
    this.end_date = model.end_date == null ? null : new Date(model.end_date.toString());

    this.is_active = model.is_active;
    this.exclude_from_points = model.exclude_from_points;
  }
}
