import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NglModule } from 'ng-lightning/ng-lightning';
import { PaginationModule } from 'ngx-bootstrap';

import { Ng2TableModule } from '../components/ng2-table/ng2-table';
import { CustomModule } from '../custom.module';
import { LoginComponent } from '../layouts/login/login.component';
import { PopupComponent } from '../layouts/popup/popup.component';
import { PipesModule } from '../pipes.module';
import { LeaderboardService } from '../services/leaderboard.service';
import { SignupService } from '../services/signup.service';

import { EventComponent } from './event/event.component';
import { ExecutiveTermsComponent } from './executive-terms/executive-terms.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyTokenComponent } from './forgot-password/verify-token.component';
import { BaseLeaderboardDirective } from './leaderboards/base-leaderboard';
import { ClassicLeaderboardComponent } from './leaderboards/classic/classic';
import { MainEventLeaderboardComponent } from './leaderboards/main-event/main-event';
import { MembershipLeaderboardComponent } from './leaderboards/membership/membership';
import { QuartzAvatarLeaderboardComponent } from './leaderboards/quartz-avatar/quartz-avatar';
import { QuartzLeaderboardComponent } from './leaderboards/quartz/quartz';
import { RecentWinnersLeaderboardComponent } from './leaderboards/recent-winners/recent-winners';
import { StatsPreviewComponent } from './player-profile/stats-preview/stats-preview';
import { GenericPlayerProfileComponent } from './player-profile/unverified/player-profile.component';
import { VerifiedPlayerProfileComponent } from './player-profile/verified/player-profile.component';
import { PlayerSignupComponent } from './player-signup/player-signup.component';
import { PointsCalculatorComponent } from './points-calculator/points-calculator.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PublicRoutingModule } from './public-routing.module';
import { SignupComponent } from './signup/signup.component';
import { StartComponent } from './start/start.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { VenueDetailComponent } from './venue-detail/venue-detail.component';
import { AdIframeComponent } from './ad-iframe/ad-iframe';

@NgModule({
  declarations: [
    LoginComponent,
    EventComponent,
    SignupComponent,
    PlayerSignupComponent,
    PrivacyComponent,
    ExecutiveTermsComponent,
    StartComponent,
    UserLoginComponent,
    ClassicLeaderboardComponent,
    QuartzAvatarLeaderboardComponent,
    QuartzLeaderboardComponent,
    BaseLeaderboardDirective,
    RecentWinnersLeaderboardComponent,
    MainEventLeaderboardComponent,
    MembershipLeaderboardComponent,
    VerifiedPlayerProfileComponent,
    GenericPlayerProfileComponent,
    StatsPreviewComponent,
    PopupComponent,
    VenueDetailComponent,
    VerifyTokenComponent,
    ForgotPasswordComponent,
    AdIframeComponent,
    PointsCalculatorComponent
  ],
  imports: [
    NglModule,
    CustomModule,
    PipesModule,
    PublicRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2TableModule,
    PaginationModule
  ],
  providers: [SignupService, LeaderboardService],
  entryComponents: []
})
export class PublicModule {}
