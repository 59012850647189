import { of, timer as observableTimer, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

import { SignupService } from '../services/signup.service';

@Injectable()
export class UniqueEmailValidator {
  constructor(public signupService: SignupService) {}
  public create(propertyName: string = 'email', options: any = {}) {
    return function(control: FormControl): Observable<ValidationErrors> {
      return observableTimer(300).pipe(
        distinctUntilChanged(),
        switchMap(() => {
          if (!this.model || control.value.trim() !== this.model[propertyName]) {
            const service = this.signupService || this.service;
            return service.checkEmailTaken(control.value.trim()).map(result => {
              const toRet = result ? { emailTaken: true } : null;

              if (options.callback) {
                options.callback(toRet);
              }

              if (options.ignoreResult) {
                return null;
              }

              return toRet;
            });
          } else {
            return of(null);
          }
        })
      );
    };
  }
}
