import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { GenericPageComponent } from '../../generic-page.component';
import { VerifyTokenForm } from '../../models/forms/verify-token.form';
import { PermissionManager } from '../../models/permission.manager';
import { AuthService } from '../../services/auth.service';
import { LoaderService } from '../../services/spinner.service';

@Component({
  templateUrl: './verify-token.component.html'
})
export class VerifyTokenComponent extends GenericPageComponent implements OnInit {
  public myForm: FormGroup;
  public loggedIn: boolean;
  public submitted = false;
  public message = '';

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private permissionManager: PermissionManager
  ) {
    super();
    const code: string = this.route.snapshot.queryParamMap.get('code');
    this.myForm = fb.group({
      reset_hash: [code, [Validators.required]],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])]
    });
  }

  get reset_hash(): FormControl {
    return this.myForm.get('reset_hash') as FormControl;
  }
  get password(): FormControl {
    return this.myForm.get('password') as FormControl;
  }

  public ngOnInit() {
    this.loaderService.loaderStatus.subscribe((val: boolean) => (this.submitted = val));
    this.loggedIn = this.authService.userLoggedIn();
    if (this.loggedIn) {
      this.permissionManager.maybeRedirect('/admin/dashboard', true);
    }
  }

  public submit(value: VerifyTokenForm): void {
    if (this.myForm.valid && !this.submitted) {
      this.loaderService.displayLoader(true);
      // submit to API
      this.authService
        .resetPassword(value)
        .pipe(
          catchError(error => {
            this.message = error.errorMessage;
            return observableThrowError(error);
          }),
          finalize(() => this.loaderService.displayLoader(false))
        )
        .subscribe(data => {
          // Page redirect when getting response
          this.message = '';
          this.permissionManager.maybeRedirect('/admin/dashboard', true);
        });
    }
  }
}
