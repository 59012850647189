import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  public bodyClasses = 'loginbg';
  constructor() {}

  public ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    const html = document.getElementsByTagName('html')[0];
    html.classList.add('login-logic');
    html.classList.add('no-js');
    // body.classList.add(this.bodyClasses);
  }

  public ngOnDestroy() {
    const body = document.getElementsByTagName('body')[0];
    const html = document.getElementsByTagName('html')[0];
    html.classList.remove('login-logic');
    html.classList.remove('no-js');
    // body.classList.remove(this.bodyClasses);
  }
}
