<!--(click)="fadeOut" -->
<!-- <a class="scrollToTop">
  <i class="fa fa-arrow-up"></i>
</a> -->

<header class="quartz-main-header">
  <a routerLink="/admin/dashboard" title="Poker League Software" class="quartz-logo">
    <img src="/assets/images/header/logo.svg" alt="Logo" />
  </a>
  <button class="quartz-nav-trigger" mat-button (click)="sidenav.toggle()"></button>
  <div class="plb-welcome-logic desktop" [matMenuTriggerFor]="plbMenu">
    <span class="loggedin-label">logged in as,</span>
    <span class="loggedin-content">{{ this.storage?.tokenPayload?.user.username }}</span>
    <i class="fas fa-chevron-down"></i>
  </div>
  <div class="user-menu-logic">
    <button mat-button class="mainMenu" [matMenuTriggerFor]="plbMenu">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #plbMenu="matMenu">
      <div class="plb-welcome-logic">
        <span class="loggedin-label">logged in as,</span>
        <span class="loggedin-content">{{ this.storage?.tokenPayload?.user.username }}</span>
      </div>
      <button *ngIf="canSeeProfile" [routerLink]="['/admin', 'profile']" mat-menu-item>
        <mat-icon>person</mat-icon> My Player Profile
      </button>
      <span *ngIf="canSeeProfile" class="menu-sep"></span>
      <button *ngIf="showDefaultScoring" onclick="window.open('/points-calculator')" mat-menu-item>
        <mat-icon>apps</mat-icon> Points Calculator
      </button>
      <span *ngIf="showDefaultScoring" class="menu-sep"></span>
      <button *ngIf="canSeeAdminLeagueListing" [routerLink]="['/system', 'leagues']" mat-menu-item>
        <mat-icon>list</mat-icon> League Listing
      </button>
      <button *ngIf="canSeeAdminAds" [routerLink]="['/system', 'ads']" mat-menu-item>
        <mat-icon>attach_money</mat-icon> System Ads
      </button>
      <button
        *ngIf="canSeeAdminSystemMessages"
        [routerLink]="['/system', 'messages']"
        mat-menu-item
      >
        <mat-icon>message</mat-icon> System Messages
      </button>
      <button *ngIf="canSeeAdminStats" [routerLink]="['/system', 'stats']" mat-menu-item>
        <mat-icon>show_chart</mat-icon> System Stats
      </button>
      <span *ngIf="canSeeAdminStats" class="menu-sep"></span>
      <mat-select
        [(ngModel)]="selectedLeague"
        [compareWith]="compareLeagues"
        placeholder="Switch league"
        class="ddlStyle leagueoption-select"
        (selectionChange)="onLeagueChanged()"
        (click)="$event.stopPropagation()"
      >
        <mat-option *ngFor="let league of leagues" [value]="league">{{ league.name }}</mat-option>
      </mat-select>
      <span class="menu-sep"></span>
      <button (click)="logout()" mat-menu-item><mat-icon>lock</mat-icon> Log Out</button>
    </mat-menu>
  </div>

  <div *ngIf="hasPreviousLogin()" class="logged-in-as-alert-logic">
    <div class="logged-in-as-alert-padding">
      <div class="logged-in-as-alert-content">
        Logged in as {{ this.storage.tokenPayload.user.username
        }}<span *ngIf="selectedLeague">, user of {{ selectedLeague.name }}</span>
        <button
          mat-raised-button
          color="primary"
          title="Return to previous login."
          class="previous-login"
          (click)="restoreUser()"
        >
          <i class="fas fa-reply"></i>
        </button>
      </div>
    </div>
  </div>
</header>
<mat-sidenav-container>
  <mat-sidenav #sidenav mode="over" [fixedInViewport]="true" fixedTopGap="45">
    <nav class="quartz-side-nav offCanvas">
      <app-admin-menu [sidenav]="sidenav"></app-admin-menu>
      <ul class="quartz-side-nav-actions">
        <li class="action-btn">
          <a
            target="_blank"
            href="http://pokerleaguefranchise.com/poker-league-software/users-guide/"
          >
            <i class="fa fa-question-circle"></i>User's Guide</a
          >
        </li>
        <li class="action-btn">
          <a target="_blank" href="http://support.fullquality.com/">
            <i class="fa fa-ticket-alt"></i> Support Ticket</a
          >
        </li>
      </ul>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <main class="quartz-main-content">
      <nav class="quartz-side-nav">
        <div class="quartz-side-nav-overflow">
          <ul>
            <app-admin-menu></app-admin-menu>
          </ul>
          <ul class="quartz-side-nav-actions">
            <li class="action-btn">
              <a
                target="_blank"
                href="https://pokerleaguefranchise.com/poker-leaderboards/introduction/"
              >
                <i class="fa fa-question-circle"></i>User Guide</a
              >
            </li>
            <li class="action-btn">
              <a target="_blank" href="http://support.fullquality.com/">
                <i class="fa fa-ticket-alt"></i> Support Ticket</a
              >
            </li>
          </ul>
        </div>
      </nav>
      <div class="content-wrapper">
        <div class="container-fluid content-logic">
          <alert></alert>
          <system-messages></system-messages>
          <span *ngIf="loading">
            <ngl-spinner size="large" type="brand" [container]="true"></ngl-spinner>
          </span>
          <div id="innerContent">
            <div class="adspot-logic">
              <app-ad-spot location="admin"></app-ad-spot>
            </div>

            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </main> </mat-sidenav-content
></mat-sidenav-container>
