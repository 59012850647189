<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/seasons">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button mat-menu-item [disabled]="isNewModel" id="btnDelete" type="submit" (click)="onDelete()">
          <mat-icon>delete</mat-icon> Remove Season
        </button>
      </mat-menu>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSaveContinue" type="submit"
        class="action-save-button" (click)="submit(myForm.value)">
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSave" type="submit" class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)">
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>
<form id="form1" [formGroup]="myForm">
  <input type="hidden" formControlName="id" />
  <div class="col-md-12">
    <div class="card">
      <div class="card-header">
        <span class="card-header-edit-label">{{ isNewModel? 'Create' : 'Edit' }} Season</span> {{name.value}}
      </div>
      <div class="card-block">
        <div class="editpanel-column left-edit col-lg-8">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="name" placeholder="Name">
              <mat-error *ngIf="name.hasError('required') && !name.pristine">
                Season Name is required
              </mat-error>
              <mat-error *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)">
                Season Name already exists
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="editpanel-column col-lg-4">
          <div class="card">
            <div class="card-header">Dates</div>
            <div class="card-block">
              <div class="switch-toggle">
                <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
              </div>
              <div class="panelrow col-md-12">
                <h3>Season Dates</h3>
              </div>
              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">

                  <input matInput formControlName="start_date" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_start_date"
                    placeholder="Start Date" (click)="picker_start_date.open()" autocomplete="off">
                  <mat-datepicker-toggle matSuffix [for]="picker_start_date"></mat-datepicker-toggle>
                  <mat-datepicker #picker_start_date></mat-datepicker>
                  <mat-error *ngIf="start_date.hasError('date') && !start_date.pristine">
                    Valid start date required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="panelrow col-md-5">
                <mat-form-field class="editor-mat-field">
                  <input matInput formControlName="end_date" [min]="minDate" [max]="maxDate" [matDatepicker]="picker_end_date"
                    placeholder="End Date" (click)="picker_end_date.open()" autocomplete="off">
                  <mat-datepicker-toggle matSuffix [for]="picker_end_date"></mat-datepicker-toggle>
                  <mat-datepicker #picker_end_date></mat-datepicker>
                  <mat-error *ngIf="end_date.hasError('date') && !end_date.pristine">
                    Valid end date required.
                  </mat-error>
                  <mat-error *ngIf="myForm.hasError('startLTEEnd') && !end_date.pristine">
                    End Date must be after Start Date.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="card-footer text-muted">
              <mat-slide-toggle formControlName="exclude_from_points">Exclude From Points</mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
