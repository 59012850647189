<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button (click)="openLeaderboard('quartz')" class="action-new-button">
        <mat-icon>note_add</mat-icon> Add New
      </button>
    </div>
  </div>
</div>
<div class="col-lg-6">
  <div class="card leaderboard-card isopen">
    <div class="card-header" (click)="toggleClassBar($event)">
      League
      <span class="paginated-style">(Paginated)</span>
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselbleague" class="leaderboard-content">
      <div class="card-block">
        <div class="col-md-12">
          <h4 class="card-title leaderboard-card-title">Embed</h4>
          <p class="card-text">
            Copy & paste this code to the web page you want the leaderboard to appear.
          </p>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="embedcollapse">Quartz Theme</span>
          <div id="collapselbleague-quartz">
            <div class="lb-embed">
              <textarea #pagedQuartz selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['quartz']
              }}</textarea>
              <button #quartzCopy mat-button [ngxClipboard]="pagedQuartz" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['quartz']" (cbOnSuccess)="textCopied('quartz')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('quartz')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
              <div class="action-leaderboardvenue">
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="selectedVenue" class="season-select" (selectionChange)="recalculateLeaderboardCodes()"
                    placeholder="All Venues">
                    <mat-option [value]="null">All Venues</mat-option>
                    <mat-option *ngFor="let venue of venues" [value]="venue">{{
                      venue.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <span class="embedcollapse">Classic Theme</span>
          <div id="collapselbleague-classic">
            <div class="lb-embed">
              <textarea #pagedClassic selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['classic']
              }}</textarea>
              <button mat-button [ngxClipboard]="pagedClassic" class="action-copyleaderboard-button copied"
                [class.copied]="copiedCss['classic']" (cbOnSuccess)="textCopied('classic')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('classic')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
              <div class="action-leaderboardvenue">
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="selectedVenue" class="season-select" (selectionChange)="recalculateLeaderboardCodes()"
                    placeholder="All Venues">
                    <mat-option [value]="null">All Venues</mat-option>
                    <mat-option *ngFor="let venue of venues" [value]="venue">{{
                      venue.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card leaderboard-card">
    <div class="card-header" (click)="toggleClassBar($event)">
      League
      <span class="paginated-style">(Paginated with Avatars)</span>
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselbleague" class="leaderboard-content">
      <div class="card-block">
        <div class="col-md-12">
          <h4 class="card-title leaderboard-card-title">Embed</h4>
          <p class="card-text">Copy & paste this code to the web page you want the leaderboard to appear.</p>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="embedcollapse">Quartz Theme</span>
          <div id="collapselbleague-quartz">
            <div class="lb-embed">
              <textarea #pagedQuartz selectOnClick readonly rows="3"
                style="width: 100%;">{{ leaderboards['quartz-avatar'] }}</textarea>
              <button #quartzCopy mat-button [ngxClipboard]="pagedQuartz" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['quartz-avatar']" (cbOnSuccess)="textCopied('quartz-avatar')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('quartz-avatar')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
              <div class="action-leaderboardvenue">
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="selectedVenue" class="season-select" (selectionChange)="recalculateLeaderboardCodes()"
                    placeholder="All Venues">
                    <mat-option [value]="null">All Venues</mat-option>
                    <mat-option *ngFor="let venue of venues" [value]="venue">{{
                        venue.name
                      }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card leaderboard-card">
    <div class="card-header" (click)="toggleClassBar($event)">
      League
      <span class="paginated-style">(Non-Paginated)</span>
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselbleague2" class="leaderboard-content">
      <div class="card-block">
        <div class="col-md-12">
          <h4 class="card-title leaderboard-card-title">Embed</h4>
          <p class="card-text">
            Copy & paste this code to the web page you want the leaderboard to appear.
          </p>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="embedcollapse">Quartz Theme</span>
          <div id="collapselbleaguenp-quartz">
            <div class="lb-embed">
              <textarea #nonpagedQuartz selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['quartz-nonpaged']
              }}</textarea>
              <button mat-button [ngxClipboard]="nonpagedQuartz" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['quartz-nonpaged']" (cbOnSuccess)="textCopied('quartz-nonpaged')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('quartz-nonpaged')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
              <div class="action-leaderboardvenue">
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="selectedVenue" class="season-select" (selectionChange)="recalculateLeaderboardCodes()"
                    placeholder="All Venues">
                    <mat-option [value]="null">All Venues</mat-option>
                    <mat-option *ngFor="let venue of venues" [value]="venue">{{
                      venue.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <span class="embedcollapse">Classic Theme</span>
          <div id="collapselbleaguenp-classic">
            <div class="lb-embed">
              <textarea #nonpagedClassic selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['classic-nonpaged']
              }}</textarea>
              <button mat-button [ngxClipboard]="nonpagedClassic" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['classic-nonpaged']" (cbOnSuccess)="textCopied('classic-nonpaged')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('classic-nonpaged')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
              <div class="action-leaderboardvenue">
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="selectedVenue" class="season-select" (selectionChange)="recalculateLeaderboardCodes()"
                    placeholder="All Venues">
                    <mat-option [value]="null">All Venues</mat-option>
                    <mat-option *ngFor="let venue of venues" [value]="venue">{{
                      venue.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card leaderboard-card">
    <div class="card-header" (click)="toggleClassBar($event)">
      League Lifetime
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselblifetime" class="leaderboard-content">
      <div class="card-block">
        <div class="col-md-12">
          <h4 class="card-title leaderboard-card-title">Embed</h4>
          <p class="card-text">
            Copy & paste this code to the web page you want the leaderboard to appear.
          </p>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="embedcollapse">Classic Theme</span>
          <div id="collapselblifetime-classic">
            <div class="lb-embed">
              <textarea #lifetime selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['lifetime']
              }}</textarea>
              <button mat-button [ngxClipboard]="lifetime" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['lifetime']" (cbOnSuccess)="textCopied('lifetime')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('lifetime')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
              <div class="action-leaderboardvenue">
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="selectedVenue" class="season-select" (selectionChange)="recalculateLeaderboardCodes()"
                    placeholder="All Venues">
                    <mat-option [value]="null">All Venues</mat-option>
                    <mat-option *ngFor="let venue of venues" [value]="venue">{{
                      venue.name
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="col-lg-6">
  <div class="card leaderboard-card">
    <div class="card-header" (click)="toggleClassBar($event)">
      Recent Winners
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselbrecentwinners" class="leaderboard-content">
      <div class="card-block">
        <div class="col-md-12">
          <h4 class="card-title leaderboard-card-title">Embed</h4>
          <p class="card-text">
            Copy & paste this code to the web page you want the leaderboard to appear.
          </p>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="embedcollapse">Classic Theme</span>
          <div id="collapselbrecentwinners-classic">
            <div class="lb-embed">
              <textarea #recentWinners selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['recent-winners']
              }}</textarea>
              <button mat-button [ngxClipboard]="recentWinners" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['recent-winners']" (cbOnSuccess)="textCopied('recent-winners')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('recent-winners')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
              <div class="action-leaderboardvenue">
                <mat-form-field floatLabel="never">
                  <mat-select [(ngModel)]="selectedVenue" class="season-select" (selectionChange)="recalculateLeaderboardCodes()"
                    placeholder="All Venues">
                    <mat-option [value]="null">All Venues</mat-option>
                    <mat-option *ngFor="let venue of venues" [value]="venue">{{
                                    venue.name
                                  }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card leaderboard-card">
    <div class="card-header" (click)="toggleClassBar($event)">
      Membership
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselbmembership" class="leaderboard-content">
      <div class="card-block">
        <div class="col-md-12">
          <h4 class="card-title leaderboard-card-title">Embed</h4>
          <p class="card-text">
            Copy & paste this code to the web page you want the leaderboard to appear.
          </p>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="embedcollapse">Classic Theme</span>
          <div id="collapselbmembership-classic">
            <div class="lb-embed">
              <textarea #membership selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['membership']
              }}</textarea>
              <button mat-button [ngxClipboard]="membership" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['membership']" (cbOnSuccess)="textCopied('membership')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('membership')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card leaderboard-card">
    <div class="card-header" (click)="toggleClassBar($event)">
      Main Event
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselbmainevent" class="leaderboard-content">
      <div class="card-block">
        <div class="col-md-12">
          <h4 class="card-title leaderboard-card-title">Embed</h4>
          <p class="card-text">
            Copy & paste this code to the web page you want the leaderboard to appear.
          </p>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          <span class="embedcollapse">Classic Theme</span>
          <div id="collapselbmainevent-classic">
            <div class="lb-embed">
              <textarea #mainEvent selectOnClick readonly rows="3" style="width: 100%;">{{
                leaderboards['main-event']
              }}</textarea>
              <button mat-button [ngxClipboard]="mainEvent" class="action-copyleaderboard-button"
                [class.copied]="copiedCss['main-event']" (cbOnSuccess)="textCopied('main-event')">
                <mat-icon>content_copy</mat-icon>
              </button>
              <button mat-button (click)="openLeaderboard('main-event')" class="action-viewleaderboard-button">
                <mat-icon>remove_red_eye</mat-icon> View Leaderboard
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="card leaderboard-card">
    <div class="card-header" (click)="toggleClassBar($event)">
      Hosted Leaderboard
      <button class="btn btn-primary text-right collapse-button" type="button"
        (click)="toggleClassBtn($event)"></button>
    </div>
    <div id="collapselbhosted" class="leaderboard-content">
      <div class="card-block">
        <button mat-button (click)="openLeaderboard('quartz')" class="action-launchhosted-button">
          <mat-icon>remove_red_eye</mat-icon> Launch Hosted Leaderboards
        </button>
      </div>
    </div>
  </div>
</div>
