<div class="dialog-newleague-logic">
  <h2 mat-dialog-title>New League</h2>
  <div class="mat-dialog-description">
    Create a new league that you will have access to.
  </div>
  <form [formGroup]="myForm">
    <mat-dialog-content>
      <mat-form-field>
        <input required matInput placeholder="League Name" formControlName="name">

        <mat-error *ngIf="name.hasError('required') && !name.pristine">
          Name is required.
        </mat-error>
        <mat-error *ngIf="name.hasError('nameTaken')">
          Name already exists.
        </mat-error>

      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button class="mat-raised-button" (click)="close()">Cancel</button>
      <button [disabled]="myForm.invalid || submitted || myForm.pristine" class="mat-raised-button" (click)="save(myForm.value)">Save</button>
    </mat-dialog-actions>
  </form>
</div>
