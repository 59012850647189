<div class="col-12">
  <div class="card leaderboard-ad">
    <div class="card-block">
      <app-ad-spot [leagueId]="leagueId" location="winners">
      </app-ad-spot>
    </div>
  </div>
  <div class="card card-leaderboard-details">
    <div class="card-header">
      Recent Winners
    </div>
    <div class="card-block recent-winners">
      <div class="players">
        <div class="player-logic" *ngFor="let record of records">
          <div class="player-avatar" title="{{record.Player.name}}'s avatar" [ngStyle]="{'background-image':'url('+record.avatar_url+')'}">
          </div>
          <div class="player-info">
            <div class="player-info-name">
              <app-player-profile-link [player]="record.Player" [season_id]="record.Tournament.season_id"></app-player-profile-link>
            </div>
            <div class="player-info-venue">
              {{record.Tournament.Venue.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="leaderboard-brand-logic">
    <div class="leaderboard-pwrby">powered by</div>
    <a class="leaderboard-footer" target="_blank" routerLink="/">
      <img alt="Logo" src="/assets/images/leaderboards/footerlogo.png" />
    </a>
  </div>
</div>
