import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { uniq } from 'lodash';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { UniqueEmailValidator } from '../../_validators/unique-email.validator';
import { UniqueNameValidator } from '../../_validators/unique-name.validator';
import { StaffForm } from '../../models/forms/staff.form';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { PermissionManager } from '../../models/permission.manager';
import { Role } from '../../models/role';
import { Staff } from '../../models/staff';
import { UUID } from '../../models/uuid';
import { AuthService } from '../../services/auth.service';
import { RoleService } from '../../services/role.service';
import { SignupService } from '../../services/signup.service';
import { LoaderService } from '../../services/spinner.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-staff-detail',
  templateUrl: './staff-detail.component.html',
  styleUrls: ['./staff-detail.component.css']
})
export class StaffDetailComponent implements OnInit, OnDestroy {
  isNewModel = true;
  _propertySubscription: Subscription;
  myForm: FormGroup;
  submitted = false;
  model = new Staff();
  league: League;
  allRoles: Role[];
  isAdmin = false;
  groups = [
    { group: 'full', description: 'Full League Access' },
    { group: 'limited', description: 'Limited League Access' },
    { group: 'none', description: 'No Access' }
  ];

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private fb: FormBuilder,
    private service: UserService,
    private roleService: RoleService,
    private signupService: SignupService,
    private uniqueNameValidator: UniqueNameValidator,
    private emailValidator: UniqueEmailValidator,
    private leagueStorage: LeagueStorage,
    private route: ActivatedRoute,
    private authService: AuthService,
    private permissionManager: PermissionManager
  ) {
    this.myForm = fb.group({
      id: '',
      username: ['', Validators.required, uniqueNameValidator.create('username').bind(this)],
      first_name: ['', Validators.required],
      last_name: '',
      password: ['', Validators.minLength(8)],
      email: [
        '',
        Validators.compose([Validators.required, Validators.email]),
        emailValidator.create('email', {}).bind(this)
      ],
      roles: fb.array([]),
      show_in_listing: [true, Validators.required]
    });

    this.isAdmin = permissionManager.isAdmin;
  }

  // handy access to form fields
  get username(): any {
    return this.myForm.get('username');
  }
  get firstName(): any {
    return this.myForm.get('first_name');
  }
  get lastName(): any {
    return this.myForm.get('last_name');
  }
  get password(): any {
    return this.myForm.get('password');
  }
  get email(): any {
    return this.myForm.get('email');
  }
  get show_in_listing(): any {
    return this.myForm.get('show_in_listing');
  }

  private setRoles() {
    const control = <FormArray>this.myForm['controls']['roles'];

    this.allRoles.forEach(role => {
      const roleGroup = this.fb.group({
        role_id: role.id,
        is_checked: false
      });

      control.push(roleGroup);
    });
  }

  loginAs() {
    if (
      confirm(
        `Are you sure you want to login as this user: ${this.model.first_name} ${this.model.last_name}?`
      )
    ) {
      this.authService
        .loginAsUser(this.model.id.toString(), this.league.id.toString())
        .subscribe(data => {
          this.permissionManager.maybeRedirect('/admin/dashboard', true);
        });
    }
  }

  ngOnDestroy() {
    this._propertySubscription.unsubscribe();
  }

  ngOnInit() {
    this._propertySubscription = this.leagueStorage.getSelectedLeague().subscribe(league => {
      this.league = league;
      if (this.league != null) {
        this.roleService.getRoles({}).subscribe(response => {
          this.allRoles = response.roles;
          this.setRoles();

          this.getStaff();
        });
      }
    });
  }

  getStaff(): void {
    const id: any = this.route.snapshot.paramMap.get('id');
    if (id !== 'new') {
      this.service.getStaff(<UUID>id).subscribe(response => {
        this.isNewModel = false;
        this.model = response.staff; // only used in the Name check, to allow the original name to work.
        this.resetForm(this.model);
      });
    }
  }

  public submitAndClose(value: StaffForm) {
    this._submit(value, (model: Staff) => {
      this.router.navigate(['/admin/staff']);
    });
  }

  public submit(value: StaffForm) {
    this._submit(value, (model: Staff) => {
      this.model = model;
      this.isNewModel = false;
      this.resetForm(model);
      this.router.navigate(['/admin/staff/' + model.id]);
    });
  }

  private resetForm(staff: Staff) {
    this.myForm.reset();
    const formValue = new StaffForm(staff);

    formValue.roles = this.allRoles.map(role => ({ role_id: role.id, is_checked: false }));
    this.myForm.setValue(formValue, { onlySelf: true, emitEvent: true });

    if (!this.isNewModel) {
      this.roleService.getUserRoles(this.model.id).subscribe(roleResponse => {
        const newRoles = this.allRoles.map(role => {
          const found = roleResponse.userRoles.find(x => x.id === role.id);
          return { role_id: role.id, is_checked: !!found };
        });
        this.myForm.patchValue({ roles: newRoles });
      });
    }
  }

  public _submit(value: StaffForm, postAction: Function): void {
    if (this.myForm.valid && !this.submitted) {
      // filter out all roles we didn't check
      value.roles = value.roles.filter(role => role.is_checked);
      this.loaderService.displayLoader(true);
      // submit to API
      const endpoint = this.isNewModel
        ? this.service.createStaff(value)
        : this.service.updateStaff(value);

      endpoint.pipe(finalize(() => this.loaderService.displayLoader(false))).subscribe(
        data => {
          // Page redirect when getting response

          postAction(data.staff);
        },
        error => {
          console.error('err', error);
        }
      );
    }
  }

  onDelete() {
    if (
      confirm(`Are you sure you want to disassociate "${this.model.first_name} ${this.model.last_name}"
     from this league? (Their login will not be deleted)`)
    ) {
      this.loaderService.displayLoader(true);
      this.service
        .deleteStaff(this.model.id)
        .pipe(finalize(() => this.loaderService.displayLoader(false)))
        .subscribe(response => {
          this.router.navigate(['/admin/staff']);
        });
    }
  }
}
