import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';
import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { EventService } from '../../services/event.service';

import { EventIframeComponent } from './event-iframe.component';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;
  datePipe: DatePipe = new DatePipe('en-US');
  showAll = false;

  constructor(
    protected leagueStorage: LeagueStorage,
    protected eventService: EventService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(eventService, leagueStorage);
    this.setColumns([
      {
        title: 'Name',
        name: 'name',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      },
      {
        title: 'Start Qualify',
        name: 'start_qualify',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'End Qualify',
        name: 'end_qualify',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'Event Date',
        name: 'event_date',
        sort: 'desc',
        filtering: false,
        render: (value, row) => {
          return this.datePipe.transform(value, environment.DATE_FORMAT);
        }
      },
      {
        title: 'IFrame Code',
        name: 'iframe',
        sort: false,
        filtering: false,
        component: EventIframeComponent
      },
      {
        title: 'View',
        name: 'view',
        sort: false,
        filtering: false,
        render: (value, row) => {
          return `<a target="_blank"><i class="fa fa-link"></i></a>`;
        }
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public showAllChanged(isChecked) {
    this.setDefaultParams(isChecked ? { show_all: true } : {});
  }

  public onCellClick(data: any): any {
    if (data.column === 'view') {
      this.router.navigate(['/event', data.row.id], { relativeTo: this.activatedRoute });
    }
  }
}
