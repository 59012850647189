import { Moment } from 'moment';

import { UUID } from './uuid';
export class Message {
  constructor(init?: Partial<Message>) {
    Object.assign(this, init);
  }
  public id: UUID;
  public title: string;
  public message: string;
  public severity: string;
  public is_dismissable: boolean;
  public is_active: boolean;
  public param_league_ids?: UUID[];
  public param_league_types?: string[];
  public param_role_ids?: UUID[];
  public expires_at?: Date;
  public last_email_sent_at?: Date;
}
