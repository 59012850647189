import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Player } from '../../../models/player';
import { Season } from '../../../models/season';
import { UUID } from '../../../models/uuid';
import { LeaderboardService } from '../../../services/leaderboard.service';
import { PlayerService } from '../../../services/player.service';
import { SeasonService } from '../../../services/season.service';
import { genericPopup } from '../../../util/window.functions';

@Component({
  templateUrl: './player-profile.component.html',
  styleUrls: ['./player-profile.component.css']
})
export class GenericPlayerProfileComponent implements OnInit {
  avgFinish = 0.0;
  avgPoints = 0.0;
  numTournaments = 0;
  player: Player;
  season: Season;
  avatar_url: string;
  tournaments: any[];
  playerId;

  constructor(
    private route: ActivatedRoute,
    private leaderboardService: LeaderboardService,
    private playerService: PlayerService,
    private seasonService: SeasonService
  ) {}

  ngOnInit() {
    this.playerId = this.route.snapshot.paramMap.get('player_id');
    let seasonId: any = this.route.snapshot.queryParamMap.get('season_id');
    seasonId = UUID.isGuid(seasonId) ? seasonId : null;
    if (this.playerId) {
      const sources = [
        this.playerService.getPublicPlayer(this.playerId, {
          includeSettings: true
        }),
        this.leaderboardService.getPlayerTournaments(this.playerId, { seasonId })
      ];

      if (seasonId) {
        sources.push(this.seasonService.getPublicSeason(seasonId));
      }
      forkJoin(sources).subscribe(([playerResponse, tournamentsResponse, seasonResponse]) => {
        this.player = playerResponse.player;

        // set the avatar url dynamically
        // if they have a random one, it comes from the assets/images dir
        // otherwise it comes from the API
        this.avatar_url = this.player.PlayerSetting.avatar_url
          ? `/assets/images/${this.player.PlayerSetting.avatar_url}`
          : `${environment.AVATARS_API}/p/player/${this.player.id}/avatar`;

        if (seasonResponse) {
          this.season = seasonResponse.season;
        }
        this.tournaments = tournamentsResponse.records;

        const sum = { place: 0, points: 0, num_tournaments: 0 };
        const fn = (totals, element) => {
          totals.place += parseInt(element.finish_position, 10);
          totals.points += parseFloat(element.points_awarded);
          totals.num_tournaments++;
          return totals;
        };
        tournamentsResponse.records.reduce(fn, sum);
        this.numTournaments = sum.num_tournaments;
        if (sum.num_tournaments > 0) {
          this.avgFinish = sum.place / sum.num_tournaments;
          this.avgPoints = sum.points / sum.num_tournaments;
        }
      });
    }
  }

  openPreview() {
    genericPopup('/stats-preview', { width: 1036, height: 817, scrollbars: false });
  }
}
