import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { League } from '../../models/league';
import { LeagueStorage } from '../../models/league.storage';
import { RegionService } from '../../services/region.service';

import { EditLinkTableCellComponent } from '../../components/edit-link.cell.component';
import { PageableComponent } from '../../components/pageable.component';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html'
})
export class RegionsComponent extends PageableComponent implements OnInit, OnDestroy {
  league: League;

  constructor(
    protected leagueStorage: LeagueStorage,
    protected regionService: RegionService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super(regionService, leagueStorage);
    this.setColumns([
      {
        title: 'Name',
        name: 'name',
        sort: 'asc',
        filtering: { filterString: '', placeholder: 'Filter by name' },
        component: EditLinkTableCellComponent
      }
    ]);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
