import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';

import { LeaderboardService } from '../../../services/leaderboard.service';
import { GetLeaderboardResponse } from '../../../services/responses/leaderboard.responses';

@Component({
  templateUrl: './main-event.html',
  styleUrls: ['./main-event.css']
})
export class MainEventLeaderboardComponent implements OnInit {
  termsSubscription: Subscription;
  private queryTerms = new Subject<any>();
  loading = false;

  public pageIndex = 0;
  public itemsPerPage = 25;
  public pageSizeOptions = [25, 50, 100, 250, 500, 1000, 2500];
  public maxSize = 5;
  public numPages = 0;
  public length = 0;
  public manualPages = [];
  public config: any = {
    paging: true
  };

  records: any[];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: LeaderboardService
  ) {}

  public ngOnInit() {
    const leagueId: any = this.route.snapshot.paramMap.get('league_id');

    this.termsSubscription = this.queryTerms
      .pipe(
        // wait 200ms after each keystroke before considering the term
        debounceTime(200),

        // ignore new term if same as previous term
        distinctUntilChanged(),

        // switch to new search observable each time the term changes
        switchMap((params: any) => {
          return this.getLeaderboard(leagueId, params);
        })
      )
      .subscribe(response => {
        this.records = response.records;
        // this.page = response.pagination.page;
        // this.numPages = response.pagination.pageCount;
        this.length = response.pagination.rowCount;
        // this.itemsPerPage = response.pagination.pageSize;

        const pagesArray = [];
        const target = Math.ceil(this.length / this.itemsPerPage);
        for (let i = 0; i < target; i++) {
          pagesArray.push(i + 1);
        }

        this.manualPages = pagesArray;
        // if the response would have some rows (length), but we didn't get anything, it
        // means we ran off the end of the list.  This can happen if we filter on a later
        // page but get less results back.
        // Back up to where we would actually get some data and try again
        if (this.records.length === 0 && this.length > 0) {
          // put us on the last page we'd get results for.
          this.pageIndex = Math.ceil(this.length / this.itemsPerPage) - 1;
          this.pageChanged();
        }
      });
    this.patchQueryParams({});
  }

  private getLeaderboard(leagueId, params): Observable<GetLeaderboardResponse> {
    this.loading = true;
    return this.service.getMainEvent(leagueId, params).pipe(finalize(() => (this.loading = false)));
  }

  manualPageChanged(newPage) {
    this.pageIndex = newPage - 1;
    this.pageChanged();
  }

  pageChanged(page: any = { pageIndex: this.pageIndex, pageSize: this.itemsPerPage }) {
    this.patchQueryParams({
      ps: page.pageSize,
      pg: page.pageIndex
    });
  }

  showAll() {
    this.patchQueryParams({ showAll: true });
  }

  patchQueryParams(values) {
    this.itemsPerPage = values.ps || this.itemsPerPage || 25;
    this.pageIndex = values.pg || 0;
    if (values.showAll === true) {
      // this.showAll = true;
      this.pageIndex = 0;
      this.itemsPerPage = 9999999;
    }

    const params: any = {
      pageSize: this.itemsPerPage,
      page: this.pageIndex + 1
    };

    this.queryTerms.next(params);
  }
}
