import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNo' })
export class YesNoPipe implements PipeTransform {
  transform(value: any) {
    return value
      ? '<i class="table-yes fas fa-check"></i>'
      : '<i class="table-no fas fa-times"></i>';
  }
}
