import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  Output,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';

import { TableCellComponent } from './table-cell.component';

@Component({
  selector: 'ng-table',
  template: `

    <table class="table dataTable" ngClass="{{config.className || ''}}"
           role="grid" style="width: 100%;">
      <thead>
        <tr role="row">
          <th *ngFor="let column of columns" [ngTableSorting]="config" [column]="column"
              (sortChanged)="onChangeSort($event)" ngClass="{{column.className || ''}}">
            {{column.title}}
            <i *ngIf="config && column.sort" class="pull-right fa"
              [ngClass]="{'fa-chevron-down': column.sort === 'desc', 'fa-chevron-up': column.sort === 'asc'}"></i>
          </th>
        </tr>
      </thead>
      <tbody>
      <tr *ngIf="showFilterRow" class="filter-row">
        <td *ngFor="let column of columns">
          <div *ngIf="column.filtering">
            <input *ngIf="!column.filtering.boolean" placeholder="{{column.filtering.placeholder}}"
                 [ngTableFiltering]="column.filtering"
                 class="form-control"
                 style="width: auto;"
                 (tableChanged)="onChangeFilter(config)"/>

            <mat-checkbox
                 *ngIf="column.filtering.boolean"
                 [indeterminate]="column.filtering.isIndeterminate"
                 [checked]="column.filtering.isChecked"
                 [ngTableFiltering]="column.filtering"
                 (tableChanged)="onChangeFilter(config)">Filter</mat-checkbox>
            </div>

        </td>
      </tr>
        <tr *ngFor="let row of rows">
          <td (click)="cellClick(row, column.name)" *ngFor="let column of columns">
            <div #container></div>
          </td>
        </tr>
      </tbody>
    </table>
  `
})
export class NgTableComponent implements AfterViewInit {
  // Table values
  @Input() public rows: Array<any> = [];
  entry: ViewContainerRef;
  @ViewChildren('container', { read: ViewContainerRef })
  _vcr: QueryList<ViewContainerRef>;

  componentFactories: { [key: string]: ComponentFactory<TableCellComponent> } = {};

  @Input()
  public set config(conf: any) {
    if (!conf.className) {
      conf.className = 'table-striped table-bordered';
    }
    if (conf.className instanceof Array) {
      conf.className = conf.className.join(' ');
    }
    this._config = conf;
  }

  sortIndex = 0;

  // Outputs (Events)
  @Output() public tableChanged: EventEmitter<any> = new EventEmitter();
  @Output() public cellClicked: EventEmitter<any> = new EventEmitter();

  public showFilterRow: Boolean = false;

  @Input()
  public set columns(values: Array<any>) {
    values.forEach((value: any) => {
      if (value.filtering) {
        this.showFilterRow = true;
      }
      if (value.className && value.className instanceof Array) {
        value.className = value.className.join(' ');
      }
      const column = this._columns.find((col: any) => col.name === value.name);
      if (column) {
        Object.assign(column, value);
      }
      if (!column) {
        this._columns.push(value);
      }
    });
  }

  private _columns: Array<any> = [];
  private _config: any = {};

  public constructor(
    private sanitizer: DomSanitizer,
    private resolver: ComponentFactoryResolver,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this._vcr.changes.subscribe(() => {
      const arr = this._vcr.toArray();
      _.chunk(arr, this.columns.length).forEach(chunk => {
        chunk.forEach((child, index) => {
          const row = child._view.parent.context['$implicit'];

          const column = this.columns[index];

          if (column) {
            const component = column.component || TableCellComponent;
            const data = component.data || {};

            this.createComponent(child, component.class || component, row, column.name, data);
          }
        });
      });

      // notify we made changes
      this.cdRef.detectChanges();
    });
  }

  public sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  createComponent(
    viewRef: ViewContainerRef,
    component: any,
    row: any,
    propertyName: string,
    extraData: any = {}
  ) {
    viewRef.clear();
    const className = component.componentName;
    if (!this.componentFactories[className]) {
      const newFactory = this.resolver.resolveComponentFactory(component as Type<
        TableCellComponent
      >);
      this.componentFactories[className] = newFactory;
    }

    const factory = this.componentFactories[className];
    const componentRef = viewRef.createComponent(factory);
    const renderValue = this.getValue(row, propertyName);

    componentRef.instance.row = row;
    componentRef.instance.renderValue = renderValue;
    componentRef.instance.data = extraData;
    componentRef.instance.onChanges();
  }

  public get columns(): Array<any> {
    return this._columns;
  }

  public get config(): any {
    return this._config;
  }

  public get configColumns(): any {
    const sortColumns: Array<any> = [];

    this.columns.forEach((column: any) => {
      if (column.sort) {
        sortColumns.push(column);
      }
    });

    return { columns: sortColumns };
  }

  public onChangeFilter(): void {
    this.tableChanged.emit({ sorting: this.configColumns });
  }

  public onChangeSort(data: any): void {
    if (data.shiftKey) {
      this.sortIndex++;
    } else {
      this.sortIndex = 0;
      this._columns.forEach((col: any) => {
        if (col.name !== data.column.name && col.sort !== false) {
          col.sort = '';
        }
      });
    }
    data.column.sortIndex = this.sortIndex;
    this.tableChanged.emit({ sorting: this.configColumns });
  }

  public getValue(row: any, propertyName: string): string | SafeHtml {
    // console.log({ row, propertyName });
    const splitProps = propertyName.split('.');

    // find the first not-null value.
    // split props could be like: [Tournament, name]
    // try to look at the full obj, but if it doesn't exist
    // (could be that we are searching in a Tournament itself so the Tournament property won't exist)
    // then try to remove the first element then try again.
    let value;
    while (value === undefined) {
      value = _.get(row, splitProps);
      // console.log('attempt to get value with props', splitProps, value);
      if (value === null || value === undefined) {
        splitProps.shift();
        if (splitProps.length === 0) {
          value = null;
        }
      }
    }

    const column = this._columns.find((col: any) => col.name === propertyName);
    if (column.defaultValue && !value) {
      // if func, get the callback value, otherwise it's a static value
      if (typeof column.defaultValue === 'function') {
        value = column.defaultValue(column, row);
      } else {
        value = column.defaultValue;
      }
    }

    if (column.render) {
      return column.render(value, row);
    } else {
      return this.sanitize(value);
    }
  }

  public cellClick(row: any, column: any): void {
    this.cellClicked.emit({ row, column });
  }
}
