import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
interface Options {
  caseInsensitive?: boolean;
  operator?: string;
  type?: string;
  allowNulls?: boolean;
  value?: any;
}
@Injectable()
export class CompareValidator {
  constructor() {}
  public create(
    propertyA: string,
    propertyB: string,
    invalidPropertyName: string,
    options?: Options
  ): ValidatorFn {
    if (!options) {
      options = {};
    }
    if (options.caseInsensitive === undefined || options.caseInsensitive === null) {
      options.caseInsensitive = false;
    }
    if (!options.operator) {
      options.operator = 'lte';
    }
    if (!options.type) {
      options.type = 'string';
    }
    if (options.allowNulls === undefined || options.allowNulls === null) {
      options.allowNulls = false;
    }

    return (group: FormGroup): { [s: string]: boolean } => {
      let a: any = group.value[propertyA];
      let b: any = group.value[propertyB];

      if (options.allowNulls && (!a || !b)) {
        return null;
      }

      if (options.type === 'string' && options.caseInsensitive) {
        a = a.toLowerCase().trim();
        b = b.toLowerCase().trim();
      }

      if (options.type === 'number') {
        a = parseFloat(a);
        b = parseFloat(b);
      }

      if (options.type === 'date') {
        a = moment(a);
        b = moment(b);
      }

      let valid = false;

      switch (options.operator) {
        case 'exists':
          // the second property must be specified if the first one is.
          // if value is specified, this check only happens if propertyA.value == value
          valid = true;
          if (!options.value || (options.value && a === options.value)) {
            valid = !(a || b) || (a && b);
          }
          break;
        case 'oneOf':
          // at least one value must be specified
          valid = a || b;
          break;
        case 'lt':
          valid = options.type === 'date' ? a.isBefore(b) : a < b;
          break;
        case 'lte':
          valid = options.type === 'date' ? a.isSameOrBefore(b) : a <= b;
          break;
        case 'gt':
          valid = options.type === 'date' ? a.isAfter(b) : a > b;
          break;
        case 'gte':
          valid = options.type === 'date' ? a.isSameOrAfter(b) : a >= b;
          break;
        case 'eq':
          valid = options.type === 'date' ? a.isSame(b) : a === b;
      }

      if (!valid) {
        const ret = {};
        ret[invalidPropertyName] = true;
        return ret;
      }
    };
  }
}
