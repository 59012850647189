<div class="secondmenulogic">
  <div class="secondmenuwrap">
    <div class="secondmenupad">
      <button mat-button class="action-menu-button" [matMenuTriggerFor]="actionmenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionmenu="matMenu">
        <button id="btnCancel" type="submit" mat-menu-item routerLink="/admin/dealers">
          <mat-icon>close</mat-icon> Cancel and Close
        </button>
        <span class="menu-sep"></span>
        <button id="btnMerge" [disabled]="isNewModel" type="submit" (click)="showMerge = true" mat-menu-item>
          <mat-icon>merge_type</mat-icon> Merge Dealer
        </button>
        <span class="menu-sep"></span>
        <button mat-menu-item [disabled]="isNewModel" id="btnDelete" type="submit" (click)="onDelete()">
          <mat-icon>delete</mat-icon> Remove Dealer
        </button>
      </mat-menu>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSaveContinue" type="submit"
        class="action-save-button" (click)="submit(myForm.value)">
        <mat-icon>cloud_done</mat-icon> Save
      </button>
      <button mat-button [disabled]="myForm.invalid || submitted || myForm.pristine" id="btnSave" type="submit" class="action-saveclose-button"
        (click)="submitAndClose(myForm.value)">
        <mat-icon>exit_to_app</mat-icon> Save & Exit
      </button>
    </div>
  </div>
</div>
<div class="col-md-12">
  <div *ngIf="showMerge" style="width:100%;">
    <form [formGroup]="mergeForm">
      <input type="hidden" formControlName="id" />
      <div class="card merge-card show-overflow">
        <div class="card-header">
          Merge Dealer
        </div>
        <div class="card-block">
          <div class="col-md-12">
            Select dealer to receive the timecard:
          </div>
          <br>
          <div class="col-md-8">
            <div class="panelrow">
              <input auto-complete [accept-user-input]="false" [source]="allDealers" [list-formatter]="nameFormatter"
                [match-formatted]="true" display-property-name="name" css="tournament-players-name" max-num-list="15"
                style="width:80%" formControlName="merge_dealer">
            </div>
          </div>
        </div>
        <div class="card-footer editor-footer text-muted">
          <button mat-raised-button color="primary" class="gridview-editor-btn" [disabled]="mergeForm.invalid || submitted"
            (click)="mergeDealer(mergeForm.value)">
            <mat-icon>merge_type</mat-icon> Merge Dealer
          </button>
        </div>
      </div>
    </form>
  </div>
  <form [formGroup]="myForm">
    <input type="hidden" formControlName="id" />
    <!-- eventually expose this as a user-selectable value -->
    <input type="hidden" formControlName="is_active" value="1">
    <div class="card ">
      <div class="card-header">
        <span class="card-header-edit-label">{{ isNewModel? 'Create' : 'Edit' }} Dealer</span> {{name.value}}</div>
      <div class="card-block">
        <div class="editpanel-column left-edit col-lg-8">
          <div class="panelrow col-md-12">
            <mat-form-field class="editor-mat-field">
              <input required matInput formControlName="name" placeholder="Name">
              <mat-error *ngIf="name.hasError('required') && !name.pristine">
                Name is required
              </mat-error>
              <mat-error *ngIf="name.hasError('nameTaken') && (isNewModel || name.value != model.name)">
                Name already exists
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="editpanel-column col-lg-4">
          <div class="card">
            <div class="card-header">Dealer Settings</div>
            <div class="card-block">
              <div class="switch-toggle">
                <mat-slide-toggle formControlName="is_active">Active</mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="col-lg-12" *ngIf="!isNewModel">
  <div class="card">
    <div class="card-header">Activity</div>
    <div class="card-block card-admin">
      <div class="gridview-logic grid-dealeractivity">
        <div class="gridview-padding">
          <ng-table [config]="config" (tableChanged)="onChangeTable(config)" [rows]="rows" [columns]="columns">
          </ng-table>
        </div>
      </div>
    </div>
    <div *ngIf="config.paging" class="card-footer page-footer">
      <div class="gridview-paginator-logic">
        <div class="mat-paginator-page-goto">
          <div class="mat-paginator-page-goto-label">Page:</div>
          <mat-form-field>
            <mat-select [ngModel]="pageIndex+1" (ngModelChange)="manualPageChanged($event)">
              <mat-option *ngFor="let mp of manualPages" [value]="mp">{{mp}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <mat-paginator [length]="length" [pageSize]="itemsPerPage" [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageIndex" (page)="onChangeTable(config, $event)" (numPages)="numPages = $event">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
